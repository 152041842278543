import React from 'react';
import { formatPrice } from '../../../utils/services';
import { Income, Payout } from "../../../components/icons";
import './styles.css';

const ManagementSummary = ({ data }) => {
  const { membershipIncome = 0, sponsorshipIncome = 0, transactionFees = 0, referralFeePayouts = 0 } = data;
  return (
    <div className="management-summary">
      <div className="item-summary">
        <span className="summary-title"><Income color="#12A222"/> Membership Income</span>
        <span className="summary-price">{formatPrice(membershipIncome)}</span>
      </div>
      <div className="item-summary">
        <span className="summary-title"><Income color="#12A222" /> Sponsorship Income</span>
        <span className="summary-price">{formatPrice(sponsorshipIncome)}</span>
      </div>
      <div className="item-summary">
        <span className="summary-title"><Income color="#12A222" /> Transaction Fees</span>
        <span className="summary-price">{formatPrice(transactionFees)}</span>
      </div>
      <div className="item-summary">
        <span className="summary-title"><Payout color="#EA4949" /> Referral Fee Payouts</span>
        <span className="summary-price">{`$${referralFeePayouts.toFixed(2)}`}</span>
      </div>
    </div>
  );
};

export default ManagementSummary;
