import React from 'react';
import lupa from '../../../assets/img/lupa.png';
import './styles.css';

export const Search = ({ placeholder, handleChange, showIcon = true }) => {
  return (
    <div className="search-component">
      {showIcon &&
        <div className="icon-lupa">
          <img className="icon" src={lupa} alt="Lupa Icon" />
        </div>
      }
      <input
        className="search-input"
        type="search"
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};
