import React, { useEffect, useState } from 'react';
import { Card, Switch, Button, ModalMessage } from '../../components/OMB';
import { PurchaseAreasList, PurchaseAreasModal } from '../';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { UploaderFile } from '../../components/OMB/UploaderFile';
import { postApi, SAVE_OFFERS, UPLOAD_IMAGE, GET_UTILS, getApi } from '../../utils/services';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import './styles.css';
import { Spinner } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  otherName: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  address: Yup.string().required('Required'),
  suite: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zipCode: Yup.string().required('Required'),
  funding: Yup.string().required('Required'),
  experienceLevel: Yup.string().required('Required'),
});

const AddBuyerProfileForm = (props) => {
  const { id: profileId } = useParams();
  const userToken = localStorage.getItem('tokenOMB');
  const {
    roleActive,
    info: { id: userId },
  } = useSelector((store) => store.user);
  const [isEntity, setIsEntity] = useState(true);
  const [articlesOfIncorporationFile, setArticlesOfIncorporationFile] = useState(null);
  const [operatingAgreementFile, setOperatingAgreementFile] = useState(null);
  const [proofOfFundsFile, setProofOfFundsFile] = useState(null);
  const [preApprovalLetterFile, setPreApprovalLetterFile] = useState(null);
  const [purchaseAreaList, setPurchaseAreaList] = useState([]);
  const [purchaseAreaListNew, setPurchaseAreaListNew] = useState([]);
  const [states, setStates] = useState([]);
  const [fundingList, setFundingList] = useState([]);
  const [experienceLevelList, setExperienceLevelList] = useState([]);
  const [showCheck, setShowCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const callEndPointUtils = () => {
    getApi(GET_UTILS, userToken)
      .then((response) => {
        const { funding, experienceLevel, estate } = response.data;
        setStates(estate);
        setFundingList(funding);
        setExperienceLevelList(experienceLevel);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndPointUtils();
  }, []);

  useEffect(() => {
    const array = []
    if(!!purchaseAreaList){
      purchaseAreaList.map((area) => {delete area.id; array.push(area)})
    }
    setPurchaseAreaListNew(array);
  }, [purchaseAreaList])

  /* useEffect(() => {
    console.log(purchaseAreaListNew)
  }, [purchaseAreaListNew]) */

  const handleAddArea = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleModalSubmit = () => {
    setShowModal(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const profileEntity = {
      entityName: values.name,
      signerName: values.otherName,
      file: {
        proofsFunds: proofOfFundsFile,
        preApproval: preApprovalLetterFile,
        articleIncorporation: articlesOfIncorporationFile,
        operationAgreement: operatingAgreementFile,
      },
    };

    const profilePersonal = {
      name: values.name,
      lastName: values.otherName,
      file: {
        proofsFunds: proofOfFundsFile,
        preApproval: preApprovalLetterFile,
      },
    };

    const commonData = {
      statusOffer: {
        id: 1,
      },
      user: {
        id: !!profileId ? profileId : userId,
      },
      buyerType: {
        id: isEntity ? 1 : 2,
      },
      phone: values.phone,
      email: values.email,
      address: {
        street: values.address,
        city: values.city,
        zipCode: values.zipCode,
        status: true,
        unit: values.suite,
        latitude: 0,
        longitude: 0,
        estate: {
          id: parseInt(values.state),
        },
      },
      funding: {
        id: parseInt(values.funding),
      },
      experienceLevel: {
        id: parseInt(values.experienceLevel),
      },
      purchaseArea: purchaseAreaListNew,
    };

    const dataForm = isEntity
      ? { ...profileEntity, ...commonData }
      : { ...profilePersonal, ...commonData };

    postApi(SAVE_OFFERS, dataForm, userToken)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setShowCheck(true);
          setTimeout(() => {
            history.push(`/${roleActive}/buyer-profiles`);
          }, 8000);
        } else {
          setShowError(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const initValues = {
    name: '',
    otherName: '',
    phone: '',
    email: '',
    address: '',
    suite: '',
    city: '',
    state: '',
    zipCode: '',
    funding: '',
    experienceLevel: '',
  };

  return (
    <>
      <div className="property-offer-card">
        <Formik
          initialValues={initValues}
          validationSchema={FormSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched }) => (
            <Form>
              <Card title="Add New Buyer Profile">
                <Switch
                  titleOne="Entity"
                  titleTwo="Personal name"
                  itemActive={isEntity}
                  setItemActive={setIsEntity}
                />
                <>
                  <div className="form-row" style={{ maxWidth: 80 + '%' }}>
                    <div className="form-group">
                      <label className="form-label">
                        {isEntity ? 'Entity Name' : 'First Name'}
                      </label>
                      <Field className="form-control" type="text" name="name" />
                      {errors.name && touched.name && <div className="error">{errors.name}</div>}
                    </div>
                    <div className="form-group">
                      <label className="form-label">
                        {isEntity ? 'Authorized Signer Full Name' : 'Last Name'}
                      </label>
                      <Field className="form-control" type="text" name="otherName" />
                      {errors.otherName && touched.otherName && (
                        <div className="error">{errors.otherName}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Phone Number</label>
                      <Field
                              name="phone"
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={phoneNumberMask}
                                  id="phone"
                                  type="text"
                                  className={`form-control`}
                                />
                              )}
                            />
                      {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-sm-6 col-md-3">
                      <label className="form-label">Email</label>
                      <Field className="form-control" type="text" name="email" />
                      {errors.email && touched.email && <div className="error">{errors.email}</div>}
                    </div>
                  </div>
                  <div className="form-row form-addres-profile">
                    <div className="form-group">
                      <label className="form-label">Address</label>
                      <Field className="form-control" type="text" name="address" />
                      {errors.address && touched.address && (
                        <div className="error">{errors.address}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Suite / Apt</label>
                      <Field className="form-control" type="text" name="suite" />
                      {errors.suite && touched.suite && <div className="error">{errors.suite}</div>}
                    </div>
                    <div className="form-group">
                      <label className="form-label">City</label>
                      <Field className="form-control" type="text" name="city" />
                      {errors.city && touched.city && <div className="error">{errors.city}</div>}
                    </div>
                    <div className="form-group">
                      <label className="form-label">State</label>
                      <Field className="form-control" as="select" name="state">
                        <option value={''}>Select</option>
                        {states.map((item) => (
                          <option value={item.id} key={`${item.name}-${item.id}`}>
                            {item.code}
                          </option>
                        ))}
                      </Field>
                      {errors.state && touched.state && <div className="error">{errors.state}</div>}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Zip Code</label>
                      <Field className="form-control" type="text" name="zipCode" />
                      {errors.zipCode && touched.zipCode && (
                        <div className="error">{errors.zipCode}</div>
                      )}
                    </div>
                  </div>
                </>
              </Card>

              <Card title="Payment Information">
                <div className="form-row">
                  <div className="form-group" style={{ maxWidth: 250 + 'px' }}>
                    <label className="form-label">Funding</label>
                    <Field className="form-control" as="select" name="funding">
                      <option value={''}>Select</option>
                      {fundingList.map((item) => (
                        <option value={item.id} key={`${item.name}-${item.id}`}>
                          {item.name}
                        </option>
                      ))}
                    </Field>
                    {errors.funding && touched.funding && (
                      <div className="error">{errors.funding}</div>
                    )}
                  </div>
                </div>
                {isEntity && (
                  <div className="form-row cols-2">
                    <div className="form-group">
                      <UploaderFile
                        titleLabel="Articles of Incorporation"
                        urlApi={UPLOAD_IMAGE}
                        userToken={userToken}
                        setRoute={setArticlesOfIncorporationFile}
                        typeFile="licenses"
                        setLoading={setLoading}
                      />
                    </div>
                    <div className="form-group">
                      <UploaderFile
                        titleLabel="Operating Agreement"
                        urlApi={UPLOAD_IMAGE}
                        Operating
                        Agreement
                        userToken={userToken}
                        setRoute={setOperatingAgreementFile}
                        typeFile="licenses"
                        setLoading={setLoading}
                      />
                    </div>
                  </div>
                )}
                <div className="form-row cols-2">
                  <div className="form-group">
                    <UploaderFile
                      titleLabel="Proof of Funds"
                      urlApi={UPLOAD_IMAGE}
                      userToken={userToken}
                      setRoute={setProofOfFundsFile}
                      typeFile="licenses"
                      setLoading={setLoading}
                    />
                  </div>
                  <div className="form-group">
                    <UploaderFile
                      titleLabel="Pre-Approval Letter (Non-Cash Offers)"
                      urlApi={UPLOAD_IMAGE}
                      userToken={userToken}
                      setRoute={setPreApprovalLetterFile}
                      typeFile="licenses"
                      setLoading={setLoading}
                    />
                  </div>
                </div>
              </Card>

              <Card title="Purchase Areas" btnTitle="Add New Area" btnHandle={handleAddArea}>
                <PurchaseAreasList areas={purchaseAreaList} />

                <div className="form-row">
                  <div className="form-group" style={{ maxWidth: 300 + 'px' }}>
                    <label className="form-label">Buyer Experience Level</label>
                    <Field className="form-control" as="select" name="experienceLevel">
                      <option value={''}>Select</option>
                      {experienceLevelList.map((item) => (
                        <option value={item.id} key={`${item.name}-${item.id}`}>
                          {item.name} {item.description}
                        </option>
                      ))}
                    </Field>
                    {errors.experienceLevel && touched.experienceLevel && (
                      <div className="error">{errors.experienceLevel}</div>
                    )}
                  </div>
                </div>
              </Card>

              <div className="btn-container">
                <Button color="yellow" type="submit">
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  ) : null}
                  SUBMIT
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {showCheck && (
          <ModalMessage
            title="Buyer Profile Saved"
            message="The Buyer Profile has been saved successfully."
            btnTitle="Return to Buyer Profiles"
            redirect="true"
          />
        )}
        {showError && (
          <ModalMessage
            title="Error Saving The Buyer Profile"
            message="The Buyer Profile could not be saved. Please review your information and try again."
          />
        )}
      </div>
      <PurchaseAreasModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleModalSubmit={handleModalSubmit}
        states={states}
        areaList={purchaseAreaList}
        setAreaList={setPurchaseAreaList}
      />
    </>
  );
};

export default AddBuyerProfileForm;
