import { useState, useEffect } from 'react';

const searchFilterAddress = (search, rows) => {
  if (rows !== undefined && rows !== null && rows !== '' && rows.length > 0) {
    if (rows[0]?.address !== undefined && rows[0]?.address !== null) {
      const column = Object.keys(rows[0]?.address); // crea un array con los keys de row
      return rows.filter((row) => {
        // devuelve los elementos que cumplan con la condicion
        return column.some((col) => {
          // verifica que al menos una columna cumpla con la condicion
          if (col === 'estate') {
            return (
              row.address[col]?.name.toString().toLowerCase().indexOf(search.toLowerCase()) > -1
            );
          } else {
            return row.address[col]?.toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
          }
        });
      });
    }
  }
};

export const useFilterAddress = (initialArray) => {
  const [filteredProperties, setFilteredProperties] = useState(initialArray);

  if (!filteredProperties) {
    setFilteredProperties(initialArray);
  }

  useEffect(() => {
    setFilteredProperties(initialArray);
  }, [initialArray]);

  const setFilterAddress = (keyword) => {
    setFilteredProperties(searchFilterAddress(keyword, initialArray));
  };

  return [filteredProperties, setFilterAddress];
};