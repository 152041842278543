import React, { useEffect, useState } from 'react';
import { Card, Button, ModalMessage } from '../../components/OMB';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { getApi, postApi, LIST_BUYERS_LISTINGS, ADD_REFERRAL } from '../../utils/services';
import { useHistory } from 'react-router-dom';
import './styles.css';

const FormSchema = Yup.object().shape({
  member: Yup.string().required('Required'),
  reguardForReferer: Yup.string('Must be number')
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(0, 'Min 0')
    .max(2, 'Max 2'),
  reguardForRecipient: Yup.string('Must be number')
    .required('Required')
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(0, 'Min 0')
    .max(2, 'Max 2'),
});

const CreateCodeForm = (props) => {
  const userToken = localStorage.getItem('tokenOMB');
  const [members, setMembers] = useState([]);
  const [showCheck, setShowCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(LIST_BUYERS_LISTINGS, userToken)
      .then((response) => {
        console.log(response.data);
        setMembers(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const handleSubmit = ({ member, reguardForReferer, reguardForRecipient }) => {
    const dataForm = {
      id: parseInt(member),
      userCode: [
        {
          percentage: {
            percentageReferee: parseInt(reguardForReferer),
            percentageRecipient: parseInt(reguardForRecipient),
          },
        },
      ],
    };

    postApi(ADD_REFERRAL, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          history.goBack();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  };

  const initValues = {
    member: '',
    reguardForReferer: '',
    reguardForRecipient: '',
  };

  return (
    <div className="create-code-form">
      <Formik
        initialValues={initValues}
        validationSchema={FormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <Card title="Create New Code">
              <div className="form-row cols-3">
                <div className="form-group">
                  <label className="form-label">Assign To A Member</label>
                  <Field className="form-control" as="select" name="member">
                    <option value={''}>Select</option>
                    {members.map((item) => (
                      <option value={item.idUser} key={`member-${item.idUser}`}>
                        {item.names}
                      </option>
                    ))}
                  </Field>
                  {errors.member && touched.member && <div className="error">{errors.member}</div>}
                </div>
              </div>
              <div className="form-row cols-2">
                <div className="form-group">
                  <label className="form-label">Reward For Referrer</label>
                  <div className="form-inline">
                    <Field
                      className="form-control form-inline-input"
                      type="text"
                      name="reguardForReferer"
                      maxlength="2"
                      placeholder="00%"
                    />
                    <span className="form-inline-text">of recipient's monthly payments</span>
                  </div>
                  {errors.reguardForReferer && touched.reguardForReferer && (
                    <div className="error">{errors.reguardForReferer}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Reward For Recipient</label>
                  <div className="form-inline">
                    <Field
                      className="form-control form-inline-input"
                      type="text"
                      name="reguardForRecipient"
                      maxlength="2"
                      placeholder="00%"
                    />
                    <span className="form-inline-text">off their monthly payments</span>
                  </div>
                  {errors.reguardForRecipient && touched.reguardForRecipient && (
                    <div className="error">{errors.reguardForRecipient}</div>
                  )}
                </div>
              </div>
            </Card>
            <div className="btn-container">
              <Button color="yellow" type="submit">
                SAVE CHANGES
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {showCheck && (
        <ModalMessage
          title="Create Code"
          message="The Code has been saved successfully."
          btnTitle="Return to Referrals"
          redirect="true"
        />
      )}
      {showError && (
        <ModalMessage
          title="Error Saving The New Code"
          message="The New Code could not be saved. Please review your information and try again."
        />
      )}
    </div>
  );
};

export default CreateCodeForm;
