import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/OMB/';
import { Spinner } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FlechaBack from '../../../assets/iconos/FlechaBack.svg';
import { FORGOT, getApi, postApi, RECOVER } from '../../../utils/services';
import './recover.css';

const StepOneSchema = Yup.object().shape({
  username: Yup.string().email('Invalid email').required('Required'),
});
const StepOne = (props) => {
  const [disabled, setDisabled] = useState(true);
  // const userToken = localStorage.getItem('tokenOMB');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const handleChangeEmail = (e) => {
    if (!!e.target.value) {
      setDisabled(false);
    }
  };

  return (
    <>
      <div className="header">
        <h2 className="titleCard">Forgot Password</h2>
        <span className="spanCard w-55">
          Please enter your email address below to reset your OMB password.
        </span>
      </div>
      <Formik
        initialValues={{
          username: '',
        }}
        validationSchema={StepOneSchema}
        onSubmit={(values) => {
          // same shape as initial values
          setLoading(true);
          console.log(values);
          if (!!values.username) {
            const urlApi = `${FORGOT}${values.username}`;
            setDisabled(false);
            getApi(urlApi)
              .then((response) => {
                setLoading(false);
                console.log('forgot', response);
                const data = response.data;
                if (response.status === 200) {
                  props.setShowStepOne(false);
                  props.setShowStepTwo(true);
                }
                if (response.status === 400) {
                  setMessage('The email entered is not registered');
                }
                if (response.status === 500) {
                  setMessage('There was an internal problem, try later.');
                }
              })
              .catch((error) => {
                if (error.response) {
                  console.log(error.response.data);
                  console.log('Código de error en recover step I:', error.response.status);
                }
              });
          }
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="body">
              <Field
                className="form-control"
                placeholder="Email Address"
                type="text"
                id="username"
                name="username"
                onBlur={(e) => handleChangeEmail(e)}
              />
              {errors.username && touched.username ? (
                <div className="error">{errors.username}</div>
              ) : null}
              <p className="message">{message}</p>
            </div>
            <div className="footer">
              <p onClick={() => window.location.reload()} className="back">
                <span className="icon">
                  <img src={FlechaBack} />
                </span>{' '}
                Back
              </p>
              <Button type="submit" disabled={disabled}>
                {loading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="mr-2 font-weight-normal"
                  />
                ) : null}
                SUBMIT
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
const StepTwo = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 12000);
  }, []);
  return (
    <>
      <div className="header">
        <h2 className="titleCard">Check your email</h2>
        <span className="spanCard">
          We sent you an email with the steps to reset your password.
        </span>
      </div>
    </>
  );
};

const TreeSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Too Short!').max(13, 'Too Long!').required('Password is required'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords do not match, Please try again.',
  ),
});

const StepTree = ({ token, setShowStepTree, setShowStepFour }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  useEffect(() => {
    console.log('m', message);
  }, [message]);
  return (
    <>
      <div className="header">
        <h2 className="titleCard">Reset your password</h2>
        <span className="spanCard">Enter your new OMB password below.</span>
      </div>
      <Formik
        initialValues={{
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={TreeSchema}
        onSubmit={(values) => {
          // same shape as initial values
          setLoading(true);
          console.log(values);
          if (!!values.password) {
            const urlApi = `${RECOVER}`;
            const data = {
              password: values.password,
              reset: token,
            };
            postApi(urlApi, data, userToken)
              .then((response) => {
                setLoading(false);
                // console.log('recover:', response)
                if (response.status === 200) {
                  setShowStepTree(false);
                  setShowStepFour(true);
                }
                if (response.status === undefined) {
                  setMessage('The token does not exist or has already been used');
                }
                if (response.status === 500) {
                  setMessage('There was an internal problem, try later.');
                }
              })
              .catch((error) => {
                if (error.response) {
                  console.log('Código de error en recover step III:', error);
                  console.log(error.response.data);
                }
              });
          }
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="body">
              <Field
                className="form-control mg-5"
                placeholder="New Password"
                type="password"
                id="password"
                name="password"
              />
              {errors.password && touched.password ? (
                <div className="error">{errors.password}</div>
              ) : null}
              <Field
                className="form-control mg-5"
                placeholder="Retype Password"
                type="password"
                id="passwordConfirmation"
                name="passwordConfirmation"
              />
              {errors.passwordConfirmation && touched.passwordConfirmation ? (
                <div className="error">{errors.passwordConfirmation}</div>
              ) : null}
              <p className="message">{message}</p>
            </div>
            <div className="footer only">
              <Button type="submit">
                {loading ? (
                  <Spinner
                    animation="border"
                    role="status"
                    size="sm"
                    className="mr-2 font-weight-normal"
                  />
                ) : null}
                RESET
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

const StepFour = () => {
  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/login';
    }, 120000);
  }, []);
  return (
    <>
      <div className="header">
        <h2 className="titleCard">Password Change Successful</h2>
        <span className="spanCard">
          Your password was changed successfully. In a few moments, you will be redirected to login.
        </span>
      </div>
    </>
  );
};

const Recover = ({ recover, token }) => {
  const [showStepOne, setShowStepOne] = useState(true);
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [showStepTree, setShowStepTree] = useState(false);
  const [showStepFour, setShowStepFour] = useState(false);

  useEffect(() => {
    if (recover) {
      setShowStepOne(false);
      setShowStepTree(true);
    }
  }, [recover]);
  return (
    <div className="cardRecover">
      {showStepOne ? (
        <StepOne setShowStepOne={setShowStepOne} setShowStepTwo={setShowStepTwo} />
      ) : null}
      {showStepTwo ? (
        <StepTwo setShowStepOne={setShowStepOne} setShowStepTwo={setShowStepTwo} />
      ) : null}
      {showStepTree ? (
        <StepTree
          token={token}
          setShowStepTree={setShowStepTree}
          setShowStepFour={setShowStepFour}
        />
      ) : null}
      {showStepFour ? <StepFour /> : null}
    </div>
  );
};

export default Recover;
