import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ItemList } from './ItemList';
import { EmptyList } from '..';
import './styles.css';

const ListInvited = ({inviteResponse = []}) => {
  const [invitations, setInvitations] = useState([]);


  useEffect(() => {
    if(!!inviteResponse.length > 0){
      setInvitations(inviteResponse);
    }
  }, [inviteResponse])
  

  const List = () => (
    <div className="list">
      <div className="fila">
        <div className="column-title bold">Name</div>
        <div className="column-title bold">User Type</div>
        <div className="column-title bold">Registered</div>
        <div className="column-title bold">Referral</div>
        <div className="column-title bold">Total Profits</div>
        <div className="column-title bold"></div>
      </div>
      {invitations.map((invite) => {
        return <ItemList invite={invite} />;
      })}
    </div>
  );

  return (
    <div className="ListInvited">
      {invitations.length > 0 ? <List /> : <EmptyList text="There are no Invited Members" />}
    </div>
  );
};

export default ListInvited;
