import React, { useState, useEffect } from 'react';
import { EmptyList, DTable, Confirm } from '../../../components/OMB';
import { Trash } from '../../../components/icons';
import {
  CONVERSATION_DELETE,
  getApi,
  LIST_MESSAGES_BUYERS,
} from '../../../utils/services';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const MessagesBuyersList = ({ userId }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const {
    roleActive,
    info: { id: profileId },
  } = useSelector((store) => store.user);
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState([]);
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showModalId, setShowModalId] = useState(null);

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    const id = userId || profileId;
    await getApi(`${LIST_MESSAGES_BUYERS}${id}`, userToken)
      .then((response) => {
        setMessages(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    const dataMessages = messages.map(({ idConversation, street, city, codEstate, messages }) => {
      return {
        id: idConversation,
        subject: `${street}, ${city}, ${codEstate}`,
        message: messages,
        icon: '>',
        handleClick: () => history.push(`/${roleActive}/messages/chat/${idConversation}`),
      };
    });
    setData(dataMessages);
  }, [messages]);

  const columns = [
    {
      name: 'Subject',
      selector: 'subject',
    },
    {
      name: 'Message',
      selector: 'message',
    },
    {
      selector: 'delete',
      cell: (row) => (
        <div onClick={() => handleShowModal(row)} data-tag="allowRowEvents">
          <Trash />
        </div>
      ),
      right: true,
      width: '50px',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px',
    },
  ];

  const handleDownload = () => {
    console.log('handleDownload');
  };

  const handleShowModal = ({ id }) => {
    setShowModalId(id);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModalId(null);
    setShowModal(false);
  };

  const handleDelete = () => {
    const urlApi = `${CONVERSATION_DELETE}${showModalId}`;
    getApi(urlApi, userToken)
    .then((response) => {
      setShowModal(false);
      // console.log('data:', data);
      if (response.status === 200) {
          console.log(response);
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <>
      {messages.length === 0 ? <EmptyList /> : <DTable columns={columns} data={data} />}
      {showModal && (
        <Confirm
          show={showModal}
          onHide={handleHideModal}
          no={handleHideModal}
          yes={() => handleDelete()}
          title="Delete Chat"
          message="Are you sure you want to delete this chat?"
        />
      )}
    </>
  );
};

export default MessagesBuyersList;
