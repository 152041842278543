import React from 'react';
import { PropertyOfferCards } from "../../containers";
import { GoBack } from "../../components/OMB";

const PropertyOffer = (props) => {
  return (
    <div className="property-offer">
      <GoBack {...props} />
      <PropertyOfferCards/>
    </div>
  )
}

export default PropertyOffer;