import React from 'react';
import { GoBack } from '../../components/OMB';
import { MembershipDetailsCards } from "../../containers";
import './styles.css';

const MembershipDetails = (props) => {
  const { id: membershipId } = props.match.params;

  return (
    <div className="membership-details">
      <GoBack {...props} />
      <MembershipDetailsCards membershipId={parseInt(membershipId)} />
    </div>
  )
}

export default MembershipDetails;
