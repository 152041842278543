import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import imgUpload from '../../../../assets/img/upload-image.png';
import {
  postApi,
  EDIT_COUPON,
  getApi,
  LIST_COUPONS_DISCOUNTS,
  GET_UTILS,
} from '../../../../utils/services';
import { useSelector } from 'react-redux';

import './styles.css';
import { Spinner } from 'react-bootstrap';
import { DatePickerField } from '../../../../components/OMB';

const EditCouponsAndDiscounts = (props) => {
  const { profileId } = useParams();
  const [couponType, setCouponType] = useState();
  const userToken = localStorage.getItem('tokenOMB');
  const userData = JSON.parse(localStorage.getItem('state'));
  const userID = userData.user.info.id;
  const user = useSelector((store) => store.user);
  const [category, setCategory] = useState();
  const [categories, setCategories] = useState([]);
  const [date, setDate] = useState(new Date());
  const [dateFormated, setDateFormated] = useState();
  const [routeImg, setRouteImg] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [typeCouponId, setTypeCouponId] = useState();
  const [percentage, setPercentage] = useState();
  const [detailsReward, setDetailsReward] = useState();
  const urlApi = `${LIST_COUPONS_DISCOUNTS}${profileId || userID}`;
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataFilter, setDataFilter] = useState([]);
  let dateForm = '';
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  if (month < 10) {
    dateForm = `${year}-0${month}-${day}`;
  } else {
    dateForm = `${year}-${month}-${day}`;
  }
  console.log(profileId);

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        const data = response.data.exit;
        const d = data.filter((item) => item.id == props.match.params.id);
        setDataFilter(d);
        console.log('Index Data', d);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const callEndPointUtils = () => {
    getApi(GET_UTILS, userToken)
      .then((response) => {
        const data = response.data.categoryCoupon;
        setCategories(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndPointUtils();
  }, []);

  const toggleMe = (value) => {
    setCouponType(value);
  };
  useEffect(() => {
    if (!!category && !!date && !!routeImg && !!title && !!description) {
      setDisabled(false);
    }
  }, [category, date, routeImg, title, description]);
  useEffect(() => {
    if (couponType === 'discount') {
      setTypeCouponId(1);
    } else {
      setTypeCouponId(2);
    }
  }, [couponType]);

  useEffect(() => {
    if (dataFilter.length >= 1) {
      const item = dataFilter[0];
      const data = new Date(item.useByDate.substr(0, 10));
      setTitle(item.title);
      setDescription(item.info);
      setDate(item.useByDate.substr(0, 10));
      setDescription(item.info)
      setDate(data);
      setCouponType(item.typeCoupon.name.toLowerCase());
      setCategory(item.categoryCoupon.id);
      setRouteImg(item.url_image);
      setDetailsReward(item.reward || '');
      setPercentage(item.discount || '');
    }
  }, [dataFilter]);

  const dataForm = {
    id: parseInt(props.match.params.id),
    title: title,
    description: description,
    use_date: date,
    url_image: routeImg,
    discountPercentaje: percentage || null,
    rewardDetails: detailsReward || null,
    typeCoupon: {
      id: typeCouponId,
    },
    categoryCoupon: {
      id: parseInt(category),
    },
    user: {
      id: profileId || userID,
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    postApi(EDIT_COUPON, dataForm, userToken)
      .then((response) => {
        //const data = response.data;
        setLoading(false);
        console.log('respuesta de save coupons', response.status);
        if (response.status === 200) {
          window.location.href = `/${user.roleActive}/coupons-and-discounts`;
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <div className="sponsors-id">
      <div className="pt-gen form-f">
        <div className="title-list sponsor-head-title text-white">Coupon Details</div>
        <form onSubmit={handleSubmit}>
          <div className="form-flex">
            <label>
              <spam className="bold"> Category</spam>
              <br />
              <select
                className="select-ipt br-br"
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                <option className="text-capitalize">Select</option>
                {categories.map((categorie) => (
                  <option key={categorie.id} value={categorie.id}>
                    {categorie.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <spam className="bold"> Use By Date</spam>
              <br />
              <div className="input-txt addres-input italic">
                <DatePickerField
                  minDate={today}
                  selected={date}
                  name="date"
                  dateFormat="MM/dd/yyyy"
                  onChange={(date) => setDate(date)}
                />
              </div>
            </label>
            <label className="ml-3">
              <spam className="bold"> Type Of Coupon</spam>
              <br />
              <div className="flex-radio">
                <div className="select-point">
                  <div onClick={() => toggleMe('discount')} className="input-radio br-br">
                    <div className={`radio ${couponType === 'discount' ? 'active' : ''}`}></div>
                  </div>
                  <div className="bold">Discount</div>
                </div>
                <div className="select-point">
                  {' '}
                  <div onClick={() => toggleMe('reward')} className="input-radio br-br">
                    <div className={`radio ${couponType === 'reward' ? 'active' : ''}`}></div>
                  </div>
                  <div className="bold">Reward</div>
                </div>
              </div>
            </label>
            <div>
              {couponType === 'discount' && (
                <div>
                  {' '}
                  <label className="cp-tit ">
                    Discount Percentage
                    <br />
                    <input
                      className="input-txt br-br"
                      type="text"
                      name="percentage"
                      placeholder="00%"
                      value={percentage}
                      onChange={(event) => setPercentage(event.target.value)}
                    />
                  </label>
                </div>
              )}
              {couponType === 'reward' && (
                <div>
                  <label className="cp-tit ">
                    <span className="bold">Reward Details</span>
                    <br />
                    <input
                      className="input-txt br-br"
                      type="text"
                      name="name"
                      placeholder="Free product, free shipping…"
                      value={detailsReward}
                      onChange={(event) => setDetailsReward(event.target.value)}
                    />
                  </label>
                </div>
              )}
            </div>
            <label>
              <br></br>
            </label>
          </div>
          <div className="form-name-description">
            <label>
              <spam className="bold"> Coupon Title</spam>
              <br />
              <input
                className="input-txt br-br"
                type="text"
                name="title"
                placeholder="Enter a Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
            </label>

            <label>
              <spam className="bold"> Description</spam>
              <br />
              <textarea
                className="input-txt br-br"
                type="text"
                name="description"
                placeholder=""
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </label>
          </div>
        </form>
      </div>

      <div className="box-btn-btn-reverse">
        <button
          className={`btn-create-membership yell-btn ${disabled ? 'disabled' : ''}`}
          onClick={handleSubmit}
        >
          {loading ? <Spinner animation="border" role="status" size="sm" className="mr-2" /> : null}
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default EditCouponsAndDiscounts;
