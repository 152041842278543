

export const loadState=()=>{
    var data=localStorage.getItem('state')
        data=JSON.parse(data);
    
        if(data!==null&&data!==''&&data!==undefined){//para no cargar los textos de errores
            data.user.message='';
        }
    return data
}

export const saveState=(state)=>{
    var estado=state
    localStorage.setItem('state',JSON.stringify(estado))
}