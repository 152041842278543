import React from 'react';
import { GoBack } from '../../components/OMB';
import { AddBuyerProfileForm } from "../../containers";
import './styles.css';

const AddBuyerProfile = (props) => {
  return (
    <div className="add-buyer-profile">
      <GoBack {...props} />
      <AddBuyerProfileForm />
    </div>
  )
}

export default AddBuyerProfile;
