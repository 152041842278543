import React from 'react';
import { Card, ContentTab, ItemTab, Button } from '../../components/OMB';
import Listing from './Listing';
import Properties from "./Properties";
import Profile from "./Profile";
import BuyerProfiles from "./BuyerProfiles";
import InvitedMembers from "../SuperAdminCommons/InvitedMembers";
import MessagesBuyersList from "../Messages/MessagesBuyersList";
import { useSelector } from 'react-redux';
import "./styles.css";

const BuyerTabs = ({ userId }) => {
  const { roleActive } = useSelector((store) => store.user);

  return (
    <div className="buyer-tabs">
      <ContentTab defaultActiveKey="listing" id="uncontrolled-tab-example">
        <ItemTab eventKey="listing" title="Listing">
          <Listing userId={userId} />
        </ItemTab>
        <ItemTab eventKey="properties" title="Properties">
          <Properties userId={userId} />
        </ItemTab>
        <ItemTab eventKey="profile" title="Profile">
          <Profile userId={userId} />
        </ItemTab>
        <ItemTab eventKey="buyer-profiles" title="Buyer Profiles">
          <div className="btn-container">
            <Button color="light-blue" to={`/${roleActive}/add-buyer-profile/${userId}`}>
              + ADD NEW BUYER PROFILE
            </Button>
          </div>
          <BuyerProfiles userId={userId} />
        </ItemTab>
        <ItemTab eventKey="invited-members" title="Invited Members">
          <InvitedMembers userId={userId} />
        </ItemTab>
        <ItemTab eventKey="messages" title="Messages">
          <Card title="My Messages">
            <MessagesBuyersList userId={userId} />
          </Card>
        </ItemTab>
      </ContentTab>
    </div>
  );
};

export default BuyerTabs;
