import React, { useState, useEffect } from 'react';
import { Loading, Card, EmptyList, DTable, HeaderBtn } from '../../../components/OMB';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const BuyersList = ({ list, loading }) => {
  const { roleActive } = useSelector((store) => store.user);
  const [data, setData] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const dataBuyers = list.map(({ idUser, names, address, mail, phone }) => {
      return {
        id: idUser,
        name: names,
        address,
        email: mail,
        phoneNumber: phone,
        icon: '>',
        handleClick: () => history.push(`/${roleActive}/buyer-details/${idUser}`),
      };
    });
    setDataOrigin(dataBuyers);
    setData(dataBuyers);
  }, [list]);

  const searchItem = (value) => {
    const wanted = value.trim().toLowerCase();
    const result =
      wanted.length >= 2
        ? list.filter(
            (item) =>
              item.names.trim().toLowerCase().includes(wanted) ||
              item.address.trim().toLowerCase().includes(wanted) ||
              item.mail.trim().toLowerCase().includes(wanted) ||
              item.phone.trim().toLowerCase().includes(wanted),
          )
        : [];
    if (result.length > 0) {
      const dataBuyers = result.map(({ idUser, names, address, mail, phone }) => {
        return {
          id: idUser,
          name: names,
          address,
          email: mail,
          phoneNumber: phone,
          icon: '>',
          handleClick: () => history.push(`/${roleActive}/buyer-details/${idUser}`),
        };
      });
      setData(dataBuyers);
    } else {
      setData(dataOrigin);
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'Address',
      selector: 'address',
    },
    {
      name: 'Email',
      selector: 'email',
    },
    {
      name: 'Phone Number',
      selector: 'phoneNumber',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px',
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <>
      <HeaderBtn showButtons={false} searchItem={searchItem} />
      <Card
        title={`Buyers (${data.length})`}
        // btnTitle="Download Template"
        // btnHandle={handleDownload}
      >
        {loading ? (
          <Loading />
        ) : list.length === 0 ? (
          <EmptyList />
        ) : (
          <DTable columns={columns} data={data} />
        )}
      </Card>
    </>
  );
};

export default BuyersList;
