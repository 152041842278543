import React from 'react';
import SponsorsDetailsCard from "./SponsorsDetailsCard";
import ActiveSponsorsCard from "./ActiveSponsorsCard";
import { useSelector } from "react-redux";

const SponsorshipDetailsCards = ({ sponsorshipId }) => {
  const { sponsorships } = useSelector((store) => store.admin);
  const sponsorship = sponsorships.find(item => item.idUser === sponsorshipId);

  return (
    <>
      <SponsorsDetailsCard data={sponsorship} />
      <ActiveSponsorsCard sponsors={sponsorship.sponsorship} />
    </>
  )
}

export default SponsorshipDetailsCards;
