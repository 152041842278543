import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Button, Loading, ModalMessage } from '../../../components/OMB';
import { EDIT_EMAIL_MARKETING, getApi, GET_UTILS, postApi } from '../../../utils/services';
import * as Yup from 'yup';

const ModalEdit = ({ personEdit, showModal, setShowModal, callListData }) => {
  const [loading, setLoading] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [titleModal, setTitleModal] = useState('Could Not Save Changes');
  const [messageModal, setMessageModal] = useState('Please review your information and try again.');
  const userToken = localStorage.getItem('tokenOMB');
  const [selectState, setSelectState] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [states, setStates] = useState([]);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const schema = Yup.object().shape({
    firstName: Yup.string(),
    lastName: Yup.string(),
    email: Yup.string(),
    city: Yup.string(),
    county: Yup.string(),
    zipCode: Yup.string(),
    phone: Yup.string()
      .required('required')
      .matches(phoneRegExp, 'Phone number is not valid')
      .min(9, 'to short')
      .max(10, 'to long'),
  });

  const callEndPointUtils = () => {
    setLoadingData(true);
    getApi(GET_UTILS, userToken)
      .then((response) => {
        setLoadingData(false);
        if (response.data) {
          setStates(response.data.estate);
        }
      })
      .catch((error) => {
        setLoadingData(false);
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if(showModal){
      callEndPointUtils();
    }
  }, [showModal]);


  const RenderForm = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          firstName: personEdit[0]?.name,
          lastName: personEdit[0]?.lastName,
          phone: personEdit[0]?.phone,
          email: personEdit[0]?.email,
          zipCode: personEdit[0]?.zipCode,
          city: personEdit[0]?.city,
          county: personEdit[0]?.county,
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          console.log(values);
          setLoading(true);
          let data = {};
          const info = {
            id: personEdit[0]?.id,
            name: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            estate: {
              id: parseInt(selectState)
            },
            city: values.city,
            county: values.county,
            zipCode: values.zipCode,
          };
          data = info;
          postApi(EDIT_EMAIL_MARKETING, data, userToken)
            .then((response) => {
              setLoading(false);
              console.log(response);
              if (response.status === 200) {
                setShowModal(false);
                setShowModalMessage(true);
                setTitleModal('User Saved');
                setMessageModal('Your changes have been saved successfully.');
                callListData()
              }
            })
            .catch((error) => {
              setShowModal(false);
              setShowModalMessage(true);
            });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col col-12">
                <div className="row">
                  <div className="col col-6 mb-4">
                    <label className="form-label bold">First Name</label>
                    <Field className="form-control" type="text" name="firstName" />
                    {errors.firstName && touched.firstName && (
                      <div className="error">{errors.firstName}</div>
                    )}
                  </div>
                  <div className="col col-6 mb-4">
                    <label className="form-label bold">Last Name</label>
                    <Field className="form-control" type="text" name="lastName" />
                    {errors.lastName && touched.lastName && (
                      <div className="error">{errors.lastName}</div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col col-4 mb-4">
                    <label className="form-label bold">Email</label>
                    <Field className="form-control" type="email" name="email" />
                    {errors.email && touched.email && <div className="error">{errors.email}</div>}
                  </div>
                  <div className="col col-4 mb-4">
                    <label className="form-label bold">Phone</label>
                    <Field className="form-control" type="phone" name="phone" />
                    {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}
                  </div>
                  <div className="col col-4 mb-4">
                    <label className="form-label bold">State</label>
                    <select
                      className="form-control"
                      as="select"
                      id="selectState"
                      name="selectState"
                      value={selectState}
                      onChange={(e) => setSelectState(e.target.value)}
                      required
                    >
                      <option value={''}>Select</option>
                      {states?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col col-4 mb-4">
                    <label className="form-label bold">City</label>
                    <Field className="form-control" type="city" name="city" />
                    {errors.city && touched.city && <div className="error">{errors.city}</div>}
                  </div>
                  <div className="col col-4 mb-4">
                    <label className="form-label bold">County</label>
                    <Field className="form-control" type="county" name="county" />
                    {errors.county && touched.county && (
                      <div className="error">{errors.county}</div>
                    )}
                  </div>
                  <div className="col col-4 mb-4">
                    <label className="form-label bold">Zip Code</label>
                    <Field className="form-control" type="zipCode" name="zipCode" />
                    {errors.zipCode && touched.zipCode && (
                      <div className="error">{errors.zipCode}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col col-12 d-flex mt-4 justify-content-end">
                <Button color="btn-gray" onClick={() => setShowModal(false)}>
                  CANCEL
                </Button>
                <Button type="submit">
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  ) : null}
                  SAVE CHANGES
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <>
      <Modal show={showModal} centered size="lg" onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex align-items-center">
              <span className="mr-2">Edit User</span>
              {loading ? (
                <Spinner animation="border" role="status" size="sm" className="mr-2" />
              ) : null}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{loadingData ? <Loading /> : <RenderForm />}</Modal.Body>
      </Modal>
      {showModalMessage && (
        <ModalMessage
          title={titleModal}
          message={messageModal}
          btnTitle="OK"
          redirect="true"
          type="message"
        />
      )}
    </>
  );
};

export default ModalEdit;
