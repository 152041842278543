import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { property_list } from '../redux/PropertyDucks';

export const usePropertyList = (userId = null) => {
  const { propertyList } = useSelector((store) => store.property);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(property_list());
  }, []);

  return [propertyList];
};
