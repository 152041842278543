import React from "react";
import { Spinner } from 'react-bootstrap';
import "./styles.css"

const Loading = ({ text }) => {
  return (
    <div className="loading">
      <Spinner animation="border" role="status" size="md" className="mr-2" />
      <span>{text || "Loading..."}</span>
    </div>
  )
}

export default Loading;