import { useState, useEffect } from 'react';

export const useFormatPrice = (dataPrice) => {
  const [formatPrice, setPrice] = useState(null);

  useEffect(() => {
    if (dataPrice === 0) {
      setPrice('N/A');
    } else {
      setPrice(
        new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: 'currency',
          currency: 'USD',
        }).format(dataPrice),
      );
    }
  }, [dataPrice]);

  return [formatPrice];
};
