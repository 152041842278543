
import React from 'react';
import { Card } from "../../../components/OMB";
import "./styles.css";

const ReferralDetailsCard = ({ info }) => {
  const { names, typeUser, totalProfit } = info;

  return (
    <Card className="referral-details-card" title="Referral Details">
      <div className="form-row cols-4">
        <div className="form-group">
          <label className="form-label">Name</label>
          <input className="form-control" type="text" value={names} disabled />
        </div>
        <div className="form-group">
          <label className="form-label">User Type</label>
          <input className="form-control" type="text" value={typeUser} disabled />
        </div>
        <div className="form-group">
          <label className="form-label">Total Profits</label>
          <input className="form-control" type="text" value={`$${totalProfit.toFixed(2)}`} disabled />
        </div>
      </div>
    </Card>
  )
};

export default ReferralDetailsCard;
