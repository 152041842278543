import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { formatPrice, getApi, VIEW_OFFER } from '../../../utils/services';
import { useSelector } from 'react-redux';
import { Button } from '..';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ModalViewOffer = ({ offerId, offer, showModal, setShowModal }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [isCheck, setIsCheck] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive } = useSelector((store) => store.user);

  const offerAddress = `${offer.property.address?.street}, ${
    !!offer.property.address.unit ? `unit ${offer.property.address.unit}, ` : ''
  }${!!offer.property.address?.city ? `${offer.property.address?.city}, ` : ''}${
    offer.property.address?.estate?.code
  } ${!!offer.property.address?.zipCode ? offer.property.address?.zipCode : ''}`;

  useEffect(() => {
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var hours = new Date().getHours(); //Current Hours
    var min = new Date().getMinutes(); //Current Minutes
    setCurrentDate(month + '/' + date + '/' + year);
    if (error) {
      setError(false);
    }
  }, [isCheck]);

  const setViewOffer = () => {
    if (!isCheck) {
      return setError(true);
    }

    setLoading(true);

    getApi(`${VIEW_OFFER}${offerId}`, userToken)
      .then((response) => {
        if (response.data.viewStatus) {
          setShowModal(false);
          setLoading(false);
          history.push(`/${roleActive}/property-offer-details/${offerId}`);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
          setLoading(false);
        }
      });
  };

  const Schema = Yup.object().shape({
    nameBuyer: Yup.string().required('Required'),
  });

  return (
    <Modal
      className="offer-item-modal"
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">View Offer</Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={{
          nameBuyer: '',
        }}
        validationSchema={Schema}
        onSubmit={(values) => {
          setLoading(true);
          const data = {
            nameBuyer: values.nameBuyer,
          };
          setViewOffer();
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Modal.Body>
              <>
                <p>
                  Off Market Broker is pleased that you have chosen to view this member's offer.
                </p>
                <p>
                  Today's Date: <span className="bold">{currentDate}</span>
                </p>
                <p>
                  Address: <span className="bold">{offerAddress}</span>
                </p>
                <p>
                  Offer Amount: <span className="bold">{formatPrice(offer.amountOffered)}</span>
                </p>
                <p>
                  Buyer/Signer Name: <span className="bold">{offer.buyerType?.name === 'PERSONAL NAME' ? offer.name : offer.signerName}</span>
                </p>
                
                <p>
                  Please acknowledge that you have read and agreed to the Terms & Conditions in
                  order for OMB to provide you with the member's contact information.
                </p>
                <div className="container-checkbox">
                  <input
                    type="checkbox"
                    id="accept"
                    name="accept"
                    onChange={() => {
                      setIsCheck(!isCheck);
                    }}
                  />
                  <label htmlFor="accept">
                    Check here to indicate you have read and agreed to the Terms & Conditions in
                    order for OMB to provide you with the member’s contact information and please
                    input your name.
                  </label>
                </div>
                <div className="row">
                  <div className="col col-6">
                    <label className="form-label">
                      Name:
                    </label>
                    <Field className="form-control" type="text" name="nameBuyer" />
                    {errors.nameBuyer && touched.nameBuyer && (
                      <div className="error">{errors.nameBuyer}</div>
                    )}
                  </div>
                </div>
                {error && <div className="error">Please agree to the Terms & Conditions</div>}
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button color="btn-gray" onClick={() => setShowModal(false)}>
                CANCEL
              </Button>
              <Button onClick={setViewOffer} disabled={loading}>
                {loading && <Spinner animation="border" role="status" size="sm" className="mr-2" />}
                VIEW
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ModalViewOffer;
