import React from 'react';
import { PropertiesCard } from '../..';

const Properties = ({ userId }) => {
  return (
    <PropertiesCard userId={userId} />
  );
};

export default Properties;
