import React from 'react';
import './styles.css';
import { Tabs, Tab } from 'react-bootstrap';

export const ItemTab = (props) => {
  return (
    <Tab {...props}>{props.children}</Tab>
  );
};

export const ContentTab = (props) => {
  return (
    <Tabs {...props} className={'tabs-members ' + props.className}>
      {props.children}
    </Tabs>
  );
};
