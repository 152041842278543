import React, { useState, useEffect } from 'react';
import { Loading, Card, EmptyList, DTable } from "../../../components/OMB";
import { formatPrice } from '../../../utils/services';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMemberships } from '../../../hooks/useMemberships';

const MembershipsList = () => {
  const { roleActive } = useSelector((store) => store.user);
  const [memberships, loading] = useMemberships();
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const dataMemberships = memberships.map(({ idUser, names, roles, amountMonthly, totalPayments }) => {
      return {
        id: idUser,
        name: names,
        userType: roles,
        monthlyPayment: `$${amountMonthly}`,
        totalPayments: `$${totalPayments}`,
        // "icon": '>',
        // "handleClick": () => history.push(`/${roleActive}/membership-details/${idUser}`)
      };
    })
    setData(dataMemberships);
  }, [memberships]);

  const columns = [
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'User Type',
      selector: 'userType',
    },
    {
      name: 'Monthly Payment',
      selector: 'monthlyPayment',
    },
    {
      name: 'Total Payments',
      selector: 'totalPayments',
    },
    // {
    //   selector: 'icon',
    //   right: true,
    //   width: '50px'
    // },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <div className="memberships-list">
      <Card
        title={`Memberships (${data.length})`}
      // btnTitle="Download Template"
      // btnHandle={handleDownload}
      >
        {
          loading ? (
            <Loading />
          )
            : (
              memberships.length === 0
                ? <EmptyList />
                : <DTable columns={columns} data={data} />
            )
        }
      </Card>
    </div>
  );
};

export default MembershipsList;
