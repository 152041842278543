import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, GoBack } from '../../../components/OMB';
import { PropertyForm } from '../../../containers';
import { getApi } from '../../../utils/services';
import { PROPERTY_INFO } from '../../../utils/services/';

const EditProperty = (props) => {
  const userToken = localStorage.getItem('tokenOMB');
  const [infoProperty, setInfoProperty] = useState(null);
  let { id } = useParams();

  const callPropertyInfo = async () => {
    const url_api = `${PROPERTY_INFO}${id}`
    await getApi(url_api, userToken)
      .then((response) => {
        if (response.status === 200) {
          setInfoProperty(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en adminNavBarLink:', error.response.status);
        }
      });
  }

  useEffect(() => {
    callPropertyInfo();
  }, [])

  return (
    <>
      <GoBack {...props} />
      <Card title={'Edit Property'}>
        <h4>Property information</h4>
        <hr />
        <PropertyForm data={infoProperty} />
      </Card>
    </>
  );
};

export default EditProperty;
