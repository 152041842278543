
import React, { useState, useEffect } from 'react';
import { Card } from "../../components/OMB";
import { getApi, REFFERRAL_DETAILS } from '../../utils/services';
import ReferralDetailsCard from "./ReferralDetailsCard";
import UsersReferralCard from "./UsersReferralCard";
import UsersReferralCodeCard from "./UsersReferralCodeCard";
import "./styles.css";

const ReferralsDetailsCards = ({ referralId }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    callEndPoints();
  }, [referralId]);

  const callEndPoints = async () => {
    await getApi(`${REFFERRAL_DETAILS}${referralId}`, userToken)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <div className="referrals-details-cards">
      {loading ? (
        <span>Loading...</span>
      ) : (
        <>
          <ReferralDetailsCard info={data.exitRefResponse[0]} />
          <UsersReferralCard info={data.exitUserCodFirstResponse} />
          <UsersReferralCodeCard info={data.exitRefTotalResponse} />
          <Card title="Amount Owed by OMB">
            <p>Total amount: ${data.exitRefResponse[0].totalProfit.toFixed(2)}</p>
          </Card>
        </>
      )}
    </div>
  );
};

export default ReferralsDetailsCards;
