import React from 'react';
import { GoBack } from '../../components/OMB';
import { Chat } from "../../containers";

const MyChat = (props) => {
  const { id: chatId, viewed } = props.match.params;

  return (
    <div className="my-chat">
      <GoBack {...props} />
      <Chat chatId={chatId} viewed={viewed}/>
    </div>
  )
}

export default MyChat;
