import React, { useState } from 'react';
import { Button, Card, ModalMessage } from '../../components/OMB';
import { Form } from 'react-bootstrap';
import { UPLOAD_CSV, DOWNLOAD_CSV, getApi, postApiFile } from '../../utils/services';
import { useParams } from 'react-router-dom';
import './styles.css';

const BulkImport = (props) => {
  const { id: profileId } = useParams();
  const urlApi = `${DOWNLOAD_CSV}`;
  const urlApiUpload = `${UPLOAD_CSV}`;
  const userToken = localStorage.getItem('tokenOMB');
  const { user } = JSON.parse(localStorage.getItem('state'));
  const userID = !!profileId ? parseInt(profileId) : user.info.id;
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState('Error Importing File');
  const [messageModal, setMessageModal] = useState('There was an error importing your properties. Please check your file and try again.');
  const [loading, setLoading] = useState(false);

  function handleDownload(e) {
    e.preventDefault();
    window.location.href = 'https://offmarketbroker.com/assets/BulkOmb.xlsx';
  }

  const loadFile = (event) => {
    setLoading(true);
    var output = document.getElementById('custom-file');
    output.src = URL.createObjectURL(event.target.files[0]);

    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };

    const fd = new FormData();
    fd.append('file', event.target.files[0]);
    fd.append('idUser', userID);

    postApiFile(urlApiUpload, fd, userToken)
      .then((response) => {
        setLoading(false);
        setShowModal(true)
        if (response.status === 200) {
          setTitleModal('Import Successful');
          setMessageModal('Your properties were imported successfully, they will appear in the My Properties tab.');
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de Error de uploader file:', error.response.status);
        }
      });
  };

  return (
    <>
      <Card title="Property List Import" loading={loading} btnTitle="DOWNLOAD TEMPLATE" btnHandle={handleDownload}>
        <div className="row">
          <div className="col-md-6">
            <Form.File
              id="custom-file"
              label="Select Files..."
              accept=".xlsx"
              onChange={(event) => {
                event.persist();
                loadFile(event);
              }}
              custom
            />
          </div>
        </div>
      </Card>
      {showModal && (
        <ModalMessage
          title={titleModal}
          message={messageModal}
          btnTitle="OK"
          redirect="true"
          type="message"
        />
      )}
    </>
  );
};

export default BulkImport;
