import React from "react";
import { Button } from "..";
import "./styles.css"

const CardPropertySendMessage = ({ address, handleMessage, handleSubmit, error, success }) => {
  return (
    <div className="card-property-send-message">
      <input
        type="text"
        className="form-control"
        value={`List Price Request - ${address}`}
        disabled
      />
      <textarea
        name="textarea"
        className="form-control"
        rows="10"
        cols="50"
        onChange={(e) => handleMessage(e.target.value)}
        placeholder="Enter your message here..."
      />
      {!!error && <span className="text-danger">Message required</span>}
      {!!success && <span className="text-success">Message sent succesfully</span>}
      <div className="btn-container">
        <Button color="yellow" type="submit" onClick={handleSubmit}>
          SEND
        </Button>
      </div>
    </div>
  )
}

export default CardPropertySendMessage;
