import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApi, GET_UTILS } from '../../../utils/services';
import { login } from '../../../redux/userDucks';
import './style.css';
import './login.css';
import { User } from '../../../components/icons';
import { Button } from '../../../components/OMB/';
import { Spinner } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import logo from '../../../assets/img/Logo-transparente.png';
import Recover from '../recover/Recover';

const LoginSchema = Yup.object().shape({
  username: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string().min(6, 'Too Short!').max(13, 'Too Long!').required('Required'),
  role: Yup.string().required('Required'),
});

const ROLE = {
  ROLE_ADMIN: 'Admin',
  ROLE_LISTING_AGENT: 'Listing Agent',
  ROLE_BUYER_AGENT: 'Buyer Agent',
  ROLE_BUYER: 'Buyer',
  ROLE_SPONSOR: 'Sponsor',
};

const Login = (props) => {
  const user = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [showLogin, setShowLogin] = useState(true);
  const [roles, setRoles] = useState([]);
  const {
    match: { params },
  } = props;

  useEffect(() => {
    callEndPointUtils();
  }, []);

  const callEndPointUtils = () => {
    getApi(GET_UTILS)
      .then((response) => {
        // console.log(response.data.role)
        const data = response.data.role;
        let dataNew = data.slice(1, 5);
        setRoles(dataNew);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!params.token) {
      setShowLogin(false);
    }
  }, [params.token]);

  function sendLogin(values) {
    dispatch(login(values));
  }

  const renderLogin = () => {
    return (
      <div className="card">
        <div className="header">
          <User size={50} />
          <span>Log in</span>
        </div>
        <Formik
          initialValues={{
            username: '',
            password: '',
            role: '1',
          }}
          validationSchema={LoginSchema}
          onSubmit={(values) => {
            // same shape as initial values
              //console.log(values)
            sendLogin(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="body">
                <Field
                  className="form-control"
                  placeholder="Email or username"
                  type="text"
                  id="username"
                  name="username"
                />
                {errors.username && touched.username && (
                  <div className="error">{errors.username}</div>
                )}
                <Field
                  className="form-control"
                  placeholder="Password"
                  type="password"
                  id="password"
                  name="password"
                />
                {errors.password && touched.password && (
                  <div className="error">{errors.password}</div>
                )}
                <Field className="form-control" as="select" id="role" name="role">
                  <option value={''}>Select role</option>
                  {roles.map((item) => (
                    <option value={item.id} key={`role-${item.id}`}>
                      {ROLE[item.role]}
                    </option>
                  ))}
                </Field>
                {errors.role && touched.role && <div className="error">{errors.role}</div>}
                {user.error && <div className="error">{user.message}</div>}
                <div id="forgot-text">
                  <a href="#" onClick={() => setShowLogin(false)}>
                    Forgot your password?
                  </a>
                </div>
              </div>
              <div className="footer">
                {/* <button className='btn btn-outline-primary'  onClick={()=>{dispatch(login())}}>Log in</button> */}
                <Button type="submit">
                  {user.loading ? (
                    <>
                      <Spinner animation="border" variant="light" /> Loading...
                    </>
                  ) : (
                    'Log in'
                  )}
                </Button>

                <p id="singup-text">
                  Don't have an account?{' '}
                  <a href="https://offmarketbroker.com/listing-agent-register/"> Sign up for free </a>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="login">
      <section className="content-login">
        <a href="https://offmarketbroker.com">
          <img className="img-fluid responsive logo" src={logo} alt="logo_image" />
        </a>
        {!!params.token ? (
          <Recover recover token={params.token} />
        ) : showLogin ? (
          renderLogin()
        ) : (
          <Recover />
        )}
      </section>
    </div>
  );
};

export default Login;
