import React, { useState, useEffect } from 'react';
import { Card, DTable, Loading, EmptyList } from '../../../components/OMB';
import { getApi, LIST_INVITE } from '../../../utils/services';
import './styles.css';

const ROLE = {
  ROLE_ADMIN: 'Admin',
  ROLE_LISTING_AGENT: 'Listing Agent',
  ROLE_BUYER_AGENT: 'Buyer Agent',
  ROLE_BUYER: 'Buyer',
  ROLE_SPONSOR: 'Sponsor',
};

const InvitedMembers = ({ userId }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(`${LIST_INVITE}${userId}`, userToken)
      .then((response) => {
        setInfo(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!info) {
      const dataInvites = info.inviteResponse.map(
        ({ name, userType, registered, referral, totalAmountEarnedJoining }, index) => {
          return {
            id: `invite-${index}`,
            name,
            userType: ROLE[userType],
            registered,
            referral,
            totalProfits: `$${totalAmountEarnedJoining}`,
          };
        },
      );
      setData(dataInvites);
    }
  }, [info]);

  const columns = [
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'User Type',
      selector: 'userType',
    },
    {
      name: 'Registered',
      selector: 'registered',
    },
    {
      name: 'Referral',
      selector: 'referral',
    },
    {
      name: 'Total Profits',
      selector: 'totalProfits',
    },
  ];

  return (
    <div className="invited-members-cards">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Card title="User’s Invited Members">
            {data.length === 0 ? <EmptyList /> : <DTable columns={columns} data={data} />}
          </Card>
          <Card title="Balance">
            <div className="balance-details-info">
              <p>
                Total Income From Sponsors: <strong>${info.totalAmountEarnedQuarter}</strong>
              </p>
              <p>
                Total amount Earned (since joining):{' '}
                <span className="bold">${info.totalAmountEarnedJoining}</span>
              </p>
              <p>
                Date Of Next Payment: <span className="bold">{info.dateNextPayment}</span>
              </p>
            </div>
          </Card>
        </>
      )}
    </div>
  );
};

export default InvitedMembers;
