import React from 'react'
import MarketingTabs from '../../containers/MarketingTabs';
import './styles.css';

const Marketing = () => {
    return (
        <div className="marketing">
            <MarketingTabs />
        </div>
    )
}

export default Marketing;
