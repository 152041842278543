import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list_sponsorships } from '../redux/adminDucks';

export const useSponsorships = () => {
  const { sponsorships, loading } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(list_sponsorships());
  }, []);

  return [sponsorships, loading];
};
