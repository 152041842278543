import React from 'react'
import './box.css'
const Box=(props)=>{

    return(
        <React.Fragment>
        {
            (props?.title)?
                <div className="title">
                    {props.title}
                </div>
            :null
        }
        <div className='box'>
           
            
            {props.children}
        </div>
        </React.Fragment>
    )
}
export default Box