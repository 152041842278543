import React from 'react';
import ProfileForm from '../../ProfileForm';

const UserProfile = ({ userId }) => {
  return (
    <ProfileForm userId={userId} />
  );
};

export default UserProfile;
