import React from 'react';

const Checkbox = ({ id, handleChange, checked }) => {
  return (
    <input
      type="checkbox"
      onChange={(e) => handleChange(id, e.target.checked)}
      defaultChecked={checked}
    />
  );
};

export default Checkbox;
