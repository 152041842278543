import React from 'react';
import { useSelector } from 'react-redux';
import { Padlock, Payout } from '../../icons';
import Button from '../Button/Button';
import MapPointer from './MapPointer';
import './styles.css';

const TitleAddress = ({ title }) => {
  const {
    roleActive,
    info: { membershipStatus },
  } = useSelector((store) => store.user);
  return (
    <div className="title-address">
      <MapPointer />
      <p
        className={
          (membershipStatus === false && roleActive === 'ROLE_BUYER_AGENT') ||
          (membershipStatus === false && roleActive === 'ROLE_BUYER')
            ? 'bold mr-4'
            : 'bold'
        }
      >
        {title}
      </p>
      {(membershipStatus === false && roleActive === 'ROLE_BUYER_AGENT') ||
      (membershipStatus === false && roleActive === 'ROLE_BUYER') ? (
        <Button color="yellow" to={`/${roleActive}/make-payment`}>
          <Padlock />
          UNLOCK PREMIUM ACCESS
        </Button>
      ) : null}
    </div>
  );
};

export default TitleAddress;
