import React from 'react';
import { Link } from 'react-router-dom';
import './button.css';

const Button = (props) => {
  const { to } = props;

  return to ? (
    <Link
      style={props.style}
      {...props}
      className={`btn-omb link-omb ${props?.color ? 'btn-omb-' + props?.color : 'btn-omb-primary'} ${props?.disabled ? 'disabled' : ''} ${props?.ml ? props?.ml : ''} ${props?.mt ? props?.mt : ''}`}
    >
      {props.children}
    </Link>
  ) : (
    <button
      style={props.style}
      {...props}
      className={`btn-omb link-omb ${props?.color ? 'btn-omb-' + props?.color : 'btn-omb-primary'} ${props?.disabled ? 'disabled' : ''} ${props?.ml ? props?.ml : ''} ${props?.mt ? props?.mt : ''}`}
    >
      {props.children}
    </button>
  );
};

export default Button;
