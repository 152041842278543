import React from 'react';

const User = (props) => {
  return (
    <svg
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      viewBox="0 0 16 16"
      className="ic-omb bi bi-person-circle"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color}
        d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"
      />
      <path fill={props.color} fillRule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"
      />
    </svg>
  );
};

const Close = (props) => {
  return (
    <svg
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      viewBox="0 0 16 16"
      className="bi bi-x"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

const Edit = (props) => {
  return (
    <svg
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      viewBox="0 0 16 16"
      className="bi bi-x"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
      />
    </svg>
  );
};

const Trash = (props) => {
  return (
    <svg
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      {...props}
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M37.111 9.273h-1.778l-1.778 29.091C33.221 40.334 31.963 42 29.999 42H14c-1.964 0-3.269-1.645-3.556-3.636L8.666 9.273H6.888a.909.909 0 010-1.818h8.889V3.82A1.8 1.8 0 0117.556 2h8.889a1.8 1.8 0 011.778 1.819v3.635h8.889a.909.909 0 010 1.818zM26.444 3.819h-8.889v3.635h8.889zm-16 5.454l1.778 29.091A1.919 1.919 0 0014 40.182h16a1.916 1.916 0 001.778-1.818l1.778-29.091zM27.3 36.545a.9.9 0 01-.856-.941l1.29-21.795a.889.889 0 111.776.066l-1.289 21.794a.9.9 0 01-.921.876zm-5.3 0a.9.9 0 01-.889-.909V13.818a.889.889 0 111.778 0v21.818a.9.9 0 01-.889.909zm-5.3 0a.9.9 0 01-.921-.875L14.47 13.877a.89.89 0 111.777-.068l1.308 21.794a.9.9 0 01-.855.942z"
      />
    </svg>
  );
};

const ChevronRight = (props) => {
  return (
    <svg
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      viewBox="0 0 16 16"
      className="bi bi-x"
      fill="currentColor"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={props.color}
        fillRule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      />
    </svg>
  );
};

const Income = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      viewBox="0 0 32 37"
      {...props}
    >
      <path
        stroke="#2ea639"
        fill={props.color}
        fillRule="evenodd"
        d="M26.344 33H6.656A.646.646 0 016 32.364V14.546a.647.647 0 01.656-.636h7.219a.637.637 0 110 1.273H7.313v16.545h18.375V15.183h-6.562a.637.637 0 110-1.273h7.219a.647.647 0 01.656.636v17.818a.646.646 0 01-.657.636zm-5.058-11.268h0a.664.664 0 01.464-.187.646.646 0 01.656.636.625.625 0 01-.192.45l-5.25 5.091h0a.671.671 0 01-.928 0h0l-5.25-5.091a.625.625 0 01-.192-.45.646.646 0 01.656-.636.664.664 0 01.464.187h0l4.13 4v-20.1a.657.657 0 011.313 0v20.1z"
      />
    </svg>
  );
};

const Payout = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      viewBox="0 0 32 37"
      {...props}
    >
      <path
        stroke="#f04a51"
        fill={props.color}
        fillRule="evenodd"
        d="M26.313 34H5.688a.684.684 0 01-.687-.682V14.227a.685.685 0 01.688-.682h7.563a.682.682 0 110 1.364H6.377v17.727h19.25V14.909H18.75a.682.682 0 110-1.364h7.563a.685.685 0 01.688.682v19.091a.684.684 0 01-.688.682zm-4.812-23.182a.686.686 0 01-.486-.2l-4.326-4.291v21.536a.688.688 0 01-1.375 0V6.327l-4.326 4.291a.685.685 0 11-.972-.964h0l5.5-5.455a.691.691 0 01.972 0l5.5 5.455h0a.681.681 0 01-.486 1.164z"
      />
    </svg>
  );
};
const Padlock = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.size ? props.size : '1em'}
      height={props?.size ? props.size : '1em'}
      viewBox="0 0 54 54"
      {...props}
    >
      <g id="Artboard_3" data-name="Artboard – 3" clip-path="url(#clip-Artboard_3)">
        <path
          id="Unlock_2x.png"
          data-name="Unlock@2x.png"
          d="M470,148H446a4,4,0,0,1-4-4V126a4,4,0,0,1,4-4h22v-6a10,10,0,0,0-20,0,1,1,0,0,1-2,0,12,12,0,0,1,24,0v6a4,4,0,0,1,4,4v18A4,4,0,0,1,470,148Zm2-22a2,2,0,0,0-2-2H446a2,2,0,0,0-2,2v18a2,2,0,0,0,2,2h24a2,2,0,0,0,2-2Zm-11.034,10.662A3.146,3.146,0,0,1,461,137a3,3,0,0,1-6,0,2.909,2.909,0,0,1,.034-.338,4,4,0,1,1,5.932,0ZM458,132a1.994,1.994,0,0,0-1,3.723V137a1,1,0,0,0,2,0v-1.277A1.994,1.994,0,0,0,458,132Z"
          transform="translate(-431 -99)"
          fill="#1b2652"
          stroke="#1b2652"
          stroke-width="1"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  );
};
export { User, Close, Edit, Trash, ChevronRight, Income, Payout, Padlock };
