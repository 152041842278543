import React from 'react';
import { Card, GoBack } from '../../../components/OMB';
import { PropertyForm } from '../../../containers';
import alertBlue from '../../../assets/iconos/bulk-import-blue.svg';
import alertRed from '../../../assets/iconos/suggested-price.svg';
import './addProperty.css';

const AddProperty = (props) => {
  return (
    <div className="box-property">
      <GoBack {...props} />
      <p className="sub-title demi">
        <img src={alertBlue} /> To import multiple properties, click on "Bulk Import" in the left
          main dashboard
        </p>
      <Card title={'Add Property'}>
        <h4>Property information</h4>
        <hr />
        <PropertyForm />
      </Card>
      <p className="sub-title demi">
        <img className="alert-red" src={alertRed} /> Since Coming Soon and Post Sale Properties do
          not have a List Price yet, you may include a Suggested Price to assist members in
          submitting Pre-List or Post Sale Offers (ie. BPO or Appraisal value)
        </p>
    </div>
  );
};

export default AddProperty;
