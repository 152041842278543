import React, { useState, useEffect } from 'react';
import { Loading, Card, EmptyList, DTable } from "../../../components/OMB";
import { formatPrice } from '../../../utils/services';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSponsorships } from '../../../hooks/useSponsorships';

const SponsorshipsList = () => {
  const { roleActive } = useSelector((store) => store.user);
  const [sponsorships, loading] = useSponsorships();
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const dataSponsorships = sponsorships.map(({ idUser, name, activeSponsorships, profits }) => {
      return {
        "id": idUser,
        name,
        activeSponsorships,
        "totalProfits": formatPrice(profits),
        "icon": '>',
        "handleClick": () => history.push(`/${roleActive}/sponsorship-details/${idUser}`)
      }
    })
    setData(dataSponsorships);
  }, [sponsorships]);

  const columns = [
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'Active Sponsorships',
      selector: 'activeSponsorships',
    },
    {
      name: 'Total Profits',
      selector: 'totalProfits',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px'
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <Card
      title={`Sponsorships (${data.length})`}
      // btnTitle="Download Template"
      // btnHandle={handleDownload}
    >
      {
        loading ? (
          <Loading />
        )
          : (
            sponsorships.length === 0
              ? <EmptyList />
              : <DTable columns={columns} data={data} />
          )
      }
    </Card>
  );
};

export default SponsorshipsList;
