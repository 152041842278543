import React, { useState, useEffect } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Button } from '../../components/OMB';
import './styles.css';
import { postApi, PROFILE_PURCHASE_AREA, PURCHASE_AREA_SAVE } from '../../utils/services';
import { useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const FormSchema = Yup.object().shape({
  state: Yup.string().required('Required'),
});

const PurchaseAreasModal = ({
  showModal,
  setShowModal,
  buyerProfileId,
  states,
  areaList,
  setAreaList,
  type = 'buyerProfile',
  userProfileID,
}) => {
  const userToken = localStorage.getItem('tokenOMB');
  const {
    info: { id: userId },
  } = useSelector((store) => store.user);
  const [selectedState, setSelectedState] = useState(null);
  const [stateName, setStateName] = useState('');
  const [loading, setLoading] = useState(false);
  const initValues = {
    state: '',
    county: '',
    zipCode: '',
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const area = {
      id: Math.random().toString(36).substr(2, 9),
      state: { id: parseInt(values.state), name: stateName, code: stateName },
      county: { name: values.county },
      zipCode: values.zipCode,
    };

    if (!!type) {
      let dataForm = {};
      let URL = `${PURCHASE_AREA_SAVE}`;
      if (type === 'buyerProfile') {
        dataForm = {
          user: {
            id: parseInt(userId),
          },
          offer: {
            id: parseInt(buyerProfileId),
          },
          estate: {
            id: parseInt(values.state),
          },
          county: {
            name: values.county,
          },
          zipCode: values.zipCode,
        };
      }
      if (type === 'profile') {
        URL = `${PROFILE_PURCHASE_AREA}`;
        dataForm = {
          profile: {
            id: parseInt(userProfileID),
          },
          estate: {
            id: parseInt(values.state),
          },
          county: {
            name: values.county,
          },
          zipCode: values.zipCode,
        };
      }

      postApi(URL, dataForm, userToken)
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            setAreaList([...areaList, area]);
            setShowModal(false);
          } else {
            console.log('NOT SAVED', response);
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response) {
            console.log(error.response.data);
            console.log('Código:', error.response.status);
          }
        });
    } else {
      setAreaList([...areaList, area]);
      setShowModal(false);
    }
  };

  useEffect(() => {
    const state = states.find((item) => item.id === parseInt(selectedState));
    setStateName(state?.code);
  }, [selectedState]);

  const handleChange = (value) => setSelectedState(value);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Add New Area</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initValues}
        validationSchema={FormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values }) => (
          <Form className="purchase-areas-modal">
            <Modal.Body>
              <div className="form-row">
                <div className="form-group">
                  <label className="form-label">State</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="state"
                    fieldinputprops={{ onChange: setTimeout(() => handleChange(values.state), 0) }}
                  >
                    <option value={''}>Select</option>
                    {states.map((item) => (
                      <option value={item.id} key={`${item.code}-${item.id}`}>
                        {item.code}
                      </option>
                    ))}
                  </Field>
                  {errors.state && touched.state && <div className="error">{errors.state}</div>}
                </div>
                <div className="form-group">
                  <label className="form-label">County</label>
                  <Field className="form-control" type="text" name="county" />
                  {errors.county && touched.county && <div className="error">{errors.county}</div>}
                </div>
                <div className="form-group">
                  <label className="form-label">Zip Code</label>
                  <Field className="form-control" type="text" name="zipCode" />
                  {errors.zipCode && touched.zipCode && (
                    <div className="error">{errors.zipCode}</div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button color="btn-gray" onClick={handleCancel}>
                CANCEL
              </Button>
              <Button type="submit">
                {loading ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-2" />
                ) : null}
                Add
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PurchaseAreasModal;
