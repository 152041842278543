import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Modal, Spinner } from 'react-bootstrap';
import { getApi, GET_UTILS, postApi, SAVE_EMAIL_MARKETING } from '../../../utils/services';
import { Button, Loading, ModalMessage } from '../../../components/OMB';

function NewEmail({
  type = 'buyersAgents',
  showModal = false,
  setShowModal,
  itemsSelected = [],
  selectedAll = false,
  roleUser = null,
  state = null,
}) {
  const userToken = localStorage.getItem('tokenOMB');
  const [selectEmail, setSelectEmail] = useState('');
  const [templateEmails, setTemplateEmails] = useState([]);
  const [arrayEmails, setArrayEmails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [titleModal, setTitleModal] = useState('Error Sending Email');
  const [messageModal, setMessageModal] = useState(
    'The email could not be sent. Please try again.',
  );
  const schemaRequired = Yup.object().shape({
    address: Yup.string().required('Required'),
    code: Yup.string().required('Required'),
    subjectEmail: Yup.string().required('Required'),
  });
  const schema = Yup.object().shape({
    address: Yup.string(),
    code: Yup.string().required('Required'),
    subjectEmail: Yup.string().required('Required'),
  });
  const [arrBuyer, setArrBuyer] = useState([]);
  const [arrBuyerAgent, setArrBuyerAgent] = useState([]);
  //const [typeEmails, setTypeEmails] = useState([]);
  const [emailsCategory, setEmailsCategory] = useState([]);
  // const [selectEmailCategory, setSelectEmailCategory] = useState([]);
  const [selectTitle, setSelectTitle] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [titles, setTitles] = useState([
    {
      id: 1,
      name: 'Explore OFF-MARKET inventory on OMB',
    },
  ]);

  useEffect(() => {
    let arr = [];
    itemsSelected.map((item) => arr.push(item.email));
    setArrayEmails(arr);
  }, [itemsSelected]);

  const callEndPointUtils = () => {
    setLoadingData(true);
    getApi(GET_UTILS, userToken)
      .then((response) => {
        setLoadingData(false);
        if (!!response.data.email) {
          console.log('aqi');
          //setTypeEmails(response.data.email);
          setTemplateEmails(response.data.email);
        }
      })
      .catch((error) => {
        setLoadingData(false);
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (showModal) {
      callEndPointUtils();
    }
  }, [showModal]);

  /* useEffect(() => {
    const typeBuyer = typeEmails.filter((item) => item?.typeUser?.role === 'ROLE_BUYER');
    console.log('typeBuyer', typeBuyer)
    const resultBuyer = [];
    typeBuyer.map((item) =>
      resultBuyer.push({
        id: item.id,
        name: item.name,
        emailCategory: item.emailCategory,
      }),
    );
    setArrBuyer(resultBuyer);
    const typeBuyerAgent = typeEmails.filter((item) => item?.typeUser?.role === 'ROLE_BUYER_AGENT');
    console.log('typeBuyerAgent', typeBuyerAgent)
    const resultBuyerAgent = [];
    typeBuyerAgent.map((item) =>
      resultBuyerAgent.push({
        id: item.id,
        name: item.name,
        emailCategory: item.emailCategory,
      }),
    );
    setArrBuyerAgent(resultBuyerAgent);
  }, [typeEmails]); */

  /* useEffect(() => {
    if (type === 'buyersAgents' && arrBuyerAgent.length > 0) {
      console.log('type buyer agents');
      setTemplateEmails(arrBuyerAgent);
    } else {
      setTemplateEmails(arrBuyer);
    }
  }, [type, arrBuyer, arrBuyerAgent]); */

  /* useEffect(() => {
    console.log('templateEmails', templateEmails);
  }, [templateEmails]); */

  /* useEffect(() => {
    const filterEmailCategory = templateEmails.filter((item) => item.id === parseInt(selectEmail));
    setEmailsCategory(filterEmailCategory[0]?.emailCategory);
  }, [selectEmail]); */

  const FormikRender = () => {
    return (
      <Formik
        enableReinitialize={true}
        initialValues={{
          address: '',
          code: '',
          subjectEmail: '',
        }}
        validationSchema={selectEmail === '1' ? schemaRequired : schema}
        onSubmit={(values) => {
          console.log(values);
          setLoading(true);
          let data = {};
          const info = {
            email: arrayEmails,
            category: parseInt(selectEmail),
            titleEmail: selectTitle,
            subject: values.subjectEmail,
            code: values.code,
            selectedAll: false

          };
          const infoTwo = {
            category: parseInt(selectEmail),
            code: values.code,
            selectedAll: selectedAll,
            state: state,
            titleEmail: selectTitle,
            subject: values.subjectEmail,
            roleuser: roleUser,
          };
          const direction = {
            address: values.address,
          };
          data = selectEmail === '1' ? { ...info, ...direction } : { ...info };
          if (selectedAll) {
            console.log('entro', selectedAll);
            data = selectEmail === '1' ? { ...infoTwo, ...direction } : { ...infoTwo };
          } else {
            data = selectEmail === '1' ? { ...info, ...direction } : { ...info };
          }
          console.log('data', data);
          postApi(SAVE_EMAIL_MARKETING, data, userToken)
            .then((response) => {
              setLoading(false);
              console.log(response);
              if (response.status === 200) {
                setShowModal(false);
                setShowModalMessage(true);
                setTitleModal('Email Sent');
                setMessageModal('The email has been successfully sent.');
              }
            })
            .catch((error) => {
              if (error.response) {
                setShowModal(false);
                setShowModalMessage(true);
                console.log(error.response.data);
                console.log('Código:', error.response.status);
              }
            });
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="row">
              <div className="col col-12">
                <div className="row">
                  <div className="col col-6 mb-4">
                    <label className="form-label bold">Select Template</label>
                    <select
                      className="form-control"
                      as="select"
                      id="selectEmail"
                      name="selectEmail"
                      value={selectEmail}
                      onChange={(e) => setSelectEmail(e.target.value)}
                      required
                    >
                      <option value={''}>Select</option>
                      {templateEmails?.map((item, i) => {
                        console.log('item', item);
                        return (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/* {!!selectEmail ? (
                    <div className="col col-6 mb-4">
                      <select
                        className="form-control"
                        as="select"
                        id="selectEmailCategory"
                        name="selectEmailCategory"
                        value={selectEmailCategory}
                        onChange={(e) => setSelectEmailCategory(e.target.value)}
                        required
                      >
                        <option value={''}>Select</option>
                        {emailsCategory?.map((item, i) => {
                          return (
                            <option value={item.id} key={i}>
                              {item.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    ''
                  )} */}
                  <div className="col col-6 mb-4">
                    <label className="form-label bold">Select Title</label>

                    <select
                      className="form-control"
                      as="select"
                      id="selectTitle"
                      name="selectTitle"
                      value={selectTitle}
                      onChange={(e) => setSelectTitle(e.target.value)}
                      required
                    >
                      <option value={''}>Select</option>
                      {titles?.map((item, i) => {
                        return (
                          <option value={item.id} key={i}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              {selectEmail === '1' ? (
                <>
                  <div className="col col-6 mb-4">
                    <label className="form-label bold">Property Address</label>
                    <Field className="form-control" type="text" name="address" />
                    {errors.address && touched.address && (
                      <div className="error">{errors.address}</div>
                    )}
                  </div>
                </>
              ) : null}
              <div className="col col-12 mb-4">
                <label className="form-label bold">Subject Email</label>
                <Field className="form-control" type="text" name="subjectEmail" />
                {errors.subjectEmail && touched.subjectEmail && (
                  <div className="error">{errors.subjectEmail}</div>
                )}
              </div>
              <div className="col col-6">
                <label className="form-label bold">Referral Code</label>
                <Field className="form-control" type="text" name="code" />
                {errors.code && touched.code && <div className="error">{errors.code}</div>}
              </div>
              <div className="col col-12 d-flex mt-4 justify-content-end">
                <Button color="btn-gray" onClick={() => setShowModal(false)}>
                  CANCEL
                </Button>
                <Button type="submit" disabled={!!selectEmail && !!selectTitle ? false : true}>
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  ) : null}
                  SEND
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };
  return (
    <>
      <Modal show={showModal} centered size="lg" onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex align-items-center">
              <span className="mr-2">Select Email</span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{loadingData ? <Loading /> : <FormikRender />}</Modal.Body>
      </Modal>
      {showModalMessage && (
        <ModalMessage
          title={titleModal}
          message={messageModal}
          btnTitle="OK"
          redirect="false"
          type="message"
        />
      )}
    </>
  );
}

export default NewEmail;
