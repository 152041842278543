import React, { useEffect, useState } from 'react';
import { ContentTab, ItemTab } from '../../components/OMB';
import ListingAgentsList from "./ListingAgentsList";
import BuyersAgentsList from "./BuyersAgentsList";
import BuyersList from "./BuyersList";
import SponsorsList from "./SponsorsList";
import { useAllUsers } from '../../hooks/useAllUsers';

const MembersTabs = () => {
  const [members, loading] = useAllUsers();
  const [listingAgents, setListingAgents] = useState([]);
  const [buyersAgents, setBuyersAgents] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [sponsors, setSponsors] = useState([]);

  useEffect(() => {
    if (members.length > 0) {
      const listingAgentsList = members.filter(item => item.roles === "LISTING AGENT")
        .map(item => ({ ...item, "roles": "Listing Agent" }));
      const buyersAgentsList = members.filter(item => item.roles === "BUYER AGENT")
        .map(item => ({ ...item, "roles": "Buyer Agent" }));
      const buyersList = members.filter(item => item.roles === "BUYER")
        .map(item => ({ ...item, "roles": "Buyer" }));
      const sponsorsList = members.filter(item => item.roles === "SPONSOR")
        .map(item => ({ ...item, "roles": "Sponsor" }));

      setListingAgents(listingAgentsList);
      setBuyersAgents(buyersAgentsList);
      setBuyers(buyersList);
      setSponsors(sponsorsList);
    };
  }, [members]);

  return (
    <div className="members-tabs">
      <ContentTab defaultActiveKey="listing-agents" id="uncontrolled-tab-example">
        <ItemTab eventKey="listing-agents" title="Listing Agents">
          <ListingAgentsList list={listingAgents} loading={loading} />
        </ItemTab>
        <ItemTab eventKey="buyers-agents" title="Buyer's Agents">
          <BuyersAgentsList list={buyersAgents} loading={loading} />
        </ItemTab>
        <ItemTab eventKey="buyers" title="Buyers">
          <BuyersList list={buyers} loading={loading} />
        </ItemTab>
        <ItemTab eventKey="sponsors-list" title="Sponsors">
          <SponsorsList list={sponsors} loading={loading} />
        </ItemTab>
      </ContentTab>
    </div>
  );
};

export default MembersTabs;
