export const formatPrice = (dataPrice) => {
  const formatedPrice = new Intl.NumberFormat('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
          style: 'currency',
          currency: 'USD',
        }).format(dataPrice)

  return formatedPrice;
};
