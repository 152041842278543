import React from 'react';
import { MembersTabs } from '../../../containers';

const Members = () => {
  return (
    <div className="members">
      <MembersTabs />
    </div>
  );
};

export default Members;
