import React, { useEffect, useState } from 'react';
import { Card, Button, ModalMessage, DatePickerField } from '../../components/OMB';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { getApi, postApi, LIST_ADDRESS, ACCOUNTING_SAVE } from '../../utils/services';
import { useHistory } from 'react-router-dom';
import './styles.css';

const FormSchema = Yup.object().shape({
  listingAgentName: Yup.string().required('Required'),
  propertyStreetAddress: Yup.string().required('Required'),
  transactionDate: Yup.date().required('Required').nullable(),
});

const AddTransactionFeeForm = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const [listingAgents, setListingAgents] = useState([]);
  const [listingAgentSelected, setListingAgentSelected] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [showCheck, setShowCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(LIST_ADDRESS, userToken)
      .then((response) => {
        setListingAgents(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!listingAgentSelected) {
      const property = listingAgents.find((item) => item.idUser === listingAgentSelected);
      setAddresses(property.addressListingResponse);
    } else {
      setAddresses([])
    }
  }, [listingAgentSelected]);

  const handleChangeAgent = (value) => {
    setListingAgentSelected(parseInt(value))
  };

  const handleSubmit = ({ listingAgentName, propertyStreetAddress, transactionDate }) => {
    const dataForm = {
      "user": {
        "id": parseInt(listingAgentName)
      },
      "property": {
        "id": parseInt(propertyStreetAddress)
      },
      "date": transactionDate
    };

    postApi(ACCOUNTING_SAVE, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          history.goBack();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  }

  const initValues = {
    listingAgentName: '',
    propertyStreetAddress: '',
    transactionDate: new Date()
  }

  return (
    <div className="add-transaction-fee-form">
      <Formik
        initialValues={initValues}
        validationSchema={FormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form>
            <Card title="Add Transaction Fee">
              <div className="form-row cols-3">
                <div className="form-group">
                  <label className="form-label">Listing Agent’s Name</label>
                  <Field className="form-control" as="select" name="listingAgentName" fieldinputprops={{ onChange: setTimeout(() => handleChangeAgent(values.listingAgentName), 0) }}>
                    <option value={''}>Select</option>
                    {
                      listingAgents.map((item, index) => (
                        <option value={item.idUser} key={`${item}-${index}`}>{item.names}</option>
                      ))
                    }
                  </Field>
                  {errors.listingAgentName && touched.listingAgentName && (
                    <div className="error">{errors.listingAgentName}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Property Street Address</label>
                  <Field className="form-control" as="select" name="propertyStreetAddress" >
                    <option value={''}>Select</option>
                    {
                      addresses.map((item) => (
                        <option value={item.idProperty} key={`address-${item.idProperty}`}>{item.address}</option>
                      ))
                    }
                  </Field>
                  {errors.propertyStreetAddress && touched.propertyStreetAddress && (
                    <div className="error">{errors.propertyStreetAddress}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Date Of Transaction</label>
                  <DatePickerField
                    selected={values.transactionDate}
                    name="transactionDate"
                    onChange={date => setFieldValue('transactionDate', date)}
                  />
                  {errors.transactionDate && touched.transactionDate && (
                    <div className="error">{errors.transactionDate}</div>
                  )}
                </div>
              </div>
            </Card>
            <div className="btn-container">
              <Button color="yellow" type="submit">
                SAVE CHANGES
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {showCheck && <ModalMessage title="Saved Sponsorship" message="The Sponsorship has been saved successfully." btnTitle="Return to Sponsorship Oportunities" redirect="true" />}
      {showError && <ModalMessage title="Error Saving The Sponsorship" message="The Sponsorship could not be saved. Please review your information and try again." />}
    </div>
  );
};

export default AddTransactionFeeForm;
