import React from 'react';
import { AgentsProfileForm } from "../../containers";

const AgentsProfile = (props) => {
  return (
    <div className="agents-profile">
      <AgentsProfileForm />
    </div>
  )
}

export default AgentsProfile;
