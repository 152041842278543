import React, { useState, useEffect } from 'react';
import {
  Card,
  Button,
  CancelSubscription,
  ChangePassword,
  ModalMessage,
  Loading,
} from '../../components/OMB';
import { Edit } from '../../components/icons';
import imgProfile from '../../assets/img/image.png';
import { useSelector } from 'react-redux';
import {
  UPLOAD_IMAGE,
  postApiFile,
  postApi,
  PROFILE_UPDATE,
  GET_USER,
  getApi,
  GET_UTILS,
} from '../../utils/services';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { UploaderFile } from '../../components/OMB/UploaderFile';
import { Spinner } from 'react-bootstrap';
import { useListStates } from '../../hooks/useListStates';
import MaskedInput from 'react-text-mask';
import './styles.css';
import { PurchaseAreasList } from '..';
import PurchaseAreasModal from '../PurchaseAreasModal';

const BuyerAgentSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  suite: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zipCode: Yup.string().required('Required'),
  brokerageName: Yup.string().required('Required').nullable(),
  brokerageAddress: Yup.string().required('Required'),
  brokerageSuite: Yup.string().nullable(),
  brokerageCity: Yup.string().required('Required'),
  brokerageState: Yup.string().required('Required'),
  brokerageZipCode: Yup.string().required('Required'),
  stateOfIssuance: Yup.string().required('Required'),
  brokerLicenseNumber: Yup.string('Required').required('Required').nullable(),
});

const BuyerSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  suite: Yup.string(),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zipCode: Yup.string().required('Required'),
});

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const AgentsProfileForm = ({ profileId }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive, info } = useSelector((store) => store.user);
  const user = JSON.parse(localStorage.getItem('state'));
  const userID = user.user.info.id;
  const [routeImg, setRouteImg] = useState();
  const [states] = useListStates();
  const [routeLicense, setRouteLicense] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [titleModalMessage, setTitleModalMessage] = useState('Profile Update');
  const [messageModalMessage, setMessageModalMessage] = useState('Your changes have been saved.');
  const [infoData, setInfoData] = useState(info);
  const { firstName, lastName, username, profile } = infoData;
  const { phone, brokerageName, licenseNumber, profileAddress, estateIssuance } = profile;
  const [addresses] = profileAddress;
  const { address, addressBrokerage } = addresses;
  const urlApi = `${GET_USER}${!!profileId ? profileId : userID}`;
  const [role, setRole] = useState(roleActive);
  const [showModalAddArea, setShowModalAddArea] = useState(false);
  const [purchaseAreaList, setPurchaseAreaList] = useState([]);
  const [purchaseAreaListNew, setPurchaseAreaListNew] = useState([]);
  const [statesAreas, setStatesAreas] = useState([]);
  const [loadingRender, setLoadingRender] = useState(false);

  const callEndPoints = async () => {
    setLoadingRender(true);
    await getApi(urlApi, userToken)
      .then((response) => {
        setLoadingRender(false);
        if (response.status === 200) {
          setInfoData(response.data);
        }
      })
      .catch((error) => {
        setLoadingRender(false);
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en adminNavBarLink:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!infoData.userRole[0].role.role && !!profileId) {
      setRole(infoData.userRole[0].role.role);
    }
    if (!!infoData.profile?.purchaseAreaProfile) {
      setPurchaseAreaList(infoData.profile?.purchaseAreaProfile);
    }
    console.log(infoData);
  }, [infoData]);

  useEffect(() => {
    callEndPoints();
  }, []);

  const loadFile = (event, d) => {
    setLoading(true);
    var output = document.getElementById(d);
    output.src = URL.createObjectURL(event.target.files[0]);

    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };

    const fd = new FormData();
    fd.append('upload', event.target.files[0]);
    fd.append('typeFile', 3);

    postApiFile(UPLOAD_IMAGE, fd, userToken, d)
      .then((response) => {
        const data = response.data.upload;
        setLoading(false);
        if (d === 'image-profile') {
          setRouteImg(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const handleShowModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    if (!!profile.file?.photo) {
      setRouteImg(profile.file?.photo);
    }
    if (!!profile.file?.license) {
      setRouteLicense(profile.file?.license);
    }
  }, [infoData]);

  const handleSubmit = (values) => {
    setLoading(true);
    let addressBrokerageData = {
      street: values.brokerageAddress,
      city: values.brokerageCity,
      zipCode: values.brokerageZipCode,
      unit: values.brokerageSuite,
      status: 1,
      estate: {
        id: values.brokerageState,
      },
    };
    if (!!addressBrokerage) {
      addressBrokerageData = {
        id: addressBrokerage.id,
        street: values.brokerageAddress,
        city: values.brokerageCity,
        zipCode: values.brokerageZipCode,
        unit: values.brokerageSuite,
        status: 1,
        estate: {
          id: values.brokerageState,
        },
      };
    }
    const profileAddressBuyerAgent = [
      {
        id: addresses.id,
        address: {
          id: address.id,
          street: values.address,
          city: values.city,
          zipCode: values.zipCode,
          unit: values.suite,
          status: 1,
          estate: {
            id: values.state,
          },
        },
        addressBrokerage: addressBrokerageData,
      },
    ];
    const profileAddressBuyer = [
      {
        id: addresses.id,
        address: {
          id: address.id,
          street: values.address,
          city: values.city,
          zipCode: values.zipCode,
          unit: values.suite,
          status: 1,
          estate: {
            id: values.state,
          },
        },
      },
    ];
    const body = {
      id: !!profileId ? infoData.id : info.id,
      firstName: values.firstName,
      lastName: values.lastName,
      username: values.email,
      profile: {
        id: !!profileId ? infoData.profile.id : info.profile.id,
        phone: values.phone,
        brokerageName: values.brokerageName,
        licenseNumber: values.brokerLicenseNumber,
        profileAddress:
          role === 'ROLE_BUYER_AGENT' ? profileAddressBuyerAgent : profileAddressBuyer,
        estateIssuance: {
          id: parseInt(values.stateOfIssuance),
        },
        file: {
          photo: routeImg,
          license: routeLicense,
        },
      },
      userRole: [
        {
          role: {
            id: infoData.userRole[0].role.id,
          },
        },
      ],
    };
    postApi(PROFILE_UPDATE, body, userToken)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setShowModalMessage(true);
          window.location.reload();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const handleAddArea = (e) => {
    e.preventDefault();
    setShowModalAddArea(true);
  };

  useEffect(() => {
    const array = [];
    if (!!purchaseAreaList) {
      purchaseAreaList.map((area) => {
        delete area.id;
        array.push(area);
      });
    }
    setPurchaseAreaListNew(array);
  }, [purchaseAreaList]);

  const callEndPointUtils = () => {
    getApi(GET_UTILS, userToken)
      .then((response) => {
        const { estate } = response.data;
        setStatesAreas(estate);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndPointUtils();
  }, []);

  const handleModalSubmit = () => {
    setShowModalAddArea(false);
  };

  const RenderForm = () => {
    return (
      <div className="agents-profile-form">
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: firstName,
            lastName: lastName,
            email: username,
            phone: phone,
            address: address.street,
            suite: address.unit,
            city: address.city,
            state: address.estate?.id,
            zipCode: address.zipCode,
            brokerageName: brokerageName,
            brokerageAddress: addressBrokerage?.street,
            brokerageSuite: addressBrokerage?.unit,
            brokerageCity: addressBrokerage?.city,
            brokerageState: addressBrokerage?.estate.id,
            brokerageZipCode: addressBrokerage?.zipCode,
            stateOfIssuance: estateIssuance.id,
            brokerLicenseNumber: licenseNumber,
          }}
          validationSchema={role === 'ROLE_BUYER_AGENT' ? BuyerAgentSchema : BuyerSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <section className="agent-profile">
                <Card title="Personal Information">
                  <div className="form-row form-avatar-wrapper">
                    <div className="d-flex justify-content-center">
                      <div className="form-group profile-picture">
                        <label htmlFor="file-profile">
                          <div className={`box-image-profile ${loading ? 'disabled' : ''}`}>
                            <img
                              src={!!profile.file?.photo ? profile.file.photo : imgProfile}
                              id="image-profile"
                              alt="profile"
                            />
                            <div className="ic-edit">
                              <Edit size={20} color="#FFF" />
                            </div>
                          </div>
                        </label>
                        <label
                          className={`btn-file-profile align-items-center ${
                            loading ? 'disabled' : ''
                          }`}
                          htmlFor="file-profile"
                        >
                          {loading ? (
                            <Spinner animation="border" role="status" size="sm" className="mr-2" />
                          ) : null}
                          Change Photo
                        </label>
                        <input
                          type="file"
                          id="file-profile"
                          onChange={(event) => {
                            const d = 'image-profile';
                            loadFile(event, d);
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="form-row">
                        <div className="form-group">
                          <label className="form-label bold">First Name</label>
                          <Field className="form-control" type="text" name="firstName" />
                          {errors.firstName && touched.firstName && (
                            <div className="error">{errors.firstName}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="form-label bold">Last Name</label>
                          <Field className="form-control" type="text" name="lastName" />
                          {errors.lastName && touched.lastName && (
                            <div className="error">{errors.lastName}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group">
                          <label className="form-label bold">Email</label>
                          <Field className="form-control" type="text" name="email" disabled />
                          {errors.email && touched.email && (
                            <div className="error">{errors.email}</div>
                          )}
                        </div>
                        <div className="form-group">
                          <label className="form-label bold">Phone</label>
                          <Field
                            name="phone"
                            render={({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={phoneNumberMask}
                                id="phone"
                                placeholder=""
                                type="text"
                                className={`form-control`}
                              />
                            )}
                          />
                          {errors.phone && touched.phone && (
                            <div className="error">{errors.phone}</div>
                          )}
                        </div>
                      </div>
                      <Button
                        color="light-blue"
                        type="submit"
                        onClick={(e) => handleShowModal(e)}
                        ml="ml-0"
                        mt="mt-2"
                      >
                        Change Password
                      </Button>
                      {showModal ? (
                        <ChangePassword showModal={showModal} setShowModal={setShowModal} />
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12 col-md-4">
                      <label className="form-label bold">Address</label>
                      <Field className="form-control" type="text" name="address" />
                      {errors.address && touched.address && (
                        <div className="error">{errors.address}</div>
                      )}
                    </div>
                    <div className="form-group col-12 col-md-2">
                      <label className="form-label bold">Suite / Apt</label>
                      <Field className="form-control" type="text" name="suite" />
                      {errors.suite && touched.suite && <div className="error">{errors.suite}</div>}
                    </div>
                    <div className="form-group col-12 col-md-2">
                      <label className="form-label bold">City</label>
                      <Field className="form-control" type="text" name="city" />
                      {errors.city && touched.city && <div className="error">{errors.city}</div>}
                    </div>
                    <div className="form-group col-12 col-md-2">
                      <label className="form-label bold">State</label>
                      <Field className="form-control" as="select" name="state">
                        <option value={''}>Select</option>
                        {states.map((item) => (
                          <option value={item.id} key={`${item.name}-${item.id}`}>
                            {item.code}
                          </option>
                        ))}
                      </Field>
                      {errors.state && touched.state && <div className="error">{errors.state}</div>}
                    </div>
                    <div className="form-group col-12 col-md-2">
                      <label className="form-label bold">Zip Code</label>
                      <Field className="form-control" type="text" name="zipCode" />
                      {errors.zipCode && touched.zipCode && (
                        <div className="error">{errors.zipCode}</div>
                      )}
                    </div>
                  </div>
                </Card>

                {role === 'ROLE_BUYER_AGENT' && (
                  <Card title="Brokerage Contact Information">
                    <div className="row">
                      <div className="form-group col-12 col-md-6">
                        <label className="form-label bold">Brokerage Name</label>
                        <Field className="form-control" type="text" name="brokerageName" />
                        {errors.brokerageName && touched.brokerageName && (
                          <div className="error">{errors.brokerageName}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-6">
                        <label className="form-label bold">Address</label>
                        <Field className="form-control" type="text" name="brokerageAddress" />
                        {errors.brokerageAddress && touched.brokerageAddress && (
                          <div className="error">{errors.brokerageAddress}</div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 col-md-3">
                        <label className="form-label bold">Suite / Apt</label>
                        <Field className="form-control" type="text" name="brokerageSuite" />
                        {errors.brokerageSuite && touched.brokerageSuite && (
                          <div className="error">{errors.brokerageSuite}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-3">
                        <label className="form-label bold">City</label>
                        <Field className="form-control" type="text" name="brokerageCity" />
                        {errors.brokerageCity && touched.brokerageCity && (
                          <div className="error">{errors.brokerageCity}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-3">
                        <label className="form-label bold">State</label>
                        <Field className="form-control" as="select" name="brokerageState">
                          <option value={''}>Select</option>
                          {states.map((item) => (
                            <option value={item.id} key={`${item.name}-${item.id}`}>
                              {item.code}
                            </option>
                          ))}
                        </Field>
                        {errors.brokerageState && touched.brokerageState && (
                          <div className="error">{errors.brokerageState}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-3">
                        <label className="form-label bold">Zip Code</label>
                        <Field className="form-control" type="text" name="brokerageZipCode" />
                        {errors.brokerageZipCode && touched.brokerageZipCode && (
                          <div className="error">{errors.brokerageZipCode}</div>
                        )}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group">
                        <label className="form-label bold">State Of Issuance</label>
                        <Field className="form-control" as="select" name="stateOfIssuance">
                          <option>Select</option>
                          {states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.code}
                            </option>
                          ))}
                        </Field>
                        {errors.stateOfIssuance && touched.stateOfIssuance && (
                          <div className="error">{errors.stateOfIssuance}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <label className="form-label bold">Broker License Number</label>
                        <Field className="form-control" type="text" name="brokerLicenseNumber" />
                        {errors.brokerLicenseNumber && touched.brokerLicenseNumber && (
                          <div className="error">{errors.brokerLicenseNumber}</div>
                        )}
                      </div>
                      <div className="form-group">
                        <UploaderFile
                          titleLabel="Copy Of License"
                          urlApi={UPLOAD_IMAGE}
                          userToken={userToken}
                          setRoute={setRouteLicense}
                          typeFile="licenses"
                          sizeLabel="normal-size"
                          setLoading={setLoading}
                          routeLicense={routeLicense}
                        />
                      </div>
                    </div>
                  </Card>
                )}

                <Card title="Purchase Areas" btnTitle="Add New Area" btnHandle={handleAddArea}>
                  <PurchaseAreasList areas={purchaseAreaList} />
                </Card>

                <Card title="Membership Details">
                  <p>
                    <span className="bold">Current Membership:</span>{' '}
                    {infoData.membershipStatus === false
                      ? 'Basic Access'
                      : infoData.idMembership === 1
                      ? 'Monthly Membership'
                      : infoData.idMembership === 2
                      ? 'Yearly Membership'
                      : ''}
                    {infoData.membershipStatus &&
                    infoData.idMembership === 1 &&
                    !!infoData.profile.userCodeReferral
                      ? ` ($${
                          99 -
                          (infoData.profile.userCodeReferral.percentage.percentageReferee / 100) *
                            99
                        }/mo)`
                      : infoData.membershipStatus && infoData.idMembership === 1
                      ? ' ($99/mo)'
                      : ''}
                    {infoData.membershipStatus &&
                    infoData.idMembership === 2 &&
                    !!infoData.profile.userCodeReferral
                      ? ` ($${
                          995 -
                          (infoData.profile.userCodeReferral.percentage.percentageReferee / 100) *
                            995
                        }/year)`
                      : infoData.membershipStatus && infoData.idMembership === 2
                      ? ' ($995/year)'
                      : ''}
                  </p>
                  <p>
                    <span className="bold">Start Date:</span>{' '}
                    {!!infoData.createdDate ? infoData.createdDate : ''}
                  </p>
                  {infoData.membershipStatus ? <CancelSubscription /> : null}
                </Card>

                <div className="btn-container">
                  <Button color="yellow" type="submit" disabled={loading}>
                    {loading ? (
                      <Spinner animation="border" role="status" size="sm" className="mr-2" />
                    ) : null}
                    SAVE CHANGES
                  </Button>
                </div>
              </section>
            </Form>
          )}
        </Formik>
        {showModalMessage && (
          <ModalMessage
            title={titleModalMessage}
            message={messageModalMessage}
            btnTitle="OK"
            redirect="false"
            type="message"
          />
        )}
        <PurchaseAreasModal
          showModal={showModalAddArea}
          setShowModal={setShowModalAddArea}
          handleModalSubmit={handleModalSubmit}
          states={statesAreas}
          areaList={purchaseAreaList}
          setAreaList={setPurchaseAreaList}
          type="profile"
          userProfileID={roleActive === 'ROLE_ADMIN' ? infoData.profile?.id : info.profile?.id}
        />
      </div>
    );
  };
  return <>{loadingRender ? <Loading /> : <RenderForm />}</>;
};

export default AgentsProfileForm;
