import React from 'react';
import { GoBack } from '../../components/OMB';
import { SponsorshipDetailsCards } from "../../containers";
import './styles.css';

const SponsorshipDetails = (props) => {
  const { id: sponsorshipId } = props.match.params;

  return (
    <div className="sponsorship-details">
      <GoBack {...props} />
      <SponsorshipDetailsCards sponsorshipId={parseInt(sponsorshipId)} />
    </div>
  )
}

export default SponsorshipDetails;
