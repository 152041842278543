import React, { useState, useEffect } from 'react';
import { Card, Loading } from '../../components/OMB';
import { useSelector } from 'react-redux';
import { formatPrice, getApi, GET_USER } from '../../utils/services';
import './styles.css';

const OfferDetailsCards = ({ propertyId }) => {
  const { roleActive } = useSelector((store) => store.user);
  const user = useSelector((store) => store.user);
  const userToken = localStorage.getItem('tokenOMB');
  const [userID, setUserId] = useState(user.info.id);
  const { offersProperties } = useSelector((store) => store.property);
  const property = offersProperties.find((item) => item.id === parseInt(propertyId));
  // debugger;
  const { address = {}, buyerAgentId, buyerType, user: userView } = property;
  console.log('buyer', property);
  const { id: userViewId } = userView;
  const { userRole } = buyerAgentId;
  let rolesArray = [];

  const [buyerAgentData, setBuyerAgentData] = useState(null);
  const titleAddress = `${address?.street} ${!!address?.unit ? `, ${address?.unit}` : ''}`;
  let paths = window.location.pathname.split('/');
  const idUserSelectForAdmin = roleActive === 'ROLE_ADMIN' ? userViewId : null; // id del usuario cuando eres super admin
  const [roleUserSelectForAdmin, setRoleUserSelectForAdmin] = useState('');
  const [filterRoleBuyerAgent, setFilterRoleBuyerAgent] = useState('');
  const callInfoUser = () => {
    const urlApi = `${GET_USER}${idUserSelectForAdmin}`;
    getApi(urlApi, userToken)
      .then((response) => {
        setRoleUserSelectForAdmin(response.data.userRole[0].role.role);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  useEffect(() => {
    console.log('cambio');
    userRole.map((role) =>
      rolesArray.push({
        role: role.role.role,
      }),
    );
  }, [userRole]);
  useEffect(() => {
    console.log(filterRoleBuyerAgent);
  }, [filterRoleBuyerAgent]);
  useEffect(() => {
    if (roleActive === 'ROLE_ADMIN') {
      callInfoUser();
    }
    const filterRoleBuyerAgent = rolesArray?.find((role) => role.role === 'ROLE_BUYER_AGENT');
    setFilterRoleBuyerAgent(filterRoleBuyerAgent?.role);
  }, [roleActive]);

  useEffect(() => {
    if (!!roleUserSelectForAdmin) {
      setUserId(idUserSelectForAdmin);
    }
  }, [roleUserSelectForAdmin]);
  useEffect(() => {
    if (buyerAgentId) {
      setBuyerAgentData(buyerAgentId);
    }
  }, []);
  let roleValidate = roleActive;
  if (!!roleUserSelectForAdmin) {
    roleValidate = roleUserSelectForAdmin;
  }
  useEffect(() => {
    // console.log(roleValidate);
  }, [roleValidate]);
  const Render = () => {
    return (
      <div className="offer-details-cards">
        {/* {!buyerAgentData && (
        <p>
          This Buyer is represented by the Listing Agent.
        </p>
      )} */}
        {filterRoleBuyerAgent === 'ROLE_BUYER_AGENT' ? (
          null
        ) :  roleValidate === 'ROLE_BUYER' || roleValidate === 'ROLE_LISTING_AGENT'  ? (
          <p className="property-offer-message">This Buyer is represented by the Listing Agent.</p>
        ) : null}
        <Card
          title="Buyer Details"
          subTitle={
            roleValidate === 'ROLE_BUYER' ||
            roleValidate === 'ROLE_LISTING_AGENT' ||
            filterRoleBuyerAgent === 'ROLE_BUYER_AGENT'
              ? ''
              : `BUYER’S AGENT IS OFFERING ${property.percentageCommission}% OF THEIR BUYER’S SIDE COMMISSION AS A REFERRAL FEE.`
          }
          subTitle={
            filterRoleBuyerAgent === 'ROLE_BUYER_AGENT'
              ? `BUYER’S AGENT IS OFFERING ${property.percentageCommission}% OF THEIR BUYER’S SIDE COMMISSION AS A REFERRAL FEE.`
              : ''
          }
          buyer
        >
          <div className="info-wrapper">
            <div className="info-row">
              {buyerType.name === 'ENTITY' ? (
                <>
                  <div className="info-columm">
                    <span>Entity Name</span>
                    <span className="bold">{property.entityName}</span>
                  </div>
                  <div className="info-columm">
                    <span>Authorized Signer Full Name</span>
                    <span className="bold">{property.signerName}</span>
                  </div>
                </>
              ) : (
                <div className="info-columm">
                  <span>Name</span>
                  <span className="bold">{property.name}</span>
                </div>
              )}
              <div className="info-columm">
                <span>Phone Number</span>
                <span className="bold">{property.phone}</span>
              </div>
              <div className="info-columm">
                <span>Email Address</span>
                <span className="bold">{property.email}</span>
              </div>
            </div>
            <div className="info-row">
              <div className="info-columm">
                <span>Mailing Address</span>
                <span className="bold">{titleAddress}</span>
              </div>
              <div className="info-columm">
                <span>City</span>
                <span className="bold">{address.city}</span>
              </div>
              <div className="info-columm">
                <span>State</span>
                <span className="bold">{address.estate.code}</span>
              </div>
              <div className="info-columm">
                <span>Zip Code</span>
                <span className="bold">{address.zipCode}</span>
              </div>
            </div>
          </div>
        </Card>
        {filterRoleBuyerAgent === 'ROLE_BUYER_AGENT' && (
            <Card
              title="Buyer Agent's Details"
              subTitle={
                roleValidate === 'ROLE_BUYER'
                  ? ''
                  : `BUYER WILL PAY THE BUYER’S AGENT A ${formatPrice(
                      property.consultingFee,
                    )} CONSULTING FEE TO BE ADDED TO THE CLOSING STATEMENT.`
              }
              subTitle={
                filterRoleBuyerAgent === 'ROLE_BUYER_AGENT'
                  ? `BUYER WILL PAY THE BUYER’S AGENT A ${formatPrice(
                      property.consultingFee,
                    )} CONSULTING FEE TO BE ADDED TO THE CLOSING STATEMENT.`
                  : ''
              }
              buyer
            >
              <div className="info-wrapper">
                <div className="info-row">
                  <div className="info-columm">
                    <span>Name</span>
                    <span className="bold">
                      {buyerAgentId.firstName} {buyerAgentId.lastName}
                    </span>
                  </div>
                  <div className="info-columm">
                    <span>Phone Number</span>
                    <span className="bold">{buyerAgentId.profile.phone}</span>
                  </div>
                  <div className="info-columm">
                    <span>Email Address</span>
                    <span className="bold">{buyerAgentId.username}</span>
                  </div>
                </div>
              </div>
            </Card> )}
        {roleValidate !== 'ROLE_BUYER' ||
          (roleValidate === 'ROLE_LISTING_AGENT' && !!buyerAgentData && (
            <Card
              title="Buyer Agent's Details"
              subTitle={
                roleValidate === 'ROLE_BUYER'
                  ? ''
                  : `BUYER WILL PAY THE BUYER’S AGENT A ${formatPrice(
                      property.consultingFee,
                    )} CONSULTING FEE TO BE ADDED TO THE CLOSING STATEMENT.`
              }
              subTitle={
                filterRoleBuyerAgent === 'ROLE_BUYER_AGENT'
                  ? `BUYER WILL PAY THE BUYER’S AGENT A ${formatPrice(
                      property.consultingFee,
                    )} CONSULTING FEE TO BE ADDED TO THE CLOSING STATEMENT.`
                  : ''
              }
              buyer
            >
              <div className="info-wrapper">
                <div className="info-row">
                  <div className="info-columm">
                    <span>Name</span>
                    <span className="bold">
                      {buyerAgentId.firstName} {buyerAgentId.lastName}
                    </span>
                  </div>
                  <div className="info-columm">
                    <span>Phone Number</span>
                    <span className="bold">{buyerAgentId.profile.phone}</span>
                  </div>
                  <div className="info-columm">
                    <span>Email Address</span>
                    <span className="bold">{buyerAgentId.username}</span>
                  </div>
                </div>
              </div>
            </Card>
          ))}

        <Card
          title="Purchase Details"
          subTitle={
            roleValidate === 'ROLE_BUYER'
              ? null
              : !buyerAgentData
              ? `BUYER WILL PAY THE BUYER’S AGENT A ${formatPrice(
                  property.consultingFee,
                )} CONSULTING FEE TO BE ADDED TO THE CLOSING STATEMENT.`
              : null
          }
          buyer
        >
          <div className="info-wrapper">
            <div className="info-row">
              <div className="info-columm">
                <span>Offer Amount</span>
                <span className="bold">{formatPrice(property.amountOffered)}</span>
              </div>
              <div className="info-columm">
                <span>EMD (Deposit) Amount</span>
                <span className="bold">{formatPrice(property.emdAmount)}</span>
              </div>
              <div className="info-columm">
                <span>Funding</span>
                <span className="bold">{property.funding.name}</span>
              </div>
            </div>
          </div>
        </Card>

        <Card title="Required Documentation">
          <div className="info-wrapper">
            {buyerType.name == 'ENTITY' && (
              <div className="info-row cols-2">
                <div className="info-columm">
                  <span>Articles of Incorporation</span>
                  <span className="bold">
                    {property.file.articleIncorporation && (
                      <a
                        href={property.file.articleIncorporation}
                        className="file-documentation"
                        download="article-incorporation.pdf"
                        target="_blank"
                      >
                        Article-incorporation.pdf
                      </a>
                    )}
                  </span>
                </div>
                <div className="info-columm">
                  <span>Operating Agreement</span>
                  <span className="bold">
                    {property.file.operationAgreement && (
                      <a
                        href={property.file.operationAgreement}
                        className="file-documentation"
                        download="operating-agreement.pdf"
                        target="_blank"
                      >
                        Operating-agreement.pdf
                      </a>
                    )}
                  </span>
                </div>
              </div>
            )}
            <div className="info-row cols-2">
              <div className="info-columm">
                <span>Proof of Funds</span>
                <span className="bold">
                  {property.file.proofsFunds && (
                    <a
                      href={property.file.proofsFunds}
                      className="file-documentation"
                      download="proof-of-funds.pdf"
                      target="_blank"
                    >
                      Proof-of-funds.pdf
                    </a>
                  )}
                </span>
              </div>
              <div className="info-columm">
                <span>Pre-Approval Letter (Non-Cash Offers)</span>
                <span className="bold">
                  {property.file.preApproval && (
                    <a
                      href={property.file.preApproval}
                      className="file-documentation"
                      download="proof-of-funds.pdf"
                      target="_blank"
                    >
                      Pre-Approval-Letter.pdf
                    </a>
                  )}
                </span>
              </div>
            </div>
          </div>
        </Card>

        <Card title="Additional Terms">
          <div className="info-wrapper">
            <div className="info-row">
              <div className="info-columm">
                <span>Loan Contingency</span>
                <div className="container-mount">
                  <div className="mount">{property.longContingency}</div>
                  <span className="bold">days</span>
                </div>
              </div>
              <div className="info-columm">
                <span>Inspection Contingency</span>
                <div className="container-mount">
                  <div className="mount">{property.inspectionContingency}</div>
                  <span className="bold">days</span>
                </div>
              </div>
              <div className="info-columm">
                <span>Appraisal Contingency</span>
                <div className="container-mount">
                  <div className="mount">{property.appraisalContingency}</div>
                  <span className="bold">days</span>
                </div>
              </div>
              <div className="info-columm">
                <span>Home Warranty</span>
                <div className="container-mount">
                  <span className="bold mr-2">$</span>
                  <div className="mount">{property.homeWarranty}</div>
                  <span className="bold">contributed by seller</span>
                </div>
              </div>
            </div>
            <div className="info-row">
              <div className="info-columm">
                <span>Additional Notes to Listing Agent about Offer</span>
                <span className="aditional_notes bold">
                  {property.message !== null ? property.message : 'N/A'}
                </span>
              </div>
            </div>
            {/* <div className="info-row">
            <div className="info-columm">
              <span>Porcentage of commission to Listing Agent</span>
              <span className="bold">{property.percentageCommission}%</span>
            </div>
          </div> */}
          </div>
        </Card>
      </div>
    );
  };
  return <>{!!property ? <Render /> : <Loading />}</>;
};

export default OfferDetailsCards;
