import React, { useState } from 'react';
import { Card, Button, ModalMessage } from '../../components/OMB';
import PermissionsCard from './PermissionsCard';
import { Spinner } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { postApi, USER_SAVE } from '../../utils/services';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles.css';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const FormSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string().required('Required'),
  typeAdmin: Yup.string().required('Required'),
  password: Yup.string().min(6, 'Too Short!').max(13, 'Too Long!').required('Required'),
  retryPassword: Yup.string()
    .required('Required')
    .when('password', {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Both passwords must match'),
    }),
});

const admminTypes = [
  { id: 1, name: 'Admin' },
  { id: 9, name: 'Marketing Rep' },
  { id: 8, name: 'Referrals' },
  { id: 6, name: 'Accounting' },
  { id: 7, name: 'Marketing' }
];

const AddAdminForm = ({ adminId }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive } = useSelector((store) => store.user);
  const { admins } = useSelector((store) => store.admin);
  const [typeSelected, setTypeSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  const admin = admins.find(item => item.idUser === parseInt(adminId));

  const handleSubmit = ({ firstName, lastName, email, phone, typeAdmin, password }) => {
    setLoading(true);

    let dataForm = {
      firstName,
      lastName,
      "username": email,
      password,
      "profile": {
        "phone": phone
      },
      "userRole": [
        {
          "role": {
            "id": parseInt(typeAdmin)
          },
          "terms": true
        }
      ]
    };

    if (!!adminId) {
      dataForm = {
        id: parseInt(adminId),
        ...dataForm,
      };
    };

    postApi(USER_SAVE, dataForm, userToken)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setShowCheck(true)
          setTimeout(() => {
            history.goBack();
          }, 8000);
        }else {
          setShowError(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          setShowError(true);
          console.log('Error');
        }
      });
  };

  const initValues = {
    firstName: admin?.names || '',
    lastName: admin?.lastName || '',
    email: admin?.email || '',
    phone: admin?.phoneNumber || '',
    typeAdmin: admin?.typeAdmin || '',
    password: '',
    retryPassword: '',
  };

  const handleTypeSelected = (val) => setTypeSelected(val);

  const handleBtnInviteds = (event) => {
    event.preventDefault();
    history.push(`/${roleActive}/invite/invited-members/${adminId}`);
  };

  return (
    <div className="add-admin-form">
      <Formik
        initialValues={initValues}
        validationSchema={FormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values }) => (
          <Form>
            <Card
              title={!!admin ? 'Admin Details' : 'Add New Admin'}
              btnTitle={admin?.typeAdmin === 'Customer Service' ? 'Invited Members' : null}
              btnHandle={handleBtnInviteds}
            >
              <div className="form-row cols-4">
                <div className="form-group">
                  <label className="form-label">First Name</label>
                  <Field className="form-control" type="text" name="firstName" />
                  {errors.firstName && touched.firstName && (
                    <div className="error">{errors.firstName}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Last Name</label>
                  <Field className="form-control" type="text" name="lastName" />
                  {errors.lastName && touched.lastName && (
                    <div className="error">{errors.lastName}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Email</label>
                  <Field className="form-control" type="text" name="email" />
                  {errors.email && touched.email && <div className="error">{errors.email}</div>}
                </div>
                <div className="form-group">
                  <label className="form-label">Phone Number</label>
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <MaskedInput
                        {...field}
                        mask={phoneNumberMask}
                        id="phone"
                        type="text"
                        className={`form-control`}
                      />
                    )}
                  />
                  {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}
                </div>
              </div>
              <div className="form-row cols-4">
                <div className="form-group">
                  <label className="form-label">Type Of Admin</label>
                  <Field
                    className="form-control"
                    as="select"
                    name="typeAdmin"
                    fieldinputprops={{
                      onChange: setTimeout(() => handleTypeSelected(values.typeAdmin), 0),
                    }}
                  >
                    <option value={''}>Select</option>
                    {admminTypes.map((item) => (
                      <option value={item.id} key={`type-${item.id}`}>
                        {item.name}
                      </option>
                    ))}
                  </Field>
                  {errors.typeAdmin && touched.typeAdmin && (
                    <div className="error">{errors.typeAdmin}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Password</label>
                  <Field className="form-control" type="password" name="password" />
                  {errors.password && touched.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Retype Password</label>
                  <Field className="form-control" type="password" name="retryPassword" />
                  {errors.retryPassword && touched.retryPassword && (
                    <div className="error">{errors.retryPassword}</div>
                  )}
                </div>
              </div>
              <div className="btn-container">
                <Button color="yellow" type="submit" disabled={loading}>
                  {loading && (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  )}
                  SAVE CHANGES
                </Button>
              </div>
            </Card>
          </Form>
        )}
      </Formik>
      <PermissionsCard typeSelected={typeSelected} />
      {showCheck && (
        <ModalMessage
          title={!!adminId ? 'Successfully Edited' : 'Successfully Created'}
          message="The Admin has been successfully saved."
          redirect="true"
          hideBtn={true}
        />
      )}
      {showError && (
        <ModalMessage title="Error Creating Admin" message="This user is already registered." />
      )}
    </div>
  );
};

export default AddAdminForm;
