import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Button } from '../../OMB';
import './styles.css';

const Card = (props) => {
  return (
    <div className={`omb-card ${props.className ? props.className : ''}`}>
      {(props.title || props.subTitle || props.btnTitle) && (
        <div className="card-header">
          {props.title && (
            <div className="card-title">
              <span>{props.title}</span>
              {props.loading ? (
                <Spinner animation="border" role="status" size="sm" className="mr-2" />
              ) : null}
            </div>
          )}
          {props.subTitle && (
            <div className={`card-sub-title${props.buyer ? ' buyer' : ''}`}>
              <span>{props.subTitle}</span>
            </div>
          )}
          {props.gridBtns ? (
            <div className="gridBtns d-md-flex justify-content-md-end align-items-center">
              {props.btnTitle && props.btnHandle && (
                <div className="card-btn">
                  <Button
                    disabled={props.disabledBtn}
                    color={props.btnColor ? props.btnColor : 'yellow'}
                    onClick={props.btnHandle}
                  >
                    {props.btnTitle}
                  </Button>
                </div>
              )}
              {props.btnTwoTitle && props.btnTwoHandle && (
                <div className="card-btn">
                  <Button
                    disabled={props.disabledBtn}
                    color={props.btnTwoColor ? props.btnTwoColor : 'yellow'}
                    onClick={props.btnTwoHandle}
                  >
                    {props.btnTwoTitle}
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <>
              {props.btnTitle && props.btnHandle && (
                <div className="card-btn">
                  <Button
                    color={props.btnColor ? props.btnColor : 'yellow'}
                    onClick={props.btnHandle}
                  >
                    {props.btnTitle}
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      )}
      {props.children && <div className="card-content">{props.children}</div>}
    </div>
  );
};

export default Card;
