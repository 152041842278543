import React from 'react';
import { MyPropertiesTabs } from '../../containers';

const MyProperties = (props) => {
  return (
    <div className="my-properties">
      <MyPropertiesTabs />
    </div>
  )
}

export default MyProperties;