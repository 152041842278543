import React, { useState, useEffect } from 'react';
import { Loading, Card, EmptyList, DTable, HeaderBtn } from '../../components/OMB';
import { formatPrice } from '../../utils/services';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useReferrals } from '../../hooks/useReferrals';

const ReferralsSaved = () => {
  const { roleActive } = useSelector((store) => store.user);
  const [referrals, loading] = useReferrals();
  const [data, setData] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const dataReferrals = referrals.map(
      ({ idUser, names, roles, numberCodes, totalProfiles = 0 }) => {
        return {
          id: idUser,
          name: names,
          userType: roles,
          referralsCodes: numberCodes,
          totalProfits: '$' + totalProfiles.toFixed(2),
          icon: '>',
          handleClick: () => history.push(`/${roleActive}/referrals-details/${idUser}`),
        };
      },
    );
    setDataOrigin(dataReferrals);
    setData(dataReferrals);
  }, [referrals]);

  const searchItem = (value) => {
    const wanted = value.trim().toLowerCase();
    const result =
      wanted.length >= 2
        ? referrals.filter(
            (item) =>
              item.names.trim().toLowerCase().includes(wanted) ||
              item.roles.trim().toLowerCase().includes(wanted) ||
              item.numberCodes.trim().toLowerCase().includes(wanted)
          )
        : [];
    if (result.length > 0) {
      const dataReferrals = result.map(
        ({ idUser, names, roles, numberCodes, totalProfiles = 0 }) => {
          const originTotalProfiles = parseInt(totalProfiles).toFixed(2);
          const convertTotalProfiles = originTotalProfiles.toString();
          console.log(typeof convertTotalProfiles)
          return {
            id: idUser,
            name: names, 
            userType: roles,
            referralsCodes: numberCodes,
            totalProfits: '$' + convertTotalProfiles,
            icon: '>',
            handleClick: () => history.push(`/${roleActive}/referrals-details/${idUser}`),
          };
        },
      );
      setData(dataReferrals);
    } else {
      setData(dataOrigin);
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'User Type',
      selector: 'userType',
    },
    {
      name: '# of Referrals Codes',
      selector: 'referralsCodes',
    },
    {
      name: 'Total Profits',
      selector: 'totalProfits',
    },
    {
      selector: 'icon',
      cell: (row) => (
        <div onClick={() => console.log(row)} data-tag="allowRowEvents">
          {'>'}
        </div>
      ),
      right: true,
      width: '50px',
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <>
      <HeaderBtn showButtons={false} searchItem={searchItem} showBtnCreateCode={true}/>
      <Card
        title={`All Referrals (${data.length})`}
        // btnTitle="Download Template"
        // btnHandle={handleDownload}
      >
        {loading ? (
          <Loading />
        ) : referrals.length === 0 ? (
          <EmptyList />
        ) : (
          <DTable columns={columns} data={data} />
        )}
      </Card>
    </>
  );
};

export default ReferralsSaved;
