import React from 'react';
import { Card, EmptyList, DTable } from '../../../components/OMB';

const CSResultsCard = ({ data }) => {
  const columns = [
    {
      name: 'Code',
      selector: 'code',
    },
    {
      name: 'Assigned To',
      selector: 'assignedTo',
    },
    {
      name: 'Reward for Referee',
      selector: 'reward',
    },
    {
      name: 'Reward for Recipient',
      selector: 'recipient',
    },
  ];

  return (
    <Card title="Result">
      {data.length === 0
        ? <EmptyList />
        : (
          <DTable columns={columns} data={data} />
        )
      }
    </Card>
  )
}

export default CSResultsCard;
