import React from 'react';
import { PropertyDetailsCards } from "../../containers";
import { GoBack } from "../../components/OMB";

const PropertyDetails = (props) => {
  const { id } = props.match.params;

  return (
    <div className="property-details">
      <GoBack {...props} />
      <PropertyDetailsCards propertyId={id} />
    </div>
  )
}

export default PropertyDetails;