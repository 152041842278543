import React from 'react';
import { MyPropertiesTabs } from '../..';

const Properties = ({ userId }) => {
  return (
    <MyPropertiesTabs userId={userId} />
  );
};

export default Properties;
