import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { EmptyList } from '../../components/OMB';
import { ItemList } from './ItemList';
import './styles.css';

const MyAssignedMembers = ({assignedMembers = []}) => {
  const [members, setMembers] = useState([]);


  useEffect(() => {
    if(!!assignedMembers.length > 0){
      setMembers(assignedMembers);
    }
  }, [assignedMembers])
  

  const List = () => (
    <div className="list">
      <div className="fila">
        <div className="column-title bold">Name</div>
        <div className="column-title bold">User Type</div>
        <div className="column-title bold">Assigned</div>
      </div>
      {members.map((member) => {
        return <ItemList member={member} />;
      })}
    </div>
  );

  return (
    <div className="ListAssignedMembers">
      {members.length > 0 ? <List /> : <EmptyList text="There are no Invited Members" />}
    </div>
  );
};

export default MyAssignedMembers;
