import React, { useState } from 'react';
import { Card, Button, ModalMessage } from '../../components/OMB';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { postApi, EXPENSE_SAVE } from '../../utils/services';
import { useHistory } from 'react-router-dom';
import './styles.css';

const FormSchema = Yup.object().shape({
  category: Yup.string().required('Required'),
  amountSpent: Yup.number().required('Required'),
  typePurchase: Yup.string().required('Required'),
});

const categories = [
  'Marketing',
  'Entertainment',
  'Maintenance',
  'Paperwork'
];

const purchases = [
  'One Time',
  'Monthly',
  'Yearly',
];

const AddTransactionFeeForm = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const [showCheck, setShowCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  const handleSubmit = ({ category, amountSpent, typePurchase }) => {
    const dataForm = {
      category,
      "amountSpent": parseInt(amountSpent),
      typePurchase
    };

    postApi(EXPENSE_SAVE, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          history.goBack();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  }

  const initValues = {
    category: '',
    amountSpent: '',
    typePurchase: ''
  }

  return (
    <div className="add-expenses-form">
      <Formik
        initialValues={initValues}
        validationSchema={FormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched }) => (
          <Form>
            <Card title="Add Expenses">
              <div className="form-row cols-4">
                <div className="form-group">
                  <label className="form-label">Category</label>
                  <Field className="form-control" as="select" name="category" >
                    <option value={''}>Select</option>
                    {
                      categories.map((item, index) => (
                        <option value={item} key={`category-${index}`}>{item}</option>
                      ))
                    }
                  </Field>
                  {errors.category && touched.category && (
                    <div className="error">{errors.category}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Amount Spent</label>
                  <Field className="form-control" type="text" name="amountSpent" />
                  {errors.amountSpent && touched.amountSpent && (
                    <div className="error">{errors.amountSpent}</div>
                  )}
                </div>
              </div>
              <div className="form-row cols-4">
                <div className="form-group">
                  <label className="form-label">Type Of Purchases</label>
                  <Field className="form-control" as="select" name="typePurchase" >
                    <option value={''}>Select</option>
                    {
                      purchases.map((item, index) => (
                        <option value={item} key={`purchase-${index}`}>{item}</option>
                      ))
                    }
                  </Field>
                  {errors.typePurchase && touched.typePurchase && (
                    <div className="error">{errors.typePurchase}</div>
                  )}
                </div>
              </div>
            </Card>
            <div className="btn-container">
              <Button color="yellow" type="submit">
                SAVE CHANGES
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {showCheck && <ModalMessage title="Saved Sponsorship" message="The Sponsorship has been saved successfully." btnTitle="Return to Sponsorship Oportunities" redirect="true" />}
      {showError && <ModalMessage title="Error Saving The Sponsorship" message="The Sponsorship could not be saved. Please review your information and try again." />}
    </div>
  );
};

export default AddTransactionFeeForm;
