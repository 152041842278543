import React, { useEffect, useState } from 'react';
import { Card, HeaderBtn } from '../../components/OMB';
import { PropertyList } from '../../containers';
// import { useFilterAddress } from '../../hooks/useFilterAddress';
// import { usePropertyList } from '../../hooks/usePropertyList';
import { getApi, PROPERTY_PENDING } from '../../utils/services';
import ModalMarketing from './ModalMarketing';
import ReactPaginate from 'react-paginate';

const PropertiesAdmin = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [propertyListOrigin, setPropertyListOrigin] = useState([]);
  const userToken = localStorage.getItem('tokenOMB');
  // const [filteredProperties, setFilteredProperties] = useFilterAddress(propertyList);
  // console.log(filteredProperties);
  //const [searchKey, setSearchKey] = useState('');
  // console.log(filteredProperties);
  const [showModalMarketing, setShowModalMarketing] = useState(false);
  const [propertyAddress, setPropertyAddress] = useState('');
  const [propertyAddressID, setPropertyAddressID] = useState('');
  const [userCode, setUserCode] = useState('');
  const [percentageCode, setPercentageCode] = useState(0);
  const [codeOwner, setCodeOwner] = useState('');

  const callPropertiesAll = async () => {
    // console.log('llamo');
    await getApi(`${PROPERTY_PENDING}/1/rows/10`, userToken)
      .then((response) => {
        /// console.log(response);
        if (response.status === 200) {
          let properties = [];
          const propertiesData = response.data.map((property) =>
            properties.push({
              address: { ...property?.property?.address },
              approvedDate: property?.property?.approvedDate,
              bath: property?.property?.bath,
              bed: property?.property?.bed,
              id: property?.property?.id,
              lot_size: property?.property?.lot_size,
              marketingDate: property?.property?.marketingDate,
              occupancy: property?.property?.occupancy,
              price: property?.property?.price,
              propertyImage: property?.property?.propertyImage,
              propertyImages: property?.property?.propertyImages,
              squareFeet: property?.property?.squareFeet,
              status: property?.property?.status,
              statusApproved: property?.property?.statusApproved,
              statusP: property?.property?.statusP,
              statusProperty: property?.property?.statusProperty,
              typeProperty: property?.property?.typeProperty,
              userId: property?.property?.userId,
              year_built: property?.property?.year_built,
              userInfo: property?.user,
            }),
          );
          // console.log(properties)
          if(propertyList.length <= 0) {
            setPropertyList(properties);
          }
          setPropertyListOrigin(properties);
        }
      })
      .catch((error) => {
        if (error.response) {
       //   console.log(error.response.data);
       //   console.log('Código de error en properties admin:', error.response.status);
        }
      });
  };

  const fetchPendingProperties = async (currentPage) => {
   //  console.log('llamo');
    await getApi(`${PROPERTY_PENDING}/${currentPage}/rows/10`, userToken)
      .then((response) => {
       //  console.log(response);
        if (response.status === 200) {
          let properties = [];
          const propertiesData = response.data.map((property) =>
            properties.push({
              address: { ...property?.property?.address },
              approvedDate: property?.property?.approvedDate,
              bath: property?.property?.bath,
              bed: property?.property?.bed,
              id: property?.property?.id,
              lot_size: property?.property?.lot_size,
              marketingDate: property?.property?.marketingDate,
              occupancy: property?.property?.occupancy,
              price: property?.property?.price,
              propertyImage: property?.property?.propertyImage,
              propertyImages: property?.property?.propertyImages,
              squareFeet: property?.property?.squareFeet,
              status: property?.property?.status,
              statusApproved: property?.property?.statusApproved,
              statusP: property?.property?.statusP,
              statusProperty: property?.property?.statusProperty,
              typeProperty: property?.property?.typeProperty,
              userId: property?.property?.userId,
              year_built: property?.property?.year_built,
              userInfo: property?.user,
            }),
          );
            setPropertyList(properties);
        }
      })
      .catch((error) => {
        if (error.response) {
       //   console.log(error.response.data);
       //   console.log('Código de error en properties admin:', error.response.status);
        }
      });
  };
  useEffect(() => {
      callPropertiesAll();
  }, []);

//   useEffect(() => {
//     fetchPendingProperties();
// }, []);

  useEffect(() => {
   // console.log('property', propertyList);
  }, [propertyList]);

  useEffect(() => {
    // console.log(propertyAddressID);
    const filter = propertyList.filter((property) => property.id === propertyAddressID);
    // console.log(filter);
    setPropertyAddress(
      `${filter[0]?.address?.street} ${
        !!filter[0]?.address?.unit && filter[0]?.address?.unit !== ',' ? '' : ''
      } ${
        !!filter[0]?.address?.unit && filter[0]?.address?.unit !== ','
          ? `unit ${filter[0]?.address?.unit},`
          : ''
      } ${!!filter[0]?.address?.city ? `${filter[0]?.address?.city},` : ''} ${
        filter[0]?.address?.estate?.code
      } ${!!filter[0]?.address?.zipCode ? filter[0]?.address?.zipCode : ''}`,
    );
    setUserCode(filter[0]?.userInfo?.userCode[0]?.code);
    setPercentageCode(filter[0]?.userInfo?.userCode[0]?.percentage?.percentageRecipient);
    setCodeOwner(`${filter[0]?.userInfo?.firstName} ${filter[0]?.userInfo?.lastName}`);
  }, [propertyAddressID]);

  const searchItem = async (value) => {
   // console.log(value);
    let wanted = await !!value ? value.trim().toLowerCase() : '';
    
    wanted = await wanted === 'publish' ? 'true' : wanted
    wanted = await wanted === 'unpublish' ? 'false' : wanted
  //  console.log(wanted)
    let result = [];

    result =
      wanted.length >= 2
        ? propertyList.filter(
            (item) =>
              item.address?.street.trim().toLowerCase().includes(wanted) ||
              item.address?.city.trim().toLowerCase().includes(wanted) ||
              item.address?.estate.name.trim().toLowerCase().includes(wanted) ||
              item.address?.zipCode.trim().toLowerCase().includes(wanted) ||
              item.userInfo?.firstName.trim().toLowerCase().includes(wanted) ||
              item.userInfo?.lastName.trim().toLowerCase().includes(wanted)||
              item.statusApproved.toString().trim().toLowerCase().includes(wanted),
          )
        : [];
    if (result.length > 0) {
      setPropertyList(result);
    } else {
      setPropertyList(propertyListOrigin)
    }
  };

  const handlePageClick = async (propertyList) => {
   //  console.log(propertyList.selected);
    let currentPage = propertyList.selected + 1;
    await fetchPendingProperties(currentPage);   
  };

  return (
    <>
      <HeaderBtn showButtons={false} searchItem={searchItem} />

      <Card
        className="properties-card"
        title={`Properties (${!!propertyList ? propertyList.length : 0})`}
      >
        <PropertyList
          contentType="CUATRO"
          properties={propertyList}
          type="admin"
          setShowModalMarketing={setShowModalMarketing}
          showModalMarketing={showModalMarketing}
          setPropertyAddressID={setPropertyAddressID}
        />
        <br/>
       <ReactPaginate
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          pageCount={10}
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
      </Card>
      <ModalMarketing
        showModalMarketing={showModalMarketing}
        setShowModalMarketing={setShowModalMarketing}
        propertyAddress={propertyAddress}
        userCode={userCode}
        percentageCode={percentageCode}
        setPercentageCode={setPercentageCode}
        codeOwner={codeOwner}
      />
    </>
  );
};

export default PropertiesAdmin;
