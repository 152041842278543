import React from 'react';
import { Card } from "../../../components/OMB";
import { formatPrice } from '../../../utils/services';
import "./styles.css"

const SponsorsDetailsCard = ({ data }) => {
  const { name, activeSponsorships, profits } = data;

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <Card
      title="Sponsor's Details"
      className="sponsors-details"
      // btnTitle="View Member"
      // btnHandle={handleDownload}
      // btnColor="light-blue"
    >
      <div className="form-row cols-4">
        <div className="form-group">
          <label className="form-label">Sponsor Name</label>
          <input className="form-control" type="text" value={name} disabled />
        </div>
        <div className="form-group">
          <label className="form-label">Active Sponsorship</label>
          <input className="form-control" type="text" value={activeSponsorships} disabled />
        </div>
        <div className="form-group">
          <label className="form-label">Total Profits</label>
          <input className="form-control" type="text" value={formatPrice(profits)} disabled />
        </div>
      </div>
    </Card>
  )
}

export default SponsorsDetailsCard;
