import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../../components/OMB/css/IndexUtilsCreateAndSearch/IndexUtilsCreateAndSearch.css';
import { Card, Button, Confirm, Loading } from '../../../components/OMB';
import { LIST_COUPONS_DISCOUNTS, getApi, DELETE_COUPON } from '../../../utils/services/';
import { useSelector } from 'react-redux';
import { useFormatDate } from '../../../hooks/useFormatDate';
import './styles.css';

const CouponsAndDiscounts = () => {
  const [dataCD, setDataCD] = useState([]);
  const userToken = localStorage.getItem('tokenOMB');
  const userData = JSON.parse(localStorage.getItem('state'));
  const userID = userData.user.info.id;
  const urlApi = `${LIST_COUPONS_DISCOUNTS}${userID}`;
  const user = useSelector((store) => store.user);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idCp, setIdCP] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        setLoading(false);
        const status = response.status;
        if (status == 200) {
          const data = response.data.exit;
          setDataCD(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const deleteCoupon = (id) => {
    setShow(!show);
    setIdCP(id);
  };

  useEffect(() => {
    if (idCp && confirmDelete === true) {
      const urlApi = `${DELETE_COUPON}${idCp}`;
      getApi(urlApi, userToken)
        .then((response) => {
          const data = response;
          if (data.status === 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log('Código:', error.response.status);
          }
        });
    }
  }, [confirmDelete, idCp]);

  const Item = (props) => {
    const { item } = props;
    const [createdDate] = useFormatDate(item.useByDate);

    return (
      <div className="item" key={item.id}>
        <div className="content-image-code">
          <div className="content-code">
            <p className="code bold">Code: {item.code}</p>
          </div>
          <div className="content-image">
            <img src={item.url_image} alt="Item" />
          </div>
        </div>
        <div className="content-info">
          <div className="bar-title">
            <p className="title-item bold">{item.title}</p>
            <p className="type">
              <span className="bold">Type:</span> {item.typeCoupon.name}
              {item.typeCoupon.id === 1 ? (
                <div>
                  <span className="bold">Percentage:</span> {item.discount} %
                </div>
              ) : (
                <div>
                  <span className="bold">Reward Details:</span> {item.reward}
                </div>
              )}
            </p>
          </div>
          <p className="info">{item.info}</p>
          <div className="content-item-extras">
            <div className="category-date">
              <p className="category">
                <span className="bold">Category:</span>
                {item.categoryCoupon.name}
              </p>
              <p className="date">
                <span className="circle-status enabled"></span>
                <span className="bold">Use By Date:</span>
                {createdDate}
              </p>
            </div>
            <div className="content-btns">
              <Button
                to={`/${user.roleActive}/coupons-and-discounts-edit/${item.id}`}
                color="gray-outline"
              >
                Edit
              </Button>
              <Button color="gray-outline" onClick={() => deleteCoupon(item.id)}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ListData = () => (
    <div className="list">
      {dataCD.map((item, i) => (
        <Item item={item} key={i} />
      ))}
    </div>
  );

  const RenderGeneral = () => {
    return (
      <>
        <div className="index-utils-create-search mt-15">
          <div className="container-btn">
            <Link className="btn-create-membership" to="create-coupons-and-discounts">
              <span className="btn-create-membership--icon">+</span> Create New
            </Link>
          </div>
        </div>
        <div className="container-list">
          <Card title="My Coupons">
            {dataCD.length > 0 ? (
              <ListData />
            ) : (
              <h5 className="ml-3 mt-3">
                You do not have any stored Coupons currently. Please create a New Coupon above
              </h5>
            )}
          </Card>
        </div>
        <Confirm
          show={show}
          onHide={() => {
            setShow(true);
          }}
          no={() => {
            setShow(false);
          }}
          yes={() => {
            setShow(false);
            setConfirmDelete(true);
          }}
          MessageBtnOne="Cancel"
          title="Delete Coupon"
          message="Are you sure you want to delete this coupon? This action cannot be undone."
        />
      </>
    );
  };

  return (
    <div className="coupons-and-discounts">
      {loading ? <Loading /> : dataCD.length >= 0 ? <RenderGeneral /> : null}
    </div>
  );
};

export default CouponsAndDiscounts;
