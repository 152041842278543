import React from 'react';
import { SponsorshipSaved } from "../../../containers";
import { Button } from "../../../components/OMB";
import { useSelector } from 'react-redux';
import './styles.css';

const Sponsorships = () => {
  const { roleActive } = useSelector((store) => store.user);

  return (
    <div className="sponsorships">
      <SponsorshipSaved />
    </div>
  )
}

export default Sponsorships;
