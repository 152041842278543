import React from 'react';
import { useFormatDate } from '../../../hooks/useFormatDate';

export const ItemList = ({ invite }) => {
  const date = invite.registered.split('-');
  const registered = [ date[1], date[0], date[2] ].join('-');

  return (
    <div className="fila" key={invite.id}>
      <div className="column">{invite.name}</div>
      <div className="column">
        {invite.userType === 'ROLE_BUYER_AGENT' ? 'Buyer Agent' : 'Buyer'}
      </div>
      <div className="column">{registered}</div>
      <div className="column">{invite.referral}</div>
      <div className="column">${invite.totalAmountEarnedJoining.toFixed(2)}</div>
    </div>
  );
};
