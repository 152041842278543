import React from 'react';
import ProfileForm from '../../../containers/ProfileForm';
import { useSelector } from 'react-redux';

const Profile = (props) => {
  const { info: { id: userId } } = useSelector((store) => store.user);

  return (
    <ProfileForm userId={userId} />
  );
};

export default Profile;
