import React from 'react';
import { GoBack } from '../../components/OMB';
import { AddTransactionFeeForm } from "../../containers";
import './styles.css';

const AddTransactionFee = (props) => {
  return (
    <div className="add-fransaction-fee-form">
      <GoBack {...props} />
      <AddTransactionFeeForm />
    </div>
  )
}

export default AddTransactionFee;
