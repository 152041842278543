import React, { useState, useEffect } from 'react';
import { Card, EmptyList, DTable } from "../../../components/OMB";
import { formatPrice } from '../../../utils/services';

const ManagementList = ({ info }) => {
  const [data, setData] = useState([]);
  const { listTransactionFees, grossProfitTotal, netProfitTotal } = info;

  useEffect(() => {
    if (!!listTransactionFees) {
      const dataManagement = listTransactionFees.map(({ id, listingAgentName, propertyAddress, transactionDate, fee = '' }) => {
        return {
          id,
          "listingName": listingAgentName,
          propertyAddress,
          transactionDate,
          fee
        }
      })
      setData(dataManagement);
    }
  }, [listTransactionFees]);

  const columns = [
    {
      name: 'Listing Agent Name',
      selector: 'listingName',
    },
    {
      name: 'Property Address',
      selector: 'propertyAddress',
    },
    {
      name: 'Transaction Date',
      selector: 'transactionDate',
    },
    {
      name: 'Fee',
      selector: 'fee',
    },
  ];

  return (
    <>
      <Card title="Transaction Fees">
        {data.length === 0
          ? <EmptyList />
          : (
            <DTable columns={columns} data={data} />
          )
        }
      </Card>
      <Card title="Total Calculations">
        <p><span className="bold">Gross Profit Totals:</span> {formatPrice(grossProfitTotal)}</p>
        <p><span className="bold">Net Profit Totals:</span> {formatPrice(netProfitTotal)}</p>
      </Card>
    </>
  );
};

export default ManagementList;
