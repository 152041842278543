import React, { useState, useEffect } from 'react';
import { Loading, Card, EmptyList, DTable } from "../../components/OMB";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAdmins } from '../../hooks/useAdmins';

const AdminsList = () => {
  const { roleActive } = useSelector((store) => store.user);
  const [admins, loading] = useAdmins();
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const dataAdmins = admins.map(({ idUser, name, typeAdmin, email, phoneNumber }) => {
      return {
        "id": idUser,
        "name": `${name}`,
        "adminType": typeAdmin === "Customer Service" ? 'Marketing Rep' : typeAdmin,
        email,
        phoneNumber,
        "icon": '>',
        "handleClick": () => history.push(`/${roleActive}/admin/${idUser}`)
      }
    })
    setData(dataAdmins);
  }, [admins]);

  const columns = [
    {
      name: 'Name',
      selector: 'name',
    },
    {
      name: 'Type of Admin',
      selector: 'adminType',
    },
    {
      name: 'Email',
      selector: 'email',
    },
    {
      name: 'Phone Number',
      selector: 'phoneNumber',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px'
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <div className="admins-list">
      <Card
        title={`Admins (${data.length})`}
      // btnTitle="Download Template"
      // btnHandle={handleDownload}
      >
        {
          loading ? (
            <Loading />
          )
            : (
              admins.length === 0
                ? <EmptyList />
                : <DTable columns={columns} data={data} />
            )
        }
      </Card>
    </div>
  );
};

export default AdminsList;
