import React, { useEffect, useState } from 'react';
import { Card, GoBack } from '../../../components/OMB';
import { LIST_USER_COUPONSR, getApi } from '../../../utils/services';
import { useFormatDate } from '../../../hooks/useFormatDate';

const MyCoupons = (props) => {
  const userData = JSON.parse(localStorage.getItem('state'));
  const userID = userData.user.info.id;
  const urlApi = `${LIST_USER_COUPONSR}${userID}`;
  const userToken = localStorage.getItem('tokenOMB');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchCoupons = () => {
    getApi(urlApi, userToken)
      .then((response) => {
        if (response.status === 200) {
          setData(response.data.exit);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response) {
        }
      });
  };

  useEffect(() => {
    fetchCoupons();
  }, []);

  const renderLoading = () => {
    return (
      <Card title={'My Redeemed Coupons'}>
        <spam>You do not currently have any coupons to redeem.</spam>
      </Card>
    );
  };
  const List = ({ item }) => {
    const [date] = useFormatDate(item.useByDate);

    return (
      <>
        <tr key={item.id}>
          <td>{item.businessName}</td>
          <td>{item.typeCoupon.id === 1 ? item.discount : item.reward}</td>
          <td>{date}</td>
        </tr>
      </>
    );
  };
  const renderData = () => {
    return (
      <>
        <GoBack {...props} />
        <div className="my-coupons-container">
          <Card title={'My Redeemed Coupons'}>
            <table className="table">
              <thead>
                <tr>
                  <td>
                    <strong>Business Name</strong>
                  </td>
                  <td>
                    <strong>Reward</strong>
                  </td>
                  <td>
                    <strong>Date</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <List item={item} />
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </>
    );
  };

  return loading ? renderLoading() : renderData();
};

export default MyCoupons;
