import React from 'react';
import Img from '../../../assets/img/image.png';
import './styles.css';

const PropertyImg = (props) => {
  // console.log(props.profileId)
  return (
    <img
      className={props.class === 'property-admin' && props.role === 'ROLE_ADMIN' && props.profileId === undefined ? 'property-admin' : 'property-img'}
      src={props.urlImage ? props.urlImage : Img}
      onClick={props.handleClick}
      alt="property"
    />
  );
};

export default PropertyImg;
