import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buyers_profiles } from '../redux/PropertyDucks';

export const useBuyersProfiles = (profileId = null) => {
  const { info: { id: userId } } = useSelector((store) => store.user);
  const { buyersProfiles } = useSelector((store) => store.property);
  const dispatch = useDispatch();
  const id = profileId || userId;

  useEffect(() => {
    dispatch(buyers_profiles(id));
  }, []);

  return [buyersProfiles];
};
