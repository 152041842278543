import React, { useState, useEffect } from 'react';
import imgProfile from '../../assets/img/image.png';
import { Card, Button, ModalOmb, ChangePassword, ModalMessage } from '../../components/OMB';
import { useSelector } from 'react-redux';
import { Edit } from '../../components/icons';
import { Formik, Field, Form } from 'formik';
import {
  postApi,
  UPLOAD_IMAGE,
  postApiFile,
  PROFILE_UPDATE,
  GET_USER,
  UPDATE_WHOLESALER,
  getApi,
} from '../../utils/services';
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { useListStates } from '../../hooks/useListStates';
import { UploaderFile } from '../../components/OMB/UploaderFile';
import { Modal, Spinner } from 'react-bootstrap';
import './profile.css';

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string().required('Required'),
  brokerageName: Yup.string('Required').required('Required').nullable(),
  license: Yup.string('Required').required('Required').nullable(),
  stateOfIssuance: Yup.string().required('Required'),
  street: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  // country: Yup.string().required('Required'),
  zipCode: Yup.string().required('Required'),
  /* experienceOwned: Yup.string().required('Required'),
  expirienceYears1: Yup.string().required('Required'),

  experienceShort: Yup.string().required('Required'),
  expirienceYears2: Yup.string().required('Required'),

  listingsOmbReo: Yup.string().required('Required'),
  listingsOmb: Yup.string().required('Required'),

  professionalDesignations: Yup.string().required('Required'),

  memberBank: Yup.string().required('Required'),

  bankPast: Yup.string().required('Required'), */

  // street: Yup.string().required('Required'),
  // bed: Yup.number().required('Required').min(1, 'Min 1'),
  // lotSize: Yup.number().required('Required'),
  // price: Yup.number().required('Required').min(1000, 'Min 1000  '),
  // unit: Yup.number().required('Required').min(1, 'Min 1'),
  // bath: Yup.number().required('Required').min(1, 'Min 1'),
  // square_feet: Yup.number().required('Required').min(1, 'Min 1'),
  // yearBuilt: Yup.number().required('Required'),
  // city: Yup.string().required('Required'),
  // zipCode: Yup.number().required('Required'),
  // estate: Yup.number().required('Required'),
  // typeProperty: Yup.number().required('Required'),
});

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const ProfileForm = ({ userId }) => {
  const userLocal = useSelector((store) => store.user);
  const { roleActive } = useSelector((store) => store.user);
  const [user, setUser] = useState(userLocal);
  const userToken = localStorage.getItem('tokenOMB');
  const [routeImg, setRouteImg] = useState('');
  const [routeLicense, setRouteLicense] = useState('');
  const [wholesaler, setWholesaler] = useState(false);
  const [states] = useListStates();
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [titleModalMessage, setTitleModalMessage] = useState('Profile Update');
  const [messageModalMessage, setMessageModalMessage] = useState('Your changes have been saved.');
  const [loading, setLoading] = useState(false);
  const [yearsExperiences, setYearsExperiences] = useState([
    {
      id: 1,
      name: '1',
    },
    {
      id: 2,
      name: '2',
    },
    {
      id: 3,
      name: '3',
    },
    {
      id: 4,
      name: '4',
    },
    {
      id: 5,
      name: '5',
    },
    {
      id: 6,
      name: '6',
    },
    {
      id: 7,
      name: '7',
    },
    {
      id: 8,
      name: '8',
    },
    {
      id: 9,
      name: '9',
    },
    {
      id: 10,
      name: '10+',
    },
  ]);

  const loadFile = (event, d) => {
    var output = document.getElementById(d);
    output.src = URL.createObjectURL(event.target.files[0]);
    console.log(output);
    setLoading(true);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };

    const fd = new FormData();
    fd.append('upload', event.target.files[0]);
    fd.append('typeFile', 3);

    postApiFile(UPLOAD_IMAGE, fd, userToken, d)
      .then((response) => {
        setLoading(false);
        const data = response.data.upload;
        if (d === 'image-profile') {
          setRouteImg(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const callInfoUser = async () => {
    const urlApi = `${GET_USER}${userId}`;
    await getApi(urlApi, userToken)
      .then((response) => {
        const info = response.data;
        const data = {
          info,
        };
        setUser(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callInfoUser();
  }, []);

  const handleShowModal = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    if (!!user.info.profile?.file?.license) {
      setRouteLicense(user.info?.profile?.file?.license);
    }
    if (!!user.info.profile.file?.photo) {
      setRouteImg(user.info.profile.file?.photo);
    }
    setWholesaler(user.info.profile.wholesaler);
  }, [user]);

  const handleWholesaler = (event) => {
    event.preventDefault();

    const dataForm = {
      userId: parseInt(userId),
      status: wholesaler ? 0 : 1,
    };

    postApi(UPDATE_WHOLESALER, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          setWholesaler(!wholesaler);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  };

  return (
    <Card
      title="User Profile"
      btnTitle={
        roleActive === 'ROLE_ADMIN' ? (wholesaler ? 'Wholesaler Off' : 'Wholesaler On') : null
      }
      btnHandle={roleActive === 'ROLE_ADMIN' ? handleWholesaler : null}
    >
      <div className="profile-container">
        <Formik
          enableReinitialize={true}
          initialValues={{
            firstName: user.info.firstName,
            lastName: user.info.lastName,
            email: user.info.username,
            phone: user.info.profile?.phone,
            brokerageName: user.info.profile?.brokerageName,
            license: user.info.profile?.licenseNumber,
            stateOfIssuance: user.info.profile?.estateIssuance?.id,

            street: user.info.profile?.profileAddress[0]?.address.street,
            city: user.info.profile?.profileAddress[0]?.address.city,

            state: user.info.profile?.profileAddress[0]?.address.estate?.id,

            zipCode: user.info.profile?.profileAddress[0]?.address.zipCode,

            experienceOwned: user.info.profile?.profileQuestion[0]?.answer,
            expirienceYears1: '',

            experienceShort: user.info.profile?.profileQuestion[1]?.answer,
            expirienceYears2: '',

            listingsOmbReo: user.info.profile?.profileQuestion[2]?.answer,
            listingsOmb: user.info.profile?.profileQuestion[3]?.answer,

            professionalDesignations: user.info.profile?.profileQuestion[4]?.answer,

            memberBank: user.info.profile?.profileQuestion[5]?.answer,

            bankPast: user.info.profile?.profileQuestion[6]?.answer,
          }}
          validationSchema={UserSchema}
          onSubmit={(values) => {
            setLoading(true);
            console.log('submit');
            // console.log('stateOfIssuance', values.stateOfIssuance)
            console.log('Data', values);
            // debugger;
            const body = {
              id: user.info.id,
              firstName: values.firstName,
              lastName: values.lastName,
              username: values.email,
              profile: {
                id: user.info.profile.id,
                phone: values.phone,
                brokerageName: values.brokerageName,
                licenseNumber: values.license,
                profileAddress: [
                  {
                    id: user.info.profile.profileAddress[0].id, // adress
                    address: {
                      id: user.info.profile.profileAddress[0].address.id,
                      street: values.street,
                      city: values.city,
                      zipCode: values.zipCode,
                      estate: {
                        id: values.state,
                      },
                    },
                  },
                ],
                profileQuestion: [
                  {
                    question: {
                      id: 1,
                    },
                    simpleSelection: true,
                    answer: values.experienceOwned,
                  },
                  {
                    question: {
                      id: 2,
                    },
                    simpleSelection: true,
                    answer: values.experienceShort,
                  },
                  {
                    question: {
                      id: 3,
                    },
                    simpleSelection: true,
                    answer: values.listingsOmbReo,
                  },
                  {
                    question: {
                      id: 4,
                    },
                    simpleSelection: true,
                    answer: values.listingsOmb,
                  },
                  {
                    question: {
                      id: 5,
                    },
                    simpleSelection: false,
                    answer: values.professionalDesignations,
                  },
                  {
                    question: {
                      id: 6,
                    },
                    simpleSelection: false,
                    answer: values.memberBank,
                  },
                  {
                    question: {
                      id: 7,
                    },
                    simpleSelection: false,
                    answer: values.bankPast,
                  },
                ],
                estateIssuance: {
                  id: parseInt(values.stateOfIssuance),
                },
                file: {
                  photo: routeImg,
                  license: routeLicense,
                },
              },
              userRole: [
                {
                  role: {
                    id: user.info.userRole[0].role.id,
                  },
                },
              ],
            };
            postApi(PROFILE_UPDATE, body, userToken)
              .then((response) => {
                setLoading(false);
                //const data = response.data;
                console.log('body:', body);
                console.log('respuesta de edit profile', response);
                if (response.status === 200) {
                  // history.push('profile');
                  setShowModalMessage(true);
                  setTimeout(() => {
                    window.location.reload();
                  }, 12000);
                }
              })
              .catch((error) => {
                if (error.response) {
                  console.log(error.response.data);
                  console.log('Código:', error.response.status);
                }
              });
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <div className="head">
                <div className="profile-picture m-auto">
                  <label htmlFor="file-profile">
                    <div className="box-image-profile">
                      <img
                        src={
                          !!user.info.profile.file?.photo
                            ? user.info.profile.file.photo
                            : imgProfile
                        }
                        id="image-profile"
                        alt="profile"
                      />
                      <div className="ic-edit">
                        <Edit size={20} color="#FFF" />
                      </div>
                    </div>
                  </label>
                  <label className="btn-file-profile" htmlFor="file-profile">
                    {loading ? (
                      <Spinner animation="border" role="status" size="sm" className="mr-2" />
                    ) : null}
                    Change Photo
                  </label>
                  <input
                    type="file"
                    id="file-profile"
                    onChange={(event) => {
                      const d = 'image-profile';
                      loadFile(event, d);
                      console.log(event);
                    }}
                  />
                </div>
                <section className="info">
                  <div>
                    <label className="form-label">First Name</label>
                    <Field className="form-control mb-3" type="text" name="firstName" />
                    {errors.firstName && touched.firstName && (
                      <div className="error">{errors.firstName}</div>
                    )}
                    <label className="form-label">Email</label>
                    <Field className="form-control" type="email" name="email" disabled />
                    {errors.email && touched.email && <div className="error">{errors.email}</div>}
                    <Button
                      color="light-blue"
                      type="submit"
                      onClick={(e) => handleShowModal(e)}
                      ml="ml-0"
                      mt="mt-4"
                    >
                      Change Password
                    </Button>
                    {showModal ? (
                      <ChangePassword showModal={showModal} setShowModal={setShowModal} />
                    ) : null}
                  </div>
                  <div>
                    <label className="form-label">Last Name</label>
                    <Field className="form-control mb-3" type="text" name="lastName" />
                    {errors.lastName && touched.lastName && (
                      <div className="error">{errors.lastName}</div>
                    )}
                    <label className="form-label">Phone Number</label>
                    <Field
                      name="phone"
                      render={({ field }) => (
                        <MaskedInput
                          {...field}
                          mask={phoneNumberMask}
                          id="phone"
                          placeholder=""
                          type="text"
                          className={`form-control`}
                        />
                      )}
                    />
                    {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}
                  </div>
                </section>
              </div>
              <div className="row second-row">
                <div className="col-md-4">
                  <label className="form-label">Brokerage Name</label>
                  <Field className="form-control" type="text" name="brokerageName" />
                  {errors.brokerageName && touched.brokerageName && (
                    <div className="error">{errors.brokerageName}</div>
                  )}
                </div>
                <div className="col-md-4">
                  <label className="form-label">License Number</label>
                  <Field className="form-control" type="text" name="license" />
                  {errors.license && touched.license && (
                    <div className="error">{errors.license}</div>
                  )}
                </div>
                <div className="col-md-3 col-sm-4">
                  <label className="form-label">State of Issuance</label>
                  <Field
                    className="form-control stateOfIssuance"
                    as="select"
                    name="stateOfIssuance"
                    custom
                  >
                    <option>Select</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.code}
                      </option>
                    ))}
                  </Field>
                  {errors.stateOfIssuance && touched.stateOfIssuance && (
                    <div className="error">{errors.stateOfIssuance}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-5">
                  <label className="form-label">Street Address</label>
                  <Field className="form-control" type="text" name="street" />
                  {errors.street && touched.street && <div className="error">{errors.street}</div>}
                </div>
                <div className="col-md-3">
                  <label className="form-label">City</label>
                  <Field className="form-control" type="text" name="city" />
                  {errors.city && touched.city && <div className="error">{errors.city}</div>}
                </div>
                <div className="col-md-2">
                  <label className="form-label">State </label>
                  <Field className="form-control" as="select" name="state" custom>
                    <option>Select</option>
                    {states.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.code}
                      </option>
                    ))}
                  </Field>
                  {errors.state && touched.state && <div className="error">{errors.state}</div>}
                </div>
                <div className="col-md-2">
                  <label className="form-label">Zip Code</label>
                  <Field className="form-control" type="text" name="zipCode" />
                  {errors.zipCode && touched.zipCode && (
                    <div className="error">{errors.zipCode}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-5">
                  <UploaderFile
                    titleLabel="Upload Broker/Salesperson License Copy (PDF/JPG)"
                    urlApi={UPLOAD_IMAGE}
                    userToken={userToken}
                    setRoute={setRouteLicense}
                    typeFile="licenses"
                    setLoading={setLoading}
                    routeLicense={routeLicense}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-7">
                  <p>Do you have any experience listing bank owned properties?</p>
                  <div className="d-flex">
                    <label htmlFor="experience-owned-1">
                      <Field
                        type={'radio'}
                        id={`experience-owned-1`}
                        label={`Yes`}
                        name="experienceOwned"
                        value="1"
                      />
                      Yes
                    </label>
                    <label htmlFor="experience-owned-2">
                      <Field
                        type={'radio'}
                        id={`experience-owned-2`}
                        label={`No`}
                        name="experienceOwned"
                        className="input-radio"
                        value="0"
                      />
                      No
                    </label>
                  </div>
                  {errors.experienceOwned && touched.experienceOwned && (
                    <div className="error">{errors.experienceOwned}</div>
                  )}
                </div>
                {/* <div className="col-md-4">
                  <p>How many years of experience?</p>
                  <Field className="form-control" as="select" name="expirienceYears1">
                    <option>Select</option>
                    {yearsExperiences.map((year) => (
                      <option key={year.id} value={year.id}>
                        {year.name}
                      </option>
                    ))}
                  </Field>
                  {errors.expirienceYears1 && touched.expirienceYears1 && (
                    <div className="error">{errors.expirienceYears1}</div>
                  )}
                </div> */}
              </div>
              <div className="row">
                <div className="col-md-7">
                  <p>Do you have any experience listing short sales?</p>
                  <div className="d-flex">
                    <label htmlFor="">
                      <Field
                        type={'radio'}
                        id={`experience`}
                        label={`Yes`}
                        name="experienceShort"
                        value="1"
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <Field
                        type={'radio'}
                        id={`experience`}
                        label={`No`}
                        name="experienceShort"
                        className="input-radio"
                        value="0"
                      />
                      No
                    </label>
                  </div>
                  {errors.experienceShort && touched.experienceShort && (
                    <div className="error">{errors.experienceShort}</div>
                  )}
                </div>
                {/* <div className="col-md-4">
                  <p>How many years of experience?</p>
                  <Field className="form-control" as="select" name="expirienceYears2">
                    <option>Select</option>
                    {yearsExperiences.map((year) => (
                      <option key={year.id} value={year.id}>
                        {year.name}
                      </option>
                    ))}
                  </Field>
                  {errors.expirienceYears2 && touched.expirienceYears2 && (
                    <div className="error">{errors.expirienceYears2}</div>
                  )}
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-7">
                  <p>
                    Are you open to being assigned bank owned (REO) listings by OMB and its clients?
                  </p>
                  <div className="d-flex">
                    <label htmlFor="">
                      <Field
                        type={'radio'}
                        id={`experience`}
                        label={`Yes`}
                        name="listingsOmbReo"
                        value="1"
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <Field
                        type={'radio'}
                        id={`experience`}
                        label={`No`}
                        name="listingsOmbReo"
                        className="input-radio"
                        value="0"
                      />
                      No
                    </label>
                  </div>
                  {errors.listingsOmbReo && touched.listingsOmbReo && (
                    <div className="error">{errors.listingsOmbReo}</div>
                  )}
                </div>
              </div>

              <div className="row ">
                <div className="col-md-7">
                  <p>Are you open to being assigned short sales by OMB and its clients?</p>
                  <div className="d-flex">
                    <label htmlFor="">
                      <Field
                        type={'radio'}
                        id={`experience`}
                        label={`Yes`}
                        name="listingsOmb"
                        value="1"
                      />
                      Yes
                    </label>
                    <label htmlFor="">
                      <Field
                        type={'radio'}
                        id={`experience`}
                        label={`No`}
                        name="listingsOmb"
                        className="input-radio"
                        value="0"
                      />
                      No
                    </label>
                  </div>
                  {errors.listingsOmb && touched.listingsOmb && (
                    <div className="error">{errors.listingsOmb}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-7 my-50">
                  <p>What professional designations do you have?</p>
                  <Field
                    type={'text'}
                    id={`experience`}
                    label={`Yes`}
                    name="professionalDesignations"
                    className="form-control"
                  />
                  {errors.professionalDesignations && touched.professionalDesignations && (
                    <div className="error">{errors.professionalDesignations}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-7 my-50">
                  <p>What Bank Owned and/or Short Sale organizations are you a member of?</p>
                  <Field
                    type={'text'}
                    id={`experience`}
                    label={`Yes`}
                    name="memberBank"
                    className="form-control"
                  />
                  {errors.memberBank && touched.memberBank && (
                    <div className="error">{errors.memberBank}</div>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-7 my-50">
                  <p>Which Bank or Servicer clients have you worked with in the past?</p>
                  <Field
                    type={'text'}
                    id={`experience`}
                    label={`Yes`}
                    name="bankPast"
                    className="form-control"
                  />
                  {errors.bankPast && touched.bankPast && (
                    <div className="error">{errors.bankPast}</div>
                  )}
                </div>
                <Button color="yellow" type="submit">
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  ) : null}
                  SAVE CHANGES
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {showModalMessage && (
        <ModalMessage
          title={titleModalMessage}
          message={messageModalMessage}
          btnTitle="OK"
          redirect="false"
          type="message"
        />
      )}
    </Card>
  );
};

export default ProfileForm;
