import React, { useState, useEffect } from 'react';
import { EmptyList, DTable, Card, Loading } from "../../../components/OMB";
import { getApi, LIST_MESSAGES } from '../../../utils/services';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Messages = ({ userId }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive } = useSelector((store) => store.user);
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(`${LIST_MESSAGES}${userId}`, userToken)
      .then((response) => {
        setMessages(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    const dataMessages = messages.map(({ idConversation, street, city, codEstate, numeroMensajes, names }) => {
      return {
        id: idConversation,
        name: `${names} (${numeroMensajes})`,
        subject: `${street}, ${city}, ${codEstate}`,
        icon: '>',
        handleClick: () => history.push(`/${roleActive}/messages/chat/${idConversation}/${userId}`),
      };
    })
    setData(dataMessages);
  }, [messages]);

  const columns = [
    {
      name: 'Agent or Buyer',
      selector: 'name',
    },
    {
      name: 'Message Subject',
      selector: 'subject',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px'
    },
  ];

  return (
    <Card title="User's Messages">
      {loading ? <Loading /> :
        messages.length === 0
          ? <EmptyList />
          : (
            <DTable columns={columns} data={data} />
        )}
    </Card>
  )
}

export default Messages;
