import React, { useState, useEffect } from 'react';
import { Loading } from '..';
import { getApi, LIST_OFFERS_PROPERTY } from '../../../utils/services';
import OfferItem from './OfferItem';
import './styles.css';

const ListOffersProperty = ({ propertyID }) => {
  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem('tokenOMB');
  const urlApi = `${LIST_OFFERS_PROPERTY}${propertyID}`;

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        const data = response.data;
        setOffers(data);
        setLoading(false)
      })
      .catch((error) => {
        if (error.response) {
          // error
        }
      });
  };

  const List = () => (
    <div className="list">
      <div className="fila">
        <div className="column column-title bold">Date</div>
        <div className="column column-title bold">Signer</div>
        <div className="column column-title bold">Amount</div>
        <div className="column column-title bold">Funding</div>
        <div className="column column-title bold"></div>
      </div>
      {offers.map((offer, i) => {
        if (offer.offerDate) {
          return <OfferItem key={`offer-${i}`} offer={offer} />;
        }
      })}
    </div>
  );

  return (
    <div className="ListOffersProperty">
      {loading && <Loading />}
      {!loading && offers.length === 0 && <h4>No offers available. Check back soon!</h4>}
      {!loading && offers.length > 0 && <List />}
    </div>
  );
};

export default ListOffersProperty;
