import React from 'react';
import { Card, GoBack, ListOffersProperty } from '../../../components/OMB';
import { useOffersProperties } from "../../../hooks/useOffersProperties";

const Offers = (props) => {
  const propertyID = props.match.params.id;
  let paths = window.location.pathname.split('/');
  console.log(paths)
  const userViewId = paths[5];
  useOffersProperties(userViewId);

  return (
    <>
      <GoBack {...props} />
      <Card title={'Property Offers'}>
        <ListOffersProperty propertyID={propertyID} />
      </Card>
    </>
  );
};

export default Offers;
