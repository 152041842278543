import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import Button from '../../Button/Button';
import './style.css';

const Confirm = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide()}
      MessageBtnOne={props.MessageBtnOne}
      MessageBtnTwo={props.MessageBtnTwo}
      centered
      size="lg"
      hideBtnOne={props.hideBtnOne}
    >
      <Modal.Body>
        <h2 className="title-modal">{props.title}</h2>
        <p className="message">{props.message}</p>
        {props.children}
      </Modal.Body>
      <Modal.Footer>
        {props.hideBtnOne ? null : ( <Button color="gray" onClick={() => props.no()}>
          {props.MessageBtnOne === undefined || props.MessageBtnOne === ''
            ? 'NO'
            : props.MessageBtnOne}
        </Button>)}
       
        <Button variant="primary" onClick={() => props.yes()} disabled={props.btnDisabled}>
          {props.loading ? (
            <Spinner animation="border" role="status" size="sm" className="mr-2" />
          ) : null}
          {props.MessageBtnTwo === undefined || props.MessageBtnTwo === ''
            ? 'YES'
            : props.MessageBtnTwo}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;
