import React from 'react';
import { Box, Button } from '../../../components/OMB';
import { useSelector } from 'react-redux';

const MarkAsPurchased = (props) => {
  const user = useSelector((store) => store.user);

  return (
    <React.Fragment>
      <Box title="Marked As Purchased">
        <p className="text-purchased">
          This property will now show with a “sold” status on your offer list.
        </p>
        <div className="text-center">
          <Button
            color="yellow"
            onClick={() =>
              (window.location.href = '/' + user.roleActive + '/offers/my-offers/' + user.info.id)
            }
          >
            RETURN TO OFFERS
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );
};

export default MarkAsPurchased;
