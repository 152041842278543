import { useState, useEffect } from 'react';
import { GET_UTILS, getApi } from '../utils/services';

export const useListStates = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const [states, setStates] = useState([]);

  useEffect(() => {
    getApi(GET_UTILS, userToken)
      .then((response) => {
        const data = response.data.estate;
        setStates(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  }, []);

  return [states];
};
