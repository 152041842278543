import React, { useEffect, useState } from 'react';
import BuyersAgentsList from './BuyersAgentsList';
import BuyersList from './BuyersList';
import { ContentTab, ItemTab, Button } from '../../components/OMB';
import { getApi, postApi } from '../../utils/services';
import { LIST_MARKETING } from '../../utils/services/endpoints';
import './styles.css';
import ListingAgentsList from './ListingAgents';
import SponsorsList from './SponsorsList';
import LeadsList from './LeadsList';

const MarketingTabs = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const [buyersAgents, setBuyersAgents] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [listingAgents, setListingAgents] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [leads, setLeads] = useState([]);
  const [numPage, setNumPage] = useState(1);
  const [pagesTotalBuyersAgents, setPagesTotalBuyersAgents] = useState(0);
  const [pagesTotalBuyers, setPagesTotalBuyers] = useState(0);
  const [pagesTotalListing, setPagesTotalListing] = useState(0);
  const [pagesTotalSponsors, setPagesTotalSponsors] = useState(0);
  const [pagesTotalLeads, setPagesTotalLeads] = useState(0);

  const [count, setCount] = useState({});
  const [stateBuyerAgent, setStateBuyerAgent] = useState('');
  const [stateBuyer, setStateBuyer] = useState('');
  const [stateListing, setStateListing] = useState('');
  const [stateSponsors, setStateSponsors] = useState('');
  const [stateLeads, setStateLeads] = useState('');

  const [loadingData, setLoadingData] = useState({
    buyerAgent: false,
    buyer: false,
    listing: false,
    sponsor: false,
  });

  const [Url, setUrl] = useState(`${LIST_MARKETING}page/${numPage}/rows/500000`);

  const callCount = () => {
    getApi(`https://offmarketbroker.com:8445/app-omb/api/emailMarketing/count`, userToken)
      .then((response) => {
        if (response.data) {
          // console.log('respuesta de count', response.data);
          setCount(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  

  const callListBuyerAgents = () => {
    const data = {
      estate: {
        code: stateBuyerAgent,
      },
      role: {
        id: 3,
      },
    };
    setLoadingData({
      ...loadingData,
      buyerAgent: true,
    });
    postApi(Url, data, userToken)
      .then((response) => {
        if (response.data) {
          setLoadingData({
            ...loadingData,
            buyerAgent: false,
          });
          console.log('respuesta de lista de marketing', response.data.Listing);
          setPagesTotalBuyersAgents(response.data.pages);
          setBuyersAgents(response.data.BuyerAgent);
        }
      })
      .catch((error) => {
        setLoadingData({
          ...loadingData,
          buyerAgent: false,
        });
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

 
  const callListBuyers = () => {
    setLoadingData({
      ...loadingData,
      buyer: true,
    });
    const data = {
      estate: {
        code: stateBuyer,
      },
      role: {
        id: 4,
      },
    };
    postApi(Url, data, userToken)
      .then((response) => {
        if (response.data) {
          setLoadingData({
            ...loadingData,
            buyer: false,
          });
          console.log('respuesta de lista de marketing', response.data.Listing);
          setPagesTotalBuyers(response.data.pages);
          setBuyers(response.data.Buyer);
        }
      })
      .catch((error) => {
        setLoadingData({
          ...loadingData,
          buyer: false,
        });
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const callListLeads = () => {
    setLoadingData({
      ...loadingData,
      buyer: true,
    });
    const data = {
      estate: {
        code: stateBuyer,
      },
      role: {
        id: 4,
      },
    };
    postApi(Url, data, userToken)
      .then((response) => {
        if (response.data) {
          setLoadingData({
            ...loadingData,
            buyer: false,
          });
          console.log('respuesta de lista de marketing', response.data.Listing);
          setPagesTotalBuyers(response.data.pages);
          setBuyers(response.data.Buyer);
        }
      })
      .catch((error) => {
        setLoadingData({
          ...loadingData,
          buyer: false,
        });
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const callListListing = () => {
    setLoadingData({
      ...loadingData,
      listing: true,
    });
    const data = {
      estate: {
        code: stateListing,
      },
      role: {
        id: 2,
      },
    };
    postApi(Url, data, userToken)
      .then((response) => {
        if (response.data) {
          setLoadingData({
            ...loadingData,
            listing: false,
          });
          console.log('respuesta de lista de marketing', response.data.Listing);
          setPagesTotalListing(response.data.pages);
          setListingAgents(response.data.Listing);
        }
      })
      .catch((error) => {
        setLoadingData({
          ...loadingData,
          listing: false,
        });
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const callListSponsors = () => {
    setLoadingData({
      ...loadingData,
      sponsor: true,
    });
    const data = {
      estate: {
        code: stateSponsors,
      },
      role: {
        id: 5,
      },
    };
    postApi(Url, data, userToken)
      .then((response) => {
        if (response.data) {
          setLoadingData({
            ...loadingData,
            sponsor: false,
          });
          console.log('respuesta de lista de marketing', response.data.Listing);
          setPagesTotalSponsors(response.data.pages);
          setSponsors(response.data.Sponsor);
        }
      })
      .catch((error) => {
        setLoadingData({
          ...loadingData,
          sponsor: false,
        });
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  
  const callBuyersAgents = () => {
    callListBuyerAgents();
  };

  
  useEffect(() => {
    if (numPage > 1) {
      setUrl(`${LIST_MARKETING}page/${numPage}/rows/1000`);
    }
  }, [numPage]);

  useEffect(() => {
    callCount();

  }, []);
  
  /* useEffect(() => {
    callListData();
  }, [Url]); */
  
  return (
    <>
      <div className="marketing-tabs">
        <ContentTab defaultActiveKey="buyers-agents" id="uncontrolled-tab-example">
          <ItemTab eventKey="buyers-agents" title="Buyer's Agents">
            <BuyersAgentsList
              numPage={numPage}
              setNumPage={setNumPage}
              pagesTotal={pagesTotalBuyersAgents}
              buyersAgents={buyersAgents}
              setBuyersAgents={setBuyersAgents}
              count={count.buyerAgent}
              stateBuyerAgent={stateBuyerAgent}
              setStateBuyerAgent={setStateBuyerAgent}
              callListBuyerAgents={callBuyersAgents}
              loadingData={loadingData.buyerAgent}
            />
          </ItemTab>
          <ItemTab eventKey="buyers" title="Buyers">
            <BuyersList
              numPage={numPage}
              setNumPage={setNumPage}
              pagesTotal={pagesTotalBuyers}
              buyers={buyers}
              setBuyers={setBuyers}
              callListData={callListBuyers}
              count={count?.buyer}
              stateBuyer={stateBuyer}
              setStateBuyer={setStateBuyer}
              loadingData={loadingData.buyer}
            />
          </ItemTab>
          <ItemTab eventKey="listing" title="Listing Agent's">
            <ListingAgentsList
              numPage={numPage}
              setNumPage={setNumPage}
              pagesTotal={pagesTotalListing}
              listingAgents={listingAgents}
              setListingAgents={setListingAgents}
              callListData={callListListing}
              count={count?.listing}
              stateListing={stateListing}
              setStateListing={setStateListing}
              loadingData={loadingData.listing}
            />
          </ItemTab>
          <ItemTab eventKey="sponsors" title="Sponsors">
            <SponsorsList
              numPage={numPage}
              setNumPage={setNumPage}
              pagesTotal={pagesTotalSponsors}
              sponsors={sponsors}
              setSponsors={setSponsors}
              callListData={callListSponsors}
              count={count?.sponsor}
              stateSponsors={stateSponsors}
              setStateSponsors={setStateSponsors}
              loadingData={loadingData.sponsor}
            />
           
          </ItemTab>
           <ItemTab eventKey="leads" title="Leads">
            <LeadsList
              numPage={numPage}
              setNumPage={setNumPage}
              pagesTotal={pagesTotalBuyers}
              leads={leads}
              setLeads={setLeads}
              callListData={callListLeads}
              count={count?.buyer}
              stateBuyer={stateBuyer}
              setStateBuyer={setStateBuyer}
              loadingData={loadingData.buyer}
            />
          </ItemTab>
        </ContentTab>
      </div>
    </>
  );
};

export default MarketingTabs;
