import React from 'react';
import { GoBack } from '../../components/OMB';
import { PropertyList } from '../../containers';
import { useOffersProperties } from "../../hooks/useOffersProperties";

const MyOffersList = (props) => {
  const [offersProperties] = useOffersProperties();

  return (
    <div className="my-offers-list">
      <GoBack {...props} />
      <PropertyList contentType="DOS" properties={offersProperties} myOffers={true}/>
    </div>
  )
}

export default MyOffersList;
