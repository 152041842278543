import React, { useState, useEffect } from 'react';
import { Card, Button, GoBack, Loading } from '../../../components/OMB';
import { GET_OFFER_DETAILS, VIEW_OFFER, getApi } from '../../../utils/services';
import './OfferDetails.css'
// import axios from 'axios'

const OfferDetails = (props) => {
  const userToken = localStorage.getItem('tokenOMB');
  const id = props.match.params.id;
  const urlApiDetail = `${GET_OFFER_DETAILS}${id}`;
  const urlApiView = `${VIEW_OFFER}${id}`;
  const [isNew, setIsNew] = React.useState(false);
  const [isCheck, setIsCheck] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [offDeals, setOffDeals] = React.useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [buyerType, setBuyerType] = useState('Entity');

  useEffect(() => {
    fetchOfferDetails();
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year
    var hours = new Date().getHours(); //Current Hours
    var min = new Date().getMinutes(); //Current Minutes
    setCurrentDate(month + '/' + date + '/' + year + ' ' + hours + ':' + min);
  }, []);

  const fetchOfferDetails = async () => {
    await getApi(urlApiDetail, userToken)
      .then((response) => {
        onLoad(response.data);
        setLoading(false);
        // console.log('Data General: ',response.data);
        if (response.data.buyerType.name) {
          setBuyerType(response.data.buyerType.name)
        }
        if (response.data.viewStatus) {
          setIsNew(true);
        } else {
          setIsNew(false);
        }
      })
      .catch((error) => {
        if (error.response) {
        }
      });
  };

  const onLoad = (dataList) => {
    setOffDeals([
      ...offDeals,
      {
        amountOffered: dataList.amountOffered,
        address:
          dataList.address.street +
          ',' +
          dataList.address.unit +
          ',' +
          dataList.address.estate.code,
        buyer: dataList.name,
        buyerName: dataList.name,
        email: dataList.email,
        phone: dataList.phone,
        entityName: dataList.entityName,
        signerName: dataList.signerName,
        signerPhone: dataList.phone,
        signerEmail: dataList.email,
        offerAmount: '$' + dataList.amountOffered,
        edmAmount: '$' + dataList.emdAmount,
        paymentType: dataList.funding.name,
        loanContingency: dataList.longContingency,
        inspectionContingency: dataList.inspectionContingency,
        appraisalContingency: dataList.appraisalContingency,
        homeWarranty: dataList.homeWarranty,
        message: dataList.message,
      },
    ]);
  };

  const setViewOffer = () => {
    //  console.log(urlApiView);
    getApi(urlApiView, userToken)
      .then((response) => {
        if (response.data.viewStatus) {
          setIsNew(true);
        } else {
          setIsNew(false);
        }
      })
      .catch((error) => {
        if (error.response) {
        }
      });
  };

  const renderLoading = () => {
    return <Loading />;
  };

  const renderData = (dataList) => {
    if (isNew) {
      return (
        <div>
          <GoBack {...props} />
          {dataList.map((offer) => {
            return (
              <Card title={"Buyer's Details"}>
                <p>
                  <strong>Offer submitted as: </strong>{buyerType.toLowerCase()}
                </p>
                {buyerType === 'PERSONAL NAME' ?
                  <div className="row">
                    <div className="col-md-4">
                      <label>Name</label>
                      <input
                        className="form-control"
                        name="offDeal[name]"
                        disabled
                        value={offer.buyerName}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Phone Number </label>
                      <input
                        className="form-control"
                        name="offDeal[phone]"
                        disabled
                        value={offer.phone}
                      />
                    </div>
                    <div className="col-md-4">
                      <label>Email </label>
                      <input
                        className="form-control"
                        name="offDeal[email]"
                        disabled
                        value={offer.email}
                      />
                    </div>
                  </div> :
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <label>Name</label>
                        <input
                          className="form-control"
                          name="offDeal[name]"
                          disabled
                          value={offer.buyerName}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Phone Number </label>
                        <input
                          className="form-control"
                          name="offDeal[phone]"
                          disabled
                          value={offer.phone}
                        />
                      </div>
                      <div className="col-md-4">
                        <label>Email </label>
                        <input
                          className="form-control"
                          name="offDeal[email]"
                          disabled
                          value={offer.email}
                        />
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: '20px', marginBottom: '20px' }}>
                      <div className="col-md-3">
                        <label>Entity Name</label>
                        <input className="form-control" disabled value={offer.entityName} />
                      </div>
                      <div className="col-md-3">
                        <label>Signer's Name </label>
                        <input className="form-control" disabled value={offer.signerName} />
                      </div>
                      <div className="col-md-3">
                        <label>Phone Number </label>
                        <input className="form-control" disabled value={offer.signerPhone} />
                      </div>
                      <div className="col-md-3">
                        <label>Email </label>
                        <input className="form-control" disabled value={offer.signerEmail} />
                      </div>
                    </div>
                  </>
                }
              </Card>
            );
          })}
          <br></br>
          {dataList.map((offer) => {
            return (
              <Card title={'Purchase Details'}>
                <div className="row">
                  <div className="col-md-4">
                    <label>Offer Amount</label>
                    <input className="form-control" disabled value={offer.offerAmount} />
                  </div>
                  <div className="col-md-4">
                    <label>EDM (Deposit) Amount </label>
                    <input className="form-control" disabled value={offer.edmAmount} />
                  </div>
                  <div className="col-md-4">
                    <label>Payment type </label>
                    <input className="form-control" disabled value={offer.paymentType} />
                  </div>
                </div>
              </Card>
            );
          })}
          <br></br>
          <Card title={'Required Documentation'}>
            <div className="row" style={{ marginTop: '20px', marginBottom: '20px' }}>
              <div className="col-md-6">
                <a href="# ">
                  <svg
                    style={{ marginRight: '5px' }}
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-file-earmark-arrow-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                    <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                    <path
                      fill-rule="evenodd"
                      d="M8 6a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 10.293V6.5A.5.5 0 0 1 8 6z"
                    />
                  </svg>
                    Download Article of Incorporation
                  </a>
              </div>
              <div className="col-md-6">
                <a href="# ">
                  <svg
                    style={{ marginRight: '5px' }}
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-file-earmark-arrow-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                    <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                    <path
                      fill-rule="evenodd"
                      d="M8 6a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 10.293V6.5A.5.5 0 0 1 8 6z"
                    />
                  </svg>
                    Download Operating Agreement
                  </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <a href="# ">
                  <svg
                    style={{ marginRight: '5px' }}
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-file-earmark-arrow-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                    <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                    <path
                      fill-rule="evenodd"
                      d="M8 6a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 10.293V6.5A.5.5 0 0 1 8 6z"
                    />
                  </svg>
                    Download Proof of Funds
                  </a>
              </div>
              <div className="col-md-6">
                <a href="# ">
                  <svg
                    style={{ marginRight: '5px' }}
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-file-earmark-arrow-down"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z" />
                    <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                    <path
                      fill-rule="evenodd"
                      d="M8 6a.5.5 0 0 1 .5.5v3.793l1.146-1.147a.5.5 0 0 1 .708.708l-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 10.293V6.5A.5.5 0 0 1 8 6z"
                    />
                  </svg>
                    Download Pre-Approval Letter
                  </a>
                <p>(Non-Cash Offers) </p>
              </div>
            </div>
          </Card>
          <br></br>
          {dataList.map((offer) => {
            return (
              <Card title={'Aditional Terms'}>
                <div className="row">
                  <div className="col-md-3">
                    <label>Loan Contingency</label>
                    <div className="d-flex">
                      <input className="form-control" disabled value={offer.loanContingency} />
                      <p style={{ marginTop: '8px', marginLeft: '7px' }}>Days</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Inspection Contingency </label>
                    <div className="d-flex">
                      <input
                        className="form-control"
                        disabled
                        value={offer.inspectionContingency}
                      />
                      <p style={{ marginTop: '8px', marginLeft: '7px' }}>Days</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Appraisal Contingency </label>
                    <div className="d-flex">
                      <input
                        className="form-control"
                        disabled
                        value={offer.appraisalContingency}
                      />
                      <p style={{ marginTop: '8px', marginLeft: '7px' }}>Days</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <label>Home Warranty </label>
                    <div className="d-flex">
                      <input className="form-control" disabled value={offer.homeWarranty} />
                      <p style={{ marginLeft: '10px' }}>Contributed by seller</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <p>Aditional Notes to Listing Agent about Offer</p>
                  <div className="aditional_notes">
                    {offer.message}
                  </div>
                </div>
              </Card>
            );
          })}
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div>
            <GoBack {...props} />
            {dataList.map((offer) => {
              return (
                <Card title={'View Offer'}>
                  <p>
                    Off Market Broker is pleased that you have chosen to view this member's offer.
                  </p>
                  <p>
                    Today's Date: <strong> {currentDate}</strong>{' '}
                  </p>
                  <p>
                    Address: <strong> {offer.address}</strong>{' '}
                  </p>
                  <p>
                    Offer Amount: <strong>{offer.amountOffered}</strong>{' '}
                  </p>
                  <p>
                    Buyer/Signer Name: <strong>{offer.buyer}</strong>{' '}
                  </p>
                  <br></br>
                  <p>
                    Please acknowledge that you have read and agreed to the Terms & Conditions in
                    order for OMB to provide you with the member's contact information.
                  </p>
                  <div>
                    <input
                      type="checkbox"
                      id="accept"
                      name="accept"
                      onChange={() => {
                        setIsCheck(!isCheck);
                      }}
                      style={{ marginRight: '10px' }}
                    />
                    <label htmlFor="accept">
                      Check here to indicate you have read and agreed to the Terms & Conditions in order for OMB to provide you with the member’s contact information and please input your name.
                    </label>
                    {error ? (
                      <div className="error">Please agree to the Terms & Conditions</div>
                    ) : null}
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      color="yellow"
                      onClick={() => {
                        if (!isCheck) {
                          setError(true);
                        } else {
                          setError(false);
                          setViewOffer();
                        }
                      }}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </Card>
              );
            })}
          </div>
        </React.Fragment>
      );
    }
  };
  return loading ? renderLoading() : renderData(offDeals);
};

export default OfferDetails;
