import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Card, ModalMessage, StripeForm } from '../../components/OMB';
import { postApi, SPONSOR_OPPORTUNITIES } from '../../utils/services';
import './styles.css';

const SponsorMemberCards = ({ userId }) => {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [month, setMonth] = useState(0);
  const [price, setPrice] = useState(0);
  const [paymentStripeId, setPaymentStripeId] = useState(null);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [paymentSubmit, setPaymentSubmit] = useState(false);
  const [titleModal, setTitleModal] = useState('Error Creating Membership');
  const [messageModal, setMessageModal] = useState('Your Sponsored Membership could not be created. Please review your information and try again.');
  const [showModal, setShowModal] = useState(false);
  const userToken = localStorage.getItem('tokenOMB');
  const userData = JSON.parse(localStorage.getItem('state'));
  const userIDSeccion = userData.user.info.id;
  const urlApi = `${SPONSOR_OPPORTUNITIES}`;
  const [goBack, setgoBack] = useState(false);

  useEffect(() => {
    const newPrice = parseInt(month) * 99;
    setPrice(newPrice);
  }, [month]);
  useEffect(() => {
    if (price > 0) {
      setDisabledStatus(true);
    }
  }, [price]);
  useEffect(() => {
    if (!!paymentStripeId) {
      setDisabledStatus(true);
    }
  }, [paymentStripeId]);
  const submitData = () => {
    setSubmitting(true);
    setPaymentSubmit(true);
  };

  useEffect(() => {
    if (paymentStripeId !== null) {
      postApi(urlApi, dataForm, userToken)
      .then((response) => {
          setShowModal(true);
          const { status } = response;
          setSubmitting(false);
          if (status === 200) {
            setTitleModal('Successfully Created');
            setMessageModal('Your Sponsored Membership has been created.');
            setgoBack(true);
          }
        })
        .catch((error) => {
          if (error.response) {
            setSubmitting(false);
            console.log(error.response.data);
            console.log('Código:', error.response.status);
          }
        });
    }
  }, [paymentStripeId]);

  const dataForm = {
    user: {
      id: userIDSeccion,
    },
    sponsor: {
      quantity: parseInt(month),
      user: {
        id: parseInt(userId),
      },
    },
    paymentMethod: paymentStripeId,
  };
  return (
    <>
      <Card title="Sponsor This Member" className="sponsor-member-cards">
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label className="form-label">
                How many months would you like to sponsor this member for?
              </label>
              <select
                className="form-control"
                as="select"
                id="month"
                name="month"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                <option value={0}>Select</option>
                {months?.map((item) => {
                  return (
                    <option value={item} key={`month-${item}`}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <span>Membership Price</span>
            <div className="price">${price}</div>
          </div>
        </div>
      </Card>
      <Card title="Payment Details">
        <StripeForm
          amount={price}
          setPaymentStripeId={setPaymentStripeId}
          paymentSubmit={paymentSubmit}
          setMessageModal={setMessageModal}
        />
      </Card>
      {!!showModal ? <ModalMessage title={titleModal} message={messageModal} goback={goBack}/> : null}
      <div className="box-btn-btn">
        <button
          className="yell-btn text-uppercase demi"
          disabled={!disabledStatus || submitting}
          onClick={submitData}
        >
          {submitting ? (
            <>
              <Spinner animation="border" variant="light" /> Loading...
            </>
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </>
  );
};

export default SponsorMemberCards;
