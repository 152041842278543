import React, { useState, useEffect } from 'react';
import { Card, Button, Confirm, Loading } from "../../../components/OMB";
import { LIST_COUPONS_DISCOUNTS, getApi, DELETE_COUPON } from '../../../utils/services/';
import { useSelector } from 'react-redux';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useParams } from 'react-router';
import './styles.css'

const CouponsAndDiscounts = ({ userId }) => {
  const { id: profileId } = useParams();
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive } = useSelector((store) => store.user);
  const [dataCD, setDataCD] = useState([]);
  const [show, setShow] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idCp, setIdCP] = useState();
  const [loading, setLoading] = useState(true);
  const urlApi = `${LIST_COUPONS_DISCOUNTS}${profileId || userId}`;

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        setLoading(false);
        const status = response.status;
        if (status == 200) {
          const data = response.data.exit;
          setDataCD(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          setLoading(false);
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const deleteCoupon = (id) => {
    setShow(!show);
    setIdCP(id);
  };

  useEffect(() => {
    if (idCp && confirmDelete === true) {
      const urlApi = `${DELETE_COUPON}${idCp}`;
      getApi(urlApi, userToken)
        .then((response) => {
          const data = response;
          if (data.status === 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log('Código:', error.response.status);
          }
        });
    }
  }, [confirmDelete, idCp]);

  const Item = (props) => {
    const { item } = props;
    const [createdDate] = useFormatDate(item.useByDate);

    return (
      <div className="item" key={item.id}>
        <div className="content-image-code">
          <div className="content-code">
            <p className="code bold">Code: {item.code}</p>
          </div>
          <div className="content-image">
            <img src={item.url_image} alt="Item" />
          </div>
        </div>
        <div className="content-info">
          <div className="bar-title">
            <p className="title-item bold">{item.title}</p>
            <p className="type">
              <span className="bold">Type:</span> {item.typeCoupon.name}
            </p>
          </div>
          <p className="info">{item.info}</p>
          <div className="content-item-extras">
            <div className="category-date">
              <p className="category">
                <span className="bold">Category:</span>
                {item.categoryCoupon.name}
              </p>
              <p className="date">
                <span className="circle-status enabled"></span>
                <span className="bold">Use By Date:</span>
                {createdDate}
              </p>
            </div>
            <div className="content-btns">
              <Button
                to={`/${roleActive}/coupons-and-discounts-edit/${item.id}`}
                color="gray-outline"
              >
                Edit
              </Button>
              <Button color="gray-outline" onClick={() => deleteCoupon(item.id)}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ListData = () => (
    <>
      {dataCD.map((item, i) => (
        <Item item={item} key={i} />
      ))}
    </>
  );

  return (
    <div className="coupons-and-discounts">
      <div className="btn-container">
        <Button color="light-blue" to={`/${roleActive}/create-coupons-and-discounts/${profileId}`}>
          + Create New
        </Button>
      </div>
      <Card title="User’s Coupons">
        {loading ? (
          <Loading />
        ) : dataCD.length > 0 ? (
          <ListData />
        ) : (
          <h5 className="ml-3 mt-3">
            You do not have any stored Coupons currently. Please create a New Coupon above
          </h5>
        )}
      </Card>
      <Confirm
        show={show}
        onHide={() => {
          setShow(true);
        }}
        no={() => {
          setShow(false);
        }}
        yes={() => {
          setShow(false);
          setConfirmDelete(true);
        }}
        MessageBtnOne="Cancel"
        title="Delete Coupon"
        message="Are you sure you want to delete this coupon? This action cannot be undone."
      />
    </div>
  );
};

export default CouponsAndDiscounts;
