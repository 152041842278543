import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list_all_users } from '../redux/adminDucks';

export const useAllUsers = () => {
  const { allUsers, loading } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(list_all_users());
  }, []);

  return [allUsers, loading];
};
