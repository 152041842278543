import { Referrals, ReferralsDetails, CreateCode } from '../views/';

export const AdminRefferalsRoutes = [
  {
    path: '/referrals',
    name: 'Referrals',
    icon: 'super-admin/referrals.svg',
    component: Referrals,
    hide: false,
    role: 'ROLE_ADMIN_REFFERALS',
    primary: true,
  },
  {
    path: '/create-code',
    name: 'Create Code',
    icon: 'pe-7s-graph',
    component: CreateCode,
    hide: true,
    role: 'ROLE_ADMIN_REFFERALS',
  },
  {
    path: '/referrals-details/:id',
    name: 'Referrals Details',
    icon: 'pe-7s-graph',
    component: ReferralsDetails,
    hide: true,
    role: 'ROLE_ADMIN_REFFERALS',
  },
];
