const columns = [
    {
      name: 'Name',
      selector: 'name',
      width: 'auto',
    },
    {
      name: 'Email',
      selector: 'email',
      width: 'auto',
    },
    {
      name: 'Phone',
      selector: 'phone',
      width: '140px',
    },
    {
      name: 'City',
      selector: 'city',
      width: '140px',
    },
    {
      name: 'County',
      selector: 'county',
      width: '140px',
    },
    {
      name: 'State',
      selector: 'state',
      width: '60px',

    },
    {
      name: 'Zip',
      selector: 'zipCode',
      width: '80px',

    },
    {
      name: '',
      selector: 'action',
      width: '80px',
    },
    {
      selector: 'icon',
      right: true,
      width: '10px',
    },
  ];

  export default columns;