import React from 'react';
import { GoBack } from '../../../components/OMB';
import { SponsorTabs } from '../../../containers';

const SponsorDetails = (props) => {
  const { id: profileId } = props.match.params;

  return (
    <div className="sponsor-details">
      <GoBack {...props} />
      <SponsorTabs userId={parseInt(profileId)} />
    </div>
  )
}

export default SponsorDetails;
