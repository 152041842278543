import React from 'react';
import { BuyersProfileSaved } from '../..';

const BuyersProfiles = ({ userId }) => {
  return (
    <BuyersProfileSaved profileId={userId} />
  );
};

export default BuyersProfiles;
