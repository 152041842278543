import React from 'react';
import { ContentTab, ItemTab, Button } from '../../components/OMB';
import UserProfile from "./UserProfile";
import Properties from "./Properties";
import Offers from "./Offers";
import InvitedMembers from "../SuperAdminCommons/InvitedMembers";
import Messages from "../SuperAdminCommons/Messages";
import { useSelector } from 'react-redux';
import bulkImport from '../../assets/iconos/bulk-import.svg';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import "./styles.css";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Bulk Import
  </Tooltip>
);

const ListingAgentTabs = ({ userId }) => {
  const { roleActive } = useSelector((store) => store.user);

  return (
    <div className="listing-agent-tabs">
      <ContentTab defaultActiveKey="user-profile" id="uncontrolled-tab-example">
        <ItemTab eventKey="user-profile" title="User Profile">
          <UserProfile userId={userId} />
        </ItemTab>
        <ItemTab eventKey="properties" title="Properties">
          <div className="btn-container">
            <Button
              color="light-blue"
              to={`/${roleActive}/add-property/listing-agent/${userId}`}
            >
              + ADD NEW PROPERTY
            </Button>
            <OverlayTrigger
              placement="left"
              delay={{ show: 250 }}
              overlay={renderTooltip}
            >
              <Button
                color="light-blue"
                to={`/${roleActive}/bulk-import/${userId}`}
              >
                <img src={bulkImport} height={32} alt="Icon Send" />
              </Button>
            </OverlayTrigger>
          </div>
          <Properties userId={userId} />
        </ItemTab>
        <ItemTab eventKey="offers" title="Offers">
          <Offers userId={userId} />
        </ItemTab>
        <ItemTab eventKey="invited-members" title="Invited Members">
          <InvitedMembers userId={userId} />
        </ItemTab>
        <ItemTab eventKey="messages" title="Messages">
          <Messages userId={userId} />
        </ItemTab>
      </ContentTab>
    </div>
  );
};

export default ListingAgentTabs;
