import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, Spinner } from 'react-bootstrap';
import Button from '../Button/Button';
import { useSelector } from 'react-redux';
import { postApi, USER_SAVE } from '../../../utils/services';
import { ModalMessage } from '..';
import { useParams } from 'react-router-dom';

const PassSchema = Yup.object().shape({
  password: Yup.string().min(6, 'Too Short!').max(13, 'Too Long!').required('Required'),
  retryPassword: Yup.string().when('password', {
    is: (val) => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both passwords must match'),
  }),
});

const ChangePassword = ({ showModal, setShowModal }) => {
  const {
    info: { id: userId },
    roleActive
  } = useSelector((store) => store.user);
  const { id: profileId } = useParams();
  const userToken = localStorage.getItem('tokenOMB');
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRender, setShowRender] = useState(true);

  const RenderModal = () => {
    return (
      <Modal show={showModal} centered size="lg">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              password: '',
              retryPassword: '',
            }}
            validationSchema={PassSchema}
            onSubmit={(values) => {
              setLoading(true);
              const data = {
                id: roleActive !== 'ROLE_ADMIN' ? userId : parseInt(profileId),
                password: values.password,
              };
              postApi(USER_SAVE, data, userToken)
                .then((response) => {
                  setLoading(false);
                  console.log(response)
                  console.log('data:', data);
                  if (response.status === 200) {
                    setShowRender(false);
                    setShowConfirm(true);
                  }else {
                    setShowRender(false);
                    setShowError(true)
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    console.log(error.response.data);
                    console.log('Código:', error.response.status);
                  }
                });
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="row">
                  <div className="col col-6">
                    <label className="form-label bold">Password</label>
                    <Field className="form-control" type="password" name="password" />
                    {errors.password && touched.password && (
                      <div className="error">{errors.password}</div>
                    )}
                  </div>
                  <div className="col col-6">
                    <label className="form-label bold">Retype Password</label>
                    <Field className="form-control" type="password" name="retryPassword" />
                    {errors.retryPassword && touched.retryPassword && (
                      <div className="error">{errors.retryPassword}</div>
                    )}
                  </div>
                  <div className="col col-12 d-flex mt-4 justify-content-end">
                    <Button color="btn-gray" onClick={() => setShowModal(false)}>
                      CANCEL
                    </Button>
                    <Button type="submit" color="yellow">
                      {loading ? (
                        <Spinner animation="border" role="status" size="sm" className="mr-2" />
                      ) : null}
                      CHANGE
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      {showRender ? <RenderModal /> : null}
      {showConfirm ? (
        <ModalMessage
          title="Password Update"
          message="Your password was updated, it will be sent to login."
          btnTitle="OK"
          redirect="true"
          redirectLogin={roleActive !== 'ROLE_ADMIN' ? "true" : "false"}
          type="message"
        />
      ) : null}
      {showError ? (
        <ModalMessage
          title="Password Error"
          message="An error has occurred"
          btnTitle="OK"
          redirect="false"
        />
      ) : null}
    </>
  );
};

export default ChangePassword;
