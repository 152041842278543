import React, { useState, useEffect } from 'react';
import { Card, EmptyList, DTable } from "../../../components/OMB";
import { formatPrice } from '../../../utils/services';

const UsersPaymentsCard = ({ payment }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const dataMemberships = payment.map(({ idUser, paymentDate, paymentType, amountPaid }) => {
      return {
        id: idUser,
        paymentDate,
        paymentType,
        "amountPaid": formatPrice(amountPaid),
      }
    })
    setData(dataMemberships);
  }, []);

  const columns = [
    {
      name: 'Payment Date',
      selector: 'paymentDate',
    },
    {
      name: 'Payment Type',
      selector: 'paymentType',
    },
    {
      name: 'Amount Paid',
      selector: 'amountPaid',
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <Card
      title="User's Payments"
      // btnTitle="Download Template"
      // btnHandle={handleDownload}
    >
      {payment.length === 0
        ? <EmptyList />
        : (
          <DTable columns={columns} data={data} />
        )
      }
    </Card>
  );
}

export default UsersPaymentsCard;
