import React, { Component } from 'react';
// import { Container,Navbar,Nav,Form,Button,NavDropdown } from "react-bootstrap";
import { Navbar } from 'react-bootstrap';

import AdminNavbarLinks from './AdminNavbarLinks.jsx';
import './AdminNavbar.css';
class Header extends Component {
  constructor(props) {
    super(props);
    this.mobileSidebarToggle = this.mobileSidebarToggle.bind(this);
    this.state = {
      sidebarExists: false,
      width: 0,
      title: '',
    };
  }
  mobileSidebarToggle(e) {
    e.preventDefault();
    document.getElementById('sidebar').classList.toggle('sidebar-close');
    document.getElementById('main-panel').classList.toggle('full-width');
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  setTitle() {
    if (this.props.location.pathname !== '/') {
      const pathname = this.props.location.pathname;
      const separator = '/';
      const limit = 3;
      const componentName = pathname.split(separator, limit);
      let title = componentName[2].replaceAll('-', ' ');
      if (title === 'property offer') {
        title = 'offer submission'
      }
      if (title === 'sponsored oportunities') {
        title = 'Sponsorship Opportunities'
      }
      this.setState({
        title,
      });
    }
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
    this.setTitle();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setTitle();
    }
  }
  render() {
    return (
      // <Navbar fluid>
      //   <Navbar.Header>
      //     <Navbar.Brand>
      //       <a href="# ">{this.props.brandText}</a>
      //     </Navbar.Brand>
      //     <Navbar.Toggle onClick={this.mobileSidebarToggle} />
      //   </Navbar.Header>

      // </Navbar>

      <Navbar collapseOnSelect expand="lg" className="Navbar">
        <Navbar.Brand href="#home">{this.props.brandText}</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={this.mobileSidebarToggle} />
        <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
          <div className="Navbar--componentName">
            {this.state.title === 'help us improve' ? 'My Offers' : this.state.title}
          </div>
          {this.state.width <= 992 ? null : <AdminNavbarLinks />}
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
