import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './styles.css';

const Item = (props) => {
  const { roleActive } = useSelector((store) => store.user);
  const history = useHistory();
  const handleClick = () => history.push(`/${roleActive}/buyers-profile-details/1`);

  return (
    <div className="item" onClick={handleClick}>
      Gary
      <div>⌲</div>
    </div>
  );
};

export default Item;
