import React, { useState, useEffect } from 'react';
import { Card, EmptyList, DTable } from "../../../components/OMB";
import { formatPrice } from '../../../utils/services';
import { getApi, LIST_EXPENSE } from '../../../utils/services';

const ExpensesList = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const [data, setData] = useState([]);
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(LIST_EXPENSE, userToken)
      .then((response) => {
        setExpenses(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    const dataExpenses = expenses.map(({ id, category, amountSpent, typePurchase }) => {
      return {
        id,
        category,
        "amountSpent": formatPrice(amountSpent),
        typePurchase
      }
    })
    setData(dataExpenses);
  }, [expenses]);

  const columns = [
    {
      name: 'Category',
      selector: 'category',
    },
    {
      name: 'Amount Spent',
      selector: 'amountSpent',
    },
    {
      name: 'Type of Purchase',
      selector: 'typePurchase',
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <Card
      title={`Expenses (${expenses.length})`}
      // btnTitle="Download Template"
      // btnHandle={handleDownload}
    >
      {expenses.length === 0
        ? <EmptyList />
        : (
          <DTable columns={columns} data={data} />
        )
      }
    </Card>
  );
};

export default ExpensesList;
