import React from 'react';
import { GoBack } from '../../components/OMB';
import { AddExpensesForm } from "../../containers";
import './styles.css';

const AddExpenses = (props) => {
  return (
    <div className="add-expenses-form">
      <GoBack {...props} />
      <AddExpensesForm />
    </div>
  )
}

export default AddExpenses;
