import React from 'react';
import { GoBack } from '../../../components/OMB';
import { BuyerAgentTabs } from "../../../containers";

const BuyerAgentDetails = (props) => {
  const { id: profileId } = props.match.params;

  return (
    <div className="buyer-agent-details">
      <GoBack {...props} />
      <BuyerAgentTabs userId={parseInt(profileId)} />
    </div>
  )
}

export default BuyerAgentDetails;
