import React, { useState, useEffect } from 'react';
import { Loading, Card, EmptyList, DTable, Confirm, HeaderBtn } from '../../components/OMB';
import {
  getApi,
  postApi,
  LIST_SPONSORED_OPORTUNITIES,
  UPDATE_SPONSOR_OP,
} from '../../utils/services';
import { Trash } from '../../components/icons';

const SponsorshipSaved = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const [loading, setLoading] = useState(true);
  const [sponsorships, setSponsorships] = useState([]);
  const [data, setData] = useState([]);
  const [dataOrigin, setDataOrigin] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalId, setShowModalId] = useState(null);

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(LIST_SPONSORED_OPORTUNITIES, userToken)
      .then((response) => {
        setSponsorships(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    const dataSponsorships = sponsorships.map(
      ({ idUser, names, userType, offerWithin30, areaBusiness, ratting }) => {
        const areas = areaBusiness?.join(', ');
        return {
          id: idUser,
          names,
          userType,
          offerWithin30,
          areas,
          rating: ratting,
        };
      },
    );
    setDataOrigin(dataSponsorships);
    setData(dataSponsorships);
  }, [sponsorships]);

  const searchItem = (value) => {
    const wanted = value.trim().toLowerCase();
    const result =
      wanted.length >= 2
        ? sponsorships.filter(
            (item) =>
              item.names.trim().toLowerCase().includes(wanted) ||
              item.userType.trim().toLowerCase().includes(wanted)
          )
        : [];
    if (result.length > 0) {
      const dataSponsorships = result.map(
        ({ idUser, names, userType, offerWithin30, areaBusiness, ratting }) => {
          const areas = areaBusiness?.join(', ');
          const originOfferWithin30 = parseInt(offerWithin30);
          const convertOfferWithin30 = originOfferWithin30.toString();
          console.log(typeof convertOfferWithin30)
          return {
            id: idUser,
            names,
            userType,
            offerWithin30: convertOfferWithin30,
            areas,
            rating: ratting,
          };
        },
      );
      setData(dataSponsorships);
    } else {
      setData(dataOrigin);
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: 'names',
    },
    {
      name: 'User Type',
      selector: 'userType',
    },
    {
      name: 'Offers Within 30 Days',
      selector: 'offerWithin30',
    },
    {
      name: 'Areas Of Business',
      selector: 'areas',
    },
    {
      name: 'Scorecard',
      selector: 'rating',
    },
    {
      selector: 'delete',
      cell: (row) => (
        <div onClick={() => handleShowModal(row)} data-tag="allowRowEvents">
          <Trash />
        </div>
      ),
      right: true,
      width: '50px',
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  const handleShowModal = ({ id }) => {
    setShowModalId(id);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModalId(null);
    setShowModal(false);
  };

  const handleDelete = () => {
    const dataForm = {
      id: showModalId,
      status: 2,
    };

    postApi(UPDATE_SPONSOR_OP, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          const newSponsorships = sponsorships.filter((sp) => sp.idUser !== showModalId);
          setSponsorships(newSponsorships);
          setShowModal(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  };

  return (
    <>
      <HeaderBtn showButtons={false} searchItem={searchItem} showBtnAddSponsorship={true}/>
      <Card
        title="Sponsorship Opportunities"
        // btnTitle="Download Template"
        // btnHandle={handleDownload}
      >
        {loading ? (
          <Loading />
        ) : sponsorships.length === 0 ? (
          <EmptyList />
        ) : (
          <DTable columns={columns} data={data} />
        )}
      </Card>
      {showModal && (
        <Confirm
          show={showModal}
          onHide={handleHideModal}
          no={handleHideModal}
          yes={() => handleDelete()}
          title="Delete Sponsorship Opportunity"
          message="Are you sure you wish to remove this Sponsorship Opportunity?"
        />
      )}
    </>
  );
};

export default SponsorshipSaved;
