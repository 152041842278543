import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import '../../components/OMB/css/IndexUtilsCreateAndSearch/IndexUtilsCreateAndSearch.css';
import { Card, Search, Loading } from '../../components/OMB/';
import { LIST_MEMBERSHIPS, getApi } from '../../utils/services/';
import { useSelector } from 'react-redux';

const SponsorsMemberships = () => {
  const [memberships, setMemberships] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem('tokenOMB');
  const userData = JSON.parse(localStorage.getItem('state'));
  const userID = userData.user.info.id;
  const urlApi = `${LIST_MEMBERSHIPS}${userID}`;
  const { roleActive } = useSelector((store) => store.user);

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        const data = response.data;
        setMemberships(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const MembershipEmpty = () => {
    return (
      <div className="membershipEmpty">
        <p className="membershipEmpty--title">You don’t have any sponsored memberships yet.</p>
        <p className="membershipEmpty--subtitle">
          Get started as an OMB Sponsor by creating your first sponsored OMB membership.
        </p>
        <Link className="btn-create-membership" to="create-sponsored-membership">
          Create a membership
        </Link>
      </div>
    );
  };

  const MembershipList = () => {
    return (
      <div className="membersList">
        <div className="index-utils-create-search">
          <div className="container-btn">
            <Link className="btn-create-membership" to="create-sponsored-membership">
              <span className="btn-create-membership--icon">+</span> Create New
            </Link>
          </div>
          <div className="container-search">
            <Search placeholder="Search..." />
          </div>
        </div>

        <div className="container-list">
          <Card title="My Sponsored Memberships">
            <div className="list">
              <div className="fila">
                <div className="column column-title bold">
                  <span className="only-desktop">Membership</span> Code
                </div>
                <div className="column column-title bold">
                  <span className="only-desktop">Member</span> Name
                </div>
                <div className="column column-title bold">Initiated</div>
                <div className="column column-title bold">Expires</div>
                <div className="column column-title"></div>
              </div>
              {memberships.map((membership, i) => (
                <div className="fila" key={i}>
                  <div className="column">{membership.membershipCode}</div>
                  <div className="column">{membership.membershipName}</div>
                  <div className="column">{membership.initiated}</div>
                  <div className="column d-flex items-center">
                    <div
                      className={
                        'circle-status ' +
                        (membership.status === 'warning'
                          ? 'warning'
                          : membership.status === 'enabled'
                          ? 'enabled'
                          : membership.status === 'disabled'
                          ? 'disabled'
                          : '')
                      }
                    ></div>{' '}
                    {membership.restain > 0
                      ? `${membership.expires} (${membership.restain} days left)`
                      : ''}
                  </div>
                  <div className="column">
                    {parseInt(membership.restain, 10) < '60' ? (
                      <>
                        <Link
                          className="btn-renew demi"
                          to={`/${roleActive}/sponsor-member/${membership.id}`}
                        >
                          Renew
                        </Link>
                        <Link className="btn-renew only-movil demi">+</Link>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="sponsors">
      {loading ?
        <Loading />
        : memberships.length > 0 ? <MembershipList /> : <MembershipEmpty />
      }
    </div>
  );
};

export default SponsorsMemberships;
