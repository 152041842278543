import React from 'react'

export default class Dashboard extends React.Component{

    render(){
        return(
            <React.Fragment>
                <div className="container">
                    <h1>Dash</h1>

                </div>
            </React.Fragment>
        )
    }

}