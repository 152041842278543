import React, { useState } from 'react';
import {
  Card,
  CardPropertyDetails,
  CardPropertySendMessage,
} from '../../components/OMB';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import OffersProperty from '../OffersProperty';
import { postApi, SEND_MESSAGE } from '../../utils/services';

const PropertyDetailsCards = ({ propertyId }) => {
  const { profileId } = useParams();
  const {
    roleActive,
    info: { id: userId },
  } = useSelector((store) => store.user);
  const store = useSelector((store) => store);
  const userToken = localStorage.getItem('tokenOMB');
  const { listProperties, propertyList, offersProperties } = useSelector((store) => store.property);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();
  
  const profileActive = store.admin.allUsers.find((user) => user.idUser === parseInt(profileId));

  const properties =
    (roleActive === 'ROLE_ADMIN' && profileActive.roles === 'LISTING AGENT') ||
    roleActive === 'ROLE_LISTING_AGENT'
      ? listProperties
      : [...propertyList, ...offersProperties];
  const property = properties.find((item) => item.id === parseInt(propertyId));
  const { address } = property;
  const titleAddress = `${address.street}, ${address.unit ? `unit ${address.unit}` + ',' : ''} ${
    address.city
  }, ${address.estate.name}, ${address.zipCode}`;

  const handleEditFeature = () => {
    console.log('editFeature');
  };

  const handleSubmitOffer = () => history.push(`/${roleActive}/property-offer/${propertyId}`);

  const handleMessage = (msg) => {
    if (error && !!message) {
      setError(false);
    }
    setMessage(msg);
  };

  const handleSubmit = () => {
    if (!message) {
      return setError(true);
    }

    const dataForm = {
      message,
      typeMessage: {
        id: 2,
      },
      user: {
        id: userId,
      },
      conversation: {
        property: {
          id: propertyId,
        },
      },
    };

    postApi(SEND_MESSAGE, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            setSuccess(false);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  };

  return (
    <div className="property-details-card">
      <Card
        title="Property Details"
        btnTitle={roleActive === 'ROLE_LISTING_AGENT' ? 'Edit features' : 'Submit offer'}
        btnHandle={roleActive === 'ROLE_LISTING_AGENT' ? handleEditFeature : handleSubmitOffer}
      >
        <CardPropertyDetails property={property} />
      </Card>
      {roleActive === 'ROLE_LISTING_AGENT' && (
        <Card title="Property Offers">
          <OffersProperty propertyID={propertyId} userID={userId} />
        </Card>
      )}
      {roleActive === 'ROLE_BUYER_AGENT' || roleActive === 'ROLE_BUYER' ? (
        <Card title="Message Listing Agent">
          <CardPropertySendMessage
            address={titleAddress}
            handleMessage={handleMessage}
            handleSubmit={handleSubmit}
            error={error}
            success={success}
          />
        </Card>
      ) : null}
    </div>
  );
};

export default PropertyDetailsCards;
