import React from 'react';
import { GoBack } from '../../../components/OMB';
import { ListingAgentTabs } from "../../../containers";

const ListingAgentDetails = (props) => {
  const { id: profileId } = props.match.params;

  return (
    <div className="listing-agent-details">
      <GoBack {...props} />
      <ListingAgentTabs userId={parseInt(profileId)} />
    </div>
  )
}

export default ListingAgentDetails;
