import React, { useState, useEffect } from 'react';
import { Card, Search, SwitchRole } from '../../components/OMB';
import { PropertyList } from '../';
import { usePropertyListUser } from '../../hooks/usePropertyListUser';
import { useFilterAddress } from '../../hooks/useFilterAddress';
import './styles.css';

const PropertiesCard = ({ userId }) => {
  const [listProperties] = usePropertyListUser(userId);
  const [filteredProperties, setFilteredProperties] = useFilterAddress(listProperties);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    setFilteredProperties(searchKey);
  }, [searchKey]);

  return (
    <Card className="properties-card" title={`My Properties (${filteredProperties?.length})`}>
      <SwitchRole />
      <div className="search-container">
        <Search
          handleChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search by country, state, city, zip, address"
        />
      </div>
      <PropertyList contentType="CUATRO" properties={filteredProperties} />
    </Card>
  )
};

export default PropertiesCard;
