import {
  LIST_ALL_USERS,
  LIST_ADMINS,
  LIST_REFERRAL,
  LIST_PAYMENTS,
  LIST_SPONSORSHIPS,
} from '../utils/services';

// ################# const #################
// definimos las data principal, el objeto y ademas las constantes para las acciones a realizar.

const initalState = {
  allUsers: [],
  admins: [],
  referrals: [],
  memberships: [],
  sponsorships: [],
  loading: false,
  error: false,
  sucessful: false,
  message: '',
};

const ALL_USERS = 'ALL_USERS';
const ADMINS = 'ADMINS';
const REFERRALS = 'REFERRALS';
const MEMBERSHIPS = 'MEMBERSHIPS';
const SPONSORSHIPS = 'SPONSORSHIPS';
const LOADING = 'LOADING';
const ERROR = 'ERROR';
const SUCESSFUL = 'SUCESSFUL';

// ################# reducer #################
// la funcion principal del reducer es actualizar el objeto data principal

export default function userReducer(state = initalState, action) {
  switch (action.type) {
    case 'ALL_USERS':
      return {
        ...state,
        allUsers: action.payload.allUsers,
      };
    case 'ADMINS':
      return {
        ...state,
        admins: action.payload.admins,
      };
    case 'REFERRALS':
      return {
        ...state,
        referrals: action.payload.referrals,
      };
    case 'MEMBERSHIPS':
      return {
        ...state,
        memberships: action.payload.memberships,
      };
    case 'SPONSORSHIPS':
      return {
        ...state,
        sponsorships: action.payload.sponsorships,
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.error,
        message: action.message,
      };
    case 'SUCESSFUL':
      return {
        ...state,
        sucessful: action.sucessful,
        message: action.message,
      };
    default:
      return state;
  }
}

// ################# acctions #################
// estas acciones su objetivo principal es comunicarse con el back

export const list_all_users = () => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(LIST_ALL_USERS, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: ALL_USERS,
        payload: {
          allUsers: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const list_admins = () => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(LIST_ADMINS, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: ADMINS,
        payload: {
          admins: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const list_referrals = () => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(LIST_REFERRAL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: REFERRALS,
        payload: {
          referrals: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const list_memberships = () => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(LIST_PAYMENTS, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: MEMBERSHIPS,
        payload: {
          memberships: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const list_sponsorships = () => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(LIST_SPONSORSHIPS, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: SPONSORSHIPS,
        payload: {
          sponsorships: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const loading = (bool) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    loading: bool,
  });
};

export const error = (error, message) => async (dispatch, getState) => {
  dispatch({
    type: ERROR,
    error,
    message,
  });
};

export const sucessful = (sucessful, message) => async (dispatch, getState) => {
  dispatch({
    type: SUCESSFUL,
    sucessful,
    message,
  });
};
