import React from 'react';
import { BuyersProfileSaved } from '../../containers';
import { Button } from '../../components/OMB';
import { useSelector } from 'react-redux';
import './styles.css';

const BuyersProfile = () => {
  const { roleActive } = useSelector((store) => store.user);

  return (
    <div className="buyers-profile">
      <div className="btn-container">
        <Button color="light-blue" to={`/${roleActive}/add-buyer-profile`}>
          + Add New Buyer Profile
        </Button>
        <p>
          OMB Buyers may submit offers under different Entities. Please save these various Entity
          formats here.
        </p>
      </div>
      <BuyersProfileSaved />
    </div>
  );
};

export default BuyersProfile;
