import React, { Component, useEffect, useState } from 'react';
// import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Star from '../../assets/iconos/Listing-BuyerAgent-Buyer/Scorecard-2.svg';
import Offer from '../../assets/iconos/Listing-BuyerAgent-Buyer/Offers.svg';
import Invited from '../../assets/iconos/Listing-BuyerAgent-Buyer/Invited.svg';
import Messages from '../../assets/iconos/Listing-BuyerAgent-Buyer/Messages.svg';
import Sponsor from '../../assets/iconos/Sponsor/Sponsor.svg';
import Admin from '../../assets/iconos/superAdmin/admins.svg';
import offers from '../../assets/iconos/superAdmin/offers.svg';
import search from '../../assets/iconos/superAdmin/search.svg';
import { getApi, POINTS } from '../../utils/services';
import { useSelector } from 'react-redux';

const AdminNavbarLinks = () => {
  const user = JSON.parse(localStorage.getItem('state'));
  const userName = `${user.user.info.firstName} ${user.user.info.lastName}`;
  const userRol = user.user.roleActive;
  const {
    info: {membershipStatus},
  } = useSelector((store) => store.user);
  const companyName = `${user.user.info.profile.businessName}`;
  const [numberPoint, setNumberPoint] = useState(0);
  const userToken = localStorage.getItem('tokenOMB');
  const userID = user.user.info.id;
  const urlApi = `${POINTS}${userID}`;

  let icon;

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        // console.log('points', response)
        const data = response.data;
        if (response.status === 200) {
          setNumberPoint(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en adminNavBarLink:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndPoints();
  }, []);

  switch (userRol) {
    case 'ROLE_ADMIN':
      icon = (
        <section className="section-icons">
          <Link to={`/${userRol}/code-search`}>
            <img src={search} className="icon" alt={search} />
          </Link>
          <Link to={`/${userRol}/offers`}>
            <img src={offers} className="icon" alt={offers} />
          </Link>
          <Link to={`/${userRol}/admins`}>
            <img src={Admin} className="icon" alt={Admin} />
          </Link>
          {userName}
        </section>
      );
      break;
    case 'ROLE_SPONSOR':
      icon = (
        <section className="section-icons">
          <img src={Sponsor} className="icon" alt={Sponsor} /> Hi, {companyName}
        </section>
      );
      break;
    case 'ROLE_LISTING_AGENT':
      icon = (
        <section className="section-icons">
          {userName}
          <div className="container-points">
            <img src={Star} className="icon" />
            <p className="point">{numberPoint}</p>
          </div>
          <Link to={`/${userRol}/my-offers`}>
            <img src={Offer} className="icon" />
          </Link>
          <Link className="ic-link" to={'/' + userRol + '/invite/invited-members/' + userID}>
            <img src={Invited} className="icon" alt={Invited} />
          </Link>
          <Link to={'/' + userRol + '/messages'}>
            <img src={Messages} className="icon" />
          </Link>
        </section>
      );
      break;
    case 'ROLE_ADMIN_MEMBERS':
      icon = (
        <section className="section-icons">
          <Link className="ic-link" to={'/' + userRol + '/invite/invited-members/' + userID}>
            <img src={Invited} className="icon" alt={Invited} />
          </Link>
          {userName}
        </section>
      );
      break;
    case 'ROLE_BUYER_AGENT':
    case 'ROLE_BUYER':
      icon = (
        <section className="section-icons">
          {userName}
          <div className="container-points">
            <img src={Star} className="icon" />
            <p className="point">{numberPoint}</p>
          </div>
          <Link className="ic-link" to={`/${userRol}/my-offers`}>
            <img src={Offer} className="icon" />
          </Link>
          <Link className="ic-link" to={`/${userRol}/invite/invited-members/${userID}`}>
            <img src={Invited} className="icon" />
          </Link>
          <Link to={membershipStatus === false ?`/${userRol}/make-payment`:`/${userRol}/messages`}>
            <img src={Messages} className="icon" />
          </Link>
        </section>
      );
      break;
    default:
      icon = <section className="section-icons">{userName}</section>;
      break;
  }

  return (
    <div>
      <Nav className="nav__container">
        <div className="nav__container--userName italic"> {icon}</div>
      </Nav>
    </div>
  );
};

export default AdminNavbarLinks;
