import React, { useEffect } from 'react';
import DataTable from 'react-data-table-component';
import Button from '../Button/Button';
import './styles.css';

const DTable = ({ columns, data, numPage, setNumPage, pagesTotal, pagination = true }) => {
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
        minWidth: '100px',
      },
    },
    headCells: {
      style: {
        fontSize: '18px',
        fontWeight: 'bolder',
        paddingLeft: '5px',
        paddingRight: '5px',
        color: 'black',
      },
    },
    cells: {
      style: {
        fontSize: '15px',
        textTransform: 'capitalize',
        paddingLeft: '5px',
        paddingRight: '5px',
        ':hover': {
          cursor: 'default',
        },
      },
    },
  };
  useEffect(() => {
   console.log('sata', data)
  }, [data])
  return (
    <>
      <DataTable
        pagination={pagination}
        paginationPerPage={100}
        paginationRowsPerPageOptions={[200, 500, 1000, 3000, 5000, 10000]}
        className="data-table"
        noHeader={true}
        data={data}
        columns={columns}
        customStyles={customStyles}
        onRowClicked={(row) => (!!row.handleClick ? row.handleClick() : null)}
      />
      {pagesTotal >= 1 ? (
        <div className="d-flex">
          <Button
            color="yellow"
            type="submit"
            onClick={() =>
              numPage <= pagesTotal ? setNumPage(numPage + 1) : console.log('no hago nada')
            }
          >
            Next Page
          </Button>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default DTable;
