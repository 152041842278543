import React, { useState, useEffect } from 'react';
import { Button } from '../../../components/OMB';
import './properties.css';
import { useSelector } from 'react-redux';
import EditProperty from '../Edit/EditProperty';
import { PropertiesCard } from '../../../containers';
import { usePropertyListUser } from '../../../hooks/usePropertyListUser';
import { useFilterAddress } from '../../../hooks/useFilterAddress';

const Properties = (props) => {
  const user = useSelector((store) => store.user);
  const [dataToEdit, setDataToEdit] = useState({});
  const [boolToEdit, setBoolToEdit] = useState(false);
  const [listProperties] = usePropertyListUser();
  const [filteredProperties, setFilteredProperties] = useFilterAddress(listProperties);
  const [searchKey, setSearchKey] = useState('');

  useEffect(() => {
    setFilteredProperties(searchKey);
  }, [searchKey]);

  return boolToEdit === false ? (
    <>
      <div style={{ margin: '10px 0px' }}>
        <Button
          color="light-blue"
          //si props.id existe entonces lo usamos para consultar sino usaremos el que tenemos en redux
          onClick={() => {
            var a = props?.id !== undefined ? props.id : user.info.id;
            window.location.href = '/' + user.roleActive + '/properties/add-property/' + a;
          }}
        >
          + Add Property
        </Button>
      </div>
      <PropertiesCard />
    </>
  ) : (
    <EditProperty
      data={dataToEdit}
      goBack={() => {
        setBoolToEdit(false);
      }}
    />
  );
};

export default Properties;
