import React from 'react';
import { CodeSearchCards } from "../../../containers";

const CodeSearch = () => {
  return (
    <div className="code-search">
      <CodeSearchCards />
    </div>
  )
}

export default CodeSearch;
