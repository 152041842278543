import React from 'react';

export const ItemList = ({ member }) => {
  const date = member.date.split('-');
  const assigned = [ date[2], date[1], date[0] ].join('-');

  return (
    <div className="fila" key={member.id}>
      <div className="column">{member.name}</div>
      <div className="column">
        Listing Agent
      </div>
      <div className="column">{assigned}</div>
    </div>
  );
};
