import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../Button/Button';
import { useHistory } from 'react-router-dom';
import './style.css';
import { useSelector } from 'react-redux';

const ModalMessage = ({
  title = 'Title',
  message = 'Message',
  btnTitle = 'OK',
  redirect = 'false',
  goback = false,
  type = 'error',
  hideBtn = false,
  redirectLogin = 'false',
  redirectSearchListing
}) => {
  const [hide, setHide] = useState(true);
  const history = useHistory();
  const { roleActive } = useSelector((store) => store.user);

  useEffect(() => {
    if (!hide) {
      if (redirect === 'false') {
        window.location.reload();
      }
      if (goback) {
        history.goBack();
      }
      if (redirectLogin === 'true') {
        sessionStorage.clear();
        localStorage.clear();
        window.location.href = '/login';
      }
      if (redirectSearchListing) {
        window.location.href = `/${roleActive}/search-listings/`;
      }
    }
  }, [hide]);

  return (
    <React.Fragment>
      <Modal show={hide} centered size="lg">
        <Modal.Body>
          <h2 className="title-modal">{title}</h2>
          <p className="message">
            {message} {type !== 'error' ? '' : ''}
          </p>
        </Modal.Body>
        <Modal.Footer>
          {hideBtn === false ? (
            <Button color="primary" onClick={() => setHide(false)}>
              {btnTitle}
            </Button>
          ) : (
            ''
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ModalMessage;
