import React, { useState, useEffect } from 'react';
import { EmptyList, DTable, Confirm } from '../../components/OMB';
import { Trash } from '../../components/icons';

const PurchaseAreasList = ({ areas }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalId, setShowModalId] = useState(null);

  useEffect(() => {
   if(!!areas){
      const dataAreas = areas.map(({ id, estate, state, county = '', zipCode = '' }) => {
        return {
          id,
          state: !!estate ? estate?.code : state?.code,
          county: county?.name,
          zipCode,
        };
      });
      setData(dataAreas);
   }
  }, [areas]);

  const handleShowModal = (row) => {
    setShowModalId(row.id);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModalId(null);
    setShowModal(false);
  };

  const handleDelete = () => {
    console.log('DELETE ModalId', showModalId);
    setShowModal(false);
  };

  const columns = [
    {
      name: 'State',
      selector: 'state',
    },
    {
      name: 'County',
      selector: 'county',
    },
    {
      name: 'Zip Code',
      selector: 'zipCode',
    },
    /* {
      selector: 'icon',
      cell: row => <div onClick={() => handleShowModal(row)} data-tag="allowRowEvents"><Trash /></div>,
      right: true,
    }, */
  ];

  return (
    <>
      {data.length === 0 ? (
        <EmptyList text="No Areas" />
      ) : (
          <DTable columns={columns} data={data} pagination={false}/>
        )}
      {showModal && (
        <Confirm
          show={showModal}
          onHide={handleHideModal}
          no={handleHideModal}
          yes={() => handleDelete()}
          title="Delete Purchased Area"
          message="Are you sure to remove the purchased area?"
        />
      )}
    </>
  );
};

export default PurchaseAreasList;
