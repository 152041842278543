import { domainServer } from '../connection/connection';
import { LISTING_OFFERS, BUYERS_OFFERS } from '../utils/services';

// ################# const #################
// definimos las data principal, el objeto y ademas las constantes para las acciones a realizar.

const initalState = {
  info: {
    bath: 1,
    bed: 2,
    lot_size: 200,
    occupancy: 'Test Update',
    price: 52000,
    square_feet: 500,
    year_built: 'Test',
    address: {
      street: '8th Street',
      city: 'Miami FL',
      zipCode: '1090',
      unit: '32',
      estate: {
        id: 1,
      },
    },
    typeProperty: {
      id: 1,
    },
    propertyImages: ['test1 Update'],
  },
  listProperties: [],
  propertyList: [],
  savedProperties: [],
  offersProperties: [],
  buyersProfiles: [],
  purchasedProperties: [],
  loading: false,
  error: false,
  sucessful: false,
  message: '',
};

const LISTING_PROPERTIES = 'LISTING_PROPERTIES';
const PROPERTY_LIST = 'PROPERTY_LIST';
const SAVED_PROPERTIES = 'SAVED_PROPERTIES';
const OFFERS_PROPERTIES = 'OFFERS_PROPERTIES';
const BUYERS_PROFILES = 'BUYERS_PROFILES';
const PURCHASED_PROPERTIES = 'PURCHASED_PROPERTIES';
const LOADING = 'LOADING';
const ERROR = 'ERROR';
const SUCESSFUL = 'SUCESSFUL';

// ################# reducer #################
// la funcion principal del reducer es actualizar el objeto data principal

export default function userReducer(state = initalState, action) {
  switch (action.type) {
    case 'LISTING_PROPERTIES':
      return {
        ...state,
        listProperties: action.payload.listProperties,
      };
    case 'PROPERTY_LIST':
      return {
        ...state,
        propertyList: action.payload.propertyList,
      };
    case 'SAVED_PROPERTIES':
      return {
        ...state,
        savedProperties: action.payload.savedProperties,
      };
    case 'OFFERS_PROPERTIES':
      return {
        ...state,
        offersProperties: action.payload.offersProperties,
      };
    case 'BUYERS_PROFILES':
      return {
        ...state,
        buyersProfiles: action.payload.buyersProfiles,
      };
    case 'PURCHASED_PROPERTIES':
      return {
        ...state,
        purchasedProperties: action.payload.purchasedProperties,
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.error,
        message: action.message,
      };
    case 'SUCESSFUL':
      return {
        ...state,
        sucessful: action.sucessful,
        message: action.message,
      };
    default:
      return state;
  }
}

// ################# acctions #################
// estas acciones su objetivo principal es comunicarse con el back

export const save = (data) => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(domainServer + '/api/property/save', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch(sucessful(true, 'Your Property has been added successfully.'));
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));

      var e;
      try {
        //aqui verificamos que si nos da un error 500 y nos trae un string lo enviamos al catch sino enviamos el error
        e = JSON.parse(err?.message);
        dispatch(error(true, e.message));
      } catch (e) {
        e = { message: 'Error 500' };
        dispatch(error(true, e.message));
      }
    });
};

export const edit = (data) => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(domainServer + '/api/property/update', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch(sucessful(true, 'Your changes have been save successfully.'));
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));

      var e;
      try {
        //aqui verificamos que si nos da un error 500 y nos trae un string lo enviamos al catch sino enviamos el error
        e = JSON.parse(err?.message);
        dispatch(error(true, e.message));
      } catch (e) {
        e = { message: 'Error 500' };
        dispatch(error(true, e.message));
      }
    });
};

export const deleteProperty = (id, idUser) => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(domainServer + '/api/property/delete/' + id, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch(listing_properties(idUser));
      dispatch(sucessful(true, 'Property deleted'));
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));

      var e;
      try {
        //aqui verificamos que si nos da un error 500 y nos trae un string lo enviamos al catch sino enviamos el error
        e = JSON.parse(err?.message);
        dispatch(error(true, e.message));
      } catch (e) {
        e = { message: 'Error 500' };
        dispatch(error(true, e.message));
      }
    });
};

export const listing_properties = (idUser) => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(domainServer + '/api/property/userListing/' + idUser, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: LISTING_PROPERTIES,
        payload: {
          listProperties: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
      // var e=JSON.parse(err?.message);
      // dispatch(error(true,e.message))
    });
};

export const property_list = () => async (dispatch, getState) => {

  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');
  fetch(`${domainServer}/api/property/list`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: PROPERTY_LIST,
        payload: {
          propertyList: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const saved_properties = (idUser) => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(`${domainServer}/api/property/like/${idUser}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: SAVED_PROPERTIES,
        payload: {
          savedProperties: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const offers_properties = (idUser) => async (dispatch, getState) => {
  dispatch(loading(true));
  const userData = JSON.parse(localStorage.getItem('state'));
  const { roleActive } = userData.user;
  const token = localStorage.getItem('tokenOMB');

  const pathArray = window.location.pathname.split('/');

  const URL =
    roleActive === 'ROLE_BUYER_AGENT' ||
    roleActive === 'ROLE_BUYER' ||
    (roleActive === 'ROLE_ADMIN' && pathArray.includes('buyer-agent-details')) ||
    (roleActive === 'ROLE_ADMIN' && pathArray.includes('buyer-details'))
      ? `${BUYERS_OFFERS}${idUser}`
      : roleActive === 'ROLE_ADMIN' && pathArray.includes('offers')
      ? `${BUYERS_OFFERS}`
      : `${LISTING_OFFERS}${idUser}`;

  fetch(URL, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: OFFERS_PROPERTIES,
        payload: {
          offersProperties: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const buyers_profiles = (idUser) => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(`${domainServer}/api/offer/listDraft/${idUser}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: BUYERS_PROFILES,
        payload: {
          buyersProfiles: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const purchased_properties = (idUser) => async (dispatch, getState) => {
  dispatch(loading(true));
  const token = localStorage.getItem('tokenOMB');

  fetch(`${domainServer}/api/property/purchased/${idUser}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      } else {
        const text = await response.text();
        throw new Error(text);
      }
    })
    .then((res) => {
      dispatch(loading(false));
      dispatch({
        type: PURCHASED_PROPERTIES,
        payload: {
          purchasedProperties: res,
        },
      });
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      console.log(err);
    });
};

export const loading = (bool) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    loading: bool,
  });
};

export const error = (error, message) => async (dispatch, getState) => {
  dispatch({
    type: ERROR,
    error,
    message,
  });
};

export const sucessful = (sucessful, message) => async (dispatch, getState) => {
  dispatch({
    type: SUCESSFUL,
    sucessful,
    message,
  });
};
