import React from 'react';
import { GoBack } from '../../../components/OMB';
import { SponsorMemberCards } from '../../../containers';

const SponsorMember = (props) => {
  const { id: profileId } = props.match.params;
  return (
    <div className="sponsor-details">
      <GoBack {...props} />
      <SponsorMemberCards userId={profileId} />
    </div>
  )
}

export default SponsorMember;
