import React from 'react';
import { ContentTab, ItemTab, Button } from '../../components/OMB';
import Properties from "./Properties";
import Listing from './Listing';
import AgentProfile from "./AgentProfile";
import BuyersProfiles from "./BuyersProfiles";
import InvitedMembers from "../SuperAdminCommons/InvitedMembers";
import Messages from "../SuperAdminCommons/Messages";
import { useSelector } from 'react-redux';
import "./styles.css";

const BuyerAgentTabs = ({ userId }) => {
  const { roleActive } = useSelector((store) => store.user);

  return (
    <div className="buyer-agent-tabs">
      <ContentTab defaultActiveKey="agent-profile" id="uncontrolled-tab-example">
        <ItemTab eventKey="agent-profile" title="Agent's Profile">
          <AgentProfile userId={userId} />
        </ItemTab>
        <ItemTab eventKey="buyer-profile" title="Buyer's Profiles">
          <div className="btn-container">
            <Button color="light-blue" to={`/${roleActive}/add-buyer-profile/${userId}`}>
              + ADD NEW BUYER PROFILE
            </Button>
          </div>
          <BuyersProfiles userId={userId} />
        </ItemTab>
        <ItemTab eventKey="listing" title="Listing">
          <Listing userId={userId} />
        </ItemTab>
        <ItemTab eventKey="properties" title="Properties">
          <Properties userId={userId} />
        </ItemTab>
        <ItemTab eventKey="invited-members" title="Invited Members">
          <InvitedMembers userId={userId} />
        </ItemTab>
        <ItemTab eventKey="messages" title="Messages">
          <Messages userId={userId} />
        </ItemTab>
      </ContentTab>
    </div>
  );
};

export default BuyerAgentTabs;
