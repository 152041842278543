import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { purchased_properties } from '../redux/PropertyDucks';

export const usePurchasedProperties = (userId = null) => {
  const user = useSelector((store) => store.user);
  const { purchasedProperties } = useSelector((store) => store.property);
  const dispatch = useDispatch();
  const id = userId || user?.info?.id;

  useEffect(() => {
    dispatch(purchased_properties(id));
  }, []);

  return [purchasedProperties];
};
