import React from 'react';
import { BuyersProfileSaved } from '../..';

const BuyerProfiles = ({userId}) => {
  return (
    <BuyersProfileSaved profileId={userId}/>
  );
};

export default BuyerProfiles;
