import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list_referrals } from '../redux/adminDucks';

export const useReferrals = () => {
  const { referrals, loading } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(list_referrals());
  }, []);

  return [referrals, loading];
};
