import React from 'react';
import { GoBack } from '../../../components/OMB';
import { BuyerTabs } from "../../../containers";

const BuyerDetails = (props) => {
  const { id: profileId } = props.match.params;

  return (
    <div className="buyer-details">
      <GoBack {...props} />
      <BuyerTabs userId={parseInt(profileId)} />
    </div>
  )
}

export default BuyerDetails;
