import React from 'react';
import { GoBack } from '../../components/OMB';
import { CreateCodeForm } from "../../containers";
import './styles.css';

const CreateCode = (props) => {
  return (
    <div className="create-code">
      <GoBack {...props} />
      <CreateCodeForm />
    </div>
  )
}

export default CreateCode;
