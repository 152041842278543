import React, { useState, useEffect } from 'react';
import { Trash } from '../../../components/icons';
import { Card, EmptyList, DTable, Confirm } from '../../../components/OMB';
import { DELETE_USERS_REFERRAL_CODE, formatPrice, getApi } from '../../../utils/services';

const UsersReferralCard = ({ info }) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalId, setShowModalId] = useState(null);
  const userToken = localStorage.getItem('tokenOMB');
  useEffect(() => {
    const dataUsersReferral = info.map(({ code, referring, recipient }, index) => {
      return {
        id: index,
        code,
        referring: `${referring}%`,
        recipient: `${recipient}%`,
      };
    });
    setData(dataUsersReferral);
  }, []);

  const columns = [
    {
      name: 'Code',
      selector: 'code',
    },
    {
      name: 'Referring',
      selector: 'referring',
    },
    {
      name: 'Recipient',
      selector: 'recipient',
    },
    {
      selector: 'delete',
      cell: (row, index) => (
        index === 0 ? '' : <div onClick={() => handleShowModal(row)} data-tag="allowRowEvents">
          <Trash />
        </div>
      ),
      right: true,
      width: '50px',
    },
  ];

  const handleShowModal = ({ id }) => {
    console.log(id)
    setShowModalId(id);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModalId(null);
    setShowModal(false);
  };

  const handleDelete = () => {

   const URL = `${DELETE_USERS_REFERRAL_CODE}${showModalId}`
    getApi(URL, userToken)
      .then((response) => {
        if (response.status === 200) {
          console.log('exito')
          window.location.reload()
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  };

  return (
    <>
      <Card
        title={`User's Referral Codes (${info.length})`}
        // btnTitle="Download Template"
        // btnHandle={handleDownload}
      >
        {info.length === 0 ? <EmptyList /> : <DTable columns={columns} data={data} />}
      </Card>
      {showModal && (
        <Confirm
          show={showModal}
          onHide={handleHideModal}
          no={handleHideModal}
          yes={() => handleDelete()}
          title="Delete User Referral Code"
          message="Are you sure you wish to remove this User Referral Code?"
        />
      )}
    </>
  );
};

export default UsersReferralCard;
