import React, { useState } from 'react';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { VIEW_OFFER, getApi, formatPrice } from '../../../utils/services';
import { Modal, Spinner } from 'react-bootstrap';
import { Button, ModalViewOffer } from '..';
import { useHistory } from 'react-router-dom';
import './styles.css';
import { useSelector } from 'react-redux';

const OfferItem = ({ offer }) => {
  const [offerDate] = useFormatDate(offer.offerDate);
  // const [currentDate] = useFormatDate(new Date());
  const [showModal, setShowModal] = useState(false);
  const [offerId, setOfferId] = useState(null);
  const { roleActive } = useSelector((store) => store.user);

  const handleShowModal = (id) => {
    setOfferId(id);
    setShowModal(true);
  };

  

  return (
    <>
      <div className="fila" key={offer.id}>
        <div className="column">{offerDate}</div>
        <div className="column">{offer.signerName}</div>
        <div className="column">{formatPrice(offer.amount)}</div>
        <div className="column">{offer.funding.name}</div>
        <div className="column">
          {offer.viewStatus === true ? (
            <Button to={`/${roleActive}/property-offer-details/${offer.id}`} color="yellow">
              View Offer
            </Button>
          ) : (
            <Button color="light-blue" onClick={() => handleShowModal(offer.id)}>
              New Offer
            </Button>
          )}
        </div>
      </div>

      <ModalViewOffer offerId={offerId} offer={offer} showModal={showModal} setShowModal={setShowModal} />
    </>
  );
};

export default OfferItem;
