import React, { useState, useEffect } from 'react';
import { Card, EmptyList, DTable, Confirm } from '../../components/OMB';
import { Trash } from '../../components/icons';
import { useBuyersProfiles } from '../../hooks/useBuyersProfiles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getApi, DELETE_BUYER_PROFILE } from '../../utils/services';

const BuyersProfileSaved = ({ profileId }) => {
  const { roleActive } = useSelector((store) => store.user);
  const [buyersProfiles] = useBuyersProfiles(profileId);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalId, setShowModalId] = useState(null);
  const userToken = localStorage.getItem('tokenOMB');
  const history = useHistory();

  useEffect(() => {
    const dataProfiles = buyersProfiles.map(({ idOffer, offerorName }) => {
      return {
        id: idOffer,
        name: offerorName,
        icon: '>',
        handleClick: () => history.push(`/${roleActive}/buyers-profile-details/${idOffer}`),
      };
    });
    setData(dataProfiles);
  }, [buyersProfiles]);

  const columns = [
    {
      name: 'Profile name',
      selector: 'name',
    },
    {
      selector: 'delete',
      cell: (row) => (
        <div onClick={() => handleShowModal(row)} data-tag="allowRowEvents">
          <Trash />
        </div>
      ),
      right: true,
      width: '50px',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px',
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  const handleShowModal = ({ id }) => {
    setShowModalId(id);
    setShowModal(true);
  };

  const handleHideModal = () => {
    setShowModalId(null);
    setShowModal(false);
  };

  const handleDelete = () => {
    const newData = data.filter((element) => element.id !== showModalId);
    const urlApi = `${DELETE_BUYER_PROFILE}${showModalId}`;

    getApi(urlApi, userToken)
      .then((response) => {
        if (response.status === 200) {
          setData(newData);
          setShowModal(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <>
      <Card title="Buyer Profiles">
        {buyersProfiles.length === 0 ? <EmptyList /> : <DTable columns={columns} data={data} />}
      </Card>
      {showModal && (
        <Confirm
          show={showModal}
          onHide={handleHideModal}
          no={handleHideModal}
          yes={() => handleDelete()}
          title="Delete Buyer Profile"
          message="Are you sure you wish to delete this Buyer Profile? This action cannot be undone."
        />
      )}
    </>
  );
};

export default BuyersProfileSaved;
