import axios from 'axios';

export const deleteApi = (urlApi, userToken) => {
  return axios
    .delete(urlApi,  {
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    })
    .then((res) => {
      //console.log('respuesta desde getAPI:', res.status);
      // console.log('respuesta completa', res)
      return res;
    })
    .catch((error) => {
      if (error.response) {
        // console.log(error.response.data);
        // console.log('Código GET API STATUS:', error.response.status);
        return error.response
      }
    });
};
