import React from 'react';
// import { Box, Button, ContentTab, ItemTab, GoBack } from '../../components/OMB';
import { Box, Button, Search } from '../../components/OMB';
import './offer.css';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Img from '../../assets/img/image.png';

const Property = (props) => {
  return (
    <React.Fragment>
      <div className="content properties">
        <div className="row">
          <div className="col-lg-3 d-flex justify-content-center align-items-center flex-column">
            <img className="img-property" src={props.data?.urlImage} />
          </div>
          <div className="col-lg-6">
            <div className="direction">
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 16 16"
                class="bi bi-geo-alt"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="#51c8ed"
                  fill-rule="evenodd"
                  d="M12.166 8.94C12.696 7.867 13 6.862 13 6A5 5 0 0 0 3 6c0 .862.305 1.867.834 2.94.524 1.062 1.234 2.12 1.96 3.07A31.481 31.481 0 0 0 8 14.58l.208-.22a31.493 31.493 0 0 0 1.998-2.35c.726-.95 1.436-2.008 1.96-3.07zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
                />
                <path
                  fill="#51c8ed"
                  fill-rule="evenodd"
                  d="M8 8a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                />
              </svg>
              <p>
                <strong>
                  {props.data?.address?.street},{props.data?.address?.unit},
                  {props.data?.address?.zip},{props.data?.address?.city},
                  {props.data?.address?.estate},
                </strong>
              </p>
            </div>
            <div className="row">
              <div className="col-md-12">
                <table className="table">
                  <tr>
                    <td>
                      <strong>Date</strong>
                    </td>
                    <td>
                      <strong>Buyer Type</strong>
                    </td>
                    <td>
                      <strong>Buyer Name</strong>
                    </td>
                    <td>
                      <strong>Amount</strong>
                    </td>
                    <td>
                      <strong>Funding</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>02-02-2020</td>
                    <td>Entity</td>
                    <td>Jessica Osborne</td>
                    <td>$440,000</td>
                    <td>Cash</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="col-lg-3  d-flex flex-column justify-content-between">
            <Button color="light-blue">MARK AS PURCHASED</Button>
            <div>
              <div className="d-flex flex-row-reverse">
                <Button color="yellow">VIEW MORE</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const Offers = (props) => {
  /* const user = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation(); */

  const [search, setSearch] = React.useState('');
  const arrProperties = [
    {
      id: 1,
      address: {
        id: 1,
        street: '1234 NW 15th st',
        city: 'Doral',
        estate: 'FL',
        zip: 33134,
        unit: 'APT 4-C',
        lat: 1.311,
        long: -123.3156,
      },
      typeProperty: {
        id: 1,
        name: 'Single family',
      },
      statusProperty: {
        id: 1,
        name: 'Listed',
      },
      beds: 4,
      baths: 3,
      squareFeet: 1800,
      lotSize: 7016,
      yearBuilt: 2001,
      occupancy: 'Vacant',
      price: 220000,
      like: 0,
      urlImage: Img,
    },
    {
      id: 2,
      address: {
        id: 2,
        street: '1234 Caracas 15th st',
        city: 'Doral',
        estate: 'FL',
        zip: 33134,
        unit: 'APT 4-C',
        lat: 1.311,
        long: -123.3156,
      },
      typeProperty: {
        id: 1,
        name: 'Single family',
      },
      statusProperty: {
        id: 1,
        name: 'Listed',
      },
      beds: 4,
      baths: 3,
      squareFeet: 1800,
      lotSize: 7016,
      yearBuilt: 2000,
      occupancy: 'Vacant',
      price: 220000,
      lat: 1.311,
      long: -123.3156,
      like: 0,
      urlImage: Img,
    },
  ];

  function search_filter_address(rows) {
    var column = Object.keys(rows[0].address); //aqui creamos un array con los keys de row
    return rows.filter((row) => {
      //aqui vamos a devolver los elementos que cumplan con la condicion
      return column.some((col) => {
        //aqui verificamos que almenos una columna cumpla con la condicion
        return row.address[col].toString().toLowerCase().indexOf(search.toLowerCase()) > -1;
      });
    });
  }
  return (
    <React.Fragment>
      <Box title={"User's Offers (" + arrProperties.length + ')'}>
        {/*
        <div className="search-properties">
          <strong>Search Address</strong>
          <input
            type="text"
            className="search-input"
            placeholder="Search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        */}

        <div className="search-properties">
          <h3>Search Address</h3>
          <Search placeholder="Search..." />
        </div>
        {search_filter_address(arrProperties).map((property, i) => {
          return <Property data={property} />;
        })}
      </Box>
    </React.Fragment>
  );
};

export default Offers;
