import {
  Accounting,
  MembershipDetails,
  SponsorshipDetails,
  ReferralsDetails,
  AddExpenses,
  AddTransactionFee,
} from '../views/';

export const AdminAccountingRoutes = [
  {
    path: '/accounting',
    name: 'Accounting',
    icon: 'super-admin/accounting.svg',
    component: Accounting,
    hide: false,
    role: 'ROLE_ADMIN_ACCOUTING',
    primary: true,
  },
  {
    path: '/membership-details/:id',
    name: 'Member Details',
    component: MembershipDetails,
    hide: true,
    role: 'ROLE_ADMIN_ACCOUTING',
  },

  {
    path: '/sponsorship-details/:id',
    name: 'Sponsorship Details',
    component: SponsorshipDetails,
    hide: true,
    role: 'ROLE_ADMIN_ACCOUTING',
  },
  {
    path: '/referrals-details/:id',
    name: 'Referrals Details',
    icon: 'pe-7s-graph',
    component: ReferralsDetails,
    hide: true,
    role: 'ROLE_ADMIN_ACCOUTING',
  },
  {
    path: '/add-expenses',
    name: 'Add Expenses',
    icon: 'pe-7s-graph',
    component: AddExpenses,
    hide: true,
    role: 'ROLE_ADMIN_ACCOUTING',
  },
  {
    path: '/add-transaction-fee',
    name: 'Add Transaction Fee',
    component: AddTransactionFee,
    hide: true,
    role: 'ROLE_ADMIN_ACCOUTING',
  },
];