import React from 'react';
import { Route } from 'react-router-dom';
import AdminNavbar from '../components/NavBars/AdminNavbar';
import Sidebar from '../components/Sidebar/Sidebar';
import routes from './routes.js';
import { SwitchRole, SwitchRoleButton } from '../components/OMB/compnentsOMB';
import { set_active_rol } from '../redux/userDucks';
import { useDispatch, useSelector } from 'react-redux';
import SearchListing from '../views/properties/searchListing';
import './Admin.css';

const AdminLayout = (props) => {
  const user = useSelector((store) => store.user);
  const ROLE_USER = user.roleActive;
  const STATUS = ROLE_USER === 'ROLE_BUYER_AGENT' || ROLE_USER === 'ROLE_BUYER' ? user.info.membershipStatus : true;
  const dispatch = useDispatch();
  const getRoutes = (routes) => {
    //este renderiza las rutas del menu segun el rol
    var rol = user.info.userRole;
    return rol.map((rol, i) => {
      return routes.map((prop, j) => {
        if (rol.role.role === prop.role) {
          return (
            <Route
              exact
              path={'/' + rol.role.role + prop.path}
              render={(props) => <prop.component {...props} />}
              key={j}
            />
          );
        }
      });
    });
  };
  const getRoutesRolMenu = (routes) => {
    var routesMenu = [];
    var rol = user.info.userRole;
    for (var i = 0; i < rol.length; i++) {
      for (var j = 0; j < routes.length; j++) {
        if (routes[j].role === rol[i].role.role) {
          routesMenu.push(routes[j]);
        }
      }
    }

    return routesMenu;
  };

  React.useEffect(() => {
    // console.log(ROLE_USER)
  });


  const primaryLayout = () => {
    return (
      <>
      <div className="wrapper">
        <Sidebar {...props} roleActive={user.roleActive} routes={getRoutesRolMenu(routes)} />

        <div
          id="main-panel"
          className={window.innerWidth <= 991 ? 'main-panel full-width' : 'main-panel'}
        >
          <AdminNavbar
            {...props}
            // brandText={this.getBrandText(this.props.location.pathname)}
          />
          <div className="content-app">
            <Route>
              {getRoutes(routes)}
              <Route
                exact
                path={'/BUYER_AGENT/search'}
                render={(props) => <SearchListing {...props} />}
              /> 
            </Route>
          </div>
        </div>
      </div>
      </>
    )
  }

  const secondaryLayout = () => {
    return (
      <>
        <div className="container">
          <div className="content-app">
            <Route>
              {getRoutes(routes)}
              <Route
                exact
                path={'/BUYER_AGENT/search'}
                render={(props) => <SearchListing {...props} />}
              />
            </Route>
          </div>
        </div>
      </>
    )
  }

  // return (STATUS ? primaryLayout() : secondaryLayout())
  return (primaryLayout() )

};
export default AdminLayout;
