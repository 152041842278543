import React, { useState, useEffect } from 'react';
import { Card, Confirm, ItemMessage } from '../../components/OMB';
import {
  getApi,
  GET_CHAT,
  SEND_MESSAGE_CHAT,
  postApi,
  CONVERSATION_VIEWED,
} from '../../utils/services';
import { useSelector } from 'react-redux';
import iconSend from '../../assets/iconos/iconsend.svg';
import './styles.css';
import { useParams } from 'react-router';

const Chat = ({ chatId, viewed }) => {
  const { profileId } = useParams();
  const userToken = localStorage.getItem('tokenOMB');
  const [messages, setMessages] = useState([]);
  const [messageChat, setMessageChat] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageCopyAndPast, setErrorMessageCopyAndPast] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    roleActive,
    info: { id: userId },
  } = useSelector((store) => store.user);
  const [titleAddress, setTitleAddress] = useState('');
  const [showModalTmc, setShowModalTmc] = useState(false);
  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(`${GET_CHAT}${chatId}`, userToken)
      .then((response) => {
        setMessages(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    const mapAddress =
      messages.length > 0 &&
      messages.map((message) => {
        const address = `${message.street}, ${message.city}, ${message.codEstate}`;
        return address;
      });
    if (!!mapAddress) {
      setTitleAddress(mapAddress[0]);
    }
  }, [messages]);

  useEffect(() => {
    if (!!viewed) {
      setShowModalTmc(true);
    }
  }, [viewed]);

  const copyAndPaste = (e) => {
    e.preventDefault()
    setErrorMessageCopyAndPast(!errorMessageCopyAndPast)
  }

  const handleMessage = (msg) => {
    if (error && !!messageChat) {
      setError(false);
    }
    const regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
    let emailClear = msg.replace(/\s+/g, '');
    const testEmail = regEmail.test(emailClear);
    const regPhone = /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/g;
    const testPhone = regPhone.test(msg);
    const regPhoneLimited = /[0-9]{6}/;
    const testPhoneLimited = regPhoneLimited.test(msg);

    if (testEmail || testPhone || testPhoneLimited) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      setMessageChat(msg);
    }
  };

  const handleSendMessage = async () => {
    const dataForm = {
      conversation: {
        id: parseInt(chatId),
      },
      message: messageChat,
    };

    await postApi(SEND_MESSAGE_CHAT, dataForm, userToken)
      .then((response) => {
        console.log('send message', response);
        if (response.status === 200) {
          setSuccess(true);
          setMessageChat('');
          setMessages([
            ...messages,
            {
              messages: messageChat,
              roles: roleActive,
              user: userId,
            },
          ]);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  };

  const changeConversationViewed = async () => {
    const id = profileId || chatId;
    await getApi(`${CONVERSATION_VIEWED}${id}`, userToken)
      .then((response) => {
        // alert('condiciones aceptadas')
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <div className="chat-wrapper">
      <Card title={titleAddress}>
        <div className="box-chat">
          {messages.length > 0 &&
            messages.map((message, i) => {
              return <ItemMessage message={message} key={`chat-${i}`} />;
            })}
        </div>
        <div className="type-area">
          <input
            type="text"
            className="type-message"
            placeholder="Type a message…"
            value={messageChat}
            onChange={(e) => handleMessage(e.target.value)}
            onCopy={(e) => copyAndPaste(e)}
            onPaste={(e) => copyAndPaste(e)}
            onCut={(e) => copyAndPaste(e)}
          />
          <a
            href="#"
            onClick={handleSendMessage}
            className={!!messageChat && errorMessage === false ? '' : 'disabled'}
          >
            <img src={iconSend} alt="Icon Send" />
          </a>
        </div>
        <div className="form-group">
          {!!error && <span className="text-danger">Message required</span>}
          {!!errorMessage && (
            <span className="text-danger">
              Your message cannot contain email addresses or phone numbers.
            </span>
          )}
          {!!errorMessageCopyAndPast && (
            <span className="text-danger">Copying, cutting or pasting text is not allowed.</span>
          )}
          {!!success && <span className="text-success">Message sent succesfully</span>}
        </div>
      </Card>
      {showModalTmc && roleActive === 'ROLE_LISTING_AGENT' && (
        <Confirm
          show={showModalTmc}
          onHide={() => {
            setShowModalTmc(false);
          }}
          yes={() => {
            changeConversationViewed();
            setShowModalTmc(false);
          }}
          MessageBtnTwo="I UNDERSTAND"
          hideBtnOne={true}
          title="Reminder"
          message="Please acknowledge below, that you have read the Terms and Conditions, and
          understand the $350 Transaction Fee owed OMB at closing for this or any other transaction closed with this OMB
          generated Buyer or Agent."
        />
      )}
    </div>
  );
};

export default Chat;
