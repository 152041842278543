import {
  SearchListings,
  MyProperties,
  MyOffersList,
  PropertyDetails,
  PropertyOffer,
  PropertyOfferDetails,
  Coupon,
  AgentsProfile,
  AddBuyerProfile,
  BuyersProfile,
  BuyersProfileDetails,
  Invite,
  MyCoupons,
  InvitedMembers,
  MyMessages,
  MyChat,
  MakePayment,
} from '../views/';

export const BuyerAgentRoutes = [
    {
    path: '/make-payment',
    name: 'Make Payment',
    icon: 'make-payment/icon.svg',
    component: MakePayment,
    role: 'ROLE_BUYER_AGENT',
    hide: true,
  },
  {
    path: '/search-listings',
    name: 'Search Listings',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: SearchListings,
    role: 'ROLE_BUYER_AGENT',
    primary: true,
  },
  {
    path: '/my-properties',
    name: 'My Properties',
    icon: 'buyer-buyers-agent/my-properties.svg',
    component: MyProperties,
    role: 'ROLE_BUYER_AGENT',
    hide: false,
  },
  {
    path: '/agents-profile',
    name: "Agent's Profile",
    icon: 'buyer-buyers-agent/profile.svg',
    component: AgentsProfile,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/buyer-profiles',
    name: "Buyer Profiles",
    icon: 'buyer-buyers-agent/buyer-profiles.svg',
    component: BuyersProfile,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/buyers-profile-details/:id',
    name: "Buyers'Profile",
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: BuyersProfileDetails,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/add-buyer-profile',
    name: 'Add Buyer Profile',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: AddBuyerProfile,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/invite',
    name: 'Invite',
    icon: 'listing-agent/invite.svg',
    component: Invite,
    hide: false,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/invite/invited-members/:id',
    name: 'Invite',
    icon: 'listing-agent/invite.svg',
    component: InvitedMembers,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/coupon-code',
    name: 'Coupon Code',
    icon: 'listing-agent/coupon-code.svg',
    component: Coupon,
    hide: false,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/my-redeemed-coupons/:id',
    name: 'Coupon Code',
    icon: 'listing-agent/coupon-code.svg',
    component: MyCoupons,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/property-details/:id',
    name: 'My Properties',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: PropertyDetails,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/property-offer/:id',
    name: 'My Properties',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: PropertyOffer,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/property-offer-details/:id',
    name: 'My Properties',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: PropertyOfferDetails,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/my-offers',
    name: 'My Properties',
    component: MyOffersList,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/messages',
    name: 'Messages',
    icon: 'pe-7s-graph',
    component: MyMessages,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
  {
    path: '/messages/chat/:id',
    name: 'Messages',
    icon: 'pe-7s-graph',
    component: MyChat,
    hide: true,
    role: 'ROLE_BUYER_AGENT',
  },
]