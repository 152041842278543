import React from 'react'
import { MakePaymentContainer } from '../../containers'


const MakePayment = () => {
    return (
        <div className="make-payment">
            <MakePaymentContainer />
        </div>
    )
}

export default MakePayment;
