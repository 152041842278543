import React, { useEffect, useState } from 'react'
import { postApiFile } from '../../../utils/services';
import './style.css';

export const UploaderFile = ({ titleLabel, urlApi, userToken, setRoute, typeFile, sizeLabel, preLoadedFile=false, setLoading= false, routeLicense}) => {
  const [fileName, setFileName] = useState('Choose file...');
  const [type, setType] = useState(1);

  useEffect(() => { setFileName(preLoadedFile ? 'file uploaded' : 'Choose file...') }, [preLoadedFile])
  useEffect(() => {
    if(!!routeLicense){
      setFileName('file uploaded')
    }
  }, [routeLicense])
  
  const loadFile = (event, d) => {
    setLoading(true);
    var output = document.getElementById(d);
    output.src = URL.createObjectURL(event.target.files[0]);
    const nameFile = event.target.files[0].name;
    setFileName(nameFile);
    switch (typeFile) {
      case 'coupon':
        setType(1)
        break;
      case 'properties':
        setType(2)
        break;
      case 'profile':
        setType(3)
        break;
      case 'sponsor':
        setType(4)
        break;
      default:
        setType(5)
        break;
    }

    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };

    const fd = new FormData();
    fd.append('upload', event.target.files[0]);
    fd.append('typeFile', type);

    postApiFile(urlApi, fd, userToken, d)
      .then((response) => {
        const data = response.data.upload;
        if (d === 'validatedInputGroupCustomFile') {
          setRoute(data)
          setFileName('file uploaded');
          setLoading(false);
        }
        // console.log('respuesta positiva de uploader file', data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de Error de uploader file:', error.response.status);
        }
      });
  };

  return (
    <div className='uploaderFile'>
      <label className={`form-label ${sizeLabel}`}>
        {titleLabel}
      </label>
      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          id="validatedInputGroupCustomFile"
          onChange={(event) => {
            const d = 'validatedInputGroupCustomFile';
            loadFile(event, d);
            // console.log(event);
          }}
        />
        <label className="custom-file-label" htmlFor="validatedInputGroupCustomFile">
          {fileName}
        </label>
      </div>
    </div>
  )
}
