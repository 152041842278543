import React from 'react';
import { PropertyList } from '..';
import { ContentTab, ItemTab } from '../../components/OMB';
import { usePropertyList } from "../../hooks/usePropertyList";
import { useSavedProperties } from "../../hooks/useSavedProperties";
import { useOffersProperties } from "../../hooks/useOffersProperties";
import { usePurchasedProperties } from "../../hooks/usePurchasedProperties";
import "./styles.css";

const MyPropertiesTabs = ({ userId }) => {
  usePropertyList();
  const [savedProperties] = useSavedProperties(userId);
  const [offersProperties] = useOffersProperties(userId);
  const [purchasedProperties] = usePurchasedProperties(userId);

  return (
    <div className="my-properties-tabs">
      <ContentTab className="tabs-colorful" defaultActiveKey="saved-properties">
        <ItemTab eventKey="saved-properties" title="Saved properties">
          <PropertyList contentType="UNO" properties={savedProperties} />
        </ItemTab>
        <ItemTab eventKey="offers" title="Offers">
          <PropertyList contentType="DOS" properties={offersProperties} myOffers={true} />
        </ItemTab>
        <ItemTab eventKey="purchased" title="Purchased">
          <PropertyList contentType="TRES" properties={purchasedProperties} />
        </ItemTab>
      </ContentTab>
    </div>
  )
}

export default MyPropertiesTabs;