import React from 'react';
import { Card } from "../../components/OMB";
import MessagesList from "./MessagesList/";
import MessagesBuyersList from "./MessagesBuyersList/";

import { useSelector } from "react-redux";

const Messages = () => {
  const { roleActive } = useSelector((store) => store.user);

  return (
    <Card title="My Messages">
      {
        roleActive === "ROLE_LISTING_AGENT"
          ? <MessagesList />
          : <MessagesBuyersList />
      }
    </Card>
  )
}

export default Messages;
