import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './userDucks';
import propertyReducer from './PropertyDucks';
import adminReducer from './adminDucks';

import { loadState, saveState } from './restoresState';

const initialData = loadState();

const rootReducer = combineReducers({
  user: userReducer,
  property: propertyReducer,
  admin: adminReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
  const store = createStore(
    rootReducer,
    { user: initialData?.user }, //usamos la data inicial que obtenemos del local storage
    composeEnhancers(applyMiddleware(thunk)),
  );

  store.subscribe(() => {
    saveState(store.getState()); //cuando haya un cambio en el estado almacenamos el ESTADO COMPLETO en el localStorage
  });
  return store;
}
