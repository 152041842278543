import React, { useState, useEffect } from 'react';
import { Button, CurrencyInput, Loading } from '../../components/OMB';
import Img from '../../assets/img/image.png';
import { Modal, Carousel, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { save, edit, error, sucessful } from '../../redux/PropertyDucks';
import * as Yup from 'yup';
import { useUtils } from '../../hooks/useUtils';
import { upload } from '../../hooks/upload';
import { useListStates } from '../../hooks/useListStates';
import { useParams } from 'react-router-dom';
import './index.css';
import { getApi, GET_USER } from '../../utils/services';

const PropertyForm = (props) => {
  const { id: userId, profileId: profileIdData } = useParams();
  const [profileId, setProfileId] = useState(!!profileIdData ? profileIdData : userId);
  console.log(profileId);
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive, info } = useSelector((store) => store.user);
  const { wholesaler: wholesalerStore } = info.profile;
  const [wholesaler, setWholesaler] = useState(wholesalerStore);
  const PropertySchema = Yup.object().shape({
    street: Yup.string().required('Required'),
    bed: Yup.number().min(0, 'Min 0'),
    lotSize: Yup.number('Must be only digits'),
    price: Yup.string(),
    unit: Yup.string(),
    bath: Yup.number('Must be only digits').min(0, 'Min 0'),
    square_feet: Yup.number('Must be only digits').min(0, 'Min 0').required('Required'),
    yearBuilt: Yup.string('Must be number')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(0, 'Min 0')
      .max(4, 'Max 4'),
    city: Yup.string(),
    zipCode: Yup.string('Must be number')
      .required('Required')
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(5, 'Min 5')
      .max(5, 'Max 5'),
    estate: Yup.object().shape({
      id: Yup.number('Must be only digits').required('Required'),
    }),
    statusP: Yup.number('Must be only digits').required('Required'),
    occupancy: Yup.string('').required('Required'),
    typeProperty: Yup.object().shape({
      id: Yup.number('Must be only digits').required('Required'),
    }),
  });
  const { property } = useSelector((store) => store);
  const dispatch = useDispatch();
  const [modaShow, setModalShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [imagesProperties, setImagesProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ultils] = useUtils();
  const [statusProperty, setStatusProperty] = useState([]);
  const [states] = useListStates();
  const [activeEdit, setActiveEdit] = useState(false);
  const [idProperty, setIdProperty] = useState(null);
  const [changeImage, setChangeImage] = useState(false);
  const [valuesInit, setValuesInit] = useState({
    bath: '',
    bed: '',
    lotSize: '',
    occupancy: '',
    price: '',
    square_feet: '',
    yearBuilt: '',
    street: '',
    city: '',
    zipCode: '',
    unit: '',
    estate: { id: '' },
    typeProperty: { id: '' },
    statusP: '',
    propertyImages: [],
  });

  const callInfoUser = async () => {
    const urlApi = `${GET_USER}${profileId}`;
    await getApi(urlApi, userToken)
      .then((response) => {
        if (response.status === 200) {
          if (!!response.data) {
            const { wholesaler } = response.data.profile;
            const statusWholesaler = !!wholesaler ? wholesaler : false;
            setWholesaler(statusWholesaler);
            let dataStatusProperty = ultils.status;
            if (statusWholesaler === false) {
              dataStatusProperty.pop();
              setStatusProperty(dataStatusProperty);
            } else {
              setStatusProperty(ultils.status);
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!profileId && roleActive === 'ROLE_ADMIN') {
      callInfoUser();
    }
  }, [profileId]);

  const removeImg = (name) => {
    var arr = [];
    for (var i = 0; i < files.length; i++) {
      if (files[i].name !== name) {
        arr.push(files[i]);
      }
    }
    setFiles(arr);
  };

  useEffect(() => {
    if (ultils.status) {
      let dataStatusProperty = ultils.status;
      if (wholesaler === false) {
        console.log('se cumple');
        dataStatusProperty.pop();
        setStatusProperty(dataStatusProperty);
      } else {
        setStatusProperty(ultils.status);
      }
    }
  }, [ultils]);

  useEffect(() => {
    if (props.data) {
      setIdProperty(props.data?.id);
      setValuesInit({
        id: props.data?.id,
        bath: props.data?.bath,
        bed: props.data?.bed,
        lotSize: props.data?.lot_size,
        occupancy: props.data?.occupancy,
        price: props.data?.price,
        square_feet: props.data?.squareFeet,
        yearBuilt: props.data?.year_built,
        street: props.data?.address?.street,
        city: props.data?.address?.city,
        zipCode: props.data?.address?.zipCode,
        unit: props.data?.address?.unit,
        estate: { id: props.data?.address?.estate?.id },
        typeProperty: { id: props.data?.typeProperty?.id },
        statusP: props.data?.statusP,
        propertyImage: props.data?.propertyImage,
      });
    }
  }, [props.data]);

  useEffect(() => {
    if (!!valuesInit.id) {
      setActiveEdit(true);
      setImagesProperties(props.data?.propertyImage);
    }
  }, [valuesInit]);

  const handleSubmit = (values) => {
    setLoading(true);

    values.estate.id = parseInt(values.estate.id);
    values.typeProperty.id = parseInt(values.typeProperty.id);
    values.statusP = parseInt(values.statusP);

    let price;

    if (typeof values.price === 'string') {
      let priceFormatOne = values.price.replaceAll('$', '');
      price = priceFormatOne.replaceAll(',', '').trim();
    } else {
      price = values.price;
    }

    let dataForm = {
      lot_size: !!values.lotSize ? values.lotSize : 0,
      occupancy: values.occupancy,
      price: price,
      address: {
        street: values.street,
        city: values.city,
        zipCode: values.zipCode,
        unit: values.unit,
        estate: { id: values.estate.id },
      },
      typeProperty: { id: values.typeProperty.id },
      statusP: values.statusP,
      userId: parseInt(userId),
    };

    if (!!values.bath) {
      dataForm = { ...dataForm, bath: values.bath };
    }

    if (!!values.bed) {
      dataForm = { ...dataForm, bed: values.bed };
    }

    if (!!values.square_feet) {
      dataForm = { ...dataForm, squareFeet: values.square_feet };
    }

    if (!!values.yearBuilt) {
      dataForm = { ...dataForm, year_built: parseInt(values.yearBuilt) };
    }

    let dataEdit = {
      id: activeEdit ? props.data?.id : null,
    };

    let data = {};
    data = activeEdit ? { ...dataEdit, ...dataForm } : { ...dataForm };

    if (changeImage === false) {
      values.propertyImages = props.data?.propertyImage;
      data.propertyImage = props.data?.propertyImage;
      activeEdit ? dispatch(edit(data)) : dispatch(save(data));
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }

    if (changeImage === true) {
      if (files.length === 0) {
        alert('Images are missing');
        setLoading(false);
      } else {
        let count = 0; //este contador nos indica la cantidad de imagenes que subieron exitosamente
        let arrStr = []; //este arreglo es el que llevara los string de las imagenes que responde el servidor
        let j = 0;
        files.map((file, i) => {
          upload(files[i], idProperty)
            .then((str) => {
              console.log('str', str);
              count = count + 1;
              arrStr[j] = [...str];
              j = j + 1;
              if (count === files.length) {
                const array = arrStr;
                const arrImg = [];
                array.map((item) => {
                  console.log('image', ...item);
                  arrImg.push(...item);
                });
                //si esto se cumple subieron todas las imagenes
                values.propertyImages = arrImg;
                data.propertyImage = arrImg;
                activeEdit ? dispatch(edit(data)) : dispatch(save(data));
                setLoading(false);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        });
      }
    }
  };

  return (
    <>
      <div className="content property-content">
        {activeEdit === false && props.data ? (
          <Loading />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={valuesInit}
            validationSchema={PropertySchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched }) => (
              <Form>
                <div className=" carrusel">
                  {imagesProperties.length > 0 && files.length <= 0 ? (
                    <Carousel>
                      {imagesProperties.map((data, i) => {
                        return (
                          <Carousel.Item key={i}>
                            <img
                              className="d-block w-100 img-carousel"
                              src={data.urlImage}
                              alt="First slide"
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <Carousel>
                      {files.length > 0 ? (
                        Object.keys(files)?.map((data, i) => {
                          return (
                            <Carousel.Item>
                              <img
                                className="d-block w-100 img-carousel"
                                src={URL.createObjectURL(files[data])}
                                alt="First slide"
                              />
                            </Carousel.Item>
                          );
                        })
                      ) : (
                        <Carousel.Item>
                          <img className="d-block w-100" src={Img} alt="First slide" />
                        </Carousel.Item>
                      )}
                    </Carousel>
                  )}

                  <label
                    className="btn-file-profile"
                    onClick={() => {
                      setModalShow(true);
                      setChangeImage(true);
                    }}
                  >
                    {props.data ? 'EDIT IMAGE' : 'ADD IMAGE'}
                  </label>
                </div>
                <div>
                  <div className="test-column">
                    <div className="">
                      <label className="form-label">Street Address*</label>
                      <Field
                        className="form-control"
                        type="text"
                        id="street"
                        name="street"
                        placeholder="Enter Address"
                      />
                      {errors.street && touched.street && (
                        <div className="error">{errors.street}</div>
                      )}
                    </div>
                    <div className="">
                      <label className="form-label">Unit </label>
                      <Field
                        className="form-control"
                        id="unit"
                        name="unit"
                        type="text"
                        placeholder="Unit"
                      />
                      {errors.unit && touched.unit && <div className="error">{errors.unit}</div>}
                    </div>
                    <div className="">
                      <label className="form-label">City</label>
                      <Field
                        className="form-control"
                        type="text"
                        id="city"
                        name="city"
                        placeholder="Enter City"
                      />
                      {errors.city && touched.city && <div className="error">{errors.city}</div>}
                    </div>
                    <div className="">
                      <label className="form-label">State*</label>
                      <Field className="form-control" as="select" id="estate" name="estate.id">
                        <option value={''}>Select</option>
                        {states.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.code}
                          </option>
                        ))}
                      </Field>
                      {errors.estate?.id && touched.estate?.id && (
                        <div className="error">{errors.estate?.id}</div>
                      )}
                    </div>
                    <div className="">
                      <label className="form-label">Zip*</label>
                      <Field
                        className="form-control"
                        id="zipCode"
                        name="zipCode"
                        type="text"
                        placeholder="Code"
                      />
                      {errors.zipCode && touched.zipCode && (
                        <div className="error">{errors.zipCode}</div>
                      )}
                    </div>
                  </div>
                  <div className="type-column">
                    <div>
                      <label className="form-label">Property Type*</label>
                      <Field
                        className="form-control"
                        id="typeProperty"
                        name="typeProperty.id"
                        as="select"
                      >
                        <option value={''}>Select</option>
                        {ultils?.typeProperty?.map((data, i) => {
                          return (
                            <option value={parseInt(data.id)} key={i}>
                              {data.name}
                            </option>
                          );
                        })}
                      </Field>
                      {errors.typeProperty?.id && touched.typeProperty?.id && (
                        <div className="error">{errors.typeProperty?.id}</div>
                      )}
                    </div>
                    <div>
                      <label className="form-label">Beds </label>
                      <Field
                        className="form-control"
                        id="bed"
                        name="bed"
                        type="number"
                        placeholder="Enter"
                        min="0"
                      />
                      {errors.bed && touched.bed && <div className="error">{errors.bed}</div>}
                    </div>
                    <div>
                      <label className="form-label">Baths </label>
                      <Field
                        className="form-control"
                        id="bath"
                        name="bath"
                        type="number"
                        placeholder="Enter"
                        min="0"
                      />
                      {errors.bath && touched.bath && <div className="error">{errors.bath}</div>}
                    </div>
                    <div>
                      <label className="form-label">Sq.Ft.*</label>
                      <Field
                        className="form-control"
                        id="square_feet"
                        name="square_feet"
                        type="number"
                        placeholder="Enter"
                      />
                      {errors.square_feet && touched.square_feet && (
                        <div className="error">{errors.square_feet}</div>
                      )}
                    </div>
                    <div>
                      <label className="form-label">Lot Size (acres) </label>
                      <Field
                        className="form-control"
                        id="lotSize"
                        name="lotSize"
                        type="number"
                        step='any'
                        placeholder="Enter"
                        min={0}
                        step="1"
                      />
                      {errors.lotSize && touched.lotSize && (
                        <div className="error">{errors.lotSize}</div>
                      )}
                    </div>
                    <div>
                      <label className="form-label">Year Built </label>
                      <Field className="form-control" id="yearBuilt" name="yearBuilt" type="text" />
                      {errors.yearBuilt && touched.yearBuilt && (
                        <div className="error">{errors.yearBuilt}</div>
                      )}
                    </div>
                  </div>
                  <div className="row d-flex justify-content-between">
                    <div className="col-md-4">
                      <label className="form-label">Status*</label>
                      <Field className="form-control" as="select" id="status" name="statusP">
                        <option value={''}>Select</option>

                        {statusProperty?.map((data, i) => {
                          return (
                            <option value={parseInt(data.id)} key={i}>
                              {data.name}
                            </option>
                          );
                        })}
                      </Field>
                      {errors?.statusP && touched?.statusP && (
                        <div className="error">{errors?.statusP}</div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Occupancy*</label>
                      <Field className="form-control" as="select" id="occupancy" name="occupancy">
                        <option value={''}>Select</option>
                        <option value="Vacant">Vacant</option>
                        <option value="Occupied">Occupied</option>
                      </Field>
                      {errors?.occupancy && touched?.occupancy && (
                        <div className="error">{errors?.occupancy}</div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Price</label>
                      <Field
                        name="price"
                        render={({ field }) => (
                          <CurrencyInput
                            {...field}
                            id="price"
                            placeholder="$0.00"
                            type="text"
                            className={`form-control`}
                          />
                        )}
                      />
                      {errors.price && touched.price && <div className="error">{errors.price}</div>}
                    </div>
                    <div className="col-md-4 d-flex align-items-center">
                      <Button color="yellow" type="submit">
                        {loading && (
                          <Spinner animation="border" role="status" size="sm">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        )}
                        {props.data ? 'Save Property' : 'Add Property'}
                      </Button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        <Modal
          {...props}
          show={modaShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Add An Image</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="grid-images">
              {files.length > 0 &&
                Object.keys(files)?.map((data, i) => {
                  return (
                    <div className="box-img-add-remove">
                      <div className="ic-remove">
                        <a
                          href="# "
                          onClick={() => {
                            removeImg(files[i].name);
                          }}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="10" fill="white" />
                            <path
                              d="M14.6667 6.27333L13.7267 5.33333L10 9.06L6.27337 5.33333L5.33337 6.27333L9.06004 10L5.33337 13.7267L6.27337 14.6667L10 10.94L13.7267 14.6667L14.6667 13.7267L10.94 10L14.6667 6.27333Z"
                              fill="#5F5F5F"
                            />
                          </svg>
                        </a>
                      </div>
                      <img
                        className="img-add-remove"
                        src={URL.createObjectURL(files[data])}
                        alt="First slide"
                        key={'img-add-' + i}
                      />
                    </div>
                  );
                })}
              {files.length === 0 ? (
                <label htmlFor="file-profile">
                  <div className={`box-img-add`}>
                    <img className="img-add" src={Img} alt="First slide" />
                  </div>
                </label>
              ) : (
                <label htmlFor="file-profile">
                  <div className={`box-img-add d-none`}>
                    <img className="img-add" src={Img} alt="First slide" />
                  </div>
                </label>
              )}
              <input
                type="file"
                // multiple
                id="file-profile"
                onChange={(event) => {
                  // loadFile(event);
                  for (var i = 0; i < event.target.files.length; i++) {
                    setFiles([...files, event.target.files[i]]);
                  }
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setModalShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          {...props}
          show={property.sucessful}
          onHide={() => dispatch(sucessful(false, ''))}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Property Added Successfully
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{property.message}</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(sucessful(false, ''));
                window.history.back();
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          {...props}
          show={property.error}
          onHide={() => dispatch(error(false, ''))}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">Error!</Modal.Title>
          </Modal.Header>
          <Modal.Body>{property.message}</Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                dispatch(error(false, ''));
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default PropertyForm;
