import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import AdminNavbarLinks from '../NavBars/AdminNavbarLinks';
import { useSelector } from 'react-redux';
import logo from '../../assets/img/Logo.png';
import logoTransparent from '../../assets/img/logo_transparent_blue.png';
import Confirm from '../OMB/Modal/ModalConfirm';
import { getApi, IMAGE_SPONSOR } from '../../utils/services';
import './sidebar.css';

const Sidebar = (props) => {
  const userToken = localStorage.getItem('tokenOMB');
  const {
    roleActive,
    info: { id: userId },
  } = useSelector((store) => store.user);
  const urlApi = `${IMAGE_SPONSOR}${userId}`;
  const routeIcon = '/../../../../assets/icons-sidebar/';
  const [srcImageSponsored, setSrcImageSponsored] = useState(null);
  const [show, setShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  useEffect(() => {
    setWidth(window.innerWidth);
  });

  const logOut = () => {
    setShow(true);
  };

  const handleYes = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = 'https://www.offmarketbroker.com/';
  };

  const callEndpointImageSponsored = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        if (response.status === 200) {
          const { image } = response.data;
          setSrcImageSponsored(image);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndpointImageSponsored();
  }, []);

  return (
    <div
      id="sidebar"
      className={window.innerWidth <= 991 ? 'sidebar sidebar-close' : 'sidebar '}
      data-color={props.color}
    >
      <Confirm
        show={show}
        message="Are you sure you want to log out? "
        MessageBtnOne="Cancel"
        MessageBtnTwo="Log out"
        yes={handleYes}
        no={() => setShow(false)}
      />
      <div className="logo">
        <a href="# ">
          {(srcImageSponsored !== null && roleActive === 'ROLE_BUYER') ||
          (srcImageSponsored !== null && roleActive === 'ROLE_BUYER_AGENT') ? (
            <div className="container_image">
              <img
                className="img-fluid responsive logo_sponsor"
                src={srcImageSponsored}
                alt="logo_image_sponsor"
              />
            </div>
          ) : (
            <img className="img-fluid responsive" src={logoTransparent} alt="logo_image" />
          )}
        </a>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav items flex-column">
          <div>
            {width <= 991 ? <AdminNavbarLinks /> : null}
            {props.routes.map((prop, key) => {
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade ? 'active active-pro' : activeRoute(prop.role + prop.path)
                    }
                    key={key}
                  >
                    {prop.routes !== undefined ? (
                      roleActive === prop.role ? (
                        <Accordion
                          className="flex-column"
                          style={{
                            marginLeft: 33,
                            paddingTop: 20,
                            paddingBottom: 30,
                          }}
                        >
                          <i className={prop.icon} />
                          <Accordion.Toggle
                            style={{
                              backgroundColor: 'transparent',
                              border: 'none',
                              color: '#FFF',
                            }}
                            eventkey={key}
                          >
                            <div>
                              <p>{prop.name}</p>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventkey={key}>
                            <ul style={{ listStyleType: 'disc' }}>
                              {prop.routes.map((propSub, keyTwo) => {
                                if (propSub.hide !== true) {
                                  return (
                                    <li key={keyTwo}>
                                      <NavLink
                                        exact
                                        to={prop.role + propSub.path}
                                        className="nav-link"
                                        activeclassname="active"
                                      >
                                        <div>
                                          <p>{propSub.name}</p>
                                        </div>
                                      </NavLink>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </Accordion.Collapse>
                        </Accordion>
                      ) : null
                    ) : roleActive === prop.role && !prop.hide ? (
                      <NavLink
                        to={'/' + prop.role + prop.path}
                        className="nav-link"
                        activeclassname="active"
                      >
                        <div className="content-item">
                          <div className="content-icon">
                            <img src={`${routeIcon}${prop.icon}`} className="icon"></img>
                          </div>
                          <p className="name">{prop.name}</p>
                        </div>
                      </NavLink>
                    ) : null}
                  </li>
                );
              return null;
            })}
          </div>
          <div>
            <li
              eventkey={3}
              onClick={() => {
                logOut();
              }}
              className="logout"
            >
              <a className="nav-link">
                <div className="content-item">
                  <div className="content-icon">
                    <img src={`${routeIcon}user/logout.svg`} className="icon"></img>
                  </div>
                  <p className="name" activeclassname="active">
                    Log out
                  </p>
                </div>
              </a>
            </li>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
