import React from 'react'

// import {Property} from '../../components/OMB/compnentsOMB'

const SearchListing =(props)=>{


    return(
        <React.Fragment>
            {/* <div className="">
                <Property/>
                <Property/>
                <Property/>
            </div> */}
        </React.Fragment>
    )
}

export default SearchListing