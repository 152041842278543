import React from 'react';
import { Messages } from "../../containers";

const MyMessages = () => {
  return (
    <div className="my-messages-list">
      <Messages />
    </div>
  )
}

export default MyMessages;
