import React, { useState } from 'react';
import { Button, ModalMessage, Card } from '../../../components/OMB';
import { Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { FIND_COUPON, REDEEM_COUPON, getApi, postApi } from '../../../utils/services';
import './coupon.css';

const Component = (props) => {
  const { roleActive } = useSelector((store) => store.user);
  const [showDetail, setShowdetail] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [cupon, setCupon] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [title, setTitle] = useState('');
  const [cuponId, setCuponId] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const userData = JSON.parse(localStorage.getItem('state'));
  const userID = userData.user.info.id;
  const [messageModal, setMessageModal] = useState('');

  const find = (cuponCode) => {
    const urlApi = `${FIND_COUPON}${cuponCode}`;
    const userToken = localStorage.getItem('tokenOMB');

    getApi(urlApi, userToken)
      .then((response) => {
        const data = response.data;
        if (data.status === 400) {
          setMessageModal('The coupon you entered does not exist.')
          console.log('error')
        }
        setBusinessName(response.data.user.profile.businessName)
        setTitle(response.data.title)
        setCuponId(response.data.id)
        setPhone(response.data.user.profile.phone)
        setEmail(response.data.user.username)
        setShowdetail(true)

      })
      .catch((error) => {
        if (error.response) {
          // console.log(error)
        }
      });
  };

  const dataForm = {
    user: {
      id: userID,
    },
    coupon: {
      id: parseInt(cuponId),
    },
  };


  const redeem = () => {
    const userToken = localStorage.getItem('tokenOMB');
    postApi(REDEEM_COUPON, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          setShowSuccess(true)
        }
      })
      .catch((error) => {
        if (error.response) {

        }
      });

  };

  return (
    <>
      <div className="box-text">
        <p className="text">
          If you have an exclusive OMB coupon code, enter it below and we will provide the business’
          information so you can get in touch.
        </p>
      </div>
      <Card
        title="Redeem A Coupon"
        btnTitle="VIEW REDEEMED COUPONS"
        btnHandle={() => {
          window.location.href = '/' + roleActive + '/my-redeemed-coupons/1';
        }}
        btnColor="light-blue"
      >
        <div className="row body-coupon">
          <div className="col-md-6 d-flex">
            <Form.Control onChange={(e) => setCupon(e.target.value)} type="text" placeholder="Enter a coupon..." />
            <Button color="yellow" onClick={() => find(cupon)}>
              REDEEM
            </Button>
            {messageModal === '' ? '' : <ModalMessage message={messageModal} title='Error Redeeming Coupon' />}
          </div>
        </div>
      </Card>
      <Modal
        {...props}
        show={showDetail}
        onHide={() => setShowdetail(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Coupon Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong>Company: </strong> {businessName}
          </p>
          <p>
            <strong>Reward: </strong>{title}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-gray" onClick={() => setShowdetail(false)}>
            CANCEL
          </button>
          <Button
            onClick={() => {
              setShowdetail(false);
              redeem();
            }}
          >
            REDEEM
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        {...props}
        show={showSuccess}
        onHide={() => setShowSuccess(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Almost Done!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Thank you for redeeming this coupon, below you will find the business’ contact
            information. Get in touch and get your reward!
            <br></br>
            <br></br>
            <strong>Phone Number:</strong> {phone}
            <br></br>
            <strong>Email:</strong> {email}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-gray" onClick={() => setShowSuccess(false)}>
            CANCEL
          </button>
          <Button onClick={() => setShowSuccess(false)}>DONE</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Component;
