import { domainServer } from '../connection/connection';
import dashboardRoutes from '../layouts/routes';
// ################# const #################
// definimos las data principal, el objeto y ademas las constantes para las acciones a realizar.

const initalState = {
  info: {
    id: 0,
    fullName: '',
    username: '',
    password: null,
    status: false,
    userRole: [
      {
        id: 0,
        role: '',
        status: false,
      },
    ],
  },
  roleActive: '',
  loading: false,
  error: false,
  sucessful: false,
  message: '',
};

const LOGIN_SUCESSFUL = 'LOGIN_SUCESSFUL';
const SET_ACTIVE_ROL = 'SET_ACTIVE_ROL';
const LOADING = 'LOADING';
const ERROR = 'ERROR';
const SUCESSFUL = 'SUCESSFUL';
const SET_MEMBERSHIP = 'SET_MEMBERSHIP';

// ################# reducer #################
// la funcion principal del reducer es actualizar el objeto data principal

export default function userReducer(state = initalState, action) {
  switch (action.type) {
    case 'LOGIN_SUCESSFUL':
      return {
        ...state,
        info: action.payload.info,
        roleActive: action.payload.roleActive,
      };
    case 'SET_ACTIVE_ROL':
      return {
        ...state,
        roleActive: action.payload.roleActive,
      };
    case 'SET_MEMBERSHIP':
      return {
        ...state,
        info: {
          ...state.info,
          membershipStatus : action.payload.status
        }
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.loading,
      };
    case 'ERROR':
      return {
        ...state,
        error: action.error,
        message: action.message,
      };
    case 'SUCESSFUL':
      return {
        ...state,
        sucessful: action.sucessful,
        message: action.message,
      };
    default:
      return state;
  }
}

// ################# acctions #################
// estas acciones su objetivo principal es comunicarse con el back

export const login = (data) => async (dispatch, getState) => {
  dispatch(loading(true));

  fetch(domainServer + '/api/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: data.username,
      password: data.password,
      userRole: [
        {
          role: {
            id: parseInt(data.role),
          },
        },
      ],
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    })
    .then((res) => {
      dispatch(loading(false));
      const ROLE_USER = res.info.user.userRole[0].role.role;
      const STATUS =
        ROLE_USER === 'ROLE_BUYER_AGENT' || ROLE_USER === 'ROLE_BUYER'
          ? res.info.user.membershipStatus
          : true;
      dispatch({
        type: LOGIN_SUCESSFUL,
        payload: {
          info: res.info.user,
          token: res.token,
          roleActive: ROLE_USER,
        },
      });
      const route = dashboardRoutes.filter((route) => route.role === ROLE_USER && route.primary);
     /*  window.location.href =
        STATUS === false ? `/${ROLE_USER}/make-payment` : `/${ROLE_USER}${route[0].path}`;
         */
         window.location.href = `/${ROLE_USER}${route[0].path}`;
      localStorage.setItem('tokenOMB', res.token);
    })
    .catch((err) => {
      if (err.message == 'Network request failed') {
        err.message =
          'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
      }
      dispatch(loading(false));
      var e = JSON.parse(err?.message);

      dispatch(error(true, e.message));
    });
};

export const set_active_rol = (data) => (dispatch) => {
  console.log(data);
  dispatch({
    type: SET_ACTIVE_ROL,
    payload: {
      roleActive: data,
    },
  });
};

export const set_membership = (data) => (dispatch) => {
  console.log('data de set membership', data);
  dispatch({
    type: SET_MEMBERSHIP,
    payload: {
      status: data,
    },
  });
};

export const loading = (bool) => async (dispatch, getState) => {
  dispatch({
    type: LOADING,
    loading: bool,
  });
};

export const error = (error, message) => async (dispatch, getState) => {
  dispatch({
    type: ERROR,
    error,
    message,
  });
};

export const sucessful = (sucessful, message) => async (dispatch, getState) => {
  dispatch({
    type: SUCESSFUL,
    sucessful,
    message,
  });
};
