import React from 'react';
import { useFormatPrice } from "../../../hooks/useFormatPrice";
import Img from '../../../assets/img/image.png';
import './styles.css';

const CardPropertyDetails = ({ property }) => {
  const [formattedPrice] = useFormatPrice(property.price);
  const urlImage = !!property.propertyImage?.length ? property.propertyImage[0].urlImage : Img;

  const { address } = property;
  const titleAddress = `${address?.street}, ${!!address?.unit ? `unit ${address?.unit},` : ''} ${!!address?.city ? `${address?.city},` : ''} ${address?.estate?.code}, ${!!address?.zipCode ? address?.zipCode : ''}`;
  return (
    <div className="card-property-details">
      <div className="details-media">
        <div className="images">
          <img
            className="img"
            src={urlImage}
          />
        </div>
        {/* <div className="map">
          MAP
        </div> */}
        <div className="address">
          <span className="bold">{titleAddress === null ? '' : titleAddress}</span>
        </div>
      </div>
      <div className="details-info">
        <p className="bold">Property features</p>
        <div>
          <span className="bold">Beds:</span> {property.bed}
        </div>
        <div>
          <span className="bold">Baths:</span> {property.bath}
        </div>
        <div>
          <span className="bold">Square feets:</span> {property.squareFeet}
        </div>
        <div>
          <span className="bold">Lot Size:</span> {property.lot_size}
        </div>
        <div>
          <span className="bold">Year Build:</span> {property.year_built}
        </div>
        <div>
          <span className="bold">Occupancy:</span> {property.occupancy}
        </div>
        <div>
          <span className="bold">Price:</span> {formattedPrice}
        </div>
      </div>
    </div>
  );
};

export default CardPropertyDetails;
