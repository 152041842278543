import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { deleteApi, STRIPE_CANCEL } from '../../../utils/services';
import Button from '../Button/Button';
import Confirm from '../Modal/ModalConfirm';

const CancelSubscription = () => {
    const [showModal, setShowModal] = useState(false);
    const { info: { id: userId }, roleActive } = useSelector((store) => store.user);
    const { id: profileId } = useParams();
    const userToken = localStorage.getItem('tokenOMB');

    const callCancelSubscription = async () => {
     const urlApi = `${STRIPE_CANCEL}/${roleActive !== 'ROLE_ADMIN' ? userId : profileId }`
     await deleteApi(urlApi, userToken)
      .then((response) => {
        console.log('respuesta de cancel', response)
        const data = response.data;
        if(response.status === 200){
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = '/login';
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en adminNavBarLink:', error.response.status);
        }
      });
    }

    const handleCancelSubcription = (e) => {
        // e.preventDefault();
        callCancelSubscription()
    }

    const handleHideModal = () => {
        setShowModal(false);
    };

    const handleShowModal = (e) => {
        e.preventDefault();
        setShowModal(true);
    };

    return (
        <>
            <Button color="yellow" onClick={handleShowModal}>
              Cancel Subscription
            </Button>
            {showModal && (
                <Confirm
                show={showModal}
                onHide={handleHideModal}
                no={handleHideModal}
                yes={handleCancelSubcription}
                title="Cancel Subscription"
                message="Are you sure to cancel your subscription?"
                />
            )}
        </>
    )
}

export default CancelSubscription;