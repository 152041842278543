import React, { useEffect, useState } from 'react';
import {
  getApi,
  LIST_INVITE,
  postApi,
  SAVE_ASSIGNED_MEMBER,
  USERS_LISTINGS,
} from '../../../utils/services';
import { Button, Card, GoBack, ListInvited, ModalMessage } from '../../../components/OMB';
import { useSelector } from 'react-redux';
import { useFormatDate } from '../../../hooks/useFormatDate';
import './invited.css';
import { MyAssignedMembers } from '../../../containers';
import { Modal, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router';

const InvitedMembers = (props) => {
  const userToken = localStorage.getItem('tokenOMB');
  const user = useSelector((store) => store.user);
  const { id: userID } = user.info;
  const { roleActive } = user;
  const { id: profileId } = useParams();
  const urlApi = `${LIST_INVITE}${roleActive === 'ROLE_ADMIN' ? profileId : userID}`;
  const [showData, setShowData] = useState(false);
  const [inviteResponse, setInviteResponse] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dateNextPayment, setDateNextPayment] = useState(null);
  const [totalAmountEarnedJoining, setTotalAmountEarnedJoining] = useState(0);
  const [totalIncomeFromSponsors, setTotalIncomeFromSponsors] = useState(0);
  const [assignedMembers, setAssignedMembers] = useState([]);
  // const [NextPayment] = useFormatDate(dateNextPayment);
  const [memberSelect, setMemberSelect] = useState();
  const [members, setMembers] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    callEndPoints();
    callListings();
  }, []);

  const callListings = async () => {
    const url = `${USERS_LISTINGS}`;
    await getApi(url, userToken)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          console.log('====================================');
          setMembers(data);
          console.log('====================================');
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          const dataInvite = data.inviteResponse;
          const userMember = data.userMember;
          setAssignedMembers(userMember);
          setShowData(true);
          setDateNextPayment(data.dateNextPayment);
          setTotalAmountEarnedJoining(data.totalAmountEarnedJoining);
          setTotalIncomeFromSponsors(data.totalAmountEarnedQuarter);
          setInviteResponse(dataInvite);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const handleAssignMember = () => {
    setShowModal(true);
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = {
      rep: {
        id: roleActive === 'ROLE_ADMIN' ? profileId : userID,
      },
      assign: {
        id: memberSelect,
      },
    };
    postApi(SAVE_ASSIGNED_MEMBER, data, userToken)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setShowModal(false);
          setShowConfirm(true);
        } else {
          setShowModal(false);
          setShowError(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <div className="my-invited-container">
      <GoBack {...props} />
      <Card title="My Invited Members">
        <ListInvited inviteResponse={inviteResponse} />
      </Card>
      {roleActive === 'ROLE_ADMIN' || roleActive === 'ROLE_ADMIN_MEMBERS' ? (
        <Card title="My Assigned Members" btnTitle="Assign Member" btnHandle={handleAssignMember}>
          <MyAssignedMembers assignedMembers={assignedMembers} />
        </Card>
      ) : (
        ''
      )}
      <Card title="Balance">
        {showData ? (
          <>
            <p>
              Total Income From Sponsors: <strong>${totalIncomeFromSponsors.toFixed(2)}</strong>
            </p>
            <p>
              Total Amount Earned (since joining):{' '}
              <strong>${totalAmountEarnedJoining.toFixed(2)}</strong>
            </p>
            <p>
              Date Of Next Payment: <strong>{dateNextPayment}</strong>
            </p>
          </>
        ) : (
          <p className="bold">Loading...</p>
        )}
      </Card>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter bold">Assign Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="row">
              <div className="col-md-8">
                <select
                  className="form-control"
                  as="select"
                  id="offeror"
                  name="offeror"
                  value={memberSelect}
                  onChange={(e) => setMemberSelect(e.target.value)}
                >
                  <option value={''}>Select</option>
                  {members?.map((member, i) => {
                    return (
                      <option value={member.id} key={i}>
                        {member.lastName} {member.firstName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="btn-gray" onClick={() => setShowModal(false)}>
            CANCEL
          </Button>
          <Button onClick={handleSubmit}>
            {loading && (
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            ASSIGN
          </Button>
        </Modal.Footer>
      </Modal>

      {showConfirm ? (
        <ModalMessage
          title="User Assigned"
          message="The user has been assigned successfully."
          btnTitle="OK"
          redirect="false"
          type="message"
        />
      ) : null}
      {showError ? (
        <ModalMessage
          title="Error Assigning User"
          message="Please review the information and try again."
          btnTitle="OK"
          redirect="true"
        />
      ) : null}
    </div>
  );
};

export default InvitedMembers;
