import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Loading } from '../../../components/OMB/';
import { useSelector } from 'react-redux';
import arrowRight from '../../../assets/iconos/Sponsor/arrowRight.svg';
import { LIST_SPONSORED_OPORTUNITIES, getApi } from '../../../utils/services/';
import { useHistory } from 'react-router-dom';

import './styles.css';

const SponsorOportunities = () => {
  const [sponsorships, setSponsorships] = useState([]);
  const { roleActive } = useSelector((store) => store.user);
  const userToken = localStorage.getItem('tokenOMB');
  const urlApi = `${LIST_SPONSORED_OPORTUNITIES}`;
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(urlApi, userToken)
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const data = response.data;
        setSponsorships(data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  // `/${roleActive}/sponsor-member/${idUser}`
  const ListData = () => {
    return (
      <div className="list">
        <div className="fila">
          <div className="column column-title bold">Name</div>
          <div className="column column-title bold">User Type</div>
          <div className="column column-title bold">Offers Within 30 Days</div>
          <div className="column column-title bold">Areas of Business</div>
          <div className="column column-title bold">Scorecard</div>
          <div className="column" />
        </div>
        {sponsorships.length > 0
          ? sponsorships?.map((sponsorship) => (
              <div
                className="fila"
                onClick={() =>
                  history.push(`/${roleActive}/sponsor-member/${sponsorship.idUser}`)
                }
              >
                <div className="column">{sponsorship.names}</div>
                <div className="column">{sponsorship.userType}</div>
                <div className="column">{sponsorship.offerWithin30}</div>
                <div className="column">
                  {sponsorship.areaBusiness?.map((code) => {
                    return sponsorship.areaBusiness.length > 1 ? (
                      <span className="spcode">{code}</span>
                    ) : (
                      <span>{code}</span>
                    );
                  })}
                </div>
                <div className="column">{sponsorship.ratting}</div>
                <div className="column">{'>'}</div>
              </div>
            ))
          : ''}
      </div>
    );
  };

  const SponsoredList = () => {
    return (
      <div className="membersList">
        <div className="container-utils">
          <p className="message">
            <span className="font-weight-bold">Users worth sponsoring.</span> Check out the OMB
            Members recommended for you below and sponsor their membership with one click. Your logo
            and message will appear on their dashboard for the duration of the sponsored membership.
          </p>
        </div>
        <div className="container-list">
          <Card title="Sponsorship Opportunities">
            {sponsorships.length > 0 ? (
              <ListData />
            ) : (
              <h4 className="ml-3 mt-3">
                There are no Sponsorship Opportunities available yet. Check back soon!
              </h4>
            )}
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div className="sponsorsOportunities">
      {loading ? <Loading /> : sponsorships.length >= 0 ? <SponsoredList /> : null}
    </div>
  );
};

export default SponsorOportunities;
