import { domainServer } from '../connection/connection';

export function upload(file, idProperty = null) {
  console.log('file', file)
  const token = localStorage.getItem('tokenOMB');
  return new Promise((resolve, reject) => {
    var data = new FormData();
    data.append('upload', file);
    if(!!idProperty){
      data.append('idProperty', idProperty);
    }
    console.log('data', data)
    fetch(domainServer + '/api/file/uploadImagesProperty', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
      body: data,
    })
      .then((response) => {
        console.log('res de upload image', response);
        if (response.ok) {
          return response.json();
        } else {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .then((res) => {
        console.log('res', res)
        resolve(res);
      })
      .catch((err) => {
        reject(err.message);
      });
  });
}
