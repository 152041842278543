import React from 'react';
import { useSelector } from 'react-redux';
import './styles.css';

const ItemMessage = ({ message }) => {
  const {
    info: { id: userId },
  } = useSelector((store) => store.user);

  let sender = '';

  if (userId === message.user) {
    sender = 'Me';
  } else {
    if (message.roles === 'ROLE_BUYER_AGENT') {
      sender = 'Buyer Agent';
    } else if (message.roles === 'ROLE_BUYER') {
      sender = 'Buyer';
    } else if (message.roles === 'ROLE_LISTING_AGENT') {
      sender = 'Listing Agent';
    }
  }

  return (
    <div>
      <span
        className={
          message.user === userId ? 'chat-message-right title-msg' : 'chat-message-left title-msg'
        }
      >
        {sender}
      </span>
      <div className={message.user === userId ? 'chat-message-right' : 'chat-message-left'}>
        <p className={message.user === userId ? 'message-right' : 'message-left'}>
          {message.messages}
        </p>
      </div>
    </div>
  );
};

export default ItemMessage;
