import React, { useEffect, useState } from 'react';
import { Card, Button, ModalMessage } from '../../components/OMB';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { getApi, postApi, LIST_BUYERS_AGENTS, UPDATE_SPONSOR_OP } from '../../utils/services';
import { useHistory } from 'react-router-dom';
import './styles.css';

const FormSchema = Yup.object().shape({
  user: Yup.string().required('Required'),
  userType: Yup.string().required('Required'),
});

const AddSponsorshipForm = (props) => {
  const userToken = localStorage.getItem('tokenOMB');
  const [buyersAgents, setBuyersAgents] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userTypeSelected, setUserTypeSelected] = useState('');
  const [users, setUsers] = useState([]);
  const [showCheck, setShowCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const history = useHistory();

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(LIST_BUYERS_AGENTS, userToken)
      .then((response) => {
        setBuyersAgents(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    setRoles([...new Set(buyersAgents.map(x => x.roles))]);
    console.log('====================================');
    console.log(buyersAgents);
    console.log('====================================');
  }, [buyersAgents]);

  useEffect(() => {
    if (!!userTypeSelected) {
      const data = buyersAgents.filter(item => item.roles === userTypeSelected);
      setUsers(data);
    } else {
      setUsers([])
    }
  }, [userTypeSelected]);

  const handleChange = (value) => {
    setUserTypeSelected(value)
  }

  const handleSubmit = ({ user }) => {
    const dataForm = {
      "id": parseInt(user),
      "status": 1
    };

    postApi(UPDATE_SPONSOR_OP, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          history.goBack();
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  }
 useEffect(() => {
   console.log('====================================');
   console.log(users);
   console.log('====================================');
 }, [users])
  const initValues = {
    user: '',
    userType: ''
  }

  return (
    <div className="add-sponsorship-form">
      <Formik
        initialValues={initValues}
        validationSchema={FormSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, touched, values }) => (
          <Form>
            <Card title="Add Sponsorship Opportunities">
              <div className="form-row cols-3">
                <div className="form-group">
                  <label className="form-label">User Type</label>
                  <Field className="form-control" as="select" name="userType" fieldinputprops={{ onChange: setTimeout(() => handleChange(values.userType), 0) }}>
                    <option value={''}>Select</option>
                    {
                      roles.map((item, index) => (
                        <option value={item} key={`${item}-${index}`}>{item}</option>
                      ))
                    }
                  </Field>
                  {errors.userType && touched.userType && (
                    <div className="error">{errors.userType}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <Field className="form-control" as="select" name="user" >
                    <option value={''}>Select</option>
                    {
                      users.map((item) => (
                        <option value={item.idUser} key={`${item.names}-${item.idUser}`}>{item.names} {`(${item.ratting})`}</option>
                      ))
                    }
                  </Field>
                  {errors.user && touched.user && (
                    <div className="error">{errors.user}</div>
                  )}
                </div>
              </div>
            </Card>
            <div className="btn-container">
              <Button color="yellow" type="submit">
                SAVE CHANGES
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {showCheck && <ModalMessage title="Saved Sponsorship" message="The Sponsorship has been saved successfully." btnTitle="Return to Sponsorship Oportunities" redirect="true" />}
      {showError && <ModalMessage title="Error Saving The Sponsorship" message="The Sponsorship could not be saved. Please review your information and try again." />}
    </div>
  );
};

export default AddSponsorshipForm;
