import React from "react";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import './styles.css'

const DatePickerField = (props) => {
  return (
    <div className="date-picker-field form-control">
      <DatePicker
        {...props}
        dateFormat={!!props.dateFormat ? props.dateFormat : "MMMM d, yyyy"}
      />
    </div>
  )
}

export default DatePickerField;