import React, { useState, useEffect } from 'react';
import { Card, Loading, EmptyList, DTable, Button } from "../../../components/OMB";
import { getApi, LIST_MEMBERSHIPS } from '../../../utils/services';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "./styles.css"

const SponsoredMemberships = ({ userId }) => {
  const userToken = localStorage.getItem('tokenOMB');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [sponsoredMemberships, setSponsoredMemberships] = useState([]);
  const { roleActive } = useSelector((store) => store.user);
  const history = useHistory();

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(`${LIST_MEMBERSHIPS}${userId}`, userToken)
      .then((response) => {
        setSponsoredMemberships(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    const dataMemberships = sponsoredMemberships.map(({ id, membershipCode, membershipName, initiated, expires, restain, status }) => {
      return {
        id,
        membershipCode,
        membershipName,
        initiated,
        expires,
        restain,
        status
      }
    })
    setData(dataMemberships);
  }, [sponsoredMemberships]);

  const columns = [
    {
      name: 'Membership Code',
      selector: 'membershipCode',
    },
    {
      name: 'Member Name',
      selector: 'membershipName',
    },
    {
      name: 'Initiated',
      selector: 'initiated',
    },
    {
      name: 'Expires',
      cell: ({ status, expires, restain }) =>
        !!status && (
          <div>
            <div
              className={`circle-status ${
                status === 'warning'
                  ? 'warning'
                  : status === 'enabled'
                  ? 'enabled'
                  : status === 'disabled'
                  ? 'disabled'
                  : ''
              }`}
            />
            {expires} ({restain} days left)
          </div>
        ),
    },
    {
      selector: 'icon',
      cell: ({ id, status, restain }) =>
        !!status &&
        parseInt(restain) <= 60 && (
          <Button
            onClick={() => history.push(`/${roleActive}/sponsor-member/${id}`)}
            color="light-blue"
          >
            RENEW
          </Button>
        ),
      width: '134px',
    },
  ];

  return (
    <Card title="User’s Sponsored Memberships" className="sponsored-memberships-card">
      {loading ?
        <Loading />
        : sponsoredMemberships.length === 0
          ? <EmptyList />
          : (
            <DTable columns={columns} data={data} />
          )
      }
    </Card>
  );
};

export default SponsoredMemberships;

