import React from 'react';
import { AdminsList } from "../../../containers";
import { Button } from "../../../components/OMB";
import { useSelector } from 'react-redux';
import './styles.css';

const Admins = () => {
  const { roleActive } = useSelector((store) => store.user);

  return (
    <div className="admins">
      <div className="btn-container">
        <Button
          color="light-blue"
          to={`/${roleActive}/admin`}
        >
          + Add New
        </Button>
      </div>
      <AdminsList />
    </div>
  )
}

export default Admins;
