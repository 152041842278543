import React from 'react';
import './styles.css';

const PropertyMyOffer = () => {
  return (
    <div className="property-my-offer">
      <div class="header-bulk">
        <p class="title">1234 NW 15th St, Apt 4-C</p>
      </div>
      <div class="property-cards">
        <div className="gen-box-br">
          {' '}
          <div className="ent-details">
            <p className="title-card-line">ENTITY DETAILS</p>
          </div>
          <div className="prop-my-off">
            <div className="head-card">
              <div className="head-title-card">Entity Name</div>
              <div className="head-ttitle-card">Housex Pro</div>
            </div>
            <div>
              <div className="head-title-card">Authorized Signer Full Name</div>
              <div className="head-ttitle-card">Jonathan Taylor Appleseed</div>
            </div>
            <div>
              <div className="head-title-card">Phone Number</div>
              <div className="head-ttitle-card">000-123-4567</div>
            </div>
            <div>
              <div className="head-title-card">Email Address</div>
              <div className="head-ttitle-card">johnsapple@mail.com</div>
            </div>
          </div>
          <div className="prop-my-bot">
            <div className="m-box-add">
              <div className="head-title-card">Mailing Address</div>
              <div className="head-ttitle-card">1123 NW 35th Ave, Apt 2C, Doral, FL, 33054</div>
            </div>
            <div className="item-bot">
              <div className="ct-box-add">
                <div className="head-title-card">City</div>
                <div className="head-ttitle-card">Doral</div>
              </div>
              <div className="st-box-add">
                <div className="head-title-card">State</div>
                <div className="head-ttitle-card">FL</div>
              </div>
              <div className="zp-box-add">
                <div className="head-title-card">Zip code</div>
                <div className="head-ttitle-card">33050</div>
              </div>
            </div>
          </div>
        </div>

        <div className="gen-box-br">
          <div className="box-p-det">
            <p className="pay-tt">PAYMENT DETAILS</p>
          </div>
          <div className="offert-am-det">
            <div className="off-am-a">
              <div className="head-title-card">Offer Amount</div>
              <div className="head-ttitle-card">$45000</div>
            </div>
            <div className="off-am-b">
              <div className="head-title-card">EMD (Deposit) Amount</div>
              <div className="head-ttitle-card">$45000</div>
            </div>
            <div className="off-am-c">
              <div className="head-title-card">Funding</div>
              <div className="head-ttitle-card">Cash</div>
            </div>
          </div>
          <div className="box-req-doc-tt">
            <p className="req-doc-tt">REQUIRED DOCUMENTATION</p>
          </div>
          <div className="offert-am-det">
            <div className="req-doc-a">
              <div className="head-title-card">Articles of Incorporation</div>
              <div className="head-ttitle-card">Incorporation1.pdf</div>
            </div>
            <div className="req-doc-b">
              <div className="head-title-card">EMD (Deposit) Amount</div>
              <div className="head-ttitle-card">Op.agremment2020.pdf</div>
            </div>
            <div className="req-doc-c">
              <div className="head-title-card">Proof of Funds</div>
              <div className="head-ttitle-card">Fundproof-jonhapple.pdf</div>
            </div>
          </div>
        </div>
        <div className="gen-box-br">
          <div className="end-b">
            <p className="pay-tt">ADITIONAL TERMS</p>
          </div>
          <div className="offert-am-det">
            <div className="off-am-a">
              <div className="head-title-card">Loan Contingency</div>
              <div className="head-ttitle-card">15 days</div>
            </div>
            <div className="off-am-b">
              <div className="head-title-card">Inspection Contingency</div>
              <div className="head-ttitle-card"> 7 days</div>
            </div>
            <div className="off-am-c">
              <div className="head-title-card">Appraisal Contingency</div>
              <div className="head-ttitle-card"> 23 days</div>
            </div>
            <div className="off-am-d">
              <div className="head-title-card">Home Warranty</div>
              <div className="head-ttitle-card">$20,000 contributed by seller</div>
            </div>
          </div>
          <div>
            <p className="">Additionals notes to listing Agent About Offer</p>
          </div>
          <div className="offert-am-dett">
            <div>
              <div className="head-ttitle-card">
                Please note that my client wants to have an answer as soon as possible. Thank you!
              </div>
            </div>
            <div>
              <div className="head-title-card">Percentage of commission to Listing Agent</div>
            </div>
            <div>
              <div className="head-ttitle-card">20%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyMyOffer;
