import React from 'react';
import MemberDetailsCard from "./MemberDetailsCard";
import UsersPaymentsCard from "./UsersPaymentsCard";
import { useSelector } from "react-redux";

const MembershipDetailsCards = ({ membershipId }) => {
  const { memberships } = useSelector((store) => store.admin);
  const membership = memberships.find(item => item.idUser === membershipId);

  return (
    <>
      <MemberDetailsCard data={membership} />
      <UsersPaymentsCard payment={membership.payment} />
    </>
  )
}

export default MembershipDetailsCards;
