import React from 'react';
import { Card } from '../../../components/OMB';
import { useFormatDate } from '../../../hooks/useFormatDate';
import { useFormatPrice } from '../../../hooks/useFormatPrice';
import './styles.css';

const MemberDetailsCard = ({ data }) => {
  const { names, roles, amountMonthly, totalPayments, startDate } = data;
  const [date] = useFormatDate(startDate);
  const [monthlyPay] = useFormatPrice(amountMonthly);
  const [totalPay] = useFormatPrice(totalPayments);

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <Card
      title="Member Details"
      className="member-details"
      // btnTitle="View Member"
      // btnHandle={handleDownload}
      // btnColor="light-blue"
    >
      <div className="form-row cols-4">
        <div className="form-group">
          <label className="form-label">Name</label>
          <input className="form-control" type="text" value={names} disabled />
        </div>
        <div className="form-group">
          <label className="form-label">User Type</label>
          <input className="form-control" type="text" value={roles} disabled />
        </div>
        <div className="form-group">
          <label className="form-label">Monthly Payment</label>
          <input className="form-control" type="text" value={monthlyPay} disabled />
        </div>
        <div className="form-group">
          <label className="form-label">Total Payments</label>
          <input className="form-control" type="text" value={totalPay} disabled />
        </div>
      </div>
      <div className="member-details-info">
        <p>
          <span className="bold">Start date:</span> {date}
        </p>
      </div>
    </Card>
  );
};

export default MemberDetailsCard;
