import React from 'react';
import { GoBack } from '../../../components/OMB';
import { PropertyList } from '../../../containers';
import { useOffersProperties } from "../../../hooks/useOffersProperties";

const Offers = (props) => {
  const [offersProperties] = useOffersProperties();

  return (
    <div className="offers-list">
      <GoBack {...props} />
      <PropertyList contentType="DOS" properties={offersProperties} myOffers={true} />
    </div>
  )
}

export default Offers;
