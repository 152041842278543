import React, { useState, useEffect } from 'react';
import { Card, Switch, Button, ModalMessage, StripeForm } from '../../components/OMB';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import './styles.css';
import { Spinner } from 'react-bootstrap';
import { CODE_DISCOUNT, getApi, postApi, STRIPE_SAVE } from '../../utils/services';
import { useSelector } from 'react-redux';
import { set_membership } from '../../redux/userDucks';
import { useDispatch } from 'react-redux';
import logoTransparent from '../../assets/img/logo_transparent_blue.png';

const MakePaymentContainer = () => {
  const PaymentSchema = Yup.object().shape({
    subscriptionId: Yup.string().required('Required'),
  });
  const [paymentStripeId, setPaymentStripeId] = useState(null);
  const [messageModal, setMessageModal] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [paymentSubmit, setPaymentSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const { roleActive, info } = useSelector((store) => store.user);
  const { id: userId, profile } = info;
  const { userCodeReferral } = profile;
  const userToken = localStorage.getItem('tokenOMB');
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [codeDiscount, setCodeDiscount] = useState(null);
  const [errorCodeDiscount, setErrorCodeDiscount] = useState(null);
  const [loadingDiscount, setLoadingDiscount] = useState(false);

  const [percentageRecipient, setPercentageRecipient] = useState(
    info?.profile?.userCodeReferral?.percentage?.percentageRecipient,
  );
  const dispatch = useDispatch();
  //console.log(info);
  useEffect(() => {
    if (!!messageModal) {
      setShowModal(true);
    }
  }, [messageModal]);

  useEffect(() => {
    // console.log('Cambio el paymentID');
    if (!!paymentStripeId) {
      setLoading(true);
      const data = {
        userId: userId,
        stripeToken: paymentStripeId,
        subscriptionId: subscriptionId,
      };
      postApi(STRIPE_SAVE, data, userToken)
        .then((response) => {
          // console.log(response);
          setLoading(false);
          if (response.status === 200) {
            setMessageModal('You will be redirected to your dashboard.');
            setTimeout(() => {
              dispatch(set_membership(true));
              window.location.href =
                roleActive === 'ROLE_BUYER_AGENT'
                  ? `/${roleActive}/search-listings`
                  : `/${roleActive}/search-listings`;
            }, 1500);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log('Error');
          }
        });
    }
  }, [paymentStripeId, subscriptionId]);
  const calcMonthOrigin = 99 - (parseInt(percentageRecipient) / 100) * 99;
  const calcMonth = calcMonthOrigin.toFixed(2);
  
  useEffect(() => {
    if (!!codeDiscount && codeDiscount.length >= 9) {
      setErrorCodeDiscount(null)
      calcCodeDiscount();
    }
  }, [codeDiscount]);

  const calcCodeDiscount = () => {
    setLoadingDiscount(true);
    getApi(`${CODE_DISCOUNT}${codeDiscount}`, userToken)
      .then((response) => {
        setLoadingDiscount(false);

        if (response.data) {
          setPercentageRecipient(response.data.percentageRecipient);
        }
        if (response.status !== 200) {
          setErrorCodeDiscount('Code Incorrect, try again.');
        }
      })
      .catch((error) => {
        setLoadingDiscount(false);
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  /* useEffect(() => {
    console.log('percentageRecipient', percentageRecipient);
  }, [percentageRecipient]); */
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          subscriptionId: '1',
        }}
        validationSchema={PaymentSchema}
        onSubmit={(values) => {
          //console.log('paymentStripeID', paymentStripeId);
          setLoading(true);
          setPaymentSubmit(true);
          // console.log(values);
          if (!!values.subscriptionId) {
            setSubscriptionId(values.subscriptionId);
          }
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <section className="make-payment-container">
              <section className="head">
                <h1 className="bold title-head">Make Payment</h1>
                <h2 className="sub">
                  Fill out the payment information below to start using all of the exclusive OMB
                  features.
                </h2>
              </section>
              <Card title="Choose Your Membership">
                <div className="row">
                    <div className="d-flex align-items-center">
                      {/* <div className="col-md-6">
                        <label htmlFor="subscription-id-3">
                          <Field
                            type={'radio'}
                            id={`subscription-id-3`}
                            label={`10 Day Free Trial`}
                            name="subscriptionId"
                            value="3"
                            className="input-radio"
                          />
                          <span className="bold ml-2">10 Day Free Trial*</span>
                        </label>
                      </div> */}
                      <div className="col-md-4">
                        <label htmlFor="subscription-id-1">
                          <Field
                            type={'radio'}
                            id={`subscription-id-1`}
                            label={`Monthly Plan ($99/mo)`}
                            name="subscriptionId"
                            value="1"
                            className="input-radio"
                          />
                          <span className="bold ml-2">
                            Monthly Plan
                            {!!info.profile.userCodeReferral || !!percentageRecipient ? `($${calcMonth}/mo)` : '($99/mo)'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="subscription-id-2">
                          <Field
                            type={'radio'}
                            id={`subscription-id-2`}
                            label={`Monthly Plan ($99/mo)`}
                            name="subscriptionId"
                            value="2"
                            className="input-radio"
                          />
                          <span className="bold ml-2">
                            Yearly Plan
                            {!!info.profile.userCodeReferral || !!percentageRecipient
                              ? `($${parseInt(995 - (percentageRecipient / 100) * 995).toFixed(
                                  2,
                                )}/year)`
                              : '($995/year)'}
                          </span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div className="form-group mb-0 d-flex align-items-center">
                          <label className="demi">Referral Code</label>
                          <input
                            className="input-form"
                            type="text"
                            readOnly={!!userCodeReferral?.code ? true : false}
                            disabled={!!userCodeReferral?.code ? true : false}
                            value={!!userCodeReferral?.code ? userCodeReferral?.code : null}
                            placeholder="Referral Code"
                            onChange={(e) => setCodeDiscount(e.target.value)}
                          />
                          {loadingDiscount ? (
                            <span class="badge badge-warning p-2 ml-2">
                              <Spinner animation="border" role="status" size="sm" className="mr-2" /> Consulting code.
                            </span>
                          ) : (
                            ''
                          )}
                          {!!percentageRecipient || !!errorCodeDiscount ? (
                            <span class="badge badge-info p-2 ml-2">
                              {!!errorCodeDiscount
                                ? errorCodeDiscount
                                : `${percentageRecipient}% Discount`}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    {errors.subscriptionId && touched.subscriptionId ? (
                      <div className="error">{errors.subscriptionId}</div>
                    ) : null}
                  </div>
              </Card>
              <Card title="Payment Information">
                <StripeForm
                  setPaymentStripeId={setPaymentStripeId}
                  typePayment="subscription"
                  paymentSubmit={paymentSubmit}
                  setMessageModal={setMessageModal}
                  setLoading={setLoading}
                />
              </Card>

              <div className="btn-container d-flex justify-content-center">
                <Button color="yellow" type="submit" disabled={loadingDiscount || loading ? true : false}>
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  ) : null}
                  MAKE PAYMENT
                </Button>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      {showModal ? (
        <ModalMessage
          title="Payment Successful"
          message={messageModal}
          type="message"
          hideBtn={true}
        />
      ) : null}
    </>
  );
};

export default MakePaymentContainer;
