import React from 'react';
import { AccountingTabs } from "../../../containers";
import './styles.css';

const Accounting = () => {
  return (
    <div className="accounting">
      <AccountingTabs/>
    </div>
  );
};

export default Accounting;
