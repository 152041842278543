import React, { useState, useEffect } from 'react';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { Button } from '../../OMB';
import './styles.css';

const StripeForm = ({ amount, setPaymentStripeId, paymentSubmit, setMessageModal, typePayment = 'sponsor', setLoading}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardName, setCardName] = useState('');
  const [primaryAddress, setPrimaryAddress] = useState('');
  const [secondaryAddress, setSecondaryAddress] = useState('');

  useEffect(() => {
    // console.log(paymentSubmit);
    if (paymentSubmit === true) {
      handleSubmit();
    }
  }, [paymentSubmit]);
  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }

      if (typePayment === 'subscription'){
        console.log('creamos el token subscription?');
        stripe.createToken(elements.getElement(CardNumberElement)).then(result => {
          if (result.error) {
            setLoading(false);
            console.log('error',result.error.message);
            const error = result.error.message;
            setMessageModal(error)
          } else {
            setLoading(false);
            const token = result.token.id;
            console.log('token',token);
            setPaymentStripeId(token);
          }
        console.log('terminamos el token')
      });
      }else {
        const payload = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        });
        console.log('Info', { amount, cardName, primaryAddress, secondaryAddress });
        console.log('[PaymentMethod]', payload.error);
        if (payload.error) {
          setMessageModal(payload.error.message);
        }
        if (payload.paymentMethod) {
          console.log('IdPayment', payload.paymentMethod.id);
          localStorage.removeItem('paymentStripeId');
          setPaymentStripeId(payload.paymentMethod.id);
        }
      }
    }

  return (
    <div className="checkout-wrapper">
      <div className="personal-form">
        <div className="form-group">
          <label className="demi">Cardholder name</label>
          <input
            className="input-form"
            type="text"
            placeholder="Cardholder Name"
            onChange={(e) => setCardName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="demi">Billing Address</label>
          <input
            className="input-form"
            type="text"
            placeholder="Address"
            onChange={(e) => setPrimaryAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label className="demi">Billing Address 2</label>
          <input
            className="input-form"
            type="text"
            placeholder="Address 2"
            onChange={(e) => setSecondaryAddress(e.target.value)}
          />
        </div>
      </div>
      <div className="checkout-form">
        <div className="form-group">
          <label className="demi">Card Number</label>
          <CardNumberElement />
        </div>
        <div className="form-group">
          <label className="demi">Expiration Date</label>
          <CardExpiryElement />
        </div>
        <div className="form-group">
          <label className="demi">CVC</label>
          <CardCvcElement />
        </div>
      </div>
      {/*  <Button color="yellow" disabled={!stripe} type="submit">
        Pay
      </Button> */}
    </div>
  );
};

export default StripeForm;
