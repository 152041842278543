import React from 'react';
import { PropertyList } from '../..';
import { Card } from '../../../components/OMB';
import { useOffersProperties } from "../../../hooks/useOffersProperties";

const Offers = ({ userId }) => {
  const [offersProperties] = useOffersProperties(userId);

  return (
    <Card title="User's Offers">
      <PropertyList contentType="DOS" properties={offersProperties} myOffers={true} />
    </Card>
  );
};

export default Offers;
