import React from 'react';
import { useFormatDate } from "../../../../hooks/useFormatDate";
import { useFormatPrice } from "../../../../hooks/useFormatPrice";

const PropertyOffer = ({ data }) => {
  const [offerDate] = useFormatDate(data.offerDate);
  const [formattedAmount] = useFormatPrice(data.amount);

  return (
    <div className="property-offer">
      <div className="offer-row">
        <div className="info-columm">
          <span>
            <strong>Date</strong>
          </span>
        </div>
        <div className="info-columm">
          <span>
            <strong>Buyer type</strong>
          </span>
        </div>
        <div className="info-columm">
          <span>
            <strong>Name</strong>
          </span>
        </div>
        <div className="info-columm">
          <span>
            <strong>Amount</strong>
          </span>
        </div>
        <div className="info-columm">
          <span>
            <strong>Funding</strong>
          </span>
        </div>
      </div>
      <div className="offer-row">
        <div className="info-columm">
          <span>{offerDate}</span>
        </div>
        <div className="info-columm">
          <span>{data.buyerType.name}</span>
        </div>
        <div className="info-columm">
          <span>{data.buyerType.name === 'ENTITY' ? `${data.entityName}` : data.name && `${data.name}`} {data.lastName && `${data.lastName}`}</span>
        </div>
        <div className="info-columm">
          <span>{formattedAmount}</span>
        </div>
        <div className="info-columm">
          <span>{data.funding.name}</span>
        </div>
      </div>
    </div>
  )
}

export default PropertyOffer;