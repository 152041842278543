import React from 'react';
import { useFormatDate } from '../../../../hooks/useFormatDate';


const PropertyInfo = ({ data, type = null }) => {
  const { userInfo, statusProperty, approvedDate } = data;
  // console.log('data property', statusProperty)
  const {createdDate} = statusProperty[0];
  const [created] = useFormatDate(createdDate);
  const listingAgentName = `${userInfo?.firstName} ${userInfo?.lastName}`;
  const [marketDate] = useFormatDate(approvedDate);

  // console.log(marketDate)
  return (
    <>
      <div className="property-info">
        <div className="info-columm">
          <div className="info-row bt-0">
            <span className="bold">Type</span>
            <span>{data.typeProperty?.name}</span>
          </div>
         
        </div>
        <div className="info-columm">
           <div className="info-row bt-0">
            <span className="bold">Date Created</span>
            <span>{created}</span>
          </div>
        </div>
      </div>
      <div className="property-info">
        <div className="info-columm">
          <div className="info-row">
            <span className="bold">Beds</span>
            <span>{data.bed}</span>
          </div>
          <div className="info-row">
            <span className="bold">Baths</span>
            <span>{data.bath}</span>
          </div>
          <div className="info-row">
            <span className="bold">Square Feet</span>
            <span>{data.squareFeet}</span>
          </div>
          {type === 'admin' ? (
            <div className="info-row">
              <span className="bold">Listing Agent</span>
              <span>{!!listingAgentName ? listingAgentName : ''}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="info-columm">
          <div className="info-row">
            <span className="bold">Lot Size</span>
            <span>{data.lot_size}</span>
          </div>
          <div className="info-row">
            <span className="bold">Year built</span>
            <span>{data.year_built}</span>
          </div>
          <div className="info-row firt-letter">
            <span className="bold">Occupancy</span>
            <span>{data.occupancy}</span>
          </div>
          {type === 'admin' ? (
            <div className="info-row">
              <span className="bold">Last Market Date</span>
              <span>{!!marketDate ? marketDate : ''}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  );
};

export default PropertyInfo;
