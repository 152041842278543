import React from 'react';
import { Box, GoBack, Button } from '../../../components/OMB';
import './help.css';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';

const PropertySchema = Yup.object().shape({
  // street: Yup.string().required('Required'),
  // recive: Yup.string().required('Required'),
  // competitive: Yup.string().required('Required'),
  // forward: Yup.string().required('Required'),
  // explain: Yup.string().required('Required'),
  // transactions: Yup.string().required('Required'),
});

const HelpUs = (props) => {
  const [purchased, setPurchased] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const user = useSelector((store) => store.user);

  return (
    <React.Fragment>
      <GoBack {...props} />
      <Box title="Help Us Improve">
        <div className="help-box">
          <p className="bold">
            Thank you for your continued support of the OMB marketplace. At OMB, we are always
            trying to improve our platform, enhance client services, and increase our users’
            transaction volume. If possible, please help us make our platform better by answering
            these short questions:
          </p>
          <div className="row">
            <div className="col-md-6">
              <Formik
                initialValues={{
                  recive: '',
                  competitive: '',
                  forward: '',
                  explain: '',
                  transactions: '',
                }}
                validationSchema={PropertySchema}
                onSubmit={(values) => {
                  console.log('here');
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <ul className="list">
                      <li className="bold">
                        Did you receive offers from OMB users on this property?
                      </li>
                    </ul>
                    <div className="options">
                      <Field className=" radio" type="radio" name="recive" value="yes" />
                      <label className="label">Yes</label>
                      <Field className="radio" type="radio" name="recive" value="no" />
                      <label className="label">No</label>
                      {errors.recive && touched.recive ? (
                        <div className="error">{errors.recive}</div>
                      ) : null}
                    </div>

                    {/* option */}
                    <ul className="list">
                      <li className="bold">Were the offers received realistic and competitive?</li>
                    </ul>
                    <div className="options">
                      <Field className="radio" type="radio" name="competitive" value="yes" />

                      <label className="label">Yes</label>
                      <Field className="radio" type="radio" name="competitive" value="no" />

                      <label className="label">No</label>
                      {errors.competitive && touched.competitive ? (
                        <div className="error">{errors.competitive}</div>
                      ) : null}
                    </div>

                    {/* option */}
                    <ul className="list">
                      <li className="bold">
                        Was there an offer you wanted to move forward with but didn’t?
                      </li>
                    </ul>
                    <div className="options">
                      <Field className="radio" type="radio" name="forward" value="yes" />
                      <label className="label">Yes</label>
                      <Field className="radio" type="radio" name="forward" value="no" />

                      <label className="label">No</label>
                      {errors.forward && touched.forward ? (
                        <div className="error">{errors.forward}</div>
                      ) : null}
                    </div>

                    {/* option */}
                    <p className="bold">If not, briefly explain why.</p>
                    <Field className="form-control" type="text" as="textarea" name="explain" />
                    {errors.explain && touched.explain ? (
                      <div className="error">{errors.explain}</div>
                    ) : null}
                    {/* option */}
                    <ul className="list">
                      <li className="bold">
                        What would make you more comfortable working with OMB users on more of your
                        transactions?
                      </li>
                    </ul>
                    <Field className="form-control" type="text" as="textarea" name="transactions" />
                    {errors.transactions && touched.transactions ? (
                      <div className="error">{errors.transactions}</div>
                    ) : null}
                    <div className="buttons">
                      <Button
                        type="submit"
                        className="skip"
                        onClick={() => {
                          window.location.href =
                            '/' + user.roleActive + '/offers/mark-as-purchased/';
                        }}
                      >
                        Skip
                      </Button>
                      <Button type="submit" color="yellow">
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Box>
    </React.Fragment>
  );
};

export default HelpUs;
