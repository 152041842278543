import React from 'react';
import { GoBack } from "../../components/OMB";
import { OfferDetailsCards } from "../../containers";

const PropertyOfferDetails = (props) => {
  const propertyId = props.match.params.id;
  return (
    <div className="property-offer-details">
      <GoBack {...props} />
      <OfferDetailsCards propertyId={propertyId} />
    </div>
  )
}

export default PropertyOfferDetails;