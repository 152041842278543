import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listing_properties } from '../redux/PropertyDucks';

export const usePropertyListUser = (userId = null) => {
  const { user } = useSelector((store) => store);
  const { listProperties } = useSelector((store) => store.property);
  const dispatch = useDispatch();
  const id = userId || user?.info?.id;

  useEffect(() => {
    dispatch(listing_properties(id));
  }, []);

  return [listProperties];
};
