import React from 'react';
import { GoBack } from '../../../components/OMB';
import { AddAdminForm } from "../../../containers";
import './styles.css';

const AddAdmin = (props) => {
  const { id: adminId } = props.match.params;

  return (
    <div className="add-admin">
      <GoBack {...props} />
      <AddAdminForm adminId={adminId} />
    </div>
  )
}

export default AddAdmin;
