const hostName = window.location.hostname;
let destine = 'app-omb'
// if (hostName !== 'admin.offmarketbroker.com') {
//   destine = 'app-omb-test'
// }
// const URL = `https://offmarketbroker.com:8445/${destine}/api/`;
const URL = `https://offmarketbroker.com:8445/api/`;
// const URL = `http://localhost:8080/app-omb/api/`;
const LIST_MEMBERSHIPS = `${URL}sponsor/membership/`;
const LIST_SPONSORSHIPS = `${URL}sponsor/listSponsorships`;
const LIST_COUPONS_DISCOUNTS = `${URL}userCoupon/user/`;
const LIST_SPONSORED_OPORTUNITIES = `${URL}sponsor/listOp`;
const SAVE_MEMBERSHIPS = `${URL}sponsor/save`;
const SAVE_COUPONS = `${URL}coupon/save`;
const UPLOAD_IMAGE = `${URL}file/upload`;
const DELETE_COUPON = `${URL}coupon/delete/`;
const EDIT_COUPON = `${URL}coupon/update`;
const GET_UTILS = `${URL}utils/list`;
const GET_USER = `${URL}user/`;
const FIND_COUPON = `${URL}coupon/`;
const REDEEM_COUPON = `${URL}userCoupon/save`;
const SAVE_INVITE = `${URL}invite/save`;
const LIST_USER_COUPONS = `${URL}userCoupon/user/`;
const LIST_USER_COUPONSR = `${URL}userCoupon/userR/`;
const PROFILE_UPDATE = `${URL}profile/update`;
const OFFER_PROPERTY = `${URL}offer/offerProperty/`;
const LIST_OFFERS_PROPERTY = `${URL}offer/offerProperty/`;
const LIST_MESSAGES = `${URL}message/`;
const LIST_MESSAGES_BUYERS = `${URL}message/buyer/`;
const GET_CHAT = `${URL}message/conversation/`;
const GET_OFFER_DETAILS = `${URL}offer/`;
const VIEW_OFFER = `${URL}offer/viewOffer/`;
const STATUS_PROPERTY = `${URL}statusProperty/save`;
const LIST_INVITE = `${URL}invite/`;
const POINTS = `${URL}user/point/`;
const LISTING_OFFERS = `${URL}offer/offerPropertyUser/`;
const BUYERS_OFFERS = `${URL}offer/list/`;
const DOWNLOAD_CSV = `${URL}file/downloadCsv`;
const UPLOAD_CSV = `${URL}file/uploadExcel`;
const SAVE_OFFERS = `${URL}offer/save`;
const SEND_MESSAGE = `${URL}message/save`;
const SAVE_LIKE = `${URL}property/save/like`;
const IMAGE_SPONSOR = `${URL}user/membershipSponsor/`;
const SEARCH_BUYER_AGENT = `${URL}user/validateBA`;
const PURCHASE_AREA_SAVE = `${URL}purchaseArea/save`;
const SEND_MESSAGE_CHAT = `${URL}message/response`;
const PROPERTY_INFO = `${URL}property/`;
const PROPERTY_EDIT = `${URL}property/update`;
const LIST_ADMINS = `${URL}user/usersAdmin`;
const LIST_BUYERS_AGENTS = `${URL}user/listBA`;
const LIST_BUYERS_LISTINGS = `${URL}user/listBuyersListing`;
const UPDATE_SPONSOR_OP = `${URL}sponsor/updateSponsorOp`;
const STRIPE_SAVE = `${URL}membership/stripe/save`;
const STRIPE_CANCEL = `${URL}membership/stripe/cancel`;
const LIST_DRAFT = `${URL}offer/listDraft/`;
const LIST_REFERRAL = `${URL}user/listReferral`;
const ADD_REFERRAL = `${URL}user/addReferral`;
const LIST_PAYMENTS = `${URL}user/listPayments`;
const FORGOT = `${URL}user/forgot/`;
const LIST_ALL_USERS = `${URL}user/listAllInfoUsers`;
const RECOVER = `${URL}user/recover`;
const USER_SAVE = `${URL}user/save`;
const ACCOUNTING_SAVE = `${URL}accounting/save`;
const EXPENSE_SAVE = `${URL}expense/save`;
const LIST_ADDRESS = `${URL}user/listAddress`;
const LIST_EXPENSE = `${URL}expense/list`;
const ACCOUNTING_MANAGEMENT = `${URL}accounting/management`;
const CONVERSATION_DELETE = `${URL}message/conversation/delete/`;
const DELETE_BUYER_PROFILE = `${URL}profile/delete/`;
const REFFERRAL_DETAILS = `${URL}user/listReferralSuper/`;
const FIND_CODE = `${URL}user/findUcode/`;
const ADD_POINT_PURCHASE = `${URL}user/`;
const CONVERSATION_VIEWED = `${URL}message/conversation/viewed/`;
const ADD_NEW_USER_ROLE = `${URL}user/addnewUserRole`;
const SPONSOR_OPPORTUNITIES = `${URL}sponsor/sponsorOpportunities`;
const UPDATE_WHOLESALER = `${URL}profile/update/wholesaler`;
const USERS_LISTINGS = `${URL}user/usersListing`;
const SAVE_ASSIGNED_MEMBER = `${URL}user/member/save`;
const LIST_MARKETING = `${URL}emailMarketing/list/`;
const CODE_DISCOUNT = `${URL}user/code/`;
const SAVE_EMAIL_MARKETING = `${URL}emailMarketing/send`;
const EDIT_EMAIL_MARKETING = `${URL}emailMarketing/save`;
const DELETE_EMAIL_MARKETING = `${URL}emailMarketing/delete/`;
const DELETE_USERS_REFERRAL_CODE = `${URL}referralCode/delete/`;
const PROFILE_PURCHASE_AREA = `${URL}purchaseArea/profile/save`;
const STATUS_APROVE_PROPERTY = `${URL}property/status/approved/`;
const PROPERTY_PENDING = `${URL}property/pending/list/all/page`;

export {
  LIST_MEMBERSHIPS,
  LIST_SPONSORSHIPS,
  LIST_COUPONS_DISCOUNTS,
  LIST_SPONSORED_OPORTUNITIES,
  SAVE_MEMBERSHIPS,
  SAVE_COUPONS,
  UPLOAD_IMAGE,
  DELETE_COUPON,
  EDIT_COUPON,
  GET_UTILS,
  GET_USER,
  FIND_COUPON,
  REDEEM_COUPON,
  SAVE_INVITE,
  LIST_USER_COUPONS,
  PROFILE_UPDATE,
  OFFER_PROPERTY,
  LIST_OFFERS_PROPERTY,
  LIST_MESSAGES,
  LIST_MESSAGES_BUYERS,
  GET_CHAT,
  GET_OFFER_DETAILS,
  VIEW_OFFER,
  STATUS_PROPERTY,
  LIST_INVITE,
  POINTS,
  LISTING_OFFERS,
  BUYERS_OFFERS,
  DOWNLOAD_CSV,
  UPLOAD_CSV,
  SAVE_OFFERS,
  SEND_MESSAGE,
  SAVE_LIKE,
  LIST_USER_COUPONSR,
  IMAGE_SPONSOR,
  SEARCH_BUYER_AGENT,
  PURCHASE_AREA_SAVE,
  SEND_MESSAGE_CHAT,
  PROPERTY_INFO,
  PROPERTY_EDIT,
  LIST_ADMINS,
  LIST_BUYERS_AGENTS,
  LIST_BUYERS_LISTINGS,
  UPDATE_SPONSOR_OP,
  STRIPE_SAVE,
  STRIPE_CANCEL,
  LIST_DRAFT,
  LIST_REFERRAL,
  ADD_REFERRAL,
  LIST_PAYMENTS,
  FORGOT,
  LIST_ALL_USERS,
  RECOVER,
  USER_SAVE,
  ACCOUNTING_SAVE,
  EXPENSE_SAVE,
  LIST_ADDRESS,
  LIST_EXPENSE,
  ACCOUNTING_MANAGEMENT,
  CONVERSATION_DELETE,
  DELETE_BUYER_PROFILE,
  REFFERRAL_DETAILS,
  FIND_CODE,
  ADD_POINT_PURCHASE,
  CONVERSATION_VIEWED,
  ADD_NEW_USER_ROLE,
  SPONSOR_OPPORTUNITIES,
  UPDATE_WHOLESALER,
  USERS_LISTINGS,
  SAVE_ASSIGNED_MEMBER,
  LIST_MARKETING,
  CODE_DISCOUNT,
  SAVE_EMAIL_MARKETING,
  DELETE_EMAIL_MARKETING,
  DELETE_USERS_REFERRAL_CODE,
  EDIT_EMAIL_MARKETING,
  PROFILE_PURCHASE_AREA,
  STATUS_APROVE_PROPERTY,
  PROPERTY_PENDING
};
