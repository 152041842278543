import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleAddress } from '../../';
import PropertyInfo from './PropertyInfo';
import PropertyOffer from './PropertyOffer';
import './styles.css';

const Center = ({ contentType, data, myOffers = false, type = null }) => {
  const { address, property, userInfo } = data;
  const [titleAddress, setTitleAddress] = useState('');
  const {
    roleActive,
    info: {membershipStatus},
  } = useSelector((store) => store.user);

  useEffect(() => {
    if (myOffers) {
      setTitleAddress(`${property?.address?.street} ${!!property?.address?.unit && property?.address?.unit !== ',' ? ',' : ''} ${!!property?.address?.unit && property?.address?.unit !== ',' ? `unit ${property?.address?.unit},` : ''} ${property?.address?.city}, ${property?.address?.estate?.code} ${property?.address?.zipCode}`)
    } else {
      setTitleAddress(`${address?.street} ${!!address?.unit && address?.unit !== ',' ? '' : ''} ${!!address?.unit && address?.unit !== ',' ? `unit ${address?.unit},` : ''} ${!!address?.city ? `${address?.city},` : ''} ${address?.estate?.code} ${!!address?.zipCode ? address?.zipCode : ''}`)
    }
    if(membershipStatus === false && roleActive === 'ROLE_BUYER_AGENT' ||  membershipStatus === false && roleActive === 'ROLE_BUYER'){
      setTitleAddress(`${!!address?.city ? `${address?.city},` : ''} ${address?.estate?.code} ${!!address?.zipCode ? address?.zipCode : ''}`)
    }
  }, []);

  

  return (
    <div className="center">
      <TitleAddress title={titleAddress} />

      {(contentType === 'UNO' || contentType === 'TRES' || contentType === 'CUATRO') && (
        <PropertyInfo data={data} type = {type} />
      )}
      {contentType === 'DOS' && <PropertyOffer data={data} />}
    </div>
  );
};

export default Center;
