import React from 'react';
import { PropertyCard, EmptyList, Loading } from '../../components/OMB';
import { useSelector } from 'react-redux';


const PropertyList = ({
  contentType,
  properties,
  myOffers,
  loadingData = false,
  type,
  showModalMarketing,
  setShowModalMarketing,
  setPropertyAddressID,
  activeSearch,
}) => {
  const { roleActive } = useSelector((store) => store.user);
  // const propertyListPrimary = activeSearch ? properties : properties.slice(0, 10);
  const propertyListPrimary = activeSearch ? properties : properties;


  let texto;
  switch (roleActive) {
    case 'ROLE_ADMIN':
      texto = 'No Offers available. Check back soon.';
      break;
    case 'ROLE_BUYER':
    case 'ROLE_BUYERAGENT':
      texto = 'There are currently no submitted offers.';
      break;
    case 'ROLE_LISTING_AGENT':
      texto = 'There are currently no offers on your properties.';
      break;
  }

  return (
    <div className="property-list">
      {propertyListPrimary.length === 0 ? (
        <EmptyList text={contentType === 'DOS' ? texto : 'No Items.'} />
      ) : (
        
        propertyListPrimary.map((property) => {
          return (
            <PropertyCard
              key={`property-${property.id}`}
              property={property}
              contentType={contentType}
              myOffers={myOffers}
              type={type}
              setShowModalMarketing={setShowModalMarketing}
              showModalMarketing={showModalMarketing}
              setPropertyAddressID={setPropertyAddressID}
            />
            
          );
         

        })
        
      )}
    </div>
  );
};

export default PropertyList;
