import React, { useState, useEffect } from 'react';
import { Loading } from '../../components/OMB';
import { getApi, FIND_CODE, formatPrice } from '../../utils/services';
import CSInputCard from "./CSInputCard";
import CSResultsCard from "./CSResultsCard";

const CodeSearchCards = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const [data, setData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchCode, setSearchCode] = useState('');

  const callEndPoints = async () => {
    setLoading(true)
    await getApi(`${FIND_CODE}${searchCode}`, userToken)
      .then((response) => {
        if (response.status === 200) {
          const { idUser, code, name, percentageRecipient, percentageReferee } = response.data;
          const newData = [
            {
              "id": idUser,
              code,
              "assignedTo": name,
              "reward": formatPrice(percentageRecipient),
              "recipient": formatPrice(percentageReferee),
            },
          ];
          setData(newData);
        } else {
          setData([]);
        }
        setShowResults(true);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
          setLoading(false)
        }
      });
  };

  useEffect(() => {
    if (!!searchCode) {
      callEndPoints();
    }
  }, [searchCode]);

  return (
    <div className="code-search-cards">
      <CSInputCard setSearchCode={setSearchCode} />
      {loading && <Loading /> }
      {!loading && showResults && <CSResultsCard data={data} />}
    </div>
  )
}

export default CodeSearchCards;
