import React, { useState } from 'react';
import { Card, Button, Confirm, ModalMessage } from '../../../components/OMB';
import * as Yup from 'yup';
import { Formik, Form, Field, FieldArray, getIn } from 'formik';
import { useSelector } from 'react-redux';
import { postApi, SAVE_INVITE } from '../../../utils/services/';
import InviteIcon from './Icono-invite.svg';
import Invited2 from './Invited.svg';
import LogoInvited from '../../../assets/img/Logo-invite.png';
import { Spinner } from 'react-bootstrap';
import IconRemove from '../../../assets/iconos/iconoMenos.svg';
import MaskedInput from 'react-text-mask';
import './invite.css';

const ReferrralSchema = Yup.object().shape({
  referral: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().min(4, 'too short').required('Required'), // these constraints take precedence
        phoneNumber: Yup.string().required('Required'), // these constraints take precedence
        email: Yup.string().email().required('Required'), // these constraints take precedence
        type: Yup.number().required('Required'), // these constraints take precedence
      }),
    )
    .required('Must have referral') // these constraints are shown if and only if inner constraints are satisfied
    .min(1, 'Minimum of 1 referral'),
});

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const ErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <div className="error">{error}</div> : null;
    }}
  />
);

const Invite = () => {
  // const [show, setShow] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const userToken = localStorage.getItem('tokenOMB');
  const userLocal = useSelector((store) => store.user);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <section className="invite-component">
        <Card title="Referrals">
          <p className="box-text">
            Welcome to <strong>OFF MARKET BROKER</strong>, an exclusive buying and listing
            experience. <br></br>
            <br></br>At OMB, we believe in building strong, long term partnerships. As an OMB
            Member, when you share the benefits of our community with your colleagues and industry
            partners, you'll earn <strong>10% off your monthly membership</strong> when your
            referral joins OMB using your personalized referral code.<br></br>
            After you refer ten paying OMB Members, we will pay you 10% of their paid membership
            dues on a quarterly basis for the life of their membership.
            <br></br>
            <br></br>You will qualify for a<strong> complimentary membership </strong>after ten
            referrals become paying OMB Members.
          </p>

          <Formik
            initialValues={{
              referral: [
                {
                  name: '',
                  phoneNumber: '',
                  email: '',
                  type: '',
                },
              ],
            }}
            validationSchema={ReferrralSchema}
            onSubmit={(values) => {
              // dispatch(save(values));
              // console.log('values', values.referral);
              setLoading(true);
              const body = values.referral.map((r) => {
                return {
                  name: r.name,
                  phone: r.phoneNumber,
                  email: r.email,
                  status: true,
                  userCodeReferral: {
                    id: userLocal.info.userCode[0].id,
                  },
                  role: {
                    id: parseInt(r.type),
                  },
                };
              });
              // console.log('body ', body);
              // console.log('enviar', confirmSubmit);
              postApi(SAVE_INVITE, body, userToken)
                .then((response) => {
                  //const data = response.data;
                  console.log('respuesta de save coupons', response.status);
                  if (response.status === 200) {
                    setConfirmSubmit(true);
                    setLoading(false);
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    console.log(error.response.data);
                    console.log('Código:', error.response.status);
                  }
                });
            }}
          >
            {({ errors, touched, values }) => (
              <Form>
                <FieldArray
                  name="referral"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.referral.map((referral, index) => (
                        <div className="row referral" key={index}>
                          {/** both these conventions do the same */}
                          <div className="col-md-3">
                            <label>Name</label>
                            <Field className="form-control" name={`referral[${index}].name`} />
                            {errors?.referral && touched?.referral ? (
                              <>
                                <ErrorMessage name={'referral[' + index + '].name'} />
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3">
                            <label>Phone Number</label>
                            <Field
                              name={`referral[${index}].phoneNumber`}
                              render={({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={phoneNumberMask}
                                  id="phone"
                                  type="text"
                                  className={`form-control`}
                                />
                              )}
                            />
                            {errors?.referral && touched?.referral ? (
                              <>
                                <ErrorMessage name={`referral[${index}].phoneNumber`} />
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-3">
                            <>
                              <label>Email Address</label>
                              <Field className="form-control" name={`referral[${index}].email`} />
                              <ErrorMessage name={'referral[' + index + '].email'} />
                            </>
                          </div>
                          <div className=" d-flex justify-content-center align-items-center mr-3 pt-4">
                            <label htmlFor={`buyer-${index}`}>
                              <Field
                                type={'radio'}
                                id={`buyer-${index}`}
                                name={`referral[${index}].type`}
                                value="4"
                                className="radio-type"
                              />
                              Buyer
                            </label>
                          </div>

                          <div className="d-flex justify-content-center align-items-center mr-3 pt-4">
                            <label htmlFor={`buyerAgent-${index}`}>
                              <Field
                                type={'radio'}
                                id={`buyerAgent-${index}`}
                                name={`referral[${index}].type`}
                                value="3"
                                className="radio-type"
                              />
                              Buyer's Agent
                            </label>
                          </div>
                          <div className=" d-flex justify-content-center align-items-center mr-3 pt-4">
                            <ErrorMessage name={'referral[' + index + '].type'} />
                          </div>
                          <div className="d-flex justify-content-center align-items-center pt-2">
                            <a
                              className="remove-referral"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <img src={IconRemove} />
                            </a>
                          </div>
                        </div>
                      ))}
                      <div className="containerAddRederral">
                        <a
                          href="#"
                          className="add-rederral"
                          onClick={() =>
                            arrayHelpers.push({
                              name: '',
                              phoneNumber: '',
                              email: '',
                              type: '',
                            })
                          }
                        >
                          <img src={InviteIcon} />
                        <span className="tooltiptext">Invite More Users</span>
                        </a>
                      </div>
                    </div>
                  )}
                />
                <div className="d-flex justify-content-center  align-items-center m-4">
                  <Button className="demi" color="yellow" type="submit">
                    {loading ? <Spinner animation="border" role="status" size="sm" /> : null}
                    Submit
                  </Button>
                </div>

                <p>
                  If you prefer to invite potential members directly,
                  <strong> please use this personalized Referral code </strong>
                  <span className="number-referral">{userLocal.info.userCode[0].code}</span>
                  <br></br>
                  <br></br>
                  You will be able to review and track your referrals by clicking{' '}
                  <img className="invited-icon-text" src={Invited2} />
                  at the top right of your dashboard.
                  <br></br>
                  <br></br>
                  <strong>
                    We look forward to working together and establishing a strong partnership as we
                    grow our mutual businesses.
                  </strong>
                </p>
                <section className="logo-footer">
                  <img src={LogoInvited} />
                </section>
              </Form>
            )}
          </Formik>
        </Card>
      </section>
      {confirmSubmit ? (
        <ModalMessage
          title="Invitation Sent"
          message="Thank you! Your invitation has been sent successfully."
          type="success primary"
        />
      ) : (
        ''
      )}
    </>
  );
};

export default Invite;
