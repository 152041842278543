import {
  SponsorsMemberships,
  CreateSponsoredMembership,
  SponsoredOportunities,
  CreateCouponsAndDiscounts,
  EditCouponsAndDiscounts,
  CouponsAndDiscounts,
  SponsorMember,
} from '../views/';

export const SponsorRoutes = [
  {
    path: '/sponsored-Memberships',
    name: 'Sponsored Memberships',
    icon: 'sponsor/sponsored-memberships.svg',
    component: SponsorsMemberships,
    hide: false,
    role: 'ROLE_SPONSOR',
    primary: true,
  },
  {
    path: '/create-sponsored-membership',
    name: 'Create Sponsored Membership',
    icon: 'sponsor/coupons-and-discounts.svg',
    component: CreateSponsoredMembership,
    hide: true,
    role: 'ROLE_SPONSOR',
  },
  {
    path: '/coupons-and-discounts',
    name: 'Coupons And Discounts',
    icon: 'sponsor/coupons-and-discounts.svg',
    component: CouponsAndDiscounts,
    hide: false,
    role: 'ROLE_SPONSOR',
  },
  {
    path: '/coupons-and-discounts-edit/:id',
    name: 'Coupons And Discounts Edit',
    icon: 'sponsor/coupons-and-discounts.svg',
    component: EditCouponsAndDiscounts,
    hide: true,
    role: 'ROLE_SPONSOR',
  },
  {
    path: '/sponsored-oportunities',
    name: 'Sponsorship Opportunities',
    icon: 'sponsor/sponsorship-opportunities.svg',
    component: SponsoredOportunities,
    hide: false,
    role: 'ROLE_SPONSOR',
  },
  {
    path: '/create-coupons-and-discounts',
    name: 'Create Coupons And Discounts',
    icon: 'pe-7s-graph',
    component: CreateCouponsAndDiscounts,
    hide: true,
    role: 'ROLE_SPONSOR',
  },
  {
    path: '/sponsor-member/:id',
    name: 'Sponsor',
    component: SponsorMember,
    hide: true,
    role: 'ROLE_SPONSOR',
  },
];