import React, { useState, useEffect } from 'react';
import { ContentTab, ItemTab, Button } from '../../components/OMB';
import { getApi, ACCOUNTING_MANAGEMENT } from '../../utils/services';
import MembershipsList from "./MembershipsList";
import SponsorshipsList from "./SponsorshipsList";
import ReferralsSaved from "../ReferralsSaved";
import ReferralsCard from "./ReferralsCard";
import ExpensesList from "./ExpensesList";
import ManagementList from "./ManagementList";
import ManagementSummary from "./ManagementSummary";
import { useSelector } from 'react-redux';
import './styles.css';

const AccountingTabs = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive } = useSelector((store) => store.user);
  const [dataManagement, setDataManagement] = useState({})

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(ACCOUNTING_MANAGEMENT, userToken)
      .then((response) => {
        setDataManagement(response.data);
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <div className="accounting-tabs">
      <ContentTab defaultActiveKey="memberships" id="uncontrolled-tab-example">
        <ItemTab eventKey="memberships" title="Memberships">
          <MembershipsList />
        </ItemTab>
        <ItemTab eventKey="sponsorships" title="Sponsorships">
          <SponsorshipsList />
        </ItemTab>
        {/* <ItemTab eventKey="referrals" title="Referrals">
          <ReferralsSaved />
          <ReferralsCard totalAmount='$3,556'/>
        </ItemTab> */}
        <ItemTab eventKey="expenses" title="Expenses">
          <div className="btn-container">
            <Button
              color="light-blue"
              to={`/${roleActive}/add-expenses`}
            >
              + ADD EXPENSES
            </Button>
          </div>
          <ExpensesList />
        </ItemTab>
        <ItemTab eventKey="management" title="Management">
          <ManagementSummary data={dataManagement} />
          <div className="btn-container">
            <Button
              color="light-blue"
              to={`/${roleActive}/add-transaction-fee`}
            >
              + ADD NEW
            </Button>
          </div>
          <ManagementList info={dataManagement} />
        </ItemTab>
      </ContentTab>
    </div>
  );
};

export default AccountingTabs;
