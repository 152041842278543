import {
  MembersLists,
  InvitedMembers,
  ListingAgentDetails
} from '../views/';

export const AdminMembersRoutes = [
    {
    path: '/members',
    name: 'Members',
    icon: 'super-admin/members.svg',
    component: MembersLists,
    hide: false,
    role: 'ROLE_ADMIN_MEMBERS',
    primary: true,
  },
  {
    path: '/invite/invited-members/:id',
    name: 'Invite',
    icon: 'listing-agent/invite.svg',
    component: InvitedMembers,
    hide: true,
    role: 'ROLE_ADMIN_MEMBERS',
  },
  {
    path: '/listing-agent-details/:id',
    name: 'Listing Agent Details',
    component: ListingAgentDetails,
    hide: true,
    role: 'ROLE_ADMIN_MEMBERS',
  },
]