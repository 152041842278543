import { Dashboard, Login, Recover } from '../views/';

import { ListingAgentsRoutes } from './ListingAgentRoutes';
import { BuyerAgentRoutes } from './BuyerAgentRoutes';
import { BuyerRoutes } from './BuyerRoutes';
import { SponsorRoutes } from './SponsorRoutesRoutes';
import { SuperAdmin } from './SuperAdminRoutes';
import { AdminMembersRoutes } from './AdminMembersRoutes';
import { AdminRefferalsRoutes } from './AdminRefferalsRoutes';
import { AdminAccountingRoutes } from './AdminAccountingRoutes';
import { AdminSponsorshipsRoutes } from './AdminSponsorshipsRoutes';

const dashboardpositions = [
  /******* vistas de LISTING-AGENT ******/
  ...ListingAgentsRoutes,
  /* ****** Vistas de ROLE_BUYER_AGENT ***** */
  ...BuyerAgentRoutes,
  /* ****** Vistas de ROLE_BUYER***** */
  ...BuyerRoutes,
  /* ****** Vistas de Sponsors ****** */
  ...SponsorRoutes,
  /******* vistas de SUPER ADMIN ******/
  ...SuperAdmin,
  /* ****** Vistas de ROLE_ADMIN_MEMBERS ***** */
  ...AdminMembersRoutes,
  /* ****** Vistas de ROLE_ADMIN_REFFERALS ***** */
  ...AdminRefferalsRoutes,
  /* ****** Vistas de ROLE_ADMIN_ACCOUTING ***** */
  ...AdminAccountingRoutes,
  /* ****** Vistas de ROLE_ADMIN_MARKETING ***** */
  ...AdminSponsorshipsRoutes,
  /* ****** Vistas  Globales ***** */
  {
    path: '/Agent',
    name: 'Dashboard agent',
    icon: 'pe-7s-graph',
    component: Dashboard,
    role: 'AGENT',
  },
  {
    path: '/user',
    name: 'Dashboard user',
    icon: 'pe-7s-graph',
    component: Login,
    role: 'user',
  },
  {
    path: '/recover',
    name: 'Dashboard recover',
    icon: 'pe-7s-graph',
    component: Recover,
    role: 'user',
  },
];

export default dashboardpositions;
