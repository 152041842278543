import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { StripeForm, ModalMessage } from '../../../components/OMB';
import { Spinner } from 'react-bootstrap';
import { SAVE_MEMBERSHIPS, postApi, getApi, CODE_DISCOUNT } from '../../../utils/services/';
import './styles.css';

function CreateSponsoredMembership() {
  const [sponsorCuantity, setNumber1] = useState(0);
  const [monthsCuantity, setNumber2] = useState(0);
  const [discountCode, setDiscountCode] = useState('');
  const [percentageDiscount, setPercentageDiscount] = useState(0);
  const [total, setTotal] = useState(sponsorCuantity * monthsCuantity * 99.99);
  const [totalVisual, setTotalVisual] = useState(sponsorCuantity * monthsCuantity * 99.99);
  const [disabledStatus, setDisabledStatus] = useState(false);
  const history = useHistory();
  const [paymentStripeId, setPaymentStripeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paymentSubmit, setPaymentSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [messageModal, setMessageModal] = useState('');
  const userToken = localStorage.getItem('tokenOMB');
  const userData = JSON.parse(localStorage.getItem('state'));
  const userID = userData.user.info.id;
  const urlApi = `${SAVE_MEMBERSHIPS}`;

  useEffect(() => {
    calculateTotal();
  }, [sponsorCuantity, monthsCuantity]);

  useEffect(() => {
    if (total > 0) {
      setDisabledStatus(true);
    }
  }, [total]);
  useEffect(() => {
    if (discountCode.length >= 9) {
      callCodeDiscount();
    }
  }, [discountCode]);

  const callCodeDiscount = () => {
    setLoading(true);
    getApi(`${CODE_DISCOUNT}${discountCode}`, userToken)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          console.log(response.data);
          setPercentageDiscount(response.data.percentageRecipient);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  const submitData = () => {
    setSubmitting(true);
    setPaymentSubmit(true);
  };

  const dataForm = {
    quantity: monthsCuantity,
    quantityPersons: sponsorCuantity,
    membershipCost: {
      id: 1,
    },
    user: {
      id: userID,
    },
    amount: parseInt(total),
    paymentMethod: paymentStripeId,
  };

  useEffect(() => {
    if (paymentStripeId !== null) {
      postApi(urlApi, dataForm, userToken)
        .then((response) => {
          const { data } = response;
          if (data.message != null) {
            setSubmitting(false);
            history.push('sponsored-Memberships');
          }
        })
        .catch((error) => {
          if (error.response) {
            setSubmitting(false);
            console.log(error.response.data);
            console.log('Código:', error.response.status);
          }
        });
    }
  }, [paymentStripeId]);

  function calculateTotal() {
    const mult = sponsorCuantity * monthsCuantity * 99.0;
    if (!!percentageDiscount > 0) {
      const fr = mult - (mult * percentageDiscount) / 100;
      console.log(fr);
      setTotalVisual(fr);
    }else {
      setTotalVisual(mult);
    }
    setTotal(parseInt(mult));
  }

  useEffect(() => {
    console.log(totalVisual)
  }, [totalVisual])

  return (
    <div className="sponsors-id">
      <div className="box-top">
        <div className="sponsor-head-title text-white">Create Sponsored Membership</div>
        <div className="sponsors-items-head row">
          <div className="col-md-12 d-md-flex">
            <div className="w-md-auto">
              <p>How many people do you wish to sponsor?</p>
              <input
                value={sponsorCuantity}
                placeholder="0"
                onChange={(e) => setNumber1(+e.target.value)}
                className="select-ipt"
                type="number"
                min="1"
                pattern="/^[0-9]+$/"
              />
            </div>
            <div className="w-md-auto">
              <p className="">How many months will the sponsorship last ?</p>
              <select
                value={monthsCuantity}
                onChange={(e) => setNumber2(+e.target.value)}
                className="select-ipt"
              >
                <option defaultValue>Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>
            </div>
          </div>
          <div className="col-md-12 d-md-flex">
            <div className="form-group w-md-auto">
              <p>
                Discount Code{' '}
                {loading ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-2" />
                ) : null}
              </p>

              <input
                type="text"
                value={discountCode}
                placeholder=""
                onChange={(e) => setDiscountCode(e.target.value)}
                className="input-form w-md-30"
              />
              {percentageDiscount > 0 ? (
                <div className="board-discount mt-2">
                  <p>{`${percentageDiscount}% Discount`}</p>
                </div>
              ) : null}
            </div>
            <div className="price-merbership w-md-auto">
              <p className="">Membership price</p>
              <span value={totalVisual} className="price">
                ${totalVisual}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-gen form-f">
        <div className="sponsor-head-title text-white">Payment Details</div>
        <div className="form-wrapper">
          <StripeForm
            amount={total}
            setPaymentStripeId={setPaymentStripeId}
            paymentSubmit={paymentSubmit}
            setMessageModal={setMessageModal}
          />
        </div>
      </div>
      {messageModal === null || messageModal === '' ? '' : <ModalMessage message={messageModal} />}

      <div className="box-btn-btn">
        <button className="text-uppercase gr-btn demi">Cancel</button>
        <button
          className="yell-btn text-uppercase demi"
          disabled={!disabledStatus || submitting}
          onClick={submitData}
        >
          {submitting ? (
            <>
              <Spinner animation="border" variant="light" /> Loading...
            </>
          ) : (
            'Submit'
          )}
        </button>
      </div>
    </div>
  );
}

export default CreateSponsoredMembership;
