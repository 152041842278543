import React from 'react';
import { AgentsProfileForm } from '../..';

const AgentProfile = ({ userId }) => {
  return (
    <AgentsProfileForm profileId={userId} />
  );
};

export default AgentProfile;
