import React, { useState, useEffect } from 'react';
import { PropertyList } from '../../containers';
import { usePropertyList } from '../../hooks/usePropertyList';
import { useSavedProperties } from '../../hooks/useSavedProperties';
import { useFilterAddress } from '../../hooks/useFilterAddress';
import { Search, SwitchRole } from '../../components/OMB';
import './styles.css';

const SearchListing = () => {
  useSavedProperties(); // used for get likes
  const [propertyList] = usePropertyList();
  
  const [filteredProperties, setFilteredProperties] = useFilterAddress(propertyList);
  const [searchKey, setSearchKey] = useState(null);
  const [activeSearch, setActiveSearch] = useState(false)
  useEffect(() => {
    if(searchKey){
      setActiveSearch(true)
      setFilteredProperties(searchKey);
    }else{
      setActiveSearch(false)
    }
  }, [searchKey]);
  
  return (
    <div className="search-listing">
      <div className="search-container">
        <SwitchRole />
        <Search handleChange={(e) => setSearchKey(e.target.value)} placeholder="Search" />
      </div>
      <PropertyList contentType="UNO" properties={filteredProperties} activeSearch={activeSearch} />
    </div>
  );
};

export default SearchListing;
