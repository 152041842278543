import React from 'react'

const GoBack = (props) => {
  return (
    <div className='content-goback'>
      <button
        className="btn btn-goback"
        onClick={() => props.history.goBack()}
      >
        <svg style={{ marginTop: '-3px', marginRight: '5px' }} width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-arrow-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill='#000' fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z" />
        </svg>
        Back
      </button>
    </div>
  )
}
export default GoBack