import React, { useState, useEffect } from 'react';
import { Card, Button, ModalMessage } from '../../components/OMB';
import './styles.css';
import { PurchaseAreasList, PurchaseAreasModal } from '../';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
  UPLOAD_IMAGE,
  GET_UTILS,
  GET_OFFER_DETAILS,
  getApi,
  SAVE_OFFERS,
  postApi,
} from '../../utils/services';
import { UploaderFile } from '../../components/OMB/UploaderFile';
import MaskedInput from 'react-text-mask';
import { Spinner } from 'react-bootstrap';

const UserSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  otherName: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
  phone: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  unit: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  zipCode: Yup.string().required('Required'),
  experienceLevel: Yup.string(),
  funding: Yup.string().required('Required'),
});

const BuyersProfileForm = ({ profileId }) => {
  console.log(profileId)
  const userToken = localStorage.getItem('tokenOMB');
  const [info, setInfo] = useState(null);
  const [isEntity, setIsEntity] = useState(null);
  const [states, setStates] = useState([]);
  const [fundingList, setFundingList] = useState([]);
  const [experienceLevelList, setExperienceLevelList] = useState([]);
  const [purchaseAreaList, setPurchaseAreaList] = useState([]);
  const [articlesOfIncorporationFile, setArticlesOfIncorporationFile] = useState(null);
  const [operatingAgreementFile, setOperatingAgreementFile] = useState(null);
  const [proofOfFundsFile, setProofOfFundsFile] = useState(null);
  const [preApprovalLetterFile, setPreApprovalLetterFile] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [titleModalMessage, setTitleModalMessage] = useState('Buyer Profile Update');
  const [messageModalMessage, setMessageModalMessage] = useState('Your profile was updated.');
  const [typeModal, setTypeModal] = useState('message');
  const [loading, setLoading] = useState(false);
  const callEndPointUtils = () => {
    getApi(GET_UTILS, userToken)
      .then((response) => {
        const { funding, experienceLevel, estate } = response.data;
        setStates(estate);
        setFundingList(funding);
        setExperienceLevelList(experienceLevel);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const fetchDetails = () => {
    getApi(`${GET_OFFER_DETAILS}${profileId}`, userToken)
      .then((response) => {
        setInfo(response.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    callEndPointUtils();
    fetchDetails();
  }, []);

  useEffect(() => {
    if (info) {
      const { buyerType, file } = info;
      // console.log(info)
      if (buyerType?.id === 1) {
        setIsEntity(true);
        setArticlesOfIncorporationFile(file?.articleIncorporation);
        setOperatingAgreementFile(file?.operationAgreement);
      } else {
        setIsEntity(false);
      }
      setPurchaseAreaList(info.purchaseArea);
      setProofOfFundsFile(file?.proofsFunds);
      setPreApprovalLetterFile(file?.preApproval);
    }
  }, [info]);

  const handleAddArea = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleModalSubmit = () => {
    console.log('handleModalSubmit');
    setShowModal(false);
  };

  const phoneNumberMask = [
    '(',
    /[1-9]/,
    /\d/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    const entity = {
      entityName: values.name,
      signerName: values.otherName,
      file: {
        articleIncorporation: articlesOfIncorporationFile,
        operationAgreement: operatingAgreementFile,
        proofsFunds: proofOfFundsFile,
        preApproval: preApprovalLetterFile,
      },
    };
    const personal = {
      name: values.name,
      lastName: values.otherName,
      file: {
        proofsFunds: proofOfFundsFile,
        preApproval: preApprovalLetterFile,
      },
    };
    const general = {
      id: info?.id,
      statusOffer: {
        id: info?.statusOffer?.id,
      },
      user: {
        id: info?.user?.id,
      },
      buyerType: {
        id: info.buyerType.id,
      },
      phone: values.phone,
      email: values.email,
      address: {
        street: values.address,
        city: values.city,
        zipCode: values.zipCode,
        status: info?.address?.status,
        unit: values.unit,
        estate: {
          id: values.state,
        },
      },
      funding: {
        id: values.funding,
      },
      experienceLevel: {
        id: values.experienceLevel,
      },
      // purchaseArea: purchaseAreaList,
    };
    let data = {};
    console.log('data', data);
    if (isEntity) {
      data = { ...general, ...entity };
    } else {
      data = { ...general, ...personal };
    }
    postApi(SAVE_OFFERS, data, userToken)
      .then((response) => {
        setLoading(false);
        if (response.data === undefined) {
          setShowModalMessage(true);
          setTitleModalMessage('Error Update');
          setMessageModalMessage('An error has occurred.');
          setTypeModal('error');
        }
        if (response.status === 200) {
          setShowModalMessage(true);
        } else {
          // setShowRender(false);
          // setShowError(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <>
      <div className="buyers-profile-form">
        {!info ? (
          <div>Loading...</div>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={{
              name: isEntity ? info?.entityName : info?.name,
              otherName: isEntity ? info?.signerName : info?.lastName,
              email: info?.email,
              phone: info?.phone,
              address: info?.address?.street,
              unit: info?.address?.unit,
              city: info?.address?.city,
              state: info?.address?.estate.id,
              zipCode: info?.address?.zipCode,
              experienceLevel: info?.experienceLevel?.id,
              funding: info?.funding?.id,
            }}
            validationSchema={UserSchema}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors, touched }) => (
              <Form>
                <Card title="Personal Information">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label className="form-label">
                        {isEntity ? 'Entity Name' : 'First Name'}
                      </label>
                      <Field className="form-control" type="text" name="name" />
                      {errors.name && touched.name && <div className="error">{errors.name}</div>}
                    </div>
                    <div className="form-group col-md-4">
                      <label className="form-label">{isEntity ? 'Signer Name' : 'Last Name'}</label>
                      <Field className="form-control" type="text" name="otherName" />
                      {errors.otherName && touched.otherName && (
                        <div className="error">{errors.otherName}</div>
                      )}
                    </div>
                    <div className="form-group col-md-4">
                      <label className="form-label">Email</label>
                      <Field className="form-control" type="text" name="email" />
                      {errors.email && touched.email && <div className="error">{errors.email}</div>}
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-6 col-md-3 ">
                      <label className="form-label">Phone</label>
                      <Field
                        name="phone"
                        render={({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={phoneNumberMask}
                            id="phone"
                            placeholder=""
                            type="text"
                            className={`form-control`}
                          />
                        )}
                      />
                      {errors.phone && touched.phone && <div className="error">{errors.phone}</div>}
                    </div>
                  </div>
                  <div className="form-row form-addres-profile">
                    <div className="form-group">
                      <label className="form-label">Address</label>
                      <Field className="form-control" type="text" name="address" />
                      {errors.address && touched.address && (
                        <div className="error">{errors.address}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Suite / Apt</label>
                      <Field className="form-control" type="text" name="unit" />
                      {errors.unit && touched.unit && <div className="error">{errors.unit}</div>}
                    </div>
                    <div className="form-group">
                      <label className="form-label">City</label>
                      <Field className="form-control" type="text" name="city" />
                      {errors.city && touched.city && <div className="error">{errors.city}</div>}
                    </div>
                    <div className="form-group">
                      <label className="form-label">State</label>
                      <Field className="form-control" as="select" name="state">
                        <option value={''}>Select</option>
                        {states.map((item) => (
                          <option value={item.id} key={`${item.name}-${item.id}`}>
                            {item.code}
                          </option>
                        ))}
                      </Field>
                      {errors.state && touched.state && <div className="error">{errors.state}</div>}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Zip Code</label>
                      <Field className="form-control" type="text" name="zipCode" />
                      {errors.zipCode && touched.zipCode && (
                        <div className="error">{errors.zipCode}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Buyer Experience Level</label>
                      <Field className="form-control" as="select" name="experienceLevel">
                        <option value={''}>Select</option>
                        {experienceLevelList.map((item) => (
                          <option value={item.id} key={`${item.name}-${item.id}`}>
                            {item.name} {item.description}
                          </option>
                        ))}
                      </Field>
                      {errors.experienceLevel && touched.experienceLevel && (
                        <div className="error">{errors.experienceLevel}</div>
                      )}
                    </div>
                  </div>
                </Card>

                <Card title="Payment Information" loading={loading}>
                  <div className="form-row">
                    <div className="form-group" style={{ maxWidth: 250 + 'px' }}>
                      <label className="form-label">Funding</label>
                      <Field className="form-control" as="select" name="funding">
                        <option value={''}>Select</option>
                        {fundingList.map((item) => (
                          <option value={item.id} key={`${item.name}-${item.id}`}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.funding && touched.funding && (
                        <div className="error">{errors.funding}</div>
                      )}
                    </div>
                  </div>
                  {isEntity && (
                    <div className="form-row">
                      <div className="form-group">
                        <UploaderFile
                          titleLabel="Articles of Incorporation"
                          urlApi={UPLOAD_IMAGE}
                          userToken={userToken}
                          setRoute={setArticlesOfIncorporationFile}
                          sizeLabel="normal-size"
                          preLoadedFile={!!articlesOfIncorporationFile}
                          setLoading={setLoading}
                        />
                      </div>
                      <div className="form-group">
                        <UploaderFile
                          titleLabel="Operating Agreement"
                          urlApi={UPLOAD_IMAGE}
                          userToken={userToken}
                          setRoute={setOperatingAgreementFile}
                          sizeLabel="normal-size"
                          preLoadedFile={!!operatingAgreementFile}
                          setLoading={setLoading}
                        />
                      </div>
                    </div>
                  )}
                  <div className="form-row">
                    <div className="form-group">
                      <UploaderFile
                        titleLabel="Proof of Funds"
                        urlApi={UPLOAD_IMAGE}
                        userToken={userToken}
                        setRoute={setProofOfFundsFile}
                        typeFile="licenses"
                        sizeLabel="normal-size"
                        preLoadedFile={!!proofOfFundsFile}
                        setLoading={setLoading}
                      />
                    </div>
                    <div className="form-group">
                      <UploaderFile
                        titleLabel="Pre-Approval Letter (Non-Cash Offers)"
                        urlApi={UPLOAD_IMAGE}
                        userToken={userToken}
                        setRoute={setPreApprovalLetterFile}
                        typeFile="licenses"
                        sizeLabel="normal-size"
                        preLoadedFile={!!preApprovalLetterFile}
                        setLoading={setLoading}
                      />
                    </div>
                  </div>
                </Card>

                <Card
                  title="Purchase Areas"
                  btnColor="light-blue"
                  btnTitle="+ ADD NEW AREA"
                  btnHandle={handleAddArea}
                >
                  <PurchaseAreasList areas={purchaseAreaList} />
                </Card>

                {/* <Card title="Membership Details">
                  <p>
                    <span className="bold">Current Membership:</span> Monthly Membership
                  </p>
                  <p>
                    <span className="bold">Start Date:</span> March 2nd, 2018
                  </p>
                  <p>
                    <span className="bold">10 days of trial remaining</span>
                  </p>
                </Card> */}

                <div className="btn-container">
                  <Button color="yellow" type="submit">
                    {loading ? (
                      <Spinner animation="border" role="status" size="sm" className="mr-2" />
                    ) : null}
                    SAVE CHANGES
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <PurchaseAreasModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleModalSubmit={handleModalSubmit}
        buyerProfileId={profileId}
        states={states}
        areaList={purchaseAreaList}
        setAreaList={setPurchaseAreaList}
      />
      {showModalMessage && (
        <ModalMessage
          title={titleModalMessage}
          message={messageModalMessage}
          btnTitle="OK"
          redirect="false"
          type="message"
        />
      )}
    </>
  );
};

export default BuyersProfileForm;
