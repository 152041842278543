import {
  PropertyDetails,
  PropertyOfferDetails,
  AddProperty,
  EditProperty,
  Offers,
  OfferDetails,
  BulkImport,
  NotFound,
  Properties,
  MyOffersList,
  Profile,
  Coupon,
  Invite,
  MyCoupons,
  InvitedMembers,
  HelpUs,
  MarkAsPurchased,
  MyMessages,
  MyChat,
  PropertyMyOffer,
} from '../views/';

export const ListingAgentsRoutes = [
         {
            path: '/properties/my-properties',
            name: 'My properties',
            icon: 'listing-agent/my-properties.svg',
            component: Properties,
            hide: false,
            role: 'ROLE_LISTING_AGENT',
            primary: 'primary',
        },
        {
            path: '/properties/add-property/:id',
            name: 'My properties',
            icon: 'listing-agent/my-properties.svg',
            component: AddProperty,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/properties/edit-property/:id',
            name: 'My properties',
            icon: 'listing-agent/my-properties.svg',
            component: EditProperty,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/bulk-import/',
            name: 'Bulk Import',
            icon: 'listing-agent/bulk-import.svg',
            component: BulkImport,
            hide: false,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/profile',
            name: 'Profile',
            icon: 'listing-agent/profile.svg',
            component: Profile,
            hide: false,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/invite',
            name: 'Invite',
            icon: 'listing-agent/invite.svg',
            component: Invite,
            hide: false,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/invite/invited-members/:id',
            name: 'Invite',
            icon: 'listing-agent/invite.svg',
            component: InvitedMembers,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/coupon-code',
            name: 'Coupon Code',
            icon: 'listing-agent/coupon-code.svg',
            component: Coupon,
            hide: false,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/my-redeemed-coupons/:id',
            name: 'Coupon Code',
            icon: 'listing-agent/coupon-code.svg',
            component: MyCoupons,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/property-details/:id',
            name: 'My Properties',
            icon: 'buyer-buyers-agent/search-listings.svg',
            component: PropertyDetails,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/properties/offers-property/:id',
            name: 'offers property',
            icon: 'listing-agent/my-properties.svg',
            component: Offers,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/property-offer-details/:id',
            name: 'Offers Details',
            icon: 'buyer-buyers-agent/search-listings.svg',
            component: PropertyOfferDetails,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/properties/offers-property/offer-details/:id',
            name: 'offers property',
            icon: 'pe-7s-graph',
            component: OfferDetails,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/my-offers',
            name: 'My Offers',
            component: MyOffersList,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            //path: '/offers/help/:id',
            path: '/help-us-improve',
            name: 'Help Us Improve',
            icon: 'pe-7s-graph',
            component: HelpUs,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/offers/mark-as-purchased',
            name: 'Mark as purchased',
            icon: 'pe-7s-graph',
            component: MarkAsPurchased,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/messages',
            name: 'Messages',
            icon: 'pe-7s-graph',
            component: MyMessages,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/messages/chat/:id/:viewed',
            name: 'Messages',
            icon: 'pe-7s-graph',
            component: MyChat,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        {
            path: '/property-my-offer',
            name: 'Property my offer',
            icon: 'pe-7s-graph',
            component: PropertyMyOffer,
            hide: true,
            role: 'ROLE_LISTING_AGENT',
        },
        // {
        //     path: "*",
        //     name: "404",
        //     icon: "pe-7s-graph",
        //     component: NotFound,
        //     role: "ROLE_LISTING_AGENT",
        //     hide:true,
        // },

        // {
        //   path: "/members/agent-details/:id",
        //   name: "Agent Details",
        //   icon: "pe-7s-graph",
        //   component: AgentDetails,
        //   hide:true,
        //   role: "LISTING_AGENT",
        // },
        // {
        //   path: "/members",
        //   name: "Members",
        //   icon: "pe-7s-graph",
        //   component: Members,
        //   role: "LISTING_AGENT",
        // },
    ]