import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { offers_properties } from '../redux/PropertyDucks';

export const useOffersProperties = (userId = null) => {
  const { user } = useSelector((store) => store);
  const { offersProperties } = useSelector((store) => store.property);
  const dispatch = useDispatch();
  const id = userId || user?.info?.id;

  useEffect(() => {
    dispatch(offers_properties(id));
  }, []);

  return [offersProperties];
};
