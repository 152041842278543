
import React, { useState, useEffect } from 'react';
import { Card, EmptyList, DTable } from "../../../components/OMB";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const UsersReferralCodeCard = ({ info }) => {
  const { roleActive } = useSelector((store) => store.user);
  const [data, setData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const dataUsersReferralCode = info.map(({ idUser, code, names, typeUser, referring, recipient, totalProfit }) => {
      return {
        id: idUser,
        code,
        names,
        userType: typeUser,
        referring: `${referring}%`,
        recipient: `${recipient}%`,
        totalProfits: `$${totalProfit.toFixed(2)}`,
        icon: '>',
        handleClick: () => history.push(`/${roleActive}/referrals-details/${idUser}`),
      };
    })
    setData(dataUsersReferralCode);
  }, []);

  const columns = [
    {
      name: 'Code',
      selector: 'code',
    },
    {
      name: 'Name',
      selector: 'names',
    },
    {
      name: 'User Type',
      selector: 'userType',
    },
    {
      name: 'Referring',
      selector: 'referring',
    },
    {
      name: 'Recipient',
      selector: 'recipient',
    },
    {
      name: 'Total Profits',
      selector: 'totalProfits',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px'
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <Card
      title={`User's Referrals (${info.length})`}
      // btnTitle="Download Template"
      // btnHandle={handleDownload}
    >
      {info.length === 0
        ? <EmptyList />
        : (
          <DTable columns={columns} data={data} />
        )
      }
    </Card>
  )
};

export default UsersReferralCodeCard;
