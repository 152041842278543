import React, { useState } from 'react';
import { Button, Card, Search } from '../../../components/OMB';
import "./styles.css"

const CSInputCard = ({ setSearchCode }) => {
  const [value, setValue] = useState('');

  const handleSubmit = () => setSearchCode(value);

  return (
    <Card title="Referral Code Search" className="cs-input-card">
      <div className="search-container">
        <Search
          placeholder="Enter a code..."
          handleChange={(e) => setValue(e.target.value)}
          showIcon={false}
        />
        <Button color="yellow" onClick={handleSubmit}>
          SEARCH
        </Button>
      </div>
    </Card>
  )
}

export default CSInputCard;
