import { Sponsorships, AddSponsorship } from '../views';

export const AdminSponsorshipsRoutes = [
  {
    path: '/sponsorships',
    name: 'Sponsorships',
    icon: 'super-admin/marketing.svg',
    component: Sponsorships,
    hide: false,
    role: 'ROLE_ADMIN_MARKETING',
    primary: true,
  },
  {
    path: '/add-sponsorship',
    name: 'Add sponsorship',
    icon: 'pe-7s-graph',
    component: AddSponsorship,
    hide: true,
    role: 'ROLE_ADMIN_MARKETING',
  },
];