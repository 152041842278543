//var ip="https://offmarketbroker.com:8445/"
//157.230.234.43 ip server
//50.116.109.122:8080 OMB server
const hostName = window.location.hostname;
let destine = 'https://offmarketbroker.com:8445'
// if (hostName !== 'admin.offmarketbroker.com') {
//   //destine = 'https://offmarketbroker.com:8445/app-omb-test'
//   destine = 'http://localhost:8080/app-omb'
// }
const domainServer=`${destine}`
module.exports = {
    domainServer,
};