import React from 'react';
import { ContentTab, ItemTab } from '../../components/OMB';
import SponsoredMemberships from "./SponsoredMemberships";
import CouponsAndDiscounts from "./CouponsAndDiscounts";
import Opportunities from "./Opportunities";

const SponsorTabs = ({ userId }) => {
  return (
    <div className="buyer-agent-tabs">
      <ContentTab defaultActiveKey="sponsored-memberships" id="uncontrolled-tab-example">
        <ItemTab eventKey="sponsored-memberships" title="Sponsored Memberships">
          <SponsoredMemberships userId={userId} />
        </ItemTab>
        <ItemTab eventKey="coupons-discounts" title="Coupons / Discounts">
          <CouponsAndDiscounts userId={userId} />
        </ItemTab>
        <ItemTab eventKey="oportunities" title="Opportunities">
          <Opportunities userId={userId} />
        </ItemTab>
      </ContentTab>
    </div>
  );
};

export default SponsorTabs;
