import React from 'react';
import { GoBack } from '../../components/OMB';
import { AddSponsorshipForm } from "../../containers";
import './styles.css';

const AddSponsorship = (props) => {
  return (
    <div className="add-sponsorship">
      <GoBack {...props} />
      <AddSponsorshipForm />
    </div>
  )
}

export default AddSponsorship;
