import React, { useState } from 'react';
import './styles.css';

const Switch = (props) => {
  const handleActive = (val) => {
    if (val !== props.itemActive) {
      props.setItemActive(val);
    }
  };

  return (
    <div className={`switch-wrapper ${props.disabled ? 'disabled' : ''}`} >
      <span className={`switch-item${props.itemActive ? ' item-active' : ''}`} onClick={() => handleActive(true)}>
        {props.titleOne}
      </span>
      <span className={`switch-item${!props.itemActive ? ' item-active' : ''}`} onClick={() => handleActive(false)}>
        {props.titleTwo}
      </span>
    </div>
  );
};

export default Switch;
