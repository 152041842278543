import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list_memberships } from '../redux/adminDucks';

export const useMemberships = () => {
  const { memberships, loading } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(list_memberships());
  }, []);

  return [memberships, loading];
};
