import React, { useState, useEffect } from 'react';
import { Card, EmptyList, DTable } from "../../../components/OMB";
import { formatPrice } from '../../../utils/services';

const ActiveSponsorsCard = ({ sponsors }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const dataMemberships = sponsors.map(({ id, code, name, payment }) => {
      return {
        "membershipCode": code,
        "memberName": name,
        "monthlyPayment": formatPrice(payment),
      }
    })
    setData(dataMemberships);
  }, []);

  const columns = [
    {
      name: 'Membership Code',
      selector: 'membershipCode',
    },
    {
      name: 'Member Name',
      selector: 'memberName',
    },
    {
      name: 'Monthly Payment',
      selector: 'monthlyPayment',
    },
  ];

  // const handleDownload = () => {
  //   console.log('handleDownload');
  // };

  return (
    <Card
      title="Active Sponsorships"
    // btnTitle="Download Template"
    // btnHandle={handleDownload}
    >
      {sponsors.length === 0
        ? <EmptyList />
        : (
          <DTable columns={columns} data={data} />
        )
      }
    </Card>
  );
}

export default ActiveSponsorsCard;
