import React, { useEffect, useState } from 'react';
import { Card, Switch, Button, ModalMessage, Confirm, CurrencyInput } from '../../components/OMB';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { UploaderFile } from '../../components/OMB/UploaderFile';
import Direction from '../../assets/iconos/Direction.svg';

import {
  postApi,
  SAVE_OFFERS,
  UPLOAD_IMAGE,
  GET_UTILS,
  getApi,
  LIST_DRAFT,
  GET_OFFER_DETAILS,
  GET_USER,
  PROPERTY_INFO,
} from '../../utils/services';
import { useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Spinner } from 'react-bootstrap';
import { useListStates } from '../../hooks/useListStates';
import iconoInfo from '../../assets/iconos/masinfo.svg';
import './styles.css';
import { useFormatPrice } from '../../hooks/useFormatPrice';

const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const PropertyOfferCards = () => {
  const { id: propertyID, profileId } = useParams();
  const [isEntity, setIsEntity] = useState(true);
  const [articlesOfIncorporationFile, setArticlesOfIncorporationFile] = useState(null);
  const [operatingAgreementFile, setOperatingAgreementFile] = useState(null);
  const [proofOfFundsFile, setProofOfFundsFile] = useState(null);
  const [preApprovalLetterFile, setPreApprovalLetterFile] = useState(null);
  const user = useSelector((store) => store.user);
  const userToken = localStorage.getItem('tokenOMB');
  let paths = window.location.pathname.split('/');
  const [userID, setUserId] = useState(user.info.id);
  const { roleActive } = useSelector((store) => store.user);
  const [funding, setFunding] = useState([]);
  const [showCheck, setShowCheck] = useState(false);
  const [showError, setShowError] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [resultsDataBuyerAgent, setResultsDataBuyerAgent] = useState(null);
  const [showBuyerAgentNotExist, setShowBuyerAgentNotExist] = useState(false);
  const [buyerAgent, setBuyerAgent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showQuestionsModal, setShowQuestionsModal] = useState(true);
  const [showModalBuyersProfile, setShowModalBuyersProfile] = useState(false);
  const [buyersProfiles, setBuyersProfiles] = useState([]);
  const [buyersProfilesSelect, setBuyersProfilesSelect] = useState('');
  const [btnModalContinue, setBtnModalContinue] = useState(true);
  const [states] = useListStates();
  const [profileData, setProfileData] = useState([]);
  const [disabledSwitch, setDisabledSwitch] = useState(false);
  const [showModalSaveProfile, setShowModalSaveProfile] = useState(false);
  const [dataSubmit, setDataSubmit] = useState(null);
  const [initEntityValues, setInitEntityValues] = useState({
    entityName: '',
    authorizedName: '',
    phone: '',
    email: '',
    mailingAddress: '',
    city: '',
    state: '',
    zipCode: '',
    offerAmount: '',
    edmAmount: '',
    funding: '',
    loanContingency: '0',
    inspectionContingency: '0',
    appraisalContingency: '0',
    homeWarranty: '0',
    additionalNotes: '',
    commissionOfferedToListing: '',
    amountGivenToBuyersAgent: '',
  });
  const history = useHistory();

  const [initPersonalValues, setInitPersonalValues] = useState({
    buyerName: '',
    buyerPhone: '',
    buyerEmail: '',
    offerAmount: '',
    edmAmount: '',
    funding: '',
    loanContingency: '0',
    inspectionContingency: '0',
    appraisalContingency: '0',
    homeWarranty: '0',
    additionalNotes: '',
    commissionOfferedToListing: '',
    amountGivenToBuyersAgent: '',
  });

  const idUserSelectForAdmin = roleActive === 'ROLE_ADMIN' ? paths[4] : null; // id del usuario cuando eres super admin
  const [roleUserSelectForAdmin, setRoleUserSelectForAdmin] = useState('');
  const [infoProperty, setInfoProperty] = useState([]);
  const { address = '', typeProperty = '', occupancy = '', price = 0, statusProperty = [] } = infoProperty;
  const { street = '', unit = '', zipCode = '', estate = [], city = '' } = address;
  const { name, code } = estate;
  let propertyAddress = `${!!street ? `${street}` : ''} ${!!unit ? ',' : ''} ${!!unit ? `unit ${unit},` : ''} ${!!city ? `${city},` : ''} ${!!code ? `${code}` : ''} ${!!zipCode ? `${zipCode}` : ''} `;
  const [formattedPrice] = useFormatPrice(price);

  let { id } = useParams();

  const callPropertyInfo = async () => {
    const url_api = `${PROPERTY_INFO}${id}`;
    await getApi(url_api, userToken)
      .then((response) => {
        if (response.status === 200) {
          setInfoProperty(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en adminNavBarLink:', error.response.status);
        }
      });
  };
  useEffect(() => {
    callPropertyInfo();
  }, []);
  useEffect(() => {
    console.log(infoProperty);
  }, [infoProperty]);
  const callInfoUser = () => {
    const urlApi = `${GET_USER}${idUserSelectForAdmin}`;
    getApi(urlApi, userToken)
      .then((response) => {
        setRoleUserSelectForAdmin(response.data.userRole[0].role.role);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (roleActive === 'ROLE_ADMIN') {
      callInfoUser();
    }
  }, [roleActive]);

  useEffect(() => {
    if (!!roleUserSelectForAdmin) {
      setUserId(idUserSelectForAdmin);
    }
  }, [roleUserSelectForAdmin]);

  const [changeInitValue, setChangeInitValue] = useState(false);

  let EntitySchema = Yup.object().shape({
    entityName: Yup.string().required('Required'),
    authorizedName: Yup.string().required('Required'),
    phone: Yup.string().required('Required'),
    email: Yup.string().email().required('Required'),
    mailingAddress: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zipCode: Yup.string().required('Required'),
    offerAmount: Yup.string().required('Required'),
    edmAmount: Yup.string().required('Required'),
    funding: Yup.string().required('Required'),
    loanContingency: Yup.number().required('Required'),
    inspectionContingency: Yup.number().required('Required'),
    appraisalContingency: Yup.number().required('Required'),
    homeWarranty: Yup.number().required('Required'),
    additionalNotes: Yup.string(),
    commissionOfferedToListing: Yup.number().required('Required'),
    amountGivenToBuyersAgent: Yup.string().required('Required'),
  });

  let PersonalSchema = Yup.object().shape({
    buyerName: Yup.string().required('Required'),
    buyerPhone: Yup.string().required('Required'),
    buyerEmail: Yup.string().email().required('Required'),
    offerAmount: Yup.string().required('Required'),
    edmAmount: Yup.string().required('Required'),
    funding: Yup.string().required('Required'),
    loanContingency: Yup.number().required('Required'),
    inspectionContingency: Yup.number().required('Required'),
    appraisalContingency: Yup.number().required('Required'),
    homeWarranty: Yup.number().required('Required'),
    additionalNotes: Yup.string(),
    commissionOfferedToListing: Yup.number().required('Required'),
    amountGivenToBuyersAgent: Yup.string().required('Required'),
  });
  let roleValidate = roleActive;
  if (!!roleUserSelectForAdmin) {
    roleValidate = roleUserSelectForAdmin;
  }
  if (roleValidate === 'ROLE_BUYER') {
    EntitySchema = Yup.object().shape({
      entityName: Yup.string().required('Required'),
      authorizedName: Yup.string().required('Required'),
      phone: Yup.string().required('Required'),
      email: Yup.string().email().required('Required'),
      mailingAddress: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zipCode: Yup.string().required('Required'),
      offerAmount: Yup.string().required('Required'),
      edmAmount: Yup.string().required('Required'),
      funding: Yup.string().required('Required'),
      loanContingency: Yup.number().required('Required'),
      inspectionContingency: Yup.number().required('Required'),
      appraisalContingency: Yup.number().required('Required'),
      homeWarranty: Yup.number().required('Required'),
      additionalNotes: Yup.string(),
      commissionOfferedToListing:
        roleValidate === 'ROLE_BUYER' ? Yup.number() : Yup.number().required('Required'),
      amountGivenToBuyersAgent: Yup.string(),
    });

    PersonalSchema = Yup.object().shape({
      buyerName: Yup.string().required('Required'),
      buyerPhone: Yup.string().required('Required'),
      buyerEmail: Yup.string().email().required('Required'),
      mailingAddress: Yup.string().required('Required'),
      city: Yup.string().required('Required'),
      state: Yup.string().required('Required'),
      zipCode: Yup.string().required('Required'),
      offerAmount: Yup.string().required('Required'),
      edmAmount: Yup.string().required('Required'),
      funding: Yup.string().required('Required'),
      loanContingency: Yup.number().required('Required'),
      inspectionContingency: Yup.number().required('Required'),
      appraisalContingency: Yup.number().required('Required'),
      homeWarranty: Yup.number().required('Required'),
      additionalNotes: Yup.string(),
      commissionOfferedToListing:
        roleValidate === 'ROLE_BUYER' ? Yup.number() : Yup.number().required('Required'),
      amountGivenToBuyersAgent: Yup.string(),
    });
    if (roleValidate === 'ROLE_BUYER_AGENT') {
      EntitySchema = Yup.object().shape({
        entityName: Yup.string().required('Required'),
        authorizedName: Yup.string().required('Required'),
        phone: Yup.string().required('Required'),
        email: Yup.string().email().required('Required'),
        mailingAddress: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        zipCode: Yup.string().required('Required'),
        offerAmount: Yup.string().required('Required'),
        edmAmount: Yup.string().required('Required'),
        funding: Yup.string().required('Required'),
        loanContingency: Yup.number(),
        inspectionContingency: Yup.number(),
        appraisalContingency: Yup.number(),
        homeWarranty: Yup.number(),
        additionalNotes: Yup.string(),
        commissionOfferedToListing:
          roleValidate === 'ROLE_BUYER' ? Yup.number() : Yup.number().required('Required'),
        amountGivenToBuyersAgent: Yup.string(),
      });
      PersonalSchema = Yup.object().shape({
        buyerName: Yup.string().required('Required'),
        buyerPhone: Yup.string().required('Required'),
        buyerEmail: Yup.string().email().required('Required'),
        mailingAddress: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.string().required('Required'),
        zipCode: Yup.string().required('Required'),
        offerAmount: Yup.string().required('Required'),
        edmAmount: Yup.string().required('Required'),
        funding: Yup.string().required('Required'),
        loanContingency: Yup.number(),
        inspectionContingency: Yup.number(),
        appraisalContingency: Yup.number(),
        homeWarranty: Yup.number(),
        additionalNotes: Yup.string(),
        commissionOfferedToListing:
          roleValidate === 'ROLE_BUYER' ? Yup.number() : Yup.number().required('Required'),
        amountGivenToBuyersAgent: Yup.string(),
      });
    }
  }

  const callEndPointUtils = () => {

    getApi(GET_UTILS, userToken)
      .then((response) => {
        const data = response.data.funding;
        setFunding(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndPointUtils();
  }, []);

  useEffect(() => {
    console.log(resultsDataBuyerAgent)
    if (roleValidate === 'ROLE_BUYER') {
      setBuyerAgent({
        id: userID,
      });
    }
  }, [resultsDataBuyerAgent]);

  useEffect(() => {
    if (roleValidate === 'ROLE_BUYER_AGENT') {
      setBuyerAgent({
        id: userID,
      });
    }
    if (roleValidate === 'ROLE_ADMIN') {
      setBuyerAgent({
        id: profileId,
      });
    }
  }, []);

  const callListBuyersProfile = async () => {
    const id = profileId || userID;
    await getApi(`${LIST_DRAFT}${id}`, userToken)
      .then((response) => {
        if (response.status === 200) {
          setBuyersProfiles(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en adminNavBarLink:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callListBuyersProfile();
  }, []);

  const handleHideModal = () => {
    setShowQuestionsModal(false);
  };

  const handleShowModalBuyersProfile = () => {
    setShowModalBuyersProfile(true);
  };

  const handleHideSaveProfileModal = () => {
    setShowModalSaveProfile(false);
    setShowCheck(true);
    /* setTimeout(() => {
      history.goBack();
    }, 8000); */
  };

  const handleSaveProfileModal = () => {
    setShowModalSaveProfile(false);

    const dataForm = {
      ...dataSubmit,
      statusOffer: {
        id: 1,
      },
    };

    postApi(SAVE_OFFERS, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          setShowCheck(true);
          /* setTimeout(() => {
            history.goBack();
          }, 8000); */
        } else {
          setShowError(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const handleChangeSelectBuyerProfile = (e) => {
    setLoading(true);
    setBuyersProfilesSelect(e.target.value);
    const id = e.target.value;
    const urlApi = `${GET_OFFER_DETAILS}${id}`;
    getApi(urlApi, userToken)
      .then((response) => {
        setLoading(false);
        const data = response.data;
        if (response.status === 200) {
          setProfileData(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en handleChangeSelectBuyerProfile:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (Object.keys(profileData).length > 1) {
      setBtnModalContinue(false);
    }
    if (!!profileData.buyerType?.id) {
      setDisabledSwitch(true);
    }
    if (profileData.buyerType?.id === 2) {
      setIsEntity(false);
      setDisabledSwitch(true);
    }
  }, [profileData]);

  useEffect(() => {
    if (changeInitValue === true) {
      setInitEntityValues({
        entityName: profileData.entityName,
        authorizedName: profileData.signerName,
        phone: profileData.phone,
        email: profileData.email,
        mailingAddress: profileData.address.street,
        city: profileData.address.city,
        state: profileData.address.estate.id,
        zipCode: profileData.address.zipCode,
        offerAmount: profileData.amountOffered,
        edmAmount: profileData.emdAmount,
        funding: profileData.funding.id,
        loanContingency: profileData.longContingency,
        inspectionContingency: profileData.inspectionContingency,
        appraisalContingency: profileData.appraisalContingency,
        homeWarranty: profileData.homeWarranty,
        additionalNotes: '',
        commissionOfferedToListing: profileData.percentageCommission,
        amountGivenToBuyersAgent: '',
      });
      setInitPersonalValues({
        buyerName: `${profileData.name} ${profileData.lastName}`,
        buyerPhone: profileData.phone,
        buyerEmail: profileData.email,
        mailingAddress: profileData.address.street,
        city: profileData.address.city,
        state: profileData.address.estate.id,
        zipCode: profileData.address.zipCode,
        offerAmount: profileData.amountOffered,
        edmAmount: profileData.emdAmount,
        funding: profileData.funding.id,
        loanContingency: profileData.longContingency,
        inspectionContingency: profileData.inspectionContingency,
        appraisalContingency: profileData.appraisalContingency,
        homeWarranty: profileData.homeWarranty,
        additionalNotes: '',
        commissionOfferedToListing: profileData.percentageCommission,
        amountGivenToBuyersAgent: '',
      });
    }
  }, [changeInitValue]);

  const OptionsValue = () => {
    let options = [];
    for (let i = 5; i < 101; i++) {
      options.push(i);
    }
    return options.map((v) => <option value={v}>{v}%</option>);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    console.log('data value', values);
    const profileEntity = {
      entityName: values.entityName,
      signerName: values.authorizedName,
      phone: values.phone,
      email: values.email,
      file: {
        proofsFunds: proofOfFundsFile,
        preApproval: preApprovalLetterFile,
        articleIncorporation: articlesOfIncorporationFile,
        operationAgreement: operatingAgreementFile,
      },
    };
    const personalName = {
      name: values.buyerName,
      lastName: '',
      phone: values.buyerPhone,
      email: values.buyerEmail,

      file: {
        proofsFunds: proofOfFundsFile,
        preApproval: preApprovalLetterFile,
      },
    };

    const buyerType = isEntity ? 1 : 2;

    let offerAmountFormatOne =
      typeof values.offerAmount === 'string'
        ? values.offerAmount.replaceAll('$', '')
        : values.offerAmount;

    let offerAmount =
      typeof values.offerAmount === 'string'
        ? offerAmountFormatOne.replaceAll(',', '').trim()
        : values.offerAmount;
    let edmAmountFormatOne =
      typeof values.edmAmount === 'string'
        ? values.edmAmount.replaceAll('$', '')
        : values.edmAmount;
    let edmAmount =
      typeof values.edmAmount === 'string'
        ? edmAmountFormatOne.replaceAll(',', '').trim()
        : values.edmAmount;
    let amountGivenToBuyersAgentFormatOne =
      typeof values.amountGivenToBuyersAgent === 'string'
        ? values.amountGivenToBuyersAgent.replaceAll('$', '')
        : values.amountGivenToBuyersAgent;
    let amountGivenToBuyersAgent =
      typeof values.amountGivenToBuyersAgentFormatOne === 'string'
        ? amountGivenToBuyersAgentFormatOne.replaceAll(',', '').trim()
        : values.amountGivenToBuyersAgent;
    let amountGivenToBuyersAgentFormatTwo =
      typeof amountGivenToBuyersAgentFormatOne === 'string'
        ? amountGivenToBuyersAgentFormatOne.replaceAll('$', '').replaceAll(',', '').trim()
        : values.amountGivenToBuyersAgent;

    const data = {
      buyerAgentId: buyerAgent,
      statusOffer: {
        id: 2,
      },
      user: {
        id: userID,
      },
      buyerType: {
        id: buyerType,
      },
      property: {
        id: parseInt(propertyID),
      },
      address: {
        street: values.mailingAddress,
        city: values.city,
        zipCode: values.zipCode,
        estate: {
          id: parseInt(values.state), //Revisar mas adelante
        },
      },
      funding: {
        id: parseInt(values.funding),
      },
      amountOffered: parseInt(offerAmount),
      amount: parseInt(offerAmount),
      emdAmount: parseInt(edmAmount),
      longContingency: parseInt(values.loanContingency),
      inspectionContingency: parseInt(values.inspectionContingency),
      appraisalContingency: parseInt(values.appraisalContingency),
      homeWarranty: parseInt(values.homeWarranty),
      message: values.additionalNotes,
      percentageCommission: parseInt(values.commissionOfferedToListing),
      consultingFee: parseFloat(amountGivenToBuyersAgentFormatTwo),
    };

    let dataForm = {};
    if (isEntity) {
      dataForm = { ...profileEntity, ...data };
    } else {
      dataForm = { ...personalName, ...data };
    }
    console.log(dataForm);
    setDataSubmit(dataForm);

    postApi(SAVE_OFFERS, dataForm, userToken)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          if (changeInitValue) {
            setShowCheck(true);
            /* setTimeout(() => {
              history.goBack();
            }, 8000); */
          } else {
            setShowModalSaveProfile(true);
          }
        } else {
          setShowError(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, []);

  const handleConfirmUser = () => {
    setArticlesOfIncorporationFile(profileData.file.articleIncorporation);
    setOperatingAgreementFile(profileData.file.operationAgreement);
    setProofOfFundsFile(profileData.file.preApproval);
    setPreApprovalLetterFile(profileData.file.preApproval);
    setShowModalBuyersProfile(false);
    setShowQuestionsModal(false);
    setChangeInitValue(true);
  };
  let statusName = null;
  if (!!statusProperty) {
    statusName = statusProperty[0]?.status.name
  }
  let bgCinta =
    statusName === 'LISTED'
      ? 'cinta-listed'
      : statusName === 'COMING SOON'
      ? 'cinta-comming'
      : statusName === 'PENDING'
      ? 'cinta-pending'
      : statusName === 'SOLD'
      ? 'cinta-sold'
      : statusName === 'BUY IT NOW'
      ? 'cinta-buyit'
      : 'cinta-sale';
  return (
    <>
      <div className="p-1 mb-3 info-cnt">
        <div className="row">
          <div className="col-md-6 cnt-direction">
            <div className="text-direction">
              <span>
                <img src={Direction} className="icon" alt="Direction" />
              </span>
              <p className="direction bold">{propertyAddress}</p>
            </div>
            <div className="other-info">
              <p className="m-0 mb-2"><span className="bold">Type:</span> {typeProperty.name}</p>
              <p className="m-0 mb-2"><span className="bold">Occupancy:</span> {occupancy}</p>
            </div>
          </div>
          <div className="col-md-6 cnt-price">
            <p className="price">
              Price: <span className="mount bold">{formattedPrice}</span>
            </p>
          </div>
          <div className={`cinta ${bgCinta}`}>
            <span className="text bold">{statusName}</span>
          </div>
        </div>
      </div>
      {roleValidate === 'ROLE_BUYER' && (
        <p className="property-offer-message">
          The Listing Agent or their Buyer Agent team will represent you in this transaction.
        </p>
      )}
      <div className="property-offer-card">
        <Formik
          enableReinitialize={true}
          initialValues={isEntity ? initEntityValues : initPersonalValues}
          validationSchema={isEntity ? EntitySchema : PersonalSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <Card title={isEntity ? 'Entity Details' : 'Buyer Details'}>
                <Switch
                  titleOne="Entity"
                  titleTwo="Personal name"
                  itemActive={isEntity}
                  setItemActive={setIsEntity}
                  disabled={disabledSwitch}
                />
                {isEntity ? (
                  <div>
                    <div className="row">
                      <div className="form-group  col-12 col-md-4">
                        <label className="form-label">Entity Name</label>
                        <Field className="form-control" type="text" name="entityName" />
                        {errors.entityName && touched.entityName && (
                          <div className="error">{errors.entityName}</div>
                        )}
                      </div>
                      <div className="form-group  col-12 col-md-4">
                        <label className="form-label">Authorized Signer Full Name</label>
                        <Field className="form-control" type="text" name="authorizedName" />
                        {errors.authorizedName && touched.authorizedName && (
                          <div className="error">{errors.authorizedName}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-2">
                        <label className="form-label">Phone Number</label>
                        <Field
                          name="phone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="phone"
                              placeholder=""
                              type="text"
                              className="form-control"
                            />
                          )}
                        />
                        {errors.phone && touched.phone && (
                          <div className="error">{errors.phone}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-2">
                        <label className="form-label">Email</label>
                        <Field className="form-control" type="text" name="email" />
                        {errors.email && touched.email && (
                          <div className="error">{errors.email}</div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 col-md-4">
                        <label className="form-label">Mailing Address</label>
                        <Field className="form-control" type="text" name="mailingAddress" />
                        {errors.mailingAddress && touched.mailingAddress && (
                          <div className="error">{errors.mailingAddress}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-4">
                        <label className="form-label">City</label>
                        <Field className="form-control" type="text" name="city" />
                        {errors.city && touched.city && <div className="error">{errors.city}</div>}
                      </div>
                      <div className="form-group col-12 col-md-2">
                        <label className="form-label">State</label>
                        <Field className="form-control" as="select" name="state" custom>
                          <option>Select</option>
                          {states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.code}
                            </option>
                          ))}
                        </Field>
                        {errors.state && touched.state && (
                          <div className="error">{errors.state}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-2">
                        <label className="form-label">Zip Code</label>
                        <Field className="form-control" type="text" name="zipCode" />
                        {errors.zipCode && touched.zipCode && (
                          <div className="error">{errors.zipCode}</div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="form-group col-12 col-md-4">
                        <label className="form-label">Name</label>
                        <Field className="form-control" type="text" name="buyerName" />
                        {errors.buyerName && touched.buyerName && (
                          <div className="error">{errors.buyerName}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-4">
                        <label className="form-label">Phone Number</label>
                        <Field
                          name="buyerPhone"
                          render={({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={phoneNumberMask}
                              id="buyerPhone"
                              placeholder=""
                              type="text"
                              className="form-control"
                            />
                          )}
                        />
                        {errors.phone && touched.phone && (
                          <div className="error">{errors.phone}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-4">
                        <label className="form-label">Email Address</label>
                        <Field className="form-control" type="text" name="buyerEmail" />
                        {errors.buyerEmail && touched.buyerEmail && (
                          <div className="error">{errors.buyerEmail}</div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-12 col-md-4">
                        <label className="form-label">Mailing Address</label>
                        <Field className="form-control" type="text" name="mailingAddress" />
                        {errors.mailingAddress && touched.mailingAddress && (
                          <div className="error">{errors.mailingAddress}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-4">
                        <label className="form-label">City</label>
                        <Field className="form-control" type="text" name="city" />
                        {errors.city && touched.city && <div className="error">{errors.city}</div>}
                      </div>
                      <div className="form-group col-12 col-md-2">
                        <label className="form-label">State</label>
                        <Field className="form-control" as="select" name="state" custom>
                          <option>Select</option>
                          {states.map((state) => (
                            <option key={state.id} value={state.id}>
                              {state.code}
                            </option>
                          ))}
                        </Field>
                        {errors.state && touched.state && (
                          <div className="error">{errors.state}</div>
                        )}
                      </div>
                      <div className="form-group col-12 col-md-2">
                        <label className="form-label">Zip Code</label>
                        <Field className="form-control" type="text" name="zipCode" />
                        {errors.zipCode && touched.zipCode && (
                          <div className="error">{errors.zipCode}</div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Card>

              {roleValidate === 'ROLE_BUYER_AGENT' && (
                <Card title="Buyer Agent's Details">
                  <div>
                    <div className="form-row">
                      <div className="form-group">
                        <label className="form-label">Name</label>
                        <p className="form-label">{`${user.info.firstName} ${user.info.lastName}`}</p>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Phone Number</label>
                        <p className="form-label">{`${user.info.profile.phone}`}</p>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Email Address</label>
                        <p className="form-label">{`${user.info.username}`}</p>
                      </div>
                    </div>
                  </div>
                </Card>
              )}

              <Card title="Purchase Details">
                <div>
                  <div className="form-row cols-3">
                    <div className="form-group">
                      <label className="form-label">Offer Amount</label>
                      <Field
                        name="offerAmount"
                        render={({ field }) => (
                          <CurrencyInput
                            {...field}
                            id="offerAmount"
                            placeholder="$0.00"
                            type="text"
                            className={`form-control`}
                          />
                        )}
                      />
                      {errors.offerAmount && touched.offerAmount && (
                        <div className="error">{errors.offerAmount}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">EMD (Deposit) Amount</label>
                      <Field
                        name="edmAmount"
                        render={({ field }) => (
                          <CurrencyInput
                            {...field}
                            id="edmAmount"
                            placeholder="$0.00"
                            type="text"
                            className={`form-control`}
                          />
                        )}
                      />
                      {errors.edmAmount && touched.edmAmount && (
                        <div className="error">{errors.edmAmount}</div>
                      )}
                    </div>
                    <div className="form-group" style={{ maxWidth: 250 + 'px' }}>
                      <label className="form-label">Funding</label>
                      <Field className="form-control" as="select" name="funding">
                        <option value={''}>Select</option>
                        {funding.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Field>
                      {errors.funding && touched.funding && (
                        <div className="error">{errors.funding}</div>
                      )}
                    </div>
                  </div>
                </div>
              </Card>

              <Card title="Required Documentation" loading={loading}>
                {isEntity && (
                  <div className="form-row cols-2">
                    <div className="form-group">
                      <UploaderFile
                        titleLabel="Articles of Incorporation"
                        urlApi={UPLOAD_IMAGE}
                        userToken={userToken}
                        setRoute={setArticlesOfIncorporationFile}
                        typeFile="licenses"
                        setLoading={setLoading}
                        preLoadedFile={articlesOfIncorporationFile}
                      />
                    </div>
                    <div className="form-group">
                      <UploaderFile
                        titleLabel="Operating Agreement"
                        urlApi={UPLOAD_IMAGE}
                        userToken={userToken}
                        setRoute={setOperatingAgreementFile}
                        typeFile="licenses"
                        setLoading={setLoading}
                        preLoadedFile={operatingAgreementFile}
                      />
                    </div>
                  </div>
                )}
                <div className="form-row cols-2">
                  <div className="form-group">
                    <UploaderFile
                      titleLabel="Proof of Funds"
                      urlApi={UPLOAD_IMAGE}
                      userToken={userToken}
                      setRoute={setProofOfFundsFile}
                      typeFile="licenses"
                      setLoading={setLoading}
                      preLoadedFile={proofOfFundsFile}
                    />
                  </div>
                  <div className="form-group">
                    <UploaderFile
                      titleLabel="Pre-Approval Letter (Non-Cash Offers)"
                      urlApi={UPLOAD_IMAGE}
                      userToken={userToken}
                      setRoute={setPreApprovalLetterFile}
                      typeFile="licenses"
                      setLoading={setLoading}
                      preLoadedFile={preApprovalLetterFile}
                    />
                  </div>
                </div>
                <p>*Not required but recommended for offer approval</p>
              </Card>

              <Card title="Additional Terms">
                <div>
                  <div className="form-row cols-2">
                    <div className="form-group">
                      <label className="form-label">Loan Contingency</label>
                      <div className="form-inner-row">
                        <Field className="form-control" type="text" name="loanContingency" />
                        <span>days</span>
                      </div>
                      {errors.loanContingency && touched.loanContingency && (
                        <div className="error">{errors.loanContingency}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Inspection Contingency</label>
                      <div className="form-inner-row">
                        <Field className="form-control" type="text" name="inspectionContingency" />
                        <span>days</span>
                      </div>
                      {errors.inspectionContingency && touched.inspectionContingency && (
                        <div className="error">{errors.inspectionContingency}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Appraisal Contingency</label>
                      <div className="form-inner-row">
                        <Field className="form-control" type="text" name="appraisalContingency" />
                        <span>days</span>
                      </div>
                      {errors.appraisalContingency && touched.appraisalContingency && (
                        <div className="error">{errors.appraisalContingency}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label">Home Warranty</label>
                      <div className="form-inner-row">
                        <span>$</span>
                        <Field className="form-control" type="text" name="homeWarranty" />
                        <span>contributed by seller</span>
                      </div>
                      {errors.homeWarranty && touched.homeWarranty && (
                        <div className="error">{errors.homeWarranty}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group">
                      <label className="form-label">
                        Additional Notes to Listing Agent about offer
                      </label>
                      <Field
                        className="form-control"
                        type="text"
                        as="textarea"
                        name="additionalNotes"
                      />
                      {errors.additionalNotes && touched.additionalNotes && (
                        <div className="error">{errors.additionalNotes}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row cols-1-2">
                    {roleValidate === 'ROLE_BUYER' ? (
                      ''
                    ) : (
                      <div className="form-group">
                        <label className="form-label">
                          Percentage of Buyer side Commission given to Listing Agent as Referral Fee
                        </label>
                        <Field
                          className="form-control"
                          as="select"
                          name="commissionOfferedToListing"
                        >
                          <option>Select</option>
                          <OptionsValue />
                        </Field>
                        {errors.commissionOfferedToListing &&
                          touched.commissionOfferedToListing && (
                            <div className="error">{errors.commissionOfferedToListing}</div>
                          )}
                      </div>
                    )}
                    {roleValidate === 'ROLE_BUYER_AGENT' && (
                      <div className="form-group">
                        <label className="form-label floating-text">
                          Amount contributed to Buyer's Agent as a Consulting Fee at closing{' '}
                          <img src={iconoInfo} />
                          <span>
                            OMB Buyers' Agents, per their Buyer's approval, may indicate to the
                            Listing Agent in their offer a dollar amount that their client, the
                            Buyer, will compensate them at closing in the form of a consulting fee
                            payment. This allows OMB Buyers' Agents to contribute a portion or all
                            of their Buyer side commissions to the Listing Agent as a referral fee.
                          </span>
                        </label>
                        <Field
                          name="amountGivenToBuyersAgent"
                          render={({ field }) => (
                            <CurrencyInput
                              {...field}
                              id="amountGivenToBuyersAgent"
                              placeholder="$0.00"
                              type="text"
                              className={`form-control`}
                            />
                          )}
                        />
                        {errors.amountGivenToBuyersAgent && touched.amountGivenToBuyersAgent && (
                          <div className="error">{errors.amountGivenToBuyersAgent}</div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
              <div className="btn-container">
                <Button color="yellow" type="submit" disabled={btnDisabled}>
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  ) : null}
                  SUBMIT
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {showCheck && (
          <ModalMessage
            title="Offer Submitted"
            message="Your offer has been submitted successfully."
            btnTitle="Return"
            redirectSearchListing={true}
          />
        )}
        {showError && (
          <ModalMessage
            title="Error With Your Offer"
            message="Your offer could not be submitted. Please review your information and try again. "
          />
        )}
        {showBuyerAgentNotExist && (
          <ModalMessage
            title="Offer Error"
            message="Looks like your Buyer's Agent is not an OMB member. Please have them join OMB in order to represent you in this offer submission."
          />
        )}
        {showQuestionsModal && buyersProfiles.length > 0 && (
          <Confirm
            show={showQuestionsModal}
            onHide={handleHideModal}
            no={handleHideModal}
            yes={handleShowModalBuyersProfile}
            title="Use Saved Buyer?"
            message="Would you like to use a saved buyer profile to complete this offer?"
          />
        )}
        {showModalBuyersProfile && (
          <Confirm
            show={showModalBuyersProfile}
            onHide={() => {
              setShowModalBuyersProfile(false);
            }}
            no={() => {
              setShowModalBuyersProfile(false);
              handleHideModal();
            }}
            MessageBtnOne="Cancel"
            MessageBtnTwo="Continue"
            loading={loading}
            yes={handleConfirmUser}
            title="Choose Saved Buyer Profile"
            message=""
            btnDisabled={btnModalContinue}
          >
            <div className="col-md-6">
              <select
                value={buyersProfilesSelect}
                onChange={handleChangeSelectBuyerProfile}
                className="form-control"
                as="select"
                id="buyersProfiles"
                name="buyersProfiles"
              >
                <option value={''}>Select</option>
                {buyersProfiles?.map((buyer, i) => {
                  return (
                    <option value={buyer.idOffer} key={i}>
                      {buyer.offerorName}
                    </option>
                  );
                })}
              </select>
            </div>
          </Confirm>
        )}
        {showModalSaveProfile && (
          <Confirm
            show={showModalSaveProfile}
            onHide={handleHideSaveProfileModal}
            no={handleHideSaveProfileModal}
            yes={handleSaveProfileModal}
            title="Save Buyer Profile"
            message="Would you like to save this buyer's profile to use in future offers?"
          />
        )}
      </div>
    </>
  );
};

export default PropertyOfferCards;
