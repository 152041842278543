import React, { useEffect, useState } from 'react';
import Button from '../Button/Button';
import { Search } from '..';
import bulkImport from '../../../assets/iconos/bulk-import-marketing.svg';
import './styles.css';
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { getApi, GET_UTILS } from '../../../utils/services';

const HeaderBtn = ({
  exportItemsSelected,
  itemsSelected,
  handleDownload,
  searchItem,
  setShowModal,
  showButtons = true,
  showBtnCreateCode = false,
  showBtnAddSponsorship = false,
  showFilterState = false,
  stateSelect = '',
  setStateSelect,
  callList,
  loading = false,
}) => {
  const { roleActive } = useSelector((store) => store.user);
  let [states, setStates] = useState([]);
  const userToken = localStorage.getItem('tokenOMB');

  // console.log(stateSelect);
  const callEndPointUtils = () => {
    getApi(GET_UTILS, userToken)
      .then((response) => {
        const { estate } = response.data;
        setStates(estate);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  useEffect(() => {
    if(showButtons){
      callEndPointUtils()
    }
  }, [showButtons])
  return (
    <div className="content-header mb-4">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8 d-md-flex align-items-center">
          {showButtons ? (
            <>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                  <Button
                    color="light-blue"
                    style={{ width: '100%', height: 'auto', minHeight: '40px' }}
                    onClick={exportItemsSelected}
                    disabled={itemsSelected.length === 0 ? true : false}
                  >
                    EXPORT DATA
                  </Button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-2">
                  <Button
                    color="light-blue"
                    style={{ width: '100%', minHeight: '40px' }}
                    onClick={() => setShowModal(true)}
                  >
                    <div className="content-icon">
                      <img src={bulkImport} className="icon" />
                    </div>
                  </Button>
                </div>
                <div className="col-sm-12 col-md-12 col-lg-6">
                  <Button
                    color="yellow"
                    style={{ width: '100%', height: 'auto', minHeight: '40px' }}
                    onClick={handleDownload}
                  >
                    DOWNLOAD TEMPLATE
                  </Button>
                </div>
              </div>
            </>
          ) : (
            ''
          )}
          {showFilterState ? (
            <>
              <div className="col-sm-12 col-md-12 col-lg-3 ml-4">
                <select
                  className="form-control"
                  as="select"
                  id="state"
                  name="state"
                  value={stateSelect}
                  onChange={(e) => setStateSelect(e.target.value)}
                >
                  <option value={''}>Select</option>
                  {states?.map((state, i) => {
                    return (
                      <option value={state.code} key={i}>
                        {state.code}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-2">
                <Button onClick={callList} disabled={stateSelect === '' ? true : false}>
                  {loading ? (
                    <Spinner animation="border" role="status" size="sm" className="mr-2" />
                  ) : (
                    ''
                  )}
                  Search
                </Button>
              </div>
            </>
          ) : (
            ''
          )}
          {showBtnCreateCode ? (
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <Button color="light-blue" to={`/${roleActive}/create-code`}>
                  + Create New Code
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
          {showBtnAddSponsorship ? (
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12">
                <Button color="light-blue" to={`/${roleActive}/add-sponsorship`}>
                  + Add New
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <Search
            placeholder="Search"
            showIcon={false}
            handleChange={(e) => searchItem(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderBtn;
