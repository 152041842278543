import React from 'react';
import { ListOffersProperty } from '../../components/OMB';

const OffersProperty = ({ propertyID, userID }) => {
  return (
    <div>
      <ListOffersProperty propertyID={propertyID} userID={userID} />
    </div>
  );
};

export default OffersProperty;
