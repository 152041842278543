import React, { useState, useEffect } from 'react';
import { Button, Confirm, ModalViewOffer } from '../..';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import {
  STATUS_PROPERTY,
  SEND_MESSAGE,
  SAVE_LIKE,
  postApi,
  LIST_BUYERS_AGENTS,
  getApi,
  ADD_POINT_PURCHASE,
  GET_USER,
  STATUS_APROVE_PROPERTY,
} from '../../../../utils/services';
import { useFormatPrice } from '../../../../hooks/useFormatPrice';
import { deleteProperty } from '../../../../redux/PropertyDucks';
import Like from './like.svg';
import NoLike from './no-like.svg';
import './styles.css';
import { useParams } from 'react-router';

const Right = ({
  contentType,
  data,
  type,
  setShowModalMarketing,
  showModalMarketing,
  setPropertyAddressID
}) => {
  const { id: profileId } = useParams();
  const userToken = localStorage.getItem('tokenOMB');
  const { roleActive, info } = useSelector((store) => store.user);
  const {
    id: userId,
    profile: { wholesaler: wholesalerStore },
    membershipStatus,
  } = info;
  const [roleWithSuper, setRoleWithSuper] = useState('');
  const [wholesaler, setWholesaler] = useState(wholesalerStore);
  const { savedProperties } = useSelector((store) => store.property);
  const [formattedPrice] = useFormatPrice(data.price);
  const [liked, setLiked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [showMarkAsPurchased, setShowMarkAsPurchased] = useState(false);
  const [showChooseOfferor, setShowChooseOfferor] = useState(false);
  const [offerorIsNotOnList, setOfferorIsNotOnList] = useState(false);
  const [statusProperty, setStatusProperty] = useState(null);
  const [statusPropertyList, setStatusPropertyList] = useState(null);
  const dispatch = useDispatch();
  const { id: propertyId, address } = data;

  const titleAddress = `${address?.street}, ${address?.unit ? `unit ${address?.unit}` + ',' : ''} ${
    address?.city
  }, ${address?.estate?.code}, ${address?.zipCode}`;
  const message =
    "I am interested in your Listing. Please update your listing with the current List Price. If you don't have one yet, please message me back with your Suggested Price if you have one. Thanks and have a great day.";
  const [offerors, setOfferors] = useState([]);
  const [offerorNameWrite, setOfferorNameWrite] = useState('');
  const [offerorNameSelect, setOfferorNameSelect] = useState('');
  const [IdProperty, setIdProperty] = useState(null);
  const [showPopopMessage, setShowPopopMessage] = useState(false);
  const [activeAddPoint, setActiveAddPoint] = useState(false);
  const [options, setOptions] = useState([
    { value: 1, label: 'COMING SOON' },
    { value: 2, label: 'LISTED' },
    { value: 3, label: 'PENDING' },
    { value: 4, label: 'SOLD' },
    { value: 5, label: 'POST SALE' },
    { value: 6, label: 'BUY IT NOW', isDisabled: !wholesaler },
  ]);
  const urlAddPoint = `${ADD_POINT_PURCHASE}${parseInt(offerorNameSelect)}/point/${userId}`;
  const [showModalOffer, setShowModalOffer] = useState(false);
  const [offerId, setOfferId] = useState(null);
  const [callInfo, setCallInfo] = useState(true);
  const [loadingAprove, setLoadingAprove] = useState(false);
  const addPoint = async () => {
    await getApi(urlAddPoint, userToken)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          console.log(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en right:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (activeAddPoint) {
      addPoint();
    }
  }, [activeAddPoint]);

  useEffect(() => {
    if (data.property?.id === undefined) {
      setIdProperty(propertyId);
    } else {
      setIdProperty(data.property.id);
    }
  }, [propertyId]);

  const callListBuyersAgents = async () => {
    await getApi(LIST_BUYERS_AGENTS, userToken)
      .then((response) => {
        const data = response.data;
        if (response.status === 200) {
          setOfferors(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en right:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!showMarkAsPurchased) {
      callListBuyersAgents();
    }
  }, [showMarkAsPurchased]);

  useEffect(() => {
    if (data) {
      if (data?.statusProperty) {
        setSelectedOption(data?.statusProperty[0]?.status?.id);
      }
      if (data?.property) {
        setSelectedOption(data?.property.statusProperty[0]?.status?.id);
        setStatusProperty(
          data?.property.statusProperty[data?.property.statusProperty.length - 1].status.id,
        );
      }
    }
  }, [data]);

  useEffect(() => {
    if (savedProperties.some((e) => e.id === propertyId)) {
      setLiked(true);
    }
  }, [savedProperties]);

  let color =
    selectedOption === 1
      ? '#4fc9ef'
      : selectedOption === 2
      ? '#459628'
      : selectedOption === 3
      ? '#FECC00'
      : selectedOption === 4
      ? '#DE3C3C'
      : selectedOption === 5
      ? '#E87F31'
      : selectedOption === 6
      ? '#00C698'
      : '#1a2353';

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: color,
    }),
  };

  const OfferorSchema = Yup.object().shape({
    offeror: Yup.string(),
    offerorNotList: Yup.number(),
  });

  const markPurshased = () => {
    setShowMarkAsPurchased(true);
  };

  const changeState = (value) => {
    const dataForm = {
      property: {
        id: IdProperty,
      },
      status: {
        id: value,
      },
    };

    postApi(STATUS_PROPERTY, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          setRefresh(true);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (refresh !== false) {
      window.location.reload();
    }
  }, [refresh]);

  const handleLike = () => {
    setLiked(!liked);
    const dataForm = {
      property: {
        id: propertyId,
      },
      user: {
        id: parseInt(profileId) || userId,
      },
    };

    postApi(SAVE_LIKE, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          if (!liked) {
            setShowPopopMessage(true);
            setTimeout(() => {
              setShowPopopMessage(false);
            }, 6000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    changeState(selectedOption.value);
  };

  const handleSubmit = () => {
    const dataForm = {
      message,
      typeMessage: {
        id: 2,
      },
      user: {
        id: parseInt(profileId) || userId,
      },
      conversation: {
        property: {
          id: propertyId,
        },
      },
    };

    postApi(SEND_MESSAGE, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            setShowModal(false);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!data.statusProperty) {
      setStatusPropertyList(data?.statusProperty[data?.statusProperty.length - 1].status.id);
    }
  }, [data]);

  const handleRedirectHelpUs = () => {
    window.location.href = `/${roleActive}/help-us-improve`;
  };

  const handleModalForListPrice = () => {
    setShowModal(true);
  };

  const callInfoUser = async () => {
    const urlApi = `${GET_USER}${profileId}`;

    await getApi(urlApi, userToken)
      .then((response) => {
        if (response.status === 200) {
          if (!!response.data) {
            if (roleWithSuper === '') {
              setRoleWithSuper(response.data.userRole[0]?.role?.role);
            }
            const { wholesaler } = response.data.profile;
            const statusWholesaler = !!wholesaler ? wholesaler : false;
            setWholesaler(statusWholesaler);
            setOptions([
              { value: 1, label: 'COMING SOON' },
              { value: 2, label: 'LISTED' },
              { value: 3, label: 'PENDING' },
              { value: 4, label: 'SOLD' },
              { value: 5, label: 'POST SALE' },
              { value: 6, label: 'BUY IT NOW', isDisabled: !statusWholesaler },
            ]);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (!!profileId && roleActive === 'ROLE_ADMIN') {
      setCallInfo(false);
    }
  }, []);

  useEffect(() => {
    if (callInfo && !!profileId) {
      callInfoUser();
    }
  }, [callInfo]);

  const handleShowModal = (id) => {
    setOfferId(id);
    setShowModalOffer(true);
  };

  const changeStatusProperty = async (propertyID) => {
    console.log('aprove', propertyID);
    const URL = `${STATUS_APROVE_PROPERTY}${propertyID}`;
    setLoadingAprove(true);
    await getApi(URL, userToken)
      .then((response) => {
        setLoadingAprove(false);
        if (response.status === 200) {
          window.location.reload();
        }
      })
      .catch((error) => {
        setLoadingAprove(false);
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de error en statusaproveproperty:', error.response.status);
        }
      });
  };

  const handleMarketing = () => {
    setShowModalMarketing(!showModalMarketing);
    setPropertyAddressID(propertyId);
  }

  return (
    <>
      <div className="right">
        {contentType === 'UNO' && !!data.price && (
          <>
            {statusPropertyList === 5 ||
            (statusPropertyList === 1 && roleActive === 'ROLE_BUYER') ||
            statusPropertyList === 5 ||
            (statusPropertyList === 1 && roleActive === 'ROLE_BUYER_AGENT') ? (
              <div className="suggested-price">
                <p>Suggested Price:</p>
                <span className="bold">{formattedPrice}</span>
              </div>
            ) : (
              <div className="price">
                <p>
                  Price: <span className="bold">{formattedPrice}</span>
                </p>
              </div>
            )}
          </>
        )}
        {contentType === 'UNO' && !data.price && membershipStatus && (
          <Button color="yellow" onClick={handleModalForListPrice}>
            Contact for list price
          </Button>
        )}
        {(contentType === 'UNO' &&
          !data.price &&
          membershipStatus === false &&
          roleActive === 'ROLE_BUYER_AGENT') ||
        (contentType === 'UNO' &&
          !data.price &&
          membershipStatus === false &&
          roleActive === 'ROLE_BUYER') ? (
          <Button color="yellow" to={`/${roleActive}/make-payment`}>
            Contact for list price
          </Button>
        ) : (
          ''
        )}
        {contentType === 'DOS' && (
          <>
            {/* Modal de showMarckAsPurchased */}
            <Confirm
              show={showMarkAsPurchased}
              onHide={() => {
                setShowMarkAsPurchased(false);
              }}
              no={() => {
                setShowMarkAsPurchased(false);
                handleRedirectHelpUs();
              }}
              yes={() => {
                setShowMarkAsPurchased(false);
                setShowChooseOfferor(true);
              }}
              title="Mark As Purchased"
              message="Was this property Sold to an OMB Member?"
            />
            {/* End Modal de showMarckAsPurchased */}
            {/* Modal de showChooseOfferor */}
            <Confirm
              show={showChooseOfferor}
              onHide={() => {
                setShowChooseOfferor(false);
              }}
              no={() => {
                setShowChooseOfferor(false);
              }}
              yes={() => {
                setShowChooseOfferor(false);
                setActiveAddPoint(true);
                changeState(4);
              }}
              title="Choose Offeror"
              message=""
            >
              <>
                <div className="col-md-4">
                  <select
                    className="form-control"
                    as="select"
                    id="offeror"
                    name="offeror"
                    value={offerorNameSelect}
                    onChange={(e) => setOfferorNameSelect(e.target.value)}
                  >
                    <option value={''}>Select</option>
                    {offerors?.map((offeror, i) => {
                      return (
                        <option value={offeror.idUser} key={i}>
                          {offeror.names}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-6 mt-4 d-flex align-items-center">
                  <input
                    id="radioOfferor"
                    type="checkbox"
                    value={offerorIsNotOnList} // could be something else for output?
                    checked={offerorIsNotOnList}
                    onChange={() => setOfferorIsNotOnList(!offerorIsNotOnList)}
                    className="checkboxOfferor"
                  />
                  <label htmlFor="radioOfferor" className="labelOfferor">
                    Offeror is not on this list
                  </label>
                </div>
                {offerorIsNotOnList ? (
                  <div className="col-md-4 mt-4">
                    <input
                      className="form-control"
                      type="text"
                      name="offeror"
                      placeholder="Enter Offeror’s Name"
                      value={offerorNameWrite}
                      onChange={(e) => setOfferorNameWrite(e.target.value)}
                    />
                  </div>
                ) : null}
              </>
            </Confirm>
            {/* End Modal de showChooseOfferor */}
            {roleActive === 'ROLE_LISTING_AGENT' && statusProperty !== 4 ? (
              <Button color="light-blue" onClick={markPurshased}>
                MARK AS PURCHASED
              </Button>
            ) : (
              ''
            )}

            {roleActive === 'ROLE_BUYER_AGENT' ||
            roleActive === 'ROLE_BUYER' ||
            roleWithSuper === 'ROLE_BUYER_AGENT' ||
            roleWithSuper === 'ROLE_BUYER' ? (
              <Button color="yellow" to={`/${roleActive}/property-offer-details/${data.id}`}>
                View More
              </Button>
            ) : (
              ''
            )}

            {(data.viewStatus === true && roleActive === 'ROLE_LISTING_AGENT') ||
            (data.viewStatus === true && roleWithSuper === 'ROLE_LISTING_AGENT') ? (
              <Button color="yellow" to={`/${roleActive}/property-offer-details/${data.id}`}>
                View More
              </Button>
            ) : (
              ''
            )}

            {(data.viewStatus === false && roleActive === 'ROLE_LISTING_AGENT') ||
            (data.viewStatus === false && roleWithSuper === 'ROLE_LISTING_AGENT') ? (
              <Button color="light-blue" onClick={() => handleShowModal(data.id)}>
                New Offers
              </Button>
            ) : (
              ''
            )}

            <ModalViewOffer
              offerId={offerId}
              offer={data}
              showModal={showModalOffer}
              setShowModal={setShowModalOffer}
            />
          </>
        )}
        {contentType === 'CUATRO' && type !== 'admin' && (
          <div className="content-right">
            <Select
              styles={colourStyles}
              // value={ props.selectedOption}
              defaultValue={options[data.statusProperty[0].status.id - 1]}
              onChange={handleChange}
              options={options}
              isOptionDisabled={(option) => option.isDisabled}
            />
            <div className="price">
              {statusPropertyList === 5 ||
              (statusPropertyList === 1 && roleActive === 'ROLE_LISTING_AGENT') ? (
                <div className="suggested-price">
                  <p>Suggested Price:</p>
                  <span className="bold">{formattedPrice}</span>
                </div>
              ) : (
                <div className="price">
                  <p>Price:</p>
                  <span className="bold">{formattedPrice}</span>
                </div>
              )}
            </div>
            <div className="botonera">
              <button
                className="btn-delete"
                onClick={() => {
                  setShowDelete(true);
                }}
              >
                DELETE
              </button>
              {data?.statusProperty[data?.statusProperty.length - 1].status.id === 4 ? (
                ''
              ) : (
                <button
                  className="btn-edit"
                  onClick={() => {
                    let id = data.id;
                    window.location.href =
                      roleActive === 'ROLE_ADMIN'
                        ? '/' + roleActive + '/properties/edit-property/' + id + '/' + profileId
                        : '/' + roleActive + '/properties/edit-property/' + id;
                  }}
                >
                  EDIT DETAILS
                </button>
              )}
            </div>
          </div>
        )}

        {contentType !== 'DOS' && contentType !== 'CUATRO' && (
          <>
            {showPopopMessage ? (
              <div className="popop-message">
                <p>Property saved to my properties</p>
              </div>
            ) : null}

            <div className="btn-like" onClick={handleLike}>
              {liked ? <img src={Like} /> : <img src={NoLike} />}
            </div>
          </>
        )}
        {type === 'admin' && (
          <div className="content-right">
            <Select
              styles={colourStyles}
              // value={ props.selectedOption}
              defaultValue={options[data.statusProperty[0].status.id - 1]}
              onChange={handleChange}
              options={options}
              isOptionDisabled={(option) => option.isDisabled}
            />
            <div className="price">
              {statusPropertyList === 5 ||
              (statusPropertyList === 1 && roleActive === 'ROLE_LISTING_AGENT') ? (
                <div className="suggested-price">
                  <p>Suggested Price:</p>
                  <span className="bold">{formattedPrice}</span>
                </div>
              ) : (
                <div className="price">
                  <p>Price:</p>
                  <span className="bold">{formattedPrice}</span>
                </div>
              )}
            </div>
            <div className="botonera">
              {data.statusApproved ? (
                <button
                  className="btn-marketing"
                  onClick={handleMarketing}
                >
                  MARKETING
                </button>
              ) : null}
              <button
                className={
                  data?.statusApproved ? 'btn-approval desapproval' : 'btn-approval approval'
                }
                onClick={() => changeStatusProperty(IdProperty)}
              >
                {loadingAprove ? (
                  <Spinner animation="border" role="status" size="sm" className="mr-2" />
                ) : null}
                {data?.statusApproved ? 'UNPUBLISH' : 'PUBLISH'}
              </button>
            </div>
          </div>
        )}
      </div>
      {contentType === 'UNO' && (
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Message Listing Agent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input type="text" className="form-control" value={titleAddress} disabled />
            </div>
            <div className="form-group">
              <textarea
                name="textarea"
                className="form-control"
                rows="6"
                value={message}
                disabled
              />
              {!!success && <span className="text-success">Message sent succesfully</span>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="btn-gray" onClick={() => setShowModal(false)}>
              CANCEL
            </Button>
            <Button onClick={handleSubmit}>SEND</Button>
          </Modal.Footer>
        </Modal>
      )}
      {contentType === 'CUATRO' && (
        <Confirm
          show={showDelete}
          onHide={() => {
            setShowDelete(false);
          }}
          no={() => {
            setShowDelete(false);
          }}
          yes={() => {
            setShowDelete(false);
            dispatch(deleteProperty(data.id, userId));
          }}
          title="Are you sure?"
          message="Are you sure you want to delete this property? This action cannot be undone. "
        />
      )}
    </>
  );
};

export default Right;
