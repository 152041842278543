import React, { useState, useEffect } from 'react';
import { Card, Loading, EmptyList, DTable } from '../../../components/OMB';
import { getApi, LIST_SPONSORED_OPORTUNITIES } from '../../../utils/services';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import "./styles.css"

const Opportunities = () => {
  const userToken = localStorage.getItem('tokenOMB');
  const {
    roleActive
  } = useSelector((store) => store.user);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const history = useHistory();

  useEffect(() => {
    callEndPoints();
  }, []);

  const callEndPoints = async () => {
    await getApi(LIST_SPONSORED_OPORTUNITIES, userToken)
      .then((response) => {
        setOpportunities(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    const dataOpportunities = opportunities.map(
      ({ idUser, names, userType, offerWithin30, areaBusiness, ratting }) => {
        const areas = areaBusiness?.join(', ');
        return {
          id: idUser,
          names,
          userType,
          offerWithin30,
          areas,
          rating: ratting,
          icon: '>',
          handleClick: () => history.push(`/${roleActive}/sponsor-member/${idUser}`),
        };
      },
    );
    setData(dataOpportunities);
  }, [opportunities]);

  const columns = [
    {
      name: 'Name',
      selector: 'names',
    },
    {
      name: 'User Type',
      selector: 'userType',
    },
    {
      name: 'Offers Within 30 Days',
      selector: 'offerWithin30',
    },
    {
      name: 'Areas Of Business',
      selector: 'areas',
    },
    {
      name: 'Scorecard',
      selector: 'rating',
    },
    {
      selector: 'icon',
      right: true,
      width: '50px',
    },
  ];

  return (
    <div className="sponsorship-opportunities">
      <p className="alert-message">To <span className="bold">add new Sponsorship Opportunities</span> to a sponsor’s dashboard, <span className="bold">go to the Marketing tab in the menu.</span></p>
      <Card title="Sponsorship Opportunities">
        {loading ? (
          <Loading />
        ) : opportunities.length === 0 ? (
          <EmptyList />
        ) : (
              <DTable columns={columns} data={data} />
            )}
      </Card>
    </div>
  );
};

export default Opportunities;
