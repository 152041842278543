import React, { useState, useEffect } from 'react';
import { Card, ContentTab, ItemTab } from '../../../components/OMB';

const PermissionsCard = ({ typeSelected }) => {
  const [activeKey, setActiveKey] = useState('members');

  useEffect(() => {

    if (!typeSelected) {
      return;
    };

    let activeTab;

    switch (typeSelected) {
      case '1':
      case '9':
        activeTab = 'members';
        break;
      case '8':
        activeTab = 'referrals';
        break;
      case '6':
        activeTab = 'accounting';
        break;
      case '7':
        activeTab = 'marketing';
        break;
      default:
        activeTab = 'members';
        break;
    };

    setActiveKey(activeTab);
  }, [typeSelected]);

  if (!typeSelected) {
    return null;
  };

  const membersDisabled = typeSelected === '1' || typeSelected === '9' ? false : true;
  const referralsDisabled = typeSelected === '1' || typeSelected === '8' ? false : true;
  const accountingDisabled = typeSelected === '1' || typeSelected === '6' ? false : true;
  const marketingDisabled = typeSelected === '1' || typeSelected === '7' ? false : true;

  return (
    <Card title="Admin Permissions">
      <ContentTab activeKey={activeKey} onSelect={(k) => setActiveKey(k)} id="uncontrolled-tab-example">
        <ItemTab eventKey="members" title="Members" disabled={membersDisabled}>
          <ul>
            <li>Login to Member account</li>
            <li>Login/Edit Member account (profile only)</li>
            <li>Login/Edit Member account (profile, properties)</li>
            <li>Login/Edit Member account (profile, properties, offers, referrals) *All member functionality</li>
          </ul>
        </ItemTab>
        <ItemTab eventKey="referrals" title="Referrals" disabled={referralsDisabled}>
          <ul>
            <li>Login (view only)</li>
            <li>Login (edit)</li>
            <ul>
              <li>Edit within a certain percentage and super admin will be notified of any changes and who makes it</li>
              <li>Full ability to make changes and super admin still notified</li>
            </ul>
          </ul>
        </ItemTab>
        <ItemTab eventKey="accounting" title="Accounting" disabled={accountingDisabled}>
          <ul>
            <li>Login (view only)</li>
            <ul>
              <li>View only Member dues</li>
              <li>View Member dues and Totals</li>
              <li>View Member dues, Totals, and Company Gross/Net profit numbers</li>
              <li>Create Excel - notify super admin if done</li>
            </ul>
            <li>Login (edit)</li>
            <ul>
              <li>Monthly dues (edit)</li>
              <li>Capital costs (edit)</li>
              <ul>
                <li>Ability to add capital costs</li>
                <li>Ability to add and delete capital costs</li>
              </ul>
            </ul>
            <li>Expenses (add, remove, edit)</li>
          </ul>
        </ItemTab>
        <ItemTab eventKey="marketing" title="Marketing" disabled={marketingDisabled}>
          <ul>
            <li>Search options (crunching data on users, offers, etc…)</li>
            <ul>
              <li>Search Members by User Type</li>
              <ul>
                <li>Get contact data (email, phone number, IG, etc…) for members searched</li>
                <li>Have access to B and BA members Offer data</li>
                <li>Have access to LA Properties</li>
                <li>Have access to LA Properties and Offer data</li>
              </ul>
              <li>Cross reference Member and other Data</li>
              <ul>
                <li>Member and Offers</li>
                <ul>
                  <li>Member and Location of Property</li>
                  <li>Member and Offer Amount details</li>
                  <li>Member and Property Type and Property details (sq. ft. lot size, bed/bath, etc…)</li>
                </ul>
                <li>Members and Dues</li>
                <ul>
                  <li>Members and Dues amounts</li>
                  <li>Members, Dues, and Total earnings from referrals</li>
                  <li>Members and Membership expirations (marketing to get continued sign-ups or sponsorship)</li>
                  <li>Members and Dues - Total Site Gross and Net</li>
                </ul>
              </ul>
            </ul>
            <li>Sponsorship data</li>
            <ul>
              <li>Sponsor contact info</li>
              <li>Members sponsored and data</li>
              <li>Coupons and discounts</li>
              <li>Profits from sponsorship</li>
              <li>Members expiring (for renewal marketing or other sponsor marketing purposes)</li>
            </ul>
            <li>Data access</li>
            <ul>
              <li>Ability to convert to Excel - notify super admin if done</li>
            </ul>
          </ul>
        </ItemTab>
      </ContentTab>
    </Card>
  );
};

export default PermissionsCard;
