import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { domainServer } from '../connection/connection';

export const useUtils = () => {
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const [utils, setUtils] = useState([]);

  useEffect(() => {
    // dispatch(util_list());
    const token = localStorage.getItem('tokenOMB');
    fetch(domainServer + '/api/utils/list', {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      Authorization: 'Bearer ' + token,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.text().then((text) => {
            throw new Error(text);
          });
        }
      })
      .then((res) => {
        setUtils(res);
      })
      .catch((err) => {
        if (err.message == 'Network request failed') {
          err.message =
            'No pudimos conectar con el servidor, por favor revisa tu conexión e intentalo más tarde';
        }
      });
  }, []);

  return [utils];
};
