import React, { useState, useEffect } from 'react';
import { GoBack } from '../../components/OMB';
import { ReferralsDetailsCards } from '../../containers';

const ReferralsDetails = (props) => {
  const { id: referralId } = props.match.params;

  return (
    <div className="referrals-details">
      <GoBack {...props} />
      <ReferralsDetailsCards referralId={parseInt(referralId)} />
    </div>
  )
}

export default ReferralsDetails;
