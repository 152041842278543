import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import imgUpload from '../../../../assets/img/upload-image.png';
import { DatePickerField } from '../../../../components/OMB';
import MaskedInput from 'react-text-mask';
import {
  postApiFile,
  postApi,
  UPLOAD_IMAGE,
  SAVE_COUPONS,
  getApi,
  GET_UTILS,
} from '../../../../utils/services';

import './styles.css';

const CreatecuponsandDiscounts = () => {
  const { profileId } = useParams();
  const [couponType, setCouponType] = useState('discount');
  const [imageName, setImageName] = useState('Select image…');
  const userToken = localStorage.getItem('tokenOMB');
  const userData = JSON.parse(localStorage.getItem('state'));
  const userID = userData.user.info.id;
  const [category, setCategory] = useState('');
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [date, setDate] = useState(new Date());
  const [dateFormated, setDateFormated] = useState();
  const [routeImg, setRouteImg] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [typeCouponId, setTypeCouponId] = useState();
  const [percentage, setPercentage] = useState();
  const [detailsReward, setDetailsReward] = useState();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  console.log(profileId);

  let dateForm = '';
  let today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let day = today.getDate();
  if (month < 10) {
    dateForm = `${year}-0${month}-${day}`;
  } else {
    dateForm = `${year}-${month}-${day}`;
  }
  const toggleMe = (value) => {
    setCouponType(value);
  };

  useEffect(() => {
    if (couponType === 'discount') {
      setTypeCouponId(1);
    } else {
      setTypeCouponId(2);
    }
  }, [couponType]);

  const loadFile = (event) => {
    let output = document.getElementById('image-upload');
    output.src = URL.createObjectURL(event.target.files[0]);
    if (output.src) {
      let imageName = document.getElementById('upload').files[0].name;
      setImageName(imageName);
    }
    // console.log(output);
    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };

    const fd = new FormData();
    fd.append('upload', event.target.files[0]);
    fd.append('typeFile', 1);
    setLoading(true);
    postApiFile(UPLOAD_IMAGE, fd, userToken)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          const data = response.data.upload;
          setRouteImg(data);
          // console.log('respuesta de api', data);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  useEffect(() => {
    console.log(percentage)
  }, [percentage])
  useEffect(() => {
    // console.log(date)
    if (!!category && !!date && !!routeImg && !!title && !!description) {
      setDisabled(false);
    }
  }, [category, date, routeImg, title, description]);

  const callEndPointUtils = () => {
    getApi(GET_UTILS, userToken)
      .then((response) => {
        const data = response.data.categoryCoupon;
        setCategories(data);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndPointUtils();
  }, []);

  const dataForm = {
    title: title,
    description: description,
    use_date: date,
    url_image: routeImg,
    discountPercentaje: percentage || null,
    rewardDetails: detailsReward || null,
    typeCoupon: {
      id: typeCouponId,
    },
    categoryCoupon: {
      id: parseInt(category),
    },
    user: {
      id: profileId || userID,
    },
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    postApi(SAVE_COUPONS, dataForm, userToken)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          history.push('coupons-and-discounts');
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  return (
    <div className="sponsors-id">
      <div className="box-top">
        <div className="title-list sponsor-head-title text-white">Upload Logo</div>
        <div className="img-btn-box">
          <div className="box-logo">
            <img src={imgUpload} id="image-upload" alt="upload" />
          </div>
          <div className="sponsors-coupons-head">
            <div className="up-box">
              <input
                type="file"
                id="upload"
                accept="image/*"
                hidden
                onChange={(event) => {
                  loadFile(event);
                }}
              />
              <div className="filesectimg">{imageName}</div>
              <label className="label-up" for="upload">
                Upload
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-gen form-f">
        <div className="title-list sponsor-head-title text-white">Coupon Details</div>
        <form onSubmit={handleSubmit}>
          <div className="form-flex">
            <label>
              <spam className="bold">Category</spam>
              <br />
              <select
                class="select-ipt br-br"
                value={category}
                onChange={(event) => setCategory(event.target.value)}
              >
                <option>Select</option>
                {categories.map((categorie) => (
                  <option key={categorie.id} value={categorie.id}>
                    {categorie.name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              <spam className="bold">Use By Date</spam>
              <br />
              <div className="input-txt addres-input italic">
                <DatePickerField
                  minDate={today}
                  selected={date}
                  name="date"
                  dateFormat="MM/dd/yyyy"
                  onChange={(date) => setDate(date)}
                />
              </div>

              {/*  <input
                className="input-txt br-br addres-input italic"
                type="date"
                name="date"
                value={date}
                min={dateForm}
                onChange={(event) => setDate(event.target.value)}
                required
              /> */}
            </label>
            <label className="ml-3">
              <spam className="bold">Type Of Coupon a</spam>
              <br />
              <div className="flex-radio">
                <div className="select-point">
                  <div onClick={() => toggleMe('discount')} className="input-radio br-br">
                    <div className={`radio ${couponType === 'discount' ? 'active' : ''}`}></div>
                  </div>
                  <div className="bold">Discount</div>
                </div>
                <div className="select-point">
                  {' '}
                  <div onClick={() => toggleMe('reward')} className="input-radio br-br">
                    <div className={`radio ${couponType === 'reward' ? 'active' : ''}`}></div>
                  </div>
                  <div className="bold">Reward</div>
                </div>
              </div>
            </label>
            <div>
              {couponType === 'discount' && (
                <div>
                  {' '}
                  <label className="cp-tit ">
                    <spam className="bold">Discount Percentage</spam>
                    <br />
                    <input
                      className="input-txt br-br"
                      type="text"
                      name="percentage"
                      placeholder="00%"
                      value={percentage}
                      onChange={(event) => setPercentage(event.target.value)}
                    />
                    {/* <MaskedInput
                      mask={[/[1-9]/, /\d/, /\d/, /\d/, '%']}
                      placeholder="00%"
                      id="my-input-id"
                      className="input-txt br-br"
                      type="text"
                      name="percentage"
                      placeholder="00%"
                      value={percentage}
                      onChange={(event) => setPercentage(event.target.value.replace('_', ''))}
                    /> */}
                  </label>
                </div>
              )}
              {couponType === 'reward' && (
                <div>
                  <label className="cp-tit">
                    <span className="bold">Reward Details</span>
                    <br />
                    <input
                      className="input-txt br-br"
                      type="text"
                      name="name"
                      placeholder="Free Product or Service..."
                      value={detailsReward}
                      onChange={(event) => setDetailsReward(event.target.value)}
                    />
                    <span className="tooltip">Free Product or Service / $ Amount of Discount</span>
                  </label>
                </div>
              )}
            </div>
            <label>
              <br></br>
            </label>
          </div>
          <div className="form-name-description">
            <label>
              <spam className="bold"> Coupon Title</spam>
              <br />
              <input
                className="input-txt br-br"
                type="text"
                name="title"
                placeholder="Enter a Title"
                value={title}
                onChange={(event) => setTitle(event.target.value)}
              />
            </label>

            <label>
              <spam className="bold"> Description</spam>
              <br />
              <textarea
                className="input-txt br-br"
                type="text"
                name="description"
                placeholder=""
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </label>
          </div>
        </form>
      </div>

      <div className="box-btn-btn-reverse">
        <button
          className={`btn-create-membership yell-btn ${disabled ? 'disabled' : ''}`}
          onClick={handleSubmit}
        >
          {loading ? <Spinner animation="border" role="status" size="sm" className="mr-2" /> : null}
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default CreatecuponsandDiscounts;
