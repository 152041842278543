import React from 'react';
import Left from './Left/';
import Center from './Center/';
import Right from './Right/';
import './styles.css';

const PropertyCard = ({
  contentType,
  property,
  myOffers,
  type,
  showModalMarketing,
  setShowModalMarketing,
  setPropertyAddressID,
}) => {
  let statusName = null;

  if (!!property.statusProperty) {
    statusName = property.statusProperty?.slice(-1)[0].status.name;
  } else {
    statusName = property.property?.statusProperty?.slice(-1)[0].status.name;
  }

  let bgCinta =
    statusName === 'LISTED'
      ? 'cinta-listed'
      : statusName === 'COMING SOON'
      ? 'cinta-comming'
      : statusName === 'PENDING'
      ? 'cinta-pending'
      : statusName === 'SOLD'
      ? 'cinta-sold'
      : statusName === 'BUY IT NOW'
      ? 'cinta-buyit'
      : 'cinta-sale';

  return (
    <div className="property-card">
      <div className="card-left">
        <Left data={property} contentType={contentType} myOffers={myOffers} type={type} />
      </div>
      <div className="card-center">
        <Center
          data={property}
          contentType={contentType}
          myOffers={myOffers}
          type={type}
        />
      </div>
      <div className="card-right">
        <Right
          data={property}
          contentType={contentType}
          type={type}
          setShowModalMarketing={setShowModalMarketing}
          showModalMarketing={showModalMarketing}
          setPropertyAddressID={setPropertyAddressID}
        />
      </div>
      {(contentType === 'UNO' || contentType === 'TRES') && statusName && (
        <div className={`cinta ${bgCinta}`}>
          <span className="text bold">{statusName}</span>
        </div>
      )}

      {contentType === 'DOS' && statusName === 'SOLD' && (
        <div className={`cinta ${bgCinta}`}>
          <span className="text bold">{statusName}</span>
        </div>
      )}
    </div>
  );
};

export default PropertyCard;
