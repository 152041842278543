import React from 'react';
import { GoBack } from '../../components/OMB';
import { BuyersProfileForm } from "../../containers";
import './styles.css';

const BuyersProfileDetails = (props) => {
  const { id: profileId } = props.match.params;

  return (
    <div className="buyers-profile-details">
      <GoBack {...props} />
      <BuyersProfileForm profileId={profileId} />
    </div>
  )
}

export default BuyersProfileDetails;
