import React, { useState, useEffect } from 'react';
import {
  Loading,
  Card,
  EmptyList,
  DTable,
  ModalMessage,
  HeaderBtn,
  Checkbox,
  Confirm,
} from '../../../components/OMB';
import { DELETE_EMAIL_MARKETING, getApi, postApiFile, UPLOAD_CSV } from '../../../utils/services';
import { Form, Modal, Spinner } from 'react-bootstrap';
import NewEmail from '../NewEmail';
import './styles.css';
import { Edit, Trash } from '../../../components/icons';
import ModalEdit from '../ModalEdit';
import columns from '../columns';

const ListingAgentsList = ({
  listingAgents,
  setListingAgents,
  callListData,
  pagesTotal,
  setNumPage,
  numPage,
  count = 0,
  setStateListing,
  stateListing = '',
  loadingData = false
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [itemsSelected, setItemsSelected] = useState([]);
  const [itemsExport, setItemsExport] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [showModalEmail, setShowModalEmail] = useState(false);
  const { user } = JSON.parse(localStorage.getItem('state'));
  const userToken = localStorage.getItem('tokenOMB');
  const [titleModal, setTitleModal] = useState('Error Importing File');
  const [showModalId, setShowModalId] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [messageModal, setMessageModal] = useState(
    'There was an error importing your properties. Please check your file and try again.',
  );
  const [personEdit, setPersonEdit] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const selectedItem = (value) => {
    const id = parseInt(value); // id del item seleccionado
    /* const item = buyersAgents.find((buyer) => buyer.id === id);
    item.check = !item.check; */
    const item = listingAgents.map((buyer) => {
      console.log(buyer);
      if (buyer.id === id) {
        return { ...buyer, check: !buyer.check };
      } else {
        return buyer;
      }
    });
    /* const arrayFilter = buyersAgents.includes(item)
      ? buyersAgents.filter((i) => i !== item)
      : [...itemsSelected, item]; */
    const newarr = [...item];
    const itemsSelect = newarr.filter((buyer) => buyer.check === true);
    setItemsSelected(itemsSelect);
    setListingAgents(newarr);
  };

  const selectAll = () => {
    const select = listingAgents.map(
      ({ id, name, lastName, email, phone, code, zipCode, check }) => {
        return {
          id,
          name,
          lastName,
          email,
          phone,
          code,
          zipCode,
          check: !check,
        };
      },
    );
    const itemsSelect = select.filter((buyer) => buyer.check === true);
    setItemsSelected(itemsSelect);
    setData(itemsSelect);
    setListingAgents(select);
  };

  useEffect(() => {
    const rows = [];
    itemsSelected.map((item) => rows.push([`${item.name} ${item.lastName}`, item.email]));
    setItemsExport(rows);
  }, [itemsSelected]);

  useEffect(() => {
    const dataBuyers = listingAgents.map(
      ({ id, name, lastName, email, phone, code, zipCode, check = false, county, city }) => {
        return {
          id: id,
          name: `${name} ${lastName}`,
          email: (
            <p className="m-0 text-lowercase">
            {email}
            </p>
          ),
          phone: phone,
          state: code,
          zipCode: zipCode,
          county: county,
          city: city,
          action: (
            <div className="d-flex justify-content-center align-items-center">
              {/* <Checkbox id={id} checked={check} handleChange={selectedItem} /> */}
              <div onClick={() => handleShowModal(id)} className="ml-2">
                <Trash />
              </div>
            </div>
          ),
          icon: '',
          handleClick: () => handleShowModalEdit(id),
        };
      },
    );
    setData(dataBuyers);
  }, [listingAgents]);

  const handleDownload = (e) => {
    e.preventDefault();
    window.location.href = 'https://offmarketbroker.com/assets/BulkOmbEmail.xlsx';
  };

  const loadFile = (event) => {
    setLoading(true);
    var output = document.getElementById('custom-file');
    output.src = URL.createObjectURL(event.target.files[0]);
    const urlApiUpload = `${UPLOAD_CSV}`;

    output.onload = function () {
      URL.revokeObjectURL(output.src); // free memory
    };

    const fd = new FormData();
    fd.append('file', event.target.files[0]);
    // fd.append('idUser', userID);

    postApiFile(urlApiUpload, fd, userToken)
      .then((response) => {
        setLoading(false);
        setShowModal(false);
        setShowModalMessage(true);
        if (response.status === 200) {
          setTitleModal('Import Successful');
          setMessageModal('Leads imported successfully.');
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código de Error de uploader file:', error.response.status);
        }
      });
  };

  const searchItem = async (value) => {
    const wanted = await !!value ? value.trim().toLowerCase() : null;
    const result = await
      value.length >= 2
        ? listingAgents.filter(
            (item) =>
              item.name?.trim().toLowerCase().includes(wanted) ||
              item.lastName?.trim().toLowerCase().includes(wanted) ||
              item.email?.trim().toLowerCase().includes(wanted) ||
              item.phone?.trim().toLowerCase().includes(wanted) ||
              item.code?.trim().toLowerCase().includes(wanted) ||
              item.county?.trim().toLowerCase().includes(wanted) ||
              item.city?.trim().toLowerCase().includes(wanted) ||
              item.zipCode?.trim().toLowerCase().includes(wanted),
          )
        : null;
    if (!!result &&  result.length >= 1) {
      setListingAgents(result);
    }

    if(result === null && wanted === null) {
      await callListData();
    }
  };

  const exportItemsSelected = () => {
    let csv = 'Name,Email\n';
    itemsExport.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';

    //provide the name for the CSV file to be downloaded
    hiddenElement.download = 'data.csv';
    hiddenElement.click();
  };

  const handleShowModal = (id) => {
    console.log(id);
    setShowModalId(id);
    setShowModalDelete(true);
  };

  const handleHideModalDelete = () => {
    setShowModalId(null);
    setShowModalDelete(false);
  };

  const handleDelete = () => {
    setLoadingDelete(true);
    const URL = `${DELETE_EMAIL_MARKETING}${showModalId}`;
    getApi(URL, userToken)
      .then((response) => {
        if (response.status === 200) {
          console.log('exito');
          callListData();
          setLoadingDelete(false);
          setShowModalDelete(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
          setLoadingDelete(false);
          setShowModalDelete(false);
        }
      });
  };

  const handleShowModalEdit = (id) => {
    const filter = listingAgents.filter((item) => item.id === id);
    setPersonEdit(filter);
    setShowModalEdit(true);
  };

  return (
    <>
      <HeaderBtn
        exportItemsSelected={exportItemsSelected}
        itemsSelected={itemsSelected}
        handleDownload={handleDownload}
        searchItem={searchItem}
        setShowModal={setShowModal}
        showButtons={false}
        stateSelect={stateListing}
        setStateSelect={setStateListing}
        callList={callListData}
        showFilterState={true}
        loading={loadingData}
      />
      <div className="listingAgents-list">
        <Card
          title={`Listing Agent's (${!!count ? count : 0})`}
          /*  gridBtns={true}
          btnTitle="NEW EMAIL"
          btnColor="light-blue"
          btnHandle={() => setShowModalEmail(true)}
          btnTwoTitle="SELECT ALL"
          btnTwoHandle={selectAll}
          disabledBtn={itemsSelected.length === 0 ? true : false} */
        >
          {loading ? (
            <Loading />
          ) : listingAgents.length === 0 ? (
            <EmptyList />
          ) : (
            <DTable
              columns={columns}
              data={data}
              setNumPage={setNumPage}
              pagesTotal={pagesTotal}
              setNumPage={setNumPage}
              numPage={numPage}
            />
          )}
        </Card>
      </div>
      <Modal show={showModal} centered size="md" onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="d-flex align-items-center">
              <span className="mr-2">Upload File</span>
              {loading ? (
                <Spinner animation="border" role="status" size="sm" className="mr-2" />
              ) : null}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <Form.File
                id="custom-file"
                label="Select Files..."
                accept=".xlsx"
                onChange={(event) => {
                  event.persist();
                  loadFile(event);
                }}
                custom
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {showModalMessage && (
        <ModalMessage
          title={titleModal}
          message={messageModal}
          btnTitle="OK"
          redirect="true"
          type="message"
        />
      )}
      <NewEmail
        type="listingAgents"
        showModal={showModalEmail}
        setShowModal={setShowModalEmail}
        itemsSelected={itemsSelected}
      />
      {showModalDelete && (
        <Confirm
          loading={loadingDelete}
          show={showModalDelete}
          onHide={handleHideModalDelete}
          no={handleHideModalDelete}
          yes={() => handleDelete()}
          title="Delete"
          message="Are you sure you wish to remove this email?"
        />
      )}
      <ModalEdit
        personEdit={personEdit}
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        callListData={callListData}
      />
    </>
  );
};

export default ListingAgentsList;
