import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Login from './views/user/login/Login';
import AdminLayout from './layouts/Admin.jsx';

import { Provider } from 'react-redux';
import generateStore from './redux/store';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PK } from "./utils/constants";

const stripePromise = loadStripe(STRIPE_PK);

function isLoggedIn() {
  var token = localStorage.getItem('tokenOMB');

  if (token !== '' && token !== null && token !== undefined) {
    return true;
  } else {
    return false;
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() === true ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export const App = () => {
  const store = generateStore();

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/login" render={(props) => <Login {...props}/>} />
          <Route path="/recover/:token" render={(props) => <Login {...props}/>} />

          <PrivateRoute path="/" component={(props) => 
            <Elements stripe={stripePromise}>
              <AdminLayout {...props} />
            </Elements>}
          />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
