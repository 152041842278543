import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { list_admins } from '../redux/adminDucks';

export const useAdmins = () => {
  const { admins, loading } = useSelector((store) => store.admin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(list_admins());
  }, []);

  return [admins, loading];
};
