import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, PropertyImg } from '../..';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { postApi, SEND_MESSAGE } from '../../../../utils/services';
import './styles.css';

const Left = ({ contentType, data, myOffers, type }) => {
  const { id: profileId } = useParams();
  const userToken = localStorage.getItem('tokenOMB');
  const {
    roleActive,
    info: { id: userId, membershipStatus },
  } = useSelector((store) => store.user);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageCopyAndPast, setErrorMessageCopyAndPast] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [statusProperty, setStatusProperty] = useState(null);
  const { id: propertyId, address, statusApproved } = data;
  let paths = window.location.pathname.split('/');
  // console.log(paths[2]);

  const userViewId = paths[3];
  const titleAddress = `${address?.street}${address?.unit ? ',' : ''} ${
    address?.unit ? `unit ${address?.unit}` + ',' : ''
  } ${address?.city}, ${address?.estate?.code}, ${address?.zipCode}`;
  let urlImage = !!data.propertyImage?.length ? data.propertyImage[0].urlImage : null;
  // console.log('id de la propiedad', propertyId)

  if (data.property) {
    urlImage = !!data.property.propertyImage?.length
      ? data.property.propertyImage[0].urlImage
      : null;
  }
  const copyAndPaste = (e) => {
    e.preventDefault();
    setErrorMessageCopyAndPast(!errorMessageCopyAndPast);
  };
  const handleMessage = (msg) => {
    if (!msg) {
      setErrorMessageCopyAndPast(false);
    }
    if (error && !!message) {
      setError(false);
    }
    const regEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
    const testEmail = regEmail.test(msg.replace(/\s+/g, ''));
    const regPhone = /^[(]?\d{3}[)]?[(\s)?.-]\d{3}[\s.-]\d{4}$/g;
    const testPhone = regPhone.test(msg);
    const regPhoneLimited = /[0-9]{6}/;
    const testPhoneLimited = regPhoneLimited.test(msg);

    if (testEmail || testPhone || testPhoneLimited) {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      setMessage(msg);
    }
  };

  const handleSubmit = () => {
    if (!message) {
      return setError(true);
    }

    const dataForm = {
      message,
      typeMessage: {
        id: 2,
      },
      user: {
        id: parseInt(profileId) || userId,
      },
      conversation: {
        property: {
          id: propertyId,
        },
      },
    };

    postApi(SEND_MESSAGE, dataForm, userToken)
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          setTimeout(() => {
            setShowModal(false);
          }, 2000);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('Error');
        }
      });
  };

  useEffect(() => {
    if (data?.statusProperty) {
      setStatusProperty(data?.statusProperty[data?.statusProperty.length - 1].status.id);
    }
  }, [data]);

  return (
    <>
      <div className={type === 'admin' && roleActive === 'ROLE_ADMIN' ? 'left-admin' : 'left'}>
        {myOffers ? (
          <PropertyImg
            urlImage={urlImage}
            handleClick={() => console.log('IMAGE')}
            profileId={profileId}
          />
        ) : paths[2] === 'properties' ? <PropertyImg
              urlImage={urlImage}
              handleClick={() => console.log('IMAGE')}
              class="property-admin"
              role={roleActive}
              profileId={profileId}
            /> : (
          <Link
            to={
              
              roleActive === 'ROLE_ADMIN'
                ? `/${roleActive}/property-details/${propertyId}/${profileId}`
                : (membershipStatus === false && roleActive === 'ROLE_BUYER_AGENT') ||
                  (membershipStatus === false && roleActive === 'ROLE_BUYER')
                ? `/${roleActive}/make-payment`
                : `/${roleActive}/property-details/${propertyId}`
            }
          >
            <PropertyImg
              urlImage={urlImage}
              handleClick={() => console.log('IMAGE')}
              class="property-admin"
              role={roleActive}
              profileId={profileId}
            />
          </Link>
        )}
        {contentType === 'UNO' && (
          <>
            {(membershipStatus === false && roleActive === 'ROLE_BUYER_AGENT') ||
            (membershipStatus === false && roleActive === 'ROLE_BUYER') ? (
              <Button color="gray-link" to={`/${roleActive}/make-payment`}>
                Message agent
              </Button>
            ) : (
              <Button
                color="gray"
                onClick={() => setShowModal(true)}
                disabled={statusProperty === 4 ? true : false}
              >
                Message agent
              </Button>
            )}

            <Button
              color="light-blue"
              to={
                (membershipStatus === false && roleActive === 'ROLE_BUYER_AGENT') ||
                (membershipStatus === false && roleActive === 'ROLE_BUYER')
                  ? `/${roleActive}/make-payment`
                  : `/${roleActive}/property-offer/${propertyId}/${profileId || ''}`
              }
              disabled={statusProperty === 4 ? true : false}
            >
              Submit offer
            </Button>
          </>
        )}
        {contentType === 'CUATRO' && type !== 'admin' && (
          <>
            <Button
              color="light-blue"
              to={
                roleActive === 'ROLE_ADMIN'
                  ? `/${roleActive}/properties/offers-property/${propertyId}/${userViewId}`
                  : `/${roleActive}/properties/offers-property/${propertyId}`
              }
            >
              VIEW OFFERS
            </Button>
          </>
        )}
        {
          (type =
            'admin' && roleActive === 'ROLE_ADMIN' && profileId === undefined ? (
              <div className={statusApproved ? 'flag approved' : 'flag pending'}>
                {statusApproved ? 'PUBLISHED' : 'PENDING TO PUBLISH'}
              </div>
            ) : (
              ''
            ))
        }
      </div>
      {contentType === 'UNO' && (
        <Modal
          show={showModal}
          onShow={() => error && setError(false)}
          onHide={() => setShowModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">Message Listing Agent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input type="text" className="form-control" value={titleAddress} disabled />
            </div>
            <div className="form-group">
              <textarea
                name="textarea"
                className="form-control"
                rows="6"
                value={message}
                onChange={(e) => handleMessage(e.target.value)}
                placeholder="Enter your message here..."
                onCopy={(e) => copyAndPaste(e)}
                onPaste={(e) => copyAndPaste(e)}
                onCut={(e) => copyAndPaste(e)}
              />
              {!!error && <span className="text-danger">Message required</span>}
              {!!errorMessage && (
                <span className="text-danger">
                  Your message cannot contain email addresses or phone numbers.
                </span>
              )}
              {!!errorMessageCopyAndPast && (
                <span className="text-danger">
                  Copying, cutting or pasting text is not allowed.
                </span>
              )}
              {!!success && <span className="text-success">Message sent succesfully</span>}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="btn-gray" onClick={() => setShowModal(false)}>
              CANCEL
            </Button>
            <Button onClick={handleSubmit} disabled={errorMessage ? true : false}>
              SEND
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Left;
