import React, { useEffect, useState } from 'react';
import './switch-role.css';
import { User } from '../../icons';
import { useSelector } from 'react-redux';
import Confirm from '../Modal/ModalConfirm';
import { ModalMessage } from '..';
import { ADD_NEW_USER_ROLE, getApi, GET_USER, GET_UTILS, postApi } from '../../../utils/services';
import { useListStates } from '../../../hooks/useListStates';

const SwitchRole = () => {
  const user = useSelector((store) => store.user);
  const userID = user.info.id;
  const userToken = localStorage.getItem('tokenOMB');
  const ROLE_USER = user.roleActive;
  const [showModalOne, setShowModalOne] = useState(false);
  const [showModalTwo, setShowModalTwo] = useState(false);
  const [showModalThree, setShowModalThree] = useState(false);
  const [titleBecomeModal, setTitleBecomeOne] = useState('Become a Buyer’s Agent');
  const [titleModalThree, setTitleModalThree] = useState('Registration Complete!');
  const [messageModalOne, setMessageModalOne] = useState(
    'Are you also a Buyer’s Agent? Would you like to have access to all of OMB’s inventory to do more deals?',
  );
  const [messageModalTwo, setMessageModalTwo] = useState(
    'Enter the information below to become a Buyer Agent on OMB.',
  );
  const [messageModalThree, setMessageModalThree] = useState(
    'Your registration has been completed successfully. You will be redirected to the login screen.',
  );
  const [brokerageName, setBrokerageName] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [stateIssuanceSelect, setStateIssuanceSelect] = useState('');
  const [states] = useListStates();
  const [roleUsersArray, setRoleUsersArray] = useState([]);

  const callEndpointUser = async () => {
    const urlApi = `${GET_USER}${userID}`;
    await getApi(urlApi, userToken)
      .then((response) => {
        if (response.status === 200) {
          if (!!response.data.userRole) {
            setRoleUsersArray(response.data.userRole);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    callEndpointUser();
  }, []);

  const handleChange = () => {
    setShowModalOne(true);
    if (ROLE_USER === 'ROLE_BUYER_AGENT') {
      setTitleBecomeOne('Become a Listing Agent');
      setMessageModalOne('Do you List Bank Owned Properties? List them now for free on OMB.');
      setMessageModalTwo('Enter the information below to become a Listing Agent on OMB.');
    }
  };
  const handleModalOne = () => {
    setShowModalOne(false);
    setShowModalTwo(true);
  };
  const handleSingUp = () => {
      changeRole();
  };

  const logout = () => {
    sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/login';
  }

  const changeRole = async () => {
    const data = {
      idUser: userID,
      idRole: ROLE_USER === 'ROLE_LISTING_AGENT' ? 3 : 2,
      estate: ROLE_USER === 'ROLE_LISTING_AGENT' ? 0 : stateIssuanceSelect,
      brokerageName: ROLE_USER === 'ROLE_LISTING_AGENT' ? brokerageName : '',
    };
    console.log('dat', data);
    await postApi(ADD_NEW_USER_ROLE, data, userToken)
      .then((response) => {
        // setLoading(false);
        //console.log(response);
        //console.log('data:', data);
        // console.log('respuesta de add role', response.status);
        if (response.status === 200) {
          setShowModalTwo(false);
          setShowModalThree(true);
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };

  useEffect(() => {
    if (brokerageName.length === 0 || brokerageName.length < 3) {
      setDisabledBtn(true);
    } else {
      setDisabledBtn(false);
    }
    if (ROLE_USER === 'ROLE_BUYER_AGENT') {
      if (stateIssuanceSelect === '' || stateIssuanceSelect === null) {
        setDisabledBtn(true);
      } else {
        setDisabledBtn(false);
      }
    }
  }, [brokerageName, stateIssuanceSelect]);

  return (
    <>
      {ROLE_USER === 'ROLE_LISTING_AGENT' || ROLE_USER === 'ROLE_BUYER_AGENT' ? (
        <div className="widget-listing" onClick={roleUsersArray.length === 2 ? logout : handleChange}>
          <span className="circle" />
          <span className="text">
            {roleUsersArray.length === 2
              ? ROLE_USER === 'ROLE_LISTING_AGENT'
                ? 'BUYER AGENT DASHBOARD'
                : 'LISTING AGENT DASHBOARD'
              : ROLE_USER === 'ROLE_LISTING_AGENT'
              ? 'BECOME A BUYER AGENT'
              : 'BECOME A LISTING AGENT'}
          </span>
        </div>
      ) : null}
      {showModalOne && (
        <Confirm
          show={showModalOne}
          onHide={() => setShowModalOne(false)}
          no={() => setShowModalOne(false)}
          yes={handleModalOne}
          title={titleBecomeModal}
          message={messageModalOne}
          MessageBtnOne="CANCEL"
          MessageBtnTwo="SIGN UP"
        />
      )}
      {showModalTwo && (
        <Confirm
          show={showModalTwo}
          onHide={() => setShowModalTwo(false)}
          no={() => setShowModalTwo(false)}
          yes={handleSingUp}
          title={titleBecomeModal}
          message={messageModalTwo}
          btnDisabled={disabledBtn}
          MessageBtnOne="CANCEL"
          MessageBtnTwo="SIGN UP"
        >
          <>
            <div className="col-md-6 p-0">
              {ROLE_USER === 'ROLE_LISTING_AGENT' ? (
                <>
                  <label htmlFor="radioOfferor" className="labelName">
                    Brokerage Name
                  </label>
                  <input
                    min="3"
                    className="form-control"
                    type="text"
                    name="brokerageName"
                    placeholder="Enter Name"
                    value={brokerageName}
                    onChange={(e) => setBrokerageName(e.target.value)}
                  />{' '}
                </>
              ) : (
                <>
                  <label htmlFor="radioOfferor" className="labelName">
                    Real Estate License State Of Issuance
                  </label>
                  <select
                    className="form-control"
                    as="select"
                    id="stateIssuance"
                    name="stateIssuance"
                    value={stateIssuanceSelect}
                    onChange={(e) => setStateIssuanceSelect(e.target.value)}
                  >
                    <option value={''}>Select</option>
                    {states?.map((state, i) => {
                      return (
                        <option value={state.id} key={i}>
                          {state.code}
                        </option>
                      );
                    })}
                  </select>
                </>
              )}
            </div>
          </>
        </Confirm>
      )}
      {showModalThree ? (
        <ModalMessage
          title={titleModalThree}
          message={messageModalThree}
          btnTitle="OK"
          redirect="true"
          redirectLogin="true"
          type="message"
        />
      ) : null}
    </>
  );
};

export default SwitchRole;
