import {
  AddProperty,
  EditProperty,
  Offers,
  OfferDetails,
  AgentDetails,
  Properties,
  MembersLists,
  Referrals,
  Accounting,
  Sponsorships,
  AddSponsorship,
  CreateCode,
  ReferralsDetails,
  AddTransactionFee,
  AddExpenses,
  MembershipDetails,
  SponsorshipDetails,
  ListingAgentDetails,
  BuyerAgentDetails,
  BuyerDetails,
  SponsorDetails,
  SponsorMember,
  Admins,
  CodeSearch,
  OffersAdmin,
  AddAdmin,
  MyChat,
  BuyersProfileDetails,
  PropertyOfferDetails,
  EditCouponsAndDiscounts,
  CreateCouponsAndDiscounts,
  BulkImport,
  AddBuyerProfile,
  InvitedMembers,
  PropertyOffer,
  PropertyDetails,
  Marketing,
  PropertiesAdmin,
} from '../views/';

export const SuperAdmin = [
  {
    path: '/members',
    name: 'Members',
    icon: 'super-admin/members.svg',
    component: MembersLists,
    hide: false,
    role: 'ROLE_ADMIN',
    primary: true,
  },
  {
    path: '/referrals',
    name: 'Referrals',
    icon: 'super-admin/referrals.svg',
    component: Referrals,
    hide: false,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/accounting',
    name: 'Accounting',
    icon: 'super-admin/accounting.svg',
    component: Accounting,
    hide: false,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/sponsorships',
    name: 'Sponsorships',
    icon: 'super-admin/sponsorships.svg',
    component: Sponsorships,
    hide: false,
    role: 'ROLE_ADMIN',
  },

  {
    path: '/marketing',
    name: 'Marketing',
    icon: 'super-admin/marketing.svg',
    component: Marketing,
    hide: false,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/properties/offers-property/:id/:userViewId',
    name: 'offers property',
    icon: 'pe-7s-graph',
    component: Offers,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/properties/offers-property/offer-details/:id',
    name: 'offers property',
    icon: 'pe-7s-graph',
    component: OfferDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/properties/my-properties',
    name: 'My properties',
    icon: 'pe-7s-graph',
    component: Properties,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/add-property/listing-agent/:id',
    name: 'Add property',
    icon: 'pe-7s-graph',
    component: AddProperty,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/properties/add-property/:id',
    name: 'My properties',
    icon: 'pe-7s-graph',
    component: AddProperty,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/properties/add-property/:id',
    name: 'Agent Details',
    icon: 'pe-7s-graph',
    component: AddProperty,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/properties/edit-property/:id/:profileId',
    name: 'My properties',
    icon: 'listing-agent/my-properties.svg',
    component: EditProperty,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/property-details/:id/:profileId',
    name: 'My Properties',
    component: PropertyDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/members/agent-details/:id',
    name: 'Agent Details',
    icon: 'pe-7s-graph',
    component: AgentDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/add-sponsorship',
    name: 'Add sponsorship',
    icon: 'pe-7s-graph',
    component: AddSponsorship,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/create-code',
    name: 'Create Code',
    icon: 'pe-7s-graph',
    component: CreateCode,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/referrals-details/:id',
    name: 'Referrals Details',
    icon: 'pe-7s-graph',
    component: ReferralsDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/add-expenses',
    name: 'Add Expenses',
    icon: 'pe-7s-graph',
    component: AddExpenses,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/add-transaction-fee',
    name: 'Add Transaction Fee',
    component: AddTransactionFee,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/membership-details/:id',
    name: 'Member Details',
    component: MembershipDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/sponsorship-details/:id',
    name: 'Sponsorship Details',
    component: SponsorshipDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/listing-agent-details/:id',
    name: 'Listing Agent Details',
    component: ListingAgentDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/buyer-agent-details/:id',
    name: 'Buyer Agent Details',
    component: BuyerAgentDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/buyer-details/:id',
    name: 'Buyer Details',
    component: BuyerDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/sponsor-details/:id',
    name: 'Sponsor Details',
    component: SponsorDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/code-search',
    name: 'Code Search',
    component: CodeSearch,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/offers',
    name: 'Offers',
    component: OffersAdmin,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/admins',
    name: 'Admins',
    component: Admins,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/admin/:id?',
    name: 'Admin',
    component: AddAdmin,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/messages/chat/:id/:profileId',
    name: 'Messages',
    component: MyChat,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/sponsor-member/:id',
    name: 'Sponsor',
    component: SponsorMember,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/buyers-profile-details/:id',
    name: "Buyers'Profile",
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: BuyersProfileDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/property-offer-details/:id',
    name: 'Offers Details',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: PropertyOfferDetails,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/coupons-and-discounts-edit/:id',
    name: 'Coupons And Discounts Edit',
    component: EditCouponsAndDiscounts,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/create-coupons-and-discounts/:profileId',
    name: 'Create Coupons And Discounts',
    component: CreateCouponsAndDiscounts,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/bulk-import/:id',
    name: 'Bulk Import',
    icon: 'listing-agent/bulk-import.svg',
    component: BulkImport,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/add-buyer-profile/:id',
    name: 'Add Buyer Profile',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: AddBuyerProfile,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/invite/invited-members/:id',
    name: 'Invite',
    icon: 'listing-agent/invite.svg',
    component: InvitedMembers,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/property-offer/:id/:profileId',
    name: 'My Properties',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: PropertyOffer,
    hide: true,
    role: 'ROLE_ADMIN',
  },
  {
    path: '/properties',
    name: 'Properties',
    icon: 'buyer-buyers-agent/search-listings.svg',
    component: PropertiesAdmin,
    hide: false,
    role: 'ROLE_ADMIN',
  },
  /* {
    path: "*",
    name: "404",
    icon: "pe-7s-graph",
    component: NotFound,
    role: "ROLE_ADMIN",
    hide:true,

  }, */
];
