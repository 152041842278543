import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { Button, ModalMessage } from '../../components/OMB';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useListStates } from '../../hooks/useListStates';
import { CODE_DISCOUNT, getApi, postApi, SAVE_EMAIL_MARKETING } from '../../utils/services';

const Schema = Yup.object().shape({
  selectedUser: Yup.number().required('Required'),
  selectedState: Yup.string().required('Required'),
  subjectEmail: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  userCode: Yup.string(),
});

const ModalMarketing = ({
  showModalMarketing,
  setShowModalMarketing,
  propertyAddress = '',
  userCode = '',
  percentageCode = 0,
  codeOwner = '',
  setPercentageCode,
}) => {
  const [loading, setLoading] = useState(false);
  const [typeUsers] = useState([
    {
      value: 2,
      type: 'Listing Agent',
    },
    {
      value: 3,
      type: 'Buyer Agent',
    },
    {
      value: 4,
      type: 'Buyer',
    },
    {
      value: 5,
      type: 'Sponsor',
    },
    /* {
      value: 10,
      type: 'Listing Agent Members',
    },
    {
      value: 11,
      type: 'Buyer Agent Members',
    },
    {
      value: 12,
      type: 'Buyer Members',
    },
    {
      value: 13,
      type: 'Sponsor Members',
    }, */
  ]);
  const [states] = useListStates();
  const [loadingDiscount, setLoadingDiscount] = useState(false);
  const [errorCodeDiscount, setErrorCodeDiscount] = useState(null);
  const userToken = localStorage.getItem('tokenOMB');
  const [userCodeVerify, setUserCodeVerify] = useState(null);
  const [showModalMessage, setShowModalMessage] = useState(false);
  const [titleModal, setTitleModal] = useState('Error Sending Email');
  const [messageModal, setMessageModal] = useState(
    'The email could not be sent. Please try again.',
  );
  const verifyCode = (code) => {
    setLoadingDiscount(true);
    // setUserCodeVerify(null)
    getApi(`${CODE_DISCOUNT}${code}`, userToken)
      .then((response) => {
        setLoadingDiscount(false);

        if (response.data) {
          setPercentageCode(response.data.percentageRecipient);
        }
        if (response.status !== 200) {
          setErrorCodeDiscount('Code Incorrect, try again.');
        } else {
          setErrorCodeDiscount(null);
        }
      })
      .catch((error) => {
        setLoadingDiscount(false);
        if (error.response) {
          console.log('error', error.response.data);
          console.log('Código:', error.response.status);
        }
      });
  };
  useEffect(() => {
    if (!!userCodeVerify) {
      verifyCode(userCodeVerify);
    }
  }, [userCodeVerify]);
  return (
    <>
      <Modal
        show={showModalMarketing}
        onHide={() => setShowModalMarketing(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">{`Marketing Property`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              selectedUser: '',
              selectedState: '',
              address: propertyAddress,
              userCode: userCode,
              subjectEmail: '',
            }}
            validationSchema={Schema}
            onSubmit={(values) => {
              setLoading(true);
              const data = {
                category: 1,
                code: values.userCode,
                selectedAll: true,
                state: values.selectedState,
                roleuser: parseInt(values.selectedUser),
                titleEmail: '',
                subject: values.subjectEmail,
                address: values.address,
              };
              postApi(SAVE_EMAIL_MARKETING, data, userToken)
                .then((response) => {
                  setLoading(false);
                  console.log(response);
                  console.log('data:', data);
                  if (response.status === 200) {
                    setShowModalMarketing(false);
                    setShowModalMessage(true);
                    setTitleModal('Email Sent');
                    setMessageModal('The email has been successfully sent.');
                  } else {
                    setShowModalMarketing(false);
                    setShowModalMessage(true);
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    console.log(error.response.data);
                    console.log('Código:', error.response.status);
                    setShowModalMarketing(false);
                    setShowModalMessage(true);
                  }
                });
            }}
          >
            {({ errors, touched, values }) => {
              console.log(values);
              if (
                !!values.userCode &&
                /* values.userCode !== userCode && */
                values.userCode.length >= 9
              ) {
                setUserCodeVerify(values.userCode);
              }
              return (
                <Form>
                  <div className="row">
                    <div className="col col-12 mb-4">
                      <label className="form-label bold">Address Property</label>
                      <Field className="form-control" type="text" name="address" />
                      {errors.address && touched.address && (
                        <div className="error">{errors.address}</div>
                      )}
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <label className="form-label bold">Select User Type</label>
                      <Field className="form-control" as="select" name="selectedUser">
                        <option value={''}>Select</option>
                        {typeUsers.map((type) => {
                          return <option value={type.value}>{type.type}</option>;
                        })}
                      </Field>
                      {errors.selectedUser && touched.selectedUser && (
                        <div className="error">{errors.selectedUser}</div>
                      )}
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <label className="form-label bold">Select State</label>
                      <Field className="form-control" as="select" name="selectedState">
                        <option value={''}>Select</option>
                        {states.map((state) => {
                          return <option value={state.code}>{state.code}</option>;
                        })}
                      </Field>
                      {errors.selectedState && touched.selectedState && (
                        <div className="error">{errors.selectedState}</div>
                      )}
                    </div>
                    <div className="col col-12 mb-4">
                      <label className="form-label bold">Email Subject</label>
                      <Field className="form-control" type="text" name="subjectEmail" />
                      {errors.subjectEmail && touched.subjectEmail && (
                        <div className="error">{errors.subjectEmail}</div>
                      )}
                    </div>
                    <div className="col-12 col-md-6 mb-4">
                      <label className="form-label bold">User Code</label>
                      <Field className="form-control" type="text" name="userCode" />
                      {loadingDiscount ? (
                        <span class="badge badge-warning mt-2">
                          <Spinner animation="border" role="status" size="sm" className="mr-2" />{' '}
                          Consulting code.
                        </span>
                      ) : (
                        ''
                      )}
                      {errors.userCode && touched.userCode && (
                        <div className="error">{errors.userCode}</div>
                      )}
                      {!!errorCodeDiscount && <div className="error">{errorCodeDiscount}</div>}
                    </div>
                    <div className="col col-12 col-md-3 mb-4 d-flex justify-content-center flex-column">
                      <label className="form-label bold">Percentage</label>
                      <span>{percentageCode}%</span>
                    </div>
                    <div className="col col-12 col-md-3 mb-4 d-flex justify-content-center flex-column">
                      <label className="form-label bold">Code Owner</label>
                      <span>{codeOwner}</span>
                    </div>
                    <div className="col col-12 d-flex mt-4 justify-content-end">
                      <Button color="btn-gray" onClick={() => setShowModalMarketing(false)}>
                        CANCEL
                      </Button>
                      <Button type="submit" color="yellow">
                        {loading ? (
                          <Spinner animation="border" role="status" size="sm" className="mr-2" />
                        ) : null}
                        SUBMIT
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button color="btn-gray" onClick={() => setShowModalMarketing(false)}>
            CANCEL
          </Button>
          <Button onClick={handleSubmit}>SEND</Button>
        </Modal.Footer> */}
      </Modal>
      {showModalMessage && (
        <ModalMessage
          title={titleModal}
          message={messageModal}
          btnTitle="OK"
          redirect="false"
          type="message"
        />
      )}
    </>
  );
};

export default ModalMarketing;
