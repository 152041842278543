import { useState, useEffect } from 'react';

export const useFormatDate = (toISOString) => {
  // console.log('toISOString' , toISOString)
  const [formatDate, setDate] = useState('');

  useEffect(() => {
    let date = new Date(toISOString);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = '0' + dt;
    }
    if (month < 10) {
      month = '0' + month;
    }
    if(!!toISOString){
      setDate(`${month}-${dt}-${year}`);
    }else {
      setDate(toISOString);
    }
  }, [toISOString]);
  return [formatDate];
};
