import React from 'react';
// import {Form} from 'react-bootstrap'
// import imgProfile from '../../assets/img/profile.jpg'
// import {Box,Button,ContentTab,ItemTab,GoBack} from '../../components/OMB'
import { ContentTab, ItemTab, GoBack } from '../../components/OMB';
//import {Edit} from '../../components/icons'
import Profile from './ViewsAgent';
import Properties from '../properties/Properties';
import Offer from '../Offer/Offer';

const AgentDetails = (props) => {
  return (
    <>
      <GoBack {...props} />
      <ContentTab defaultActiveKey="offers" id="uncontrolled-tab-example">
        <ItemTab eventKey="user-profile" title="User-profile">
          <Profile />
        </ItemTab>
        <ItemTab eventKey="properties" title="Properties">
          <Properties />
        </ItemTab>
        <ItemTab eventKey="offers" title="Offers">
          <Offer />
        </ItemTab>
        <ItemTab eventKey="invited-members" title="Invited-Members"></ItemTab>
        <ItemTab eventKey="message" title="Message"></ItemTab>
      </ContentTab>
    </>
  );
};

export default AgentDetails;
